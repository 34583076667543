import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: string; output: string; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: { [key: string]: any }; output: { [key: string]: any }; }
};

export type AcceptAllRequestsToJoinInput = {
  organizationId: Scalars['String']['input'];
};

export type AcceptRequestToJoinInput = {
  requestToJoinId: Scalars['String']['input'];
};

export enum ActivityTypes {
  Comment = 'COMMENT',
  Group = 'GROUP',
  GroupMembership = 'GROUP_MEMBERSHIP',
  OrganizationMembership = 'ORGANIZATION_MEMBERSHIP',
  PollVote = 'POLL_VOTE',
  Post = 'POST',
  Reaction = 'REACTION'
}

export type AddEmbedLoadInput = {
  organizationId: Scalars['ID']['input'];
  url: Scalars['String']['input'];
};

export type AddEntryToWaitlistInput = {
  email: Scalars['String']['input'];
  source: WaitlistEntrySource;
};

export type AddGroupMembersInput = {
  groupId: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']>;
};

export enum AddMemberPolicy {
  RequestToJoin = 'REQUEST_TO_JOIN',
  Unrestricted = 'UNRESTRICTED'
}

export type AddNewInvitedOrganizationInput = {
  domain: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type AddOrganizationInput = {
  domain: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type AddOrganizationVisitInput = {
  organizationId: Scalars['ID']['input'];
};

export type AddViewInput = {
  level: ViewLevels;
  location: ViewLocations;
  viewedId: Scalars['ID']['input'];
};

export type AdminSubscription = {
  __typename?: 'AdminSubscription';
  expiresAt: Scalars['DateTime']['output'];
  grandfathered: Scalars['Boolean']['output'];
  stripeCancelAtPeriodEnd: Scalars['Boolean']['output'];
  stripeSubscriptionStatus: Scalars['String']['output'];
  subscriptionPlan: AdminSubscriptionPlanId;
  subscriptionPrice: AdminSubscriptionPlanPriceLookupKey;
};

export enum AdminSubscriptionPlanId {
  Plus = 'PLUS',
  Pro = 'PRO'
}

export enum AdminSubscriptionPlanPriceLookupKey {
  PlusMonthlyUsd = 'PLUS_MONTHLY_USD',
  PlusYearlyUsd = 'PLUS_YEARLY_USD',
  ProMonthlyUsd = 'PRO_MONTHLY_USD',
  ProYearlyUsd = 'PRO_YEARLY_USD'
}

export type AssignRoleInput = {
  organizationId: Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type BillingDetails = {
  __typename?: 'BillingDetails';
  address: Scalars['String']['output'];
  cardBrand?: Maybe<Scalars['String']['output']>;
  cardLast4?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CheckoutSession = {
  __typename?: 'CheckoutSession';
  url: Scalars['String']['output'];
};

export type Comment = Node & {
  __typename?: 'Comment';
  content: Scalars['JSON']['output'];
  contentVersion: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  id: Scalars['ID']['output'];
  isBestAnswer: Scalars['Boolean']['output'];
  media: Array<CommentMedia>;
  reactions: Array<Reaction>;
  resources: Array<Resource>;
  type: CommentTypes;
};

export type CommentConfig = {
  __typename?: 'CommentConfig';
  maxCommentLength: Scalars['Int']['output'];
};

export type CommentMedia = Giphy | Image;

export enum CommentTypes {
  Post = 'POST'
}

export type Coordinates = {
  __typename?: 'Coordinates';
  height: Scalars['Int']['output'];
  width: Scalars['Int']['output'];
  x: Scalars['Int']['output'];
  y: Scalars['Int']['output'];
};

export type Course = Node & {
  __typename?: 'Course';
  coverId?: Maybe<Scalars['String']['output']>;
  createdBy: User;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['JSON']['output']>;
  emoji: Scalars['String']['output'];
  hidden?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  members: Array<User>;
  name: Scalars['String']['output'];
};

export type CourseCompletionPayload = Node & {
  __typename?: 'CourseCompletionPayload';
  id: Scalars['ID']['output'];
  percentage: Scalars['Int']['output'];
};

export type CourseError = {
  __typename?: 'CourseError';
  message: Scalars['String']['output'];
};

export type CourseObjectEdge = {
  __typename?: 'CourseObjectEdge';
  cursor: Scalars['String']['output'];
  node: Course;
};

export type CoursePayload = Course | CourseError;

export type CreateCommentInput = {
  content: Scalars['JSON']['input'];
  mediaIds: Array<Scalars['ID']['input']>;
  parentId: Scalars['ID']['input'];
  resources: Array<ResourceInput>;
  type: CommentTypes;
};

export type CreateCourseInput = {
  coverImageId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['JSON']['input']>;
  emoji: Scalars['String']['input'];
  menuSectionId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type CreateDirectMessageInput = {
  content: Scalars['JSON']['input'];
  mediaIds: Array<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  urls: Array<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type CreateEventInput = {
  coverImageId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['JSON']['input']>;
  endAt: Scalars['DateTime']['input'];
  location?: InputMaybe<LocationInput>;
  meetingUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  recurringCronExpression?: InputMaybe<Scalars['String']['input']>;
  recurringOccurrencesLimit?: InputMaybe<Scalars['Int']['input']>;
  startAt: Scalars['DateTime']['input'];
  timeZone: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CreateGiphyInput = {
  giphyId: Scalars['String']['input'];
  giphyMp4Url: Scalars['String']['input'];
  giphyUrl: Scalars['String']['input'];
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export type CreateGroupInput = {
  coverImageId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['JSON']['input']>;
  emoji: Scalars['String']['input'];
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  menuSectionId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  postCreationPolicy?: InputMaybe<PostCreationPolicy>;
  referenceId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateImageInput = {
  bytes: Scalars['Int']['input'];
  format: Scalars['String']['input'];
  height: Scalars['Int']['input'];
  publicId: Scalars['String']['input'];
  width: Scalars['Int']['input'];
};

export type CreateMenuItemUrlInput = {
  sectionId: Scalars['ID']['input'];
  url: Scalars['String']['input'];
};

export type CreateMenuItemWithPostInput = {
  organizationId: Scalars['ID']['input'];
  postId: Scalars['ID']['input'];
};

export type CreateMenuSectionInput = {
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type CreateOrDeleteDirectMessageReactionInput = {
  emojiUnicode: Scalars['String']['input'];
  messageId: Scalars['String']['input'];
  threadId: Scalars['String']['input'];
};

export type CreateOrDeletePostBookmarkInput = {
  postId: Scalars['ID']['input'];
};

export type CreateOrDeleteReactionInput = {
  emojiUnicode: Scalars['String']['input'];
  parentId: Scalars['ID']['input'];
  type: ReactionTypes;
};

export type CreateOrUpdateOrganizationInput = {
  addMemberPolicy?: InputMaybe<AddMemberPolicy>;
  description?: InputMaybe<Scalars['String']['input']>;
  groupCreationPolicy?: InputMaybe<GroupCreationPolicy>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  joinPolicy?: InputMaybe<JoinPolicy>;
  logoId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  shortId?: InputMaybe<Scalars['String']['input']>;
  templateReferenceId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrganizationRoleInput = {
  color?: InputMaybe<OrganizationRoleColor>;
  label: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type CreatePollInput = {
  options: Array<Scalars['String']['input']>;
  postId: Scalars['String']['input'];
};

export type CreateResourceInput = {
  expanded: Scalars['Boolean']['input'];
  url: Scalars['String']['input'];
};

export type CreateTemporaryImageInput = {
  bytes: Scalars['Int']['input'];
  format: Scalars['String']['input'];
  height: Scalars['Int']['input'];
  publicId: Scalars['String']['input'];
  token: Scalars['String']['input'];
  width: Scalars['Int']['input'];
};

export type CreateTemporaryPostInput = {
  groupId?: InputMaybe<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};

export type CreateTemporaryVideoPresignedUploadInput = {
  mirror: Scalars['Boolean']['input'];
  token: Scalars['String']['input'];
  videoType: VideoType;
};

export type CreateUserApnsPushSubscriptionInput = {
  deviceToken: Scalars['String']['input'];
};

export type CreateUserFcmPushSubscriptionInput = {
  deviceToken: Scalars['String']['input'];
};

export type CreateUserInput = {
  auth0Id?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['ID']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserWebPushSubscriptionInput = {
  endpoint: Scalars['String']['input'];
  keyAuth: Scalars['String']['input'];
  keyP256dh: Scalars['String']['input'];
};

export type CreateVideoPresignedUploadInput = {
  mirror: Scalars['Boolean']['input'];
  videoType: VideoType;
};

export type CreateVoteInput = {
  pollOptionId: Scalars['String']['input'];
};

export type CustomDomain = {
  __typename?: 'CustomDomain';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  status: CustomDomainStatus;
};

export enum CustomDomainStatus {
  Active = 'ACTIVE',
  Misconfigured = 'MISCONFIGURED',
  Unverified = 'UNVERIFIED'
}

export type CustomDomainVerification = {
  __typename?: 'CustomDomainVerification';
  domain: Scalars['String']['output'];
  reason: Scalars['String']['output'];
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type CustomDomainVerificationInfo = {
  __typename?: 'CustomDomainVerificationInfo';
  apexName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  verification: Array<CustomDomainVerification>;
  verified: Scalars['Boolean']['output'];
};

export type DeleteDirectMessageThreadInput = {
  id: Scalars['String']['input'];
};

export type DeleteEventInput = {
  eventId: Scalars['ID']['input'];
  seriesSelection?: InputMaybe<SeriesSelection>;
};

export type DeleteGroupInput = {
  id: Scalars['ID']['input'];
};

export type DeleteImageInput = {
  imageId: Scalars['ID']['input'];
};

export type DeleteMenuItemInput = {
  itemId: Scalars['String']['input'];
};

export type DeleteMenuSectionInput = {
  sectionId: Scalars['ID']['input'];
};

export type DeleteOrganizationRoleInput = {
  roleId: Scalars['ID']['input'];
};

export type DeletePollInput = {
  pollId: Scalars['String']['input'];
};

export type DeletePostInput = {
  id: Scalars['ID']['input'];
};

export type DeleteVoteInput = {
  pollOptionId: Scalars['String']['input'];
};

export type DemoteUserInput = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export enum DigestNotificationFrequency {
  Daily = 'DAILY',
  Disabled = 'DISABLED',
  Weekly = 'WEEKLY'
}

export type DirectMessage = Node & {
  __typename?: 'DirectMessage';
  createdOn: Scalars['DateTime']['output'];
  editedOn?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  media: Array<DirectMessageMedia>;
  messageId: Scalars['String']['output'];
  reactions: Array<Reaction>;
  resources: Array<Resource>;
  version: Scalars['Float']['output'];
};

export type DirectMessageMedia = Giphy | Image;

export type DirectMessageNotificationCounts = {
  __typename?: 'DirectMessageNotificationCounts';
  unread: Scalars['Float']['output'];
};

export type DirectMessageToken = {
  __typename?: 'DirectMessageToken';
  communicationUserId: Scalars['String']['output'];
  expiresOn: Scalars['DateTime']['output'];
  token: Scalars['String']['output'];
};

export type DirectMessageUser = Node & {
  __typename?: 'DirectMessageUser';
  communicationUserId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  user: User;
};

export type DirectMessageUserThread = Node & {
  __typename?: 'DirectMessageUserThread';
  createdBy: DirectMessageUser;
  id: Scalars['ID']['output'];
  lastMessage?: Maybe<Scalars['String']['output']>;
  lastMessageReceivedOn?: Maybe<Scalars['DateTime']['output']>;
  settings: DirectMessageUserThreadSettings;
  threadId: Scalars['String']['output'];
  unreadCount?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: DirectMessageUser;
};

export type DirectMessageUserThreadSettings = Node & {
  __typename?: 'DirectMessageUserThreadSettings';
  id: Scalars['ID']['output'];
  markedUnread?: Maybe<Scalars['Boolean']['output']>;
  mutedNotifications?: Maybe<Scalars['Boolean']['output']>;
};

export type DismissFrondIntroPostInput = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type DismissWelcomePostInput = {
  organizationId: Scalars['ID']['input'];
};

export type DuplicateOrganizationInput = {
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  shortId?: InputMaybe<Scalars['String']['input']>;
};

export type EmbedLoad = {
  __typename?: 'EmbedLoad';
  id: Scalars['String']['output'];
  organizationName: Scalars['String']['output'];
  shortId: Scalars['String']['output'];
  url: Scalars['String']['output'];
  widgetSizeVariant: WidgetSizeVariant;
};

export type Event = Node & {
  __typename?: 'Event';
  coverImageId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['JSON']['output']>;
  emoji: Scalars['String']['output'];
  endAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  location?: Maybe<Location>;
  meetingUrl?: Maybe<Scalars['String']['output']>;
  members: Array<User>;
  membership?: Maybe<GroupMembership>;
  name: Scalars['String']['output'];
  numMembers: Scalars['Int']['output'];
  numPosts: Scalars['Int']['output'];
  postCreationPolicy: PostCreationPolicy;
  series?: Maybe<EventSeries>;
  startAt: Scalars['DateTime']['output'];
  timeZone?: Maybe<Scalars['String']['output']>;
  type: GroupTypes;
  url?: Maybe<Scalars['String']['output']>;
};

export type EventError = {
  __typename?: 'EventError';
  message: Scalars['String']['output'];
};

export type EventObjectEdge = {
  __typename?: 'EventObjectEdge';
  cursor: Scalars['String']['output'];
  node: Event;
};

export type EventPayload = Event | EventError;

export type EventSeries = Node & {
  __typename?: 'EventSeries';
  id: Scalars['ID']['output'];
  recurringCronExpression: Scalars['String']['output'];
  recurringOccurrencesLimit?: Maybe<Scalars['Int']['output']>;
};

export type EventsConnection = {
  __typename?: 'EventsConnection';
  edges: Array<EventObjectEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum FeatureEntitlement {
  Courses = 'COURSES',
  CustomDomains = 'CUSTOM_DOMAINS',
  CustomRoles = 'CUSTOM_ROLES',
  Events = 'EVENTS',
  Insights = 'INSIGHTS',
  Polls = 'POLLS'
}

export enum FeatureQuota {
  Admins = 'ADMINS',
  ImageUploadSize = 'IMAGE_UPLOAD_SIZE',
  Members = 'MEMBERS',
  PaywallPlatformFee = 'PAYWALL_PLATFORM_FEE',
  VideoUploadSize = 'VIDEO_UPLOAD_SIZE'
}

export type FeedItem = OrganizationMembership | Post;

export type FeedItemConnection = {
  __typename?: 'FeedItemConnection';
  edges: Array<FeedItemEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type FeedItemEdge = {
  __typename?: 'FeedItemEdge';
  cursor: Scalars['String']['output'];
  node: FeedItem;
};

export type Giphy = Node & {
  __typename?: 'Giphy';
  aspectRatio: Scalars['Float']['output'];
  giphyId: Scalars['String']['output'];
  giphyMp4Url: Scalars['String']['output'];
  giphyUrl: Scalars['String']['output'];
  height: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  width: Scalars['Int']['output'];
};

export type Group = Node & {
  __typename?: 'Group';
  autoAddMembers: Scalars['Boolean']['output'];
  coverImageId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['JSON']['output']>;
  emoji: Scalars['String']['output'];
  enableNewPostNotificationsForNewMembers: Scalars['Boolean']['output'];
  hasPosts: Scalars['Boolean']['output'];
  hasUnseenPosts?: Maybe<Scalars['Boolean']['output']>;
  hidden: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  includePostsOnHome: Scalars['Boolean']['output'];
  isDraft: Scalars['Boolean']['output'];
  isPrivate?: Maybe<Scalars['Boolean']['output']>;
  lastActivityAt: Scalars['DateTime']['output'];
  members: Array<User>;
  membership?: Maybe<GroupMembership>;
  name: Scalars['String']['output'];
  numMembers: Scalars['Int']['output'];
  numPosts: Scalars['Int']['output'];
  organization: Organization;
  postCreationPolicy: PostCreationPolicy;
  questionCronExpression?: Maybe<Scalars['String']['output']>;
  questionEnabled?: Maybe<Scalars['Boolean']['output']>;
  questionText?: Maybe<Scalars['String']['output']>;
  sortIndex: Scalars['Float']['output'];
  timeZone?: Maybe<Scalars['String']['output']>;
  type: GroupTypes;
  unlockedBy?: Maybe<MemberSubscriptionPlan>;
  updatedAt: Scalars['DateTime']['output'];
  views: Array<GroupView>;
};

export enum GroupCreationPolicy {
  Administrators = 'ADMINISTRATORS',
  Unrestricted = 'UNRESTRICTED'
}

export type GroupError = {
  __typename?: 'GroupError';
  message: Scalars['String']['output'];
  reason: GroupErrorReason;
};

export enum GroupErrorReason {
  NotAllowed = 'NOT_ALLOWED',
  Paywall = 'PAYWALL',
  Private = 'PRIVATE'
}

export type GroupMembership = Node & {
  __typename?: 'GroupMembership';
  allowEventNotifications?: Maybe<Scalars['Boolean']['output']>;
  allowNewOccurrenceNotifications?: Maybe<Scalars['Boolean']['output']>;
  allowNewPostNotifications?: Maybe<Scalars['Boolean']['output']>;
  group: Group;
  id: Scalars['ID']['output'];
  membership?: Maybe<GroupMembership>;
};

export type GroupObjectEdge = {
  __typename?: 'GroupObjectEdge';
  cursor: Scalars['String']['output'];
  node: Group;
};

export type GroupPayload = Group | GroupError;

export enum GroupTypes {
  Course = 'COURSE',
  Event = 'EVENT',
  General = 'GENERAL',
  Regular = 'REGULAR',
  Welcome = 'WELCOME'
}

export type GroupView = Node & {
  __typename?: 'GroupView';
  id: Scalars['ID']['output'];
  level: ViewLevels;
  location?: Maybe<ViewLocations>;
  user: User;
};

export type GroupsConnection = {
  __typename?: 'GroupsConnection';
  edges: Array<GroupObjectEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type GroupsMenuSectionsState = {
  __typename?: 'GroupsMenuSectionsState';
  menuSectionIdsCollapsed?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type GroupsSidebarState = {
  __typename?: 'GroupsSidebarState';
  courseGroupsExpanded?: Maybe<Scalars['Boolean']['output']>;
  privateGroupsExpanded?: Maybe<Scalars['Boolean']['output']>;
  publicGroupsExpanded?: Maybe<Scalars['Boolean']['output']>;
};

export type HelloPage = Node & {
  __typename?: 'HelloPage';
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  emojiUnicodeOrCustomImageId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  helloPageBlocks: Array<HelloPageBlock>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  recipientProfileImageId?: Maybe<Scalars['String']['output']>;
  shortId: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  template: HelloPageTemplateTypes;
  title?: Maybe<Scalars['String']['output']>;
};

export type HelloPageBlock = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  sortIndex: Scalars['Int']['output'];
  type: HelloPageBlockTypes;
};

export type HelloPageBlockEmail = HelloPageBlock & Node & {
  __typename?: 'HelloPageBlockEmail';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  sortIndex: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
  type: HelloPageBlockTypes;
};

export type HelloPageBlockImage = HelloPageBlock & Node & {
  __typename?: 'HelloPageBlockImage';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  imageId: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
  type: HelloPageBlockTypes;
};

export type HelloPageBlockInternal = HelloPageBlock & Node & {
  __typename?: 'HelloPageBlockInternal';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  internalType: HelloPageBlockInternalTypes;
  sortIndex: Scalars['Int']['output'];
  type: HelloPageBlockTypes;
};

export enum HelloPageBlockInternalTypes {
  Email = 'EMAIL',
  Invite = 'INVITE',
  Record = 'RECORD',
  Resource = 'RESOURCE'
}

export type HelloPageBlockIntroduction = HelloPageBlock & Node & {
  __typename?: 'HelloPageBlockIntroduction';
  content?: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['DateTime']['output'];
  emojiUnicodeOrCustomImageId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  sortIndex: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
  type: HelloPageBlockTypes;
};

export type HelloPageBlockNote = HelloPageBlock & Node & {
  __typename?: 'HelloPageBlockNote';
  content?: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  sortIndex: Scalars['Int']['output'];
  type: HelloPageBlockTypes;
};

export type HelloPageBlockResource = HelloPageBlock & Node & {
  __typename?: 'HelloPageBlockResource';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  logoTitle?: Maybe<Scalars['String']['output']>;
  sortIndex: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
  type: HelloPageBlockTypes;
  url?: Maybe<Scalars['String']['output']>;
};

export enum HelloPageBlockTypes {
  Email = 'EMAIL',
  Image = 'IMAGE',
  Internal = 'INTERNAL',
  Introduction = 'INTRODUCTION',
  Note = 'NOTE',
  Resource = 'RESOURCE',
  Video = 'VIDEO'
}

export type HelloPageBlockVideo = HelloPageBlock & Node & {
  __typename?: 'HelloPageBlockVideo';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  sortIndex: Scalars['Int']['output'];
  type: HelloPageBlockTypes;
  user: User;
  video: Video;
};

export type HelloPageObjectEdge = {
  __typename?: 'HelloPageObjectEdge';
  cursor: Scalars['String']['output'];
  node: HelloPage;
};

export enum HelloPageTemplateTypes {
  Birthday = 'BIRTHDAY',
  Blank = 'BLANK',
  Hiring = 'HIRING',
  Welcome = 'WELCOME'
}

export type HideOrganizationVisitInput = {
  organizationId: Scalars['ID']['input'];
};

export type HideUpsellInput = {
  upsellId: Scalars['String']['input'];
};

export type Image = Node & {
  __typename?: 'Image';
  aspectRatio: Scalars['Float']['output'];
  coordinates?: Maybe<Coordinates>;
  height: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  publicId: Scalars['String']['output'];
  width: Scalars['Int']['output'];
};

export type InboxState = {
  __typename?: 'InboxState';
  showRead?: Maybe<Scalars['Boolean']['output']>;
};

export type InviteUsersInput = {
  emails: Array<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
};

export type InviteUsersPayload = RequestToJoin | RequestToJoinError | UserInvite;

export type InvitesOrderByInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InvitesPaginated = {
  __typename?: 'InvitesPaginated';
  items: Array<UserInvite>;
  totalCount: Scalars['Int']['output'];
};

export type JoinGroupInput = {
  groupId: Scalars['ID']['input'];
};

export type JoinOrganizationInput = {
  inviteCode?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export enum JoinPolicy {
  RequestToJoin = 'REQUEST_TO_JOIN',
  Restricted = 'RESTRICTED',
  Unrestricted = 'UNRESTRICTED'
}

export type LeaveOrganizationInput = {
  organizationId: Scalars['ID']['input'];
};

export type LessonCompletion = Node & {
  __typename?: 'LessonCompletion';
  id: Scalars['ID']['output'];
  user: User;
};

export type Location = Node & {
  __typename?: 'Location';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lat: Scalars['Float']['output'];
  lon: Scalars['Float']['output'];
  placeId: Scalars['String']['output'];
  type: LocationTypes;
};

export type LocationInput = {
  displayName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  lat: Scalars['Float']['input'];
  lon: Scalars['Float']['input'];
  placeId: Scalars['String']['input'];
  type: LocationTypes;
};

export enum LocationTypes {
  Custom = 'CUSTOM',
  GooglePlaces = 'GOOGLE_PLACES',
  LocationIq = 'LOCATION_IQ'
}

export type MarkDirectMessageReadInput = {
  messageId: Scalars['String']['input'];
  threadId: Scalars['String']['input'];
};

export type MemberActivityStat = Node & {
  __typename?: 'MemberActivityStat';
  activityDate: Scalars['DateTime']['output'];
  entityId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  type: ActivityTypes;
  user: User;
};

export type MemberCountStat = Node & {
  __typename?: 'MemberCountStat';
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  user: User;
};

export type MemberSubscription = {
  __typename?: 'MemberSubscription';
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  stripeCancelAtPeriodEnd: Scalars['Boolean']['output'];
  subscriptionPlan: MemberSubscriptionPlan;
};

export type MemberSubscriptionPlan = {
  __typename?: 'MemberSubscriptionPlan';
  active: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  groupEntitlements: Array<Group>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  pendingBalance: Scalars['Int']['output'];
  priceMonthlyUsd: Scalars['Float']['output'];
  stripeAccount?: Maybe<StripeAccount>;
  subscriberCount: Scalars['Int']['output'];
  totalRevenue: Scalars['Int']['output'];
};

export type MembersOrderByInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MembersPaginated = {
  __typename?: 'MembersPaginated';
  items: Array<OrganizationMembership>;
  totalCount: Scalars['Int']['output'];
};

export type MenuItem = Node & {
  __typename?: 'MenuItem';
  group?: Maybe<Group>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  post?: Maybe<Post>;
  resource?: Maybe<Resource>;
  sortIndex: Scalars['Float']['output'];
};

export type MenuSection = Node & {
  __typename?: 'MenuSection';
  id: Scalars['ID']['output'];
  menuItems: Array<MenuItem>;
  name?: Maybe<Scalars['String']['output']>;
  sortIndex: Scalars['Float']['output'];
  type: MenuSectionTypes;
};

export enum MenuSectionTypes {
  Courses = 'COURSES',
  Custom = 'CUSTOM',
  Groups = 'GROUPS'
}

export type MovePostInput = {
  groupId: Scalars['ID']['input'];
  postId: Scalars['ID']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptAllRequestsToJoin: Organization;
  acceptRequestToJoin: RequestToJoinPayload;
  addEmbedLoad: EmbedLoad;
  addEntryToWaitlist: WaitlistEntryPayload;
  addGroupMembers: GroupPayload;
  addGroupView: Group;
  addNewInvitedOrganization: Organization;
  addOrganization: Organization;
  addOrganizationVisit: Organization;
  addPostView: Post;
  assignRole: User;
  cancelAdminSubscription?: Maybe<AdminSubscription>;
  cancelMemberSubscription?: Maybe<MemberSubscription>;
  changeAdminSubscriptionPlan?: Maybe<AdminSubscription>;
  createAdminPlanCheckoutSession: CheckoutSession;
  createAdminPlanPortalSession: CheckoutSession;
  createAndJoinGroup: GroupPayload;
  createComment: Comment;
  createCourse: CoursePayload;
  createDirectMessage: DirectMessageUserThread;
  createEvent: EventPayload;
  createExpressDashboardSession: CheckoutSession;
  createGiphy: Giphy;
  createImage: Image;
  createMemberPlanCheckoutSession: CheckoutSession;
  createMemberPlanPortalSession: CheckoutSession;
  createMenuItemUrl: MenuItem;
  createMenuItemWithPost: MenuItem;
  createMenuSection: MenuSection;
  createOrDeleteDirectMessageReaction: DirectMessage;
  createOrDeletePostBookmark: PostBookmark;
  createOrDeleteReaction: Reaction;
  createOrUpdateOrganization: Organization;
  createOrganizationRole: OrganizationRole;
  createPoll: PollPayload;
  createResource: ResourcePayload;
  createStripeAccountLinkSession: CheckoutSession;
  createTemporaryImage: Image;
  createTemporaryPost: TemporaryPost;
  createTemporaryVideoPresignedUpload: VideoPresignedUpload;
  createUser: User;
  createUserAPNSPushSubscription: User;
  createUserFCMPushSubscription: User;
  createUserWebPushSubscription: User;
  createVideoPresignedUpload: VideoPresignedUpload;
  createVote: PollPayload;
  deactivateMemberSubscriptionPlan: MemberSubscriptionPlan;
  deleteComment: Comment;
  deleteDirectMessageThread: DirectMessageUserThread;
  deleteEvent: EventPayload;
  deleteGroup: GroupPayload;
  deleteImage: Image;
  deleteMenuItem: MenuItem;
  deleteMenuSection: MenuSection;
  deleteOrganization: Organization;
  deleteOrganizationRole: OrganizationRole;
  deletePoll: PollPayload;
  deletePost: PostPayload;
  deleteUser: User;
  deleteVote: PollPayload;
  demoteUser: User;
  dismissFrondIntroPost: OrganizationMembership;
  dismissWelcomePost: OrganizationMembership;
  duplicateOrganization: Organization;
  getDemoOrganization: Organization;
  hideOrganizationVisit: Organization;
  hideUpsell: UpsellPayload;
  inviteUsers: Array<InviteUsersPayload>;
  joinGroup: GroupPayload;
  joinOrganization: OrganizationJoinPayload;
  leaveOrganization: User;
  markAllNotificationsRead: NotificationCounts;
  markDirectMessageRead: DirectMessageUserThread;
  markNotificationRead: Notification;
  movePost: Post;
  profileSetup: User;
  promoteUser: User;
  publishCourse: Group;
  publishDraftPost: Post;
  reactivateMemberSubscription?: Maybe<MemberSubscription>;
  reactivateMemberSubscriptionPlan: MemberSubscriptionPlan;
  rejectRequestToJoin: RequestToJoinPayload;
  removeCustomDomain: Organization;
  removeGroupMember: GroupPayload;
  reorderGroups: GroupsConnection;
  reorderLesson: PostPayload;
  reorderMenuItems: Array<MenuItem>;
  reorderMenuSections: Array<MenuSection>;
  reorderOrganizationMembership: OrganizationMembership;
  requestToJoinOrganization: RequestToJoinPayload;
  rescheduleDraftPost: Post;
  resendUserInvite: UserInvite;
  resetOrganizationInvite: OrganizationInvite;
  restoreUser: User;
  revokeUserInvites: Array<UserInvite>;
  sendDigestToUser?: Maybe<Organization>;
  sendFeedbackEmail: User;
  sendNotificationsDigestToUser?: Maybe<Organization>;
  setCustomDomain: Organization;
  suspendUser: User;
  toggleAutoMessageNewMembers: Organization;
  toggleCommentAsBestAnswer: Comment;
  toggleEventNotifications: EventPayload;
  toggleEventPostCreationPolicy: EventPayload;
  toggleFollowPost: Post;
  toggleGroupAutoAddMembers: Group;
  toggleGroupEnableNewPostNotificationsForNewMembers: Group;
  toggleGroupIncludePostsOnHome: Group;
  toggleHideFromProfilePost: Post;
  toggleLessonCompleted: PostPayload;
  toggleNewMemberIntroductions: Organization;
  toggleOrganizationFeature: Organization;
  toggleOrganizationMembershipOnboardingTask: OrganizationMembership;
  togglePinnedPost: Post;
  togglePublicCommunity: Organization;
  toggleShowAuthorOnPost: Post;
  toggleShowCommentsAndReactionsOnPost: Post;
  toggleWelcomeGroup: Group;
  toggleWelcomePost: Post;
  unassignRole: User;
  unfurlUrl: ResourcePayload;
  updateAutoMessageNewMembersMessage: Organization;
  updateComment: Comment;
  updateDirectMessageThreadSettings: DirectMessageUserThreadSettings;
  updateEvent: EventPayload;
  updateGroup: GroupPayload;
  updateImageCoordinates: Image;
  updateMembership: GroupMembership;
  updateMenuItem: MenuItem;
  updateMenuSection: MenuSection;
  updateNewMemberIntroductionMessage: Organization;
  updateOrganizationRole: OrganizationRole;
  updateTemporaryPost: TemporaryPost;
  updateUser: User;
  updateUserDigestNotificationFrequency: UserPreferences;
  updateUserLanguage: User;
  updateUserNotificationPreferences: UserNotificationPreferences;
  updateUserOnboarding: User;
  updateWidgetSizeVariant: Organization;
  upsertMemberSubscriptionPlan: MemberSubscriptionPlan;
  upsertPost: PostPayload;
};


export type MutationAcceptAllRequestsToJoinArgs = {
  input: AcceptAllRequestsToJoinInput;
};


export type MutationAcceptRequestToJoinArgs = {
  input: AcceptRequestToJoinInput;
};


export type MutationAddEmbedLoadArgs = {
  input: AddEmbedLoadInput;
};


export type MutationAddEntryToWaitlistArgs = {
  input: AddEntryToWaitlistInput;
};


export type MutationAddGroupMembersArgs = {
  input: AddGroupMembersInput;
};


export type MutationAddGroupViewArgs = {
  input: AddViewInput;
};


export type MutationAddNewInvitedOrganizationArgs = {
  input: AddNewInvitedOrganizationInput;
};


export type MutationAddOrganizationArgs = {
  input: AddOrganizationInput;
};


export type MutationAddOrganizationVisitArgs = {
  input: AddOrganizationVisitInput;
};


export type MutationAddPostViewArgs = {
  input: AddViewInput;
};


export type MutationAssignRoleArgs = {
  input: AssignRoleInput;
};


export type MutationCancelAdminSubscriptionArgs = {
  organizationId: Scalars['ID']['input'];
};


export type MutationCancelMemberSubscriptionArgs = {
  memberSubscriptionId: Scalars['ID']['input'];
};


export type MutationChangeAdminSubscriptionPlanArgs = {
  lookupKey: AdminSubscriptionPlanPriceLookupKey;
  organizationId: Scalars['ID']['input'];
};


export type MutationCreateAdminPlanCheckoutSessionArgs = {
  lookupKey: AdminSubscriptionPlanPriceLookupKey;
  organizationId: Scalars['String']['input'];
};


export type MutationCreateAdminPlanPortalSessionArgs = {
  organizationId: Scalars['String']['input'];
  returnUrl: Scalars['String']['input'];
};


export type MutationCreateAndJoinGroupArgs = {
  input: CreateGroupInput;
};


export type MutationCreateCommentArgs = {
  input: CreateCommentInput;
};


export type MutationCreateCourseArgs = {
  input: CreateCourseInput;
};


export type MutationCreateDirectMessageArgs = {
  input: CreateDirectMessageInput;
};


export type MutationCreateEventArgs = {
  input: CreateEventInput;
};


export type MutationCreateExpressDashboardSessionArgs = {
  organizationId: Scalars['String']['input'];
};


export type MutationCreateGiphyArgs = {
  input: CreateGiphyInput;
};


export type MutationCreateImageArgs = {
  input: CreateImageInput;
};


export type MutationCreateMemberPlanCheckoutSessionArgs = {
  memberSubscriptionPlanId: Scalars['String']['input'];
  returnToGroupId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateMemberPlanPortalSessionArgs = {
  returnUrl: Scalars['String']['input'];
};


export type MutationCreateMenuItemUrlArgs = {
  input: CreateMenuItemUrlInput;
};


export type MutationCreateMenuItemWithPostArgs = {
  input: CreateMenuItemWithPostInput;
};


export type MutationCreateMenuSectionArgs = {
  input: CreateMenuSectionInput;
};


export type MutationCreateOrDeleteDirectMessageReactionArgs = {
  input: CreateOrDeleteDirectMessageReactionInput;
};


export type MutationCreateOrDeletePostBookmarkArgs = {
  input: CreateOrDeletePostBookmarkInput;
};


export type MutationCreateOrDeleteReactionArgs = {
  input: CreateOrDeleteReactionInput;
};


export type MutationCreateOrUpdateOrganizationArgs = {
  input: CreateOrUpdateOrganizationInput;
};


export type MutationCreateOrganizationRoleArgs = {
  input: CreateOrganizationRoleInput;
};


export type MutationCreatePollArgs = {
  input: CreatePollInput;
};


export type MutationCreateResourceArgs = {
  input: CreateResourceInput;
};


export type MutationCreateStripeAccountLinkSessionArgs = {
  memberSubscriptionPlanId: Scalars['String']['input'];
};


export type MutationCreateTemporaryImageArgs = {
  input: CreateTemporaryImageInput;
};


export type MutationCreateTemporaryPostArgs = {
  input: CreateTemporaryPostInput;
};


export type MutationCreateTemporaryVideoPresignedUploadArgs = {
  input: CreateTemporaryVideoPresignedUploadInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateUserApnsPushSubscriptionArgs = {
  input: CreateUserApnsPushSubscriptionInput;
};


export type MutationCreateUserFcmPushSubscriptionArgs = {
  input: CreateUserFcmPushSubscriptionInput;
};


export type MutationCreateUserWebPushSubscriptionArgs = {
  input: CreateUserWebPushSubscriptionInput;
};


export type MutationCreateVideoPresignedUploadArgs = {
  input: CreateVideoPresignedUploadInput;
};


export type MutationCreateVoteArgs = {
  input: CreateVoteInput;
};


export type MutationDeactivateMemberSubscriptionPlanArgs = {
  memberSubscriptionPlanId: Scalars['String']['input'];
};


export type MutationDeleteCommentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDirectMessageThreadArgs = {
  input: DeleteDirectMessageThreadInput;
};


export type MutationDeleteEventArgs = {
  input: DeleteEventInput;
};


export type MutationDeleteGroupArgs = {
  input: DeleteGroupInput;
};


export type MutationDeleteImageArgs = {
  input: DeleteImageInput;
};


export type MutationDeleteMenuItemArgs = {
  input: DeleteMenuItemInput;
};


export type MutationDeleteMenuSectionArgs = {
  input: DeleteMenuSectionInput;
};


export type MutationDeleteOrganizationArgs = {
  organizationId: Scalars['ID']['input'];
};


export type MutationDeleteOrganizationRoleArgs = {
  input: DeleteOrganizationRoleInput;
};


export type MutationDeletePollArgs = {
  input: DeletePollInput;
};


export type MutationDeletePostArgs = {
  input: DeletePostInput;
};


export type MutationDeleteUserArgs = {
  userEmail: Scalars['String']['input'];
};


export type MutationDeleteVoteArgs = {
  input: DeleteVoteInput;
};


export type MutationDemoteUserArgs = {
  input: DemoteUserInput;
};


export type MutationDismissFrondIntroPostArgs = {
  input: DismissFrondIntroPostInput;
};


export type MutationDismissWelcomePostArgs = {
  input: DismissWelcomePostInput;
};


export type MutationDuplicateOrganizationArgs = {
  input: DuplicateOrganizationInput;
};


export type MutationHideOrganizationVisitArgs = {
  input: HideOrganizationVisitInput;
};


export type MutationHideUpsellArgs = {
  input: HideUpsellInput;
};


export type MutationInviteUsersArgs = {
  input: InviteUsersInput;
};


export type MutationJoinGroupArgs = {
  input: JoinGroupInput;
};


export type MutationJoinOrganizationArgs = {
  input: JoinOrganizationInput;
};


export type MutationLeaveOrganizationArgs = {
  input: LeaveOrganizationInput;
};


export type MutationMarkAllNotificationsReadArgs = {
  organizationId: Scalars['String']['input'];
};


export type MutationMarkDirectMessageReadArgs = {
  input: MarkDirectMessageReadInput;
};


export type MutationMarkNotificationReadArgs = {
  input: ReadNotificationInput;
};


export type MutationMovePostArgs = {
  input: MovePostInput;
};


export type MutationProfileSetupArgs = {
  input: ProfileSetupInput;
};


export type MutationPromoteUserArgs = {
  input: PromoteUserInput;
};


export type MutationPublishCourseArgs = {
  input: PublishCourseInput;
};


export type MutationPublishDraftPostArgs = {
  input: PublishDraftPostInput;
};


export type MutationReactivateMemberSubscriptionArgs = {
  memberSubscriptionId: Scalars['ID']['input'];
};


export type MutationReactivateMemberSubscriptionPlanArgs = {
  memberSubscriptionPlanId: Scalars['String']['input'];
};


export type MutationRejectRequestToJoinArgs = {
  input: RejectRequestToJoinInput;
};


export type MutationRemoveCustomDomainArgs = {
  organizationId: Scalars['ID']['input'];
};


export type MutationRemoveGroupMemberArgs = {
  input: RemoveGroupMemberInput;
};


export type MutationReorderGroupsArgs = {
  input: ReorderGroupsInput;
};


export type MutationReorderLessonArgs = {
  input: ReorderLessonInput;
};


export type MutationReorderMenuItemsArgs = {
  input: ReorderMenuItemsInput;
};


export type MutationReorderMenuSectionsArgs = {
  input: ReorderMenuSectionsInput;
};


export type MutationReorderOrganizationMembershipArgs = {
  input: ReorderOrganizationMembershipInput;
};


export type MutationRequestToJoinOrganizationArgs = {
  input: RequestToJoinOrganizationInput;
};


export type MutationRescheduleDraftPostArgs = {
  input: RescheduleDraftPostInput;
};


export type MutationResendUserInviteArgs = {
  input: ResendUserInviteInput;
};


export type MutationResetOrganizationInviteArgs = {
  input: ResetOrganizationInviteInput;
};


export type MutationRestoreUserArgs = {
  input: RestoreUserInput;
};


export type MutationRevokeUserInvitesArgs = {
  input: RevokeUserInvitesInput;
};


export type MutationSendDigestToUserArgs = {
  userEmail: Scalars['String']['input'];
};


export type MutationSendFeedbackEmailArgs = {
  input: SendFeedbackEmailInput;
};


export type MutationSendNotificationsDigestToUserArgs = {
  userEmail: Scalars['String']['input'];
};


export type MutationSetCustomDomainArgs = {
  input: SetOrganizationCustomDomainInput;
};


export type MutationSuspendUserArgs = {
  input: SuspendUserInput;
};


export type MutationToggleAutoMessageNewMembersArgs = {
  input: ToggleAutoMessageNewMembersInput;
};


export type MutationToggleCommentAsBestAnswerArgs = {
  input: ToggleCommentAsBestAnswerInput;
};


export type MutationToggleEventNotificationsArgs = {
  input: ToggleEventNotificationsInput;
};


export type MutationToggleEventPostCreationPolicyArgs = {
  input: ToggleEventPostCreationPolicyInput;
};


export type MutationToggleFollowPostArgs = {
  input: ToggleFollowPostInput;
};


export type MutationToggleGroupAutoAddMembersArgs = {
  input: ToggleGroupAutoAddMembersInput;
};


export type MutationToggleGroupEnableNewPostNotificationsForNewMembersArgs = {
  input: ToggleGroupEnableNewPostNotificationsForNewMembersInput;
};


export type MutationToggleGroupIncludePostsOnHomeArgs = {
  input: ToggleGroupIncludePostsOnHomeInput;
};


export type MutationToggleHideFromProfilePostArgs = {
  input: ToggleHideFromProfilePostInput;
};


export type MutationToggleLessonCompletedArgs = {
  input: ToggleLessonCompletedInput;
};


export type MutationToggleNewMemberIntroductionsArgs = {
  input: ToggleNewMemberIntroductionsInput;
};


export type MutationToggleOrganizationFeatureArgs = {
  input: ToggleOrganizationFeatureInput;
};


export type MutationToggleOrganizationMembershipOnboardingTaskArgs = {
  input: ToggleOrganizationMembershipOnboardingTaskInput;
};


export type MutationTogglePinnedPostArgs = {
  input: TogglePinnedPostInput;
};


export type MutationTogglePublicCommunityArgs = {
  input: TogglePublicCommunityInput;
};


export type MutationToggleShowAuthorOnPostArgs = {
  input: ToggleShowAuthorOnPostInput;
};


export type MutationToggleShowCommentsAndReactionsOnPostArgs = {
  input: ToggleShowCommentsAndReactionsOnPostInput;
};


export type MutationToggleWelcomeGroupArgs = {
  input: ToggleWelcomeGroupInput;
};


export type MutationToggleWelcomePostArgs = {
  input: ToggleWelcomePostInput;
};


export type MutationUnassignRoleArgs = {
  input: UnassignRoleInput;
};


export type MutationUnfurlUrlArgs = {
  input: UnfurlUrlInput;
};


export type MutationUpdateAutoMessageNewMembersMessageArgs = {
  input: UpdateAutoMessageNewMembersMessageInput;
};


export type MutationUpdateCommentArgs = {
  input: UpdateCommentInput;
};


export type MutationUpdateDirectMessageThreadSettingsArgs = {
  input: UpdateDirectMessageUserThreadSettingsInput;
};


export type MutationUpdateEventArgs = {
  input: UpdateEventInput;
};


export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};


export type MutationUpdateImageCoordinatesArgs = {
  input: UpdateImageCoordinatesInput;
};


export type MutationUpdateMembershipArgs = {
  input: UpdateMembershipInput;
};


export type MutationUpdateMenuItemArgs = {
  input: UpdateMenuItemInput;
};


export type MutationUpdateMenuSectionArgs = {
  input: UpdateMenuSectionInput;
};


export type MutationUpdateNewMemberIntroductionMessageArgs = {
  input: UpdateNewMemberIntroductionMessageInput;
};


export type MutationUpdateOrganizationRoleArgs = {
  input: UpdateOrganizationRoleInput;
};


export type MutationUpdateTemporaryPostArgs = {
  input: UpdateTemporaryPostInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserDigestNotificationFrequencyArgs = {
  input: UpdateUserDigestNotificationFrequencyInput;
};


export type MutationUpdateUserLanguageArgs = {
  input: UpdateUserLanguageInput;
};


export type MutationUpdateUserNotificationPreferencesArgs = {
  input: UpdateUserNotificationPreferencesInput;
};


export type MutationUpdateUserOnboardingArgs = {
  input: UpdateUserOnboardingInput;
};


export type MutationUpdateWidgetSizeVariantArgs = {
  input: UpdateWidgetSizeVariantInput;
};


export type MutationUpsertMemberSubscriptionPlanArgs = {
  input: UpsertMemberSubscriptionPlanInput;
};


export type MutationUpsertPostArgs = {
  input: UpsertPostInput;
};

export type Node = {
  id: Scalars['ID']['output'];
};

export type Notification = Node & {
  __typename?: 'Notification';
  admin?: Maybe<User>;
  content: Scalars['JSON']['output'];
  createdAt: Scalars['DateTime']['output'];
  emojiUnicode?: Maybe<Scalars['String']['output']>;
  eventName: NotificationEventNames;
  group?: Maybe<Group>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  link: Scalars['String']['output'];
  memberSubscription?: Maybe<MemberSubscription>;
  memberSubscriptionPlan?: Maybe<MemberSubscriptionPlan>;
  otherUser?: Maybe<User>;
  quoteContent?: Maybe<Scalars['JSON']['output']>;
  read: Scalars['Boolean']['output'];
  requestToJoins: Array<RequestToJoin>;
  role?: Maybe<OrganizationRole>;
  text: Scalars['String']['output'];
  type: NotificationTypes;
  user?: Maybe<User>;
};

export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  edges: Array<NotificationObjectEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type NotificationCounts = {
  __typename?: 'NotificationCounts';
  unread: Scalars['Float']['output'];
};

export enum NotificationEventNames {
  CommentCreated = 'COMMENT_CREATED',
  CommentMentioned = 'COMMENT_MENTIONED',
  CommentParentMentioned = 'COMMENT_PARENT_MENTIONED',
  CommentReplied = 'COMMENT_REPLIED',
  EventCreated = 'EVENT_CREATED',
  EventDateChanged = 'EVENT_DATE_CHANGED',
  EventDeleted = 'EVENT_DELETED',
  EventLocationChanged = 'EVENT_LOCATION_CHANGED',
  EventReminder = 'EVENT_REMINDER',
  EventTimeChanged = 'EVENT_TIME_CHANGED',
  GroupPostCreated = 'GROUP_POST_CREATED',
  GroupPostUpdated = 'GROUP_POST_UPDATED',
  GroupQuestionOccurrence = 'GROUP_QUESTION_OCCURRENCE',
  MemberSubscriptionCanceled = 'MEMBER_SUBSCRIPTION_CANCELED',
  MemberSubscriptionCreated = 'MEMBER_SUBSCRIPTION_CREATED',
  MemberSubscriptionExpiry = 'MEMBER_SUBSCRIPTION_EXPIRY',
  MemberSubscriptionGifted = 'MEMBER_SUBSCRIPTION_GIFTED',
  MemberSubscriptionPlanPriceChanged = 'MEMBER_SUBSCRIPTION_PLAN_PRICE_CHANGED',
  NewMemberIntroduction = 'NEW_MEMBER_INTRODUCTION',
  ParentMentioned = 'PARENT_MENTIONED',
  PollVoteCreated = 'POLL_VOTE_CREATED',
  ReactionCreated = 'REACTION_CREATED',
  ReactionParentMentioned = 'REACTION_PARENT_MENTIONED',
  RequestToJoin = 'REQUEST_TO_JOIN',
  RequestToJoinInvite = 'REQUEST_TO_JOIN_INVITE',
  UserJoined = 'USER_JOINED',
  UserPromoted = 'USER_PROMOTED',
  UserRoleAssignment = 'USER_ROLE_ASSIGNMENT'
}

export type NotificationObjectEdge = {
  __typename?: 'NotificationObjectEdge';
  cursor: Scalars['String']['output'];
  node: Notification;
};

export enum NotificationPreferencesType {
  Email = 'EMAIL',
  Mobile = 'MOBILE',
  Web = 'WEB'
}

export enum NotificationTypes {
  Group = 'GROUP'
}

export type Organization = Node & {
  __typename?: 'Organization';
  activeMembers: Array<User>;
  addMemberPolicy: AddMemberPolicy;
  adminSubscription?: Maybe<AdminSubscription>;
  administrators: Array<User>;
  autoMessageNewMembersFromId?: Maybe<Scalars['String']['output']>;
  autoMessageNewMembersMessage: Scalars['String']['output'];
  color: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  customDomain?: Maybe<CustomDomain>;
  description?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  enableAutoMessageNewMembers: Scalars['Boolean']['output'];
  enableNewMemberIntroductions: Scalars['Boolean']['output'];
  entitlements?: Maybe<OrganizationEntitlements>;
  groupCreationPolicy: GroupCreationPolicy;
  groups: Array<Group>;
  hasCoursesEnabled: Scalars['Boolean']['output'];
  hasEventsEnabled: Scalars['Boolean']['output'];
  hasInsightsEnabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isGoogleWorkspace: Scalars['Boolean']['output'];
  isPublic: Scalars['Boolean']['output'];
  isSetup: Scalars['Boolean']['output'];
  joinPolicy: JoinPolicy;
  legacyShortId?: Maybe<Scalars['String']['output']>;
  logoId?: Maybe<Scalars['String']['output']>;
  memberSubscriptionPlan?: Maybe<MemberSubscriptionPlan>;
  name: Scalars['String']['output'];
  newMemberIntroductionMessage: Scalars['String']['output'];
  numGroups: Scalars['Int']['output'];
  numMembers: Scalars['Int']['output'];
  quotaUsage: Array<QuotaUsage>;
  quotas?: Maybe<Array<QuotaUsage>>;
  shortId: Scalars['String']['output'];
  templateType: OrganizationTemplateType;
  widgetSizeVariant: WidgetSizeVariant;
};

export type OrganizationConnection = {
  __typename?: 'OrganizationConnection';
  edges: Array<OrganizationObjectEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OrganizationEntitlements = {
  __typename?: 'OrganizationEntitlements';
  features: Array<FeatureEntitlement>;
  quotas: Array<QuotaEntitlements>;
};

export type OrganizationInvite = Node & {
  __typename?: 'OrganizationInvite';
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  inviteCode: Scalars['String']['output'];
};

export type OrganizationJoinError = {
  __typename?: 'OrganizationJoinError';
  message: Scalars['String']['output'];
};

export type OrganizationJoinPayload = Organization | OrganizationJoinError;

export type OrganizationMembership = Node & {
  __typename?: 'OrganizationMembership';
  active: Scalars['Boolean']['output'];
  completedOnboardingTasks: Array<OrganizationMembershipOnboardingTasks>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  hideFrondIntroPost: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isCreator: Scalars['Boolean']['output'];
  organization: Organization;
  roles: Array<OrganizationRole>;
  user: User;
};

export enum OrganizationMembershipOnboardingTasks {
  ActivateTrial = 'ACTIVATE_TRIAL',
  AddAdmins = 'ADD_ADMINS',
  AddCourses = 'ADD_COURSES',
  AddDomain = 'ADD_DOMAIN',
  AddEvents = 'ADD_EVENTS',
  AddMembers = 'ADD_MEMBERS',
  CreateRoles = 'CREATE_ROLES',
  EditPermissions = 'EDIT_PERMISSIONS',
  SetUpNewMemberOnboarding = 'SET_UP_NEW_MEMBER_ONBOARDING',
  SetUpPaywall = 'SET_UP_PAYWALL'
}

export type OrganizationObjectEdge = {
  __typename?: 'OrganizationObjectEdge';
  cursor: Scalars['String']['output'];
  node: Organization;
};

export type OrganizationRole = Node & {
  __typename?: 'OrganizationRole';
  color: OrganizationRoleColor;
  id: Scalars['ID']['output'];
  isAdmin: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  memberSubscriptionPlan?: Maybe<MemberSubscriptionPlan>;
  users: Array<User>;
};

export enum OrganizationRoleColor {
  Blue = 'BLUE',
  Gray = 'GRAY',
  Green = 'GREEN',
  Purple = 'PURPLE',
  Red = 'RED',
  Yellow = 'YELLOW'
}

export type OrganizationShortId = {
  __typename?: 'OrganizationShortId';
  shortId: Scalars['String']['output'];
};

export type OrganizationShortIdError = {
  __typename?: 'OrganizationShortIdError';
  message: Scalars['String']['output'];
};

export type OrganizationShortIdPayload = OrganizationShortId | OrganizationShortIdError;

export type OrganizationStats = Node & {
  __typename?: 'OrganizationStats';
  id: Scalars['ID']['output'];
  totalComments: Scalars['Int']['output'];
  totalCommentsInTheLast14Days: Scalars['Int']['output'];
  totalMembers: Scalars['Int']['output'];
  totalMembersInTheLast14Days: Scalars['Int']['output'];
  totalPosts: Scalars['Int']['output'];
  totalPostsInTheLast14Days: Scalars['Int']['output'];
  totalReactions: Scalars['Int']['output'];
  totalReactionsInTheLast14Days: Scalars['Int']['output'];
};

export enum OrganizationTemplateType {
  BuildTogether = 'BUILD_TOGETHER',
  ClubOrAlumniGroup = 'CLUB_OR_ALUMNI_GROUP',
  ContentCreator = 'CONTENT_CREATOR',
  Personal = 'PERSONAL',
  StartFromScratch = 'START_FROM_SCRATCH',
  Work = 'WORK'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
};

export type Poll = Node & {
  __typename?: 'Poll';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  options: Array<PollOption>;
};

export type PollError = {
  __typename?: 'PollError';
  message: Scalars['String']['output'];
};

export type PollOption = Node & {
  __typename?: 'PollOption';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  votes: Array<PollVote>;
};

export type PollPayload = Poll | PollError;

export type PollVote = Node & {
  __typename?: 'PollVote';
  createdBy: User;
  id: Scalars['ID']['output'];
};

export type Post = Node & {
  __typename?: 'Post';
  aspectRatio: Scalars['Float']['output'];
  bgColor?: Maybe<Scalars['String']['output']>;
  colorTheme: PostColorTheme;
  comments: Array<Comment>;
  completions: Array<LessonCompletion>;
  content?: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  group: Group;
  hideFromProfile: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isBookmarked: Scalars['Boolean']['output'];
  isDraft: Scalars['Boolean']['output'];
  media: Array<PostMedia>;
  numOfAnonymousViews: Scalars['Int']['output'];
  organization: Organization;
  pinned: Scalars['Boolean']['output'];
  poll?: Maybe<Poll>;
  reactions: Array<Reaction>;
  resource?: Maybe<Resource>;
  resources: Array<Resource>;
  revisions: Array<PostRevision>;
  scheduledAt?: Maybe<Scalars['DateTime']['output']>;
  showAuthor: Scalars['Boolean']['output'];
  showCommentsAndReactions: Scalars['Boolean']['output'];
  sortIndex?: Maybe<Scalars['Float']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type: PostTypes;
  updatedAt: Scalars['DateTime']['output'];
  views: Array<PostView>;
};

export type PostBookmark = Node & {
  __typename?: 'PostBookmark';
  id: Scalars['ID']['output'];
  post: Post;
};

export type PostBookmarkConnection = {
  __typename?: 'PostBookmarkConnection';
  edges: Array<PostBookmarkObjectEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PostBookmarkObjectEdge = {
  __typename?: 'PostBookmarkObjectEdge';
  cursor: Scalars['String']['output'];
  node: PostBookmark;
};

export enum PostColorTheme {
  Blue = 'BLUE',
  Gray = 'GRAY',
  Green = 'GREEN',
  Purple = 'PURPLE',
  Red = 'RED',
  Yellow = 'YELLOW'
}

export type PostConnection = {
  __typename?: 'PostConnection';
  edges: Array<PostObjectEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum PostCreationPolicy {
  Administrators = 'ADMINISTRATORS',
  Unrestricted = 'UNRESTRICTED'
}

export type PostError = {
  __typename?: 'PostError';
  message: Scalars['String']['output'];
};

export type PostMedia = Giphy | Image | Video;

export type PostObjectEdge = {
  __typename?: 'PostObjectEdge';
  cursor: Scalars['String']['output'];
  node: Post;
};

export type PostPayload = Post | PostError;

export type PostRevision = Node & {
  __typename?: 'PostRevision';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
};

export type PostSubscription = Node & {
  __typename?: 'PostSubscription';
  id: Scalars['ID']['output'];
  postId: Scalars['ID']['output'];
};

export enum PostTypes {
  Event = 'EVENT',
  Lesson = 'LESSON',
  NewUser = 'NEW_USER',
  Regular = 'REGULAR',
  Welcome = 'WELCOME'
}

export type PostView = Node & {
  __typename?: 'PostView';
  id: Scalars['ID']['output'];
  level: ViewLevels;
  location?: Maybe<ViewLocations>;
  user: User;
};

export type Price = {
  __typename?: 'Price';
  currency: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lookupKey: AdminSubscriptionPlanPriceLookupKey;
  unitAmount?: Maybe<Scalars['Float']['output']>;
};

export type PriceList = {
  __typename?: 'PriceList';
  prices: Array<Price>;
};

export type ProfileSetupInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  avatarBgColor?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  profileImageId?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
  username: Scalars['String']['input'];
};

export type PromoteUserInput = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type PublishCourseInput = {
  courseId: Scalars['ID']['input'];
};

export type PublishDraftPostInput = {
  postId: Scalars['ID']['input'];
};

export type PushEvent = PushRefetchHint;

export type PushRefetchHint = {
  __typename?: 'PushRefetchHint';
  groupId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  postId?: Maybe<Scalars['ID']['output']>;
  userId: Scalars['ID']['output'];
};

export type Query = {
  __typename?: 'Query';
  adminSubscriptionPlanPriceList: PriceList;
  allUsers: UserConnection;
  checkIfWidgetIsDetected?: Maybe<EmbedLoad>;
  comment?: Maybe<Comment>;
  commentConfig: CommentConfig;
  courseCompletion: CourseCompletionPayload;
  customDomainVerificationInfo: CustomDomainVerificationInfo;
  directMessage: DirectMessage;
  directMessageNotificationCounts: DirectMessageNotificationCounts;
  directMessageThreads: Array<DirectMessageUserThread>;
  directMessageToken: DirectMessageToken;
  directMessageUserThread?: Maybe<DirectMessageUserThread>;
  draftPosts: PostConnection;
  event: EventPayload;
  events: EventsConnection;
  group?: Maybe<GroupPayload>;
  groups: GroupsConnection;
  groupsMenuSectionsState: GroupsMenuSectionsState;
  groupsSidebarState: GroupsSidebarState;
  helloPageByShortId?: Maybe<HelloPage>;
  homeFeed?: Maybe<FeedItemConnection>;
  homeFeedLatestItem?: Maybe<FeedItem>;
  inboxState: InboxState;
  isFollowingPost?: Maybe<PostSubscription>;
  lessons: PostConnection;
  locationByName: Array<Location>;
  locationByPlaceId: Location;
  memberSubscriptionPlan: MemberSubscriptionPlan;
  members: MembersPaginated;
  membersWithHighestCommentCount: Array<MemberCountStat>;
  membersWithHighestPostCount: Array<MemberCountStat>;
  membersWithHighestReactionCount: Array<MemberCountStat>;
  menuSections: Array<MenuSection>;
  mostRecentOrganizationActivity: Array<MemberActivityStat>;
  newMemberPost?: Maybe<Post>;
  newMemberships: Array<OrganizationMembership>;
  notification: Notification;
  notificationCounts: NotificationCounts;
  notificationPreferences: Array<UserNotificationPreferences>;
  notifications: NotificationConnection;
  organization?: Maybe<Organization>;
  organizationByDomain?: Maybe<Organization>;
  organizationByInviteCode?: Maybe<Organization>;
  organizationByShortId?: Maybe<Organization>;
  organizationInvite: OrganizationInvite;
  organizationRoles: Array<OrganizationRole>;
  organizationRolesByUserId: Array<OrganizationRole>;
  organizationStats: OrganizationStats;
  organizations: OrganizationConnection;
  post?: Maybe<PostPayload>;
  postBookmarks: PostBookmarkConnection;
  postsByEvent: PostConnection;
  postsByGroup: PostConnection;
  publicOrganizationShortIds: Array<Scalars['String']['output']>;
  search?: Maybe<Array<Search>>;
  shortIdAvailable: OrganizationShortIdPayload;
  shortIdSuggestion: OrganizationShortIdPayload;
  temporaryPost?: Maybe<TemporaryPost>;
  upsells: Array<Upsell>;
  user?: Maybe<User>;
  userActivityCounts?: Maybe<UserActivityCounts>;
  userInvites: InvitesPaginated;
  userRequestToJoinOrganization?: Maybe<RequestToJoin>;
  userRequestsToJoinOrganization: RequestsPaginated;
  usernameAvailable: UsernamePayload;
  users: UserConnection;
  video?: Maybe<Video>;
  viewer: User;
  visitedPublicOrganizations: Array<Organization>;
  welcomeGroup: Group;
  welcomePost?: Maybe<PostPayload>;
};


export type QueryAllUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCheckIfWidgetIsDetectedArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryCommentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCourseCompletionArgs = {
  courseId: Scalars['ID']['input'];
};


export type QueryCustomDomainVerificationInfoArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryDirectMessageArgs = {
  id: Scalars['String']['input'];
  threadId: Scalars['String']['input'];
};


export type QueryDirectMessageNotificationCountsArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryDirectMessageThreadsArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryDirectMessageTokenArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryDirectMessageUserThreadArgs = {
  organizationId: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type QueryDraftPostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  isScheduled?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['ID']['input'];
};


export type QueryEventArgs = {
  id: Scalars['String']['input'];
};


export type QueryEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
  past?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGroupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
};


export type QueryHelloPageByShortIdArgs = {
  shortId: Scalars['String']['input'];
};


export type QueryHomeFeedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
  tag?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};


export type QueryHomeFeedLatestItemArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryIsFollowingPostArgs = {
  postId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryLessonsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  courseId: Scalars['String']['input'];
  first: Scalars['Int']['input'];
};


export type QueryLocationByNameArgs = {
  name: Scalars['String']['input'];
};


export type QueryLocationByPlaceIdArgs = {
  placeId: Scalars['String']['input'];
};


export type QueryMemberSubscriptionPlanArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMembersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MembersOrderByInput>;
  organizationId: Scalars['String']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMembersWithHighestCommentCountArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryMembersWithHighestPostCountArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryMembersWithHighestReactionCountArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryMenuSectionsArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryMostRecentOrganizationActivityArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryNewMemberPostArgs = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryNewMembershipsArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryNotificationArgs = {
  id: Scalars['String']['input'];
};


export type QueryNotificationCountsArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryNotificationPreferencesArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
};


export type QueryOrganizationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrganizationByDomainArgs = {
  domain: Scalars['String']['input'];
};


export type QueryOrganizationByInviteCodeArgs = {
  inviteCode: Scalars['ID']['input'];
};


export type QueryOrganizationByShortIdArgs = {
  shortId: Scalars['ID']['input'];
};


export type QueryOrganizationInviteArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryOrganizationRolesArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryOrganizationRolesByUserIdArgs = {
  organizationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type QueryOrganizationStatsArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryOrganizationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPostArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPostBookmarksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryPostsByEventArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['ID']['input'];
  first: Scalars['Int']['input'];
};


export type QueryPostsByGroupArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  groupId: Scalars['ID']['input'];
};


export type QuerySearchArgs = {
  organizationId: Scalars['String']['input'];
  query: Scalars['String']['input'];
};


export type QueryShortIdAvailableArgs = {
  shortId: Scalars['String']['input'];
};


export type QueryShortIdSuggestionArgs = {
  name: Scalars['String']['input'];
};


export type QueryTemporaryPostArgs = {
  token: Scalars['String']['input'];
};


export type QueryUpsellsArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryUserArgs = {
  organizationId: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type QueryUserActivityCountsArgs = {
  organizationId: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type QueryUserInvitesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<InvitesOrderByInput>;
  organizationId: Scalars['String']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserRequestToJoinOrganizationArgs = {
  groupId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};


export type QueryUserRequestsToJoinOrganizationArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RequestsOrderByInput>;
  organizationId: Scalars['String']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUsernameAvailableArgs = {
  username: Scalars['String']['input'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  groupId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryVideoArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVisitedPublicOrganizationsArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryWelcomeGroupArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryWelcomePostArgs = {
  organizationId: Scalars['ID']['input'];
};

export type QuotaEntitlements = {
  __typename?: 'QuotaEntitlements';
  limit: Scalars['Int']['output'];
  name: FeatureQuota;
};

export type QuotaUsage = {
  __typename?: 'QuotaUsage';
  limit: Scalars['Int']['output'];
  name: FeatureQuota;
  used: Scalars['Int']['output'];
};

export type Reaction = Node & {
  __typename?: 'Reaction';
  createdByUsers: Array<User>;
  emojiUnicode: Scalars['String']['output'];
  hasReacted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  numReactions: Scalars['Int']['output'];
  type: ReactionTypes;
};

export enum ReactionTypes {
  Comment = 'COMMENT',
  DirectMessage = 'DIRECT_MESSAGE',
  Post = 'POST'
}

export type ReadNotificationInput = {
  notificationId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type RejectRequestToJoinInput = {
  requestToJoinId: Scalars['String']['input'];
};

export type RemoveGroupMemberInput = {
  groupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type ReorderGroupsInput = {
  groupIds: Array<Scalars['String']['input']>;
};

export type ReorderLessonInput = {
  lessonId: Scalars['ID']['input'];
  order: Scalars['Float']['input'];
};

export type ReorderMenuItemsInput = {
  itemIds: Array<Scalars['String']['input']>;
  sectionId: Scalars['String']['input'];
};

export type ReorderMenuSectionsInput = {
  sectionIds: Array<Scalars['String']['input']>;
};

export type ReorderOrganizationMembershipInput = {
  order: Scalars['Float']['input'];
  organizationMembershipId: Scalars['ID']['input'];
};

export type RequestToJoin = Node & {
  __typename?: 'RequestToJoin';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  status: RequestToJoinStatusTypes;
  user: User;
};

export type RequestToJoinError = {
  __typename?: 'RequestToJoinError';
  message: Scalars['String']['output'];
};

export type RequestToJoinOrganizationInput = {
  email: Scalars['String']['input'];
  groupId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};

export type RequestToJoinPayload = RequestToJoin | RequestToJoinError;

export enum RequestToJoinStatusTypes {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type RequestsOrderByInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RequestsPaginated = {
  __typename?: 'RequestsPaginated';
  items: Array<RequestToJoin>;
  totalCount: Scalars['Int']['output'];
};

export type RescheduleDraftPostInput = {
  postId: Scalars['ID']['input'];
  scheduledAt: Scalars['DateTime']['input'];
};

export type ResendUserInviteInput = {
  userInviteId: Scalars['ID']['input'];
};

export type ResetOrganizationInviteInput = {
  organizationId: Scalars['ID']['input'];
};

export type Resource = Node & {
  __typename?: 'Resource';
  description?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  expanded: Scalars['Boolean']['output'];
  favicon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  logoTitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type ResourceError = {
  __typename?: 'ResourceError';
  message: Scalars['String']['output'];
};

export type ResourceInput = {
  expanded: Scalars['Boolean']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type ResourcePayload = Resource | ResourceError;

export type RestoreUserInput = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type RevokeUserInvitesInput = {
  email: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type Search = Node & {
  __typename?: 'Search';
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  excerpt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  link: Scalars['String']['output'];
  node?: Maybe<SearchItem>;
  rank: Scalars['Float']['output'];
  type: SearchResultTypes;
};

export type SearchItem = Group;

export enum SearchResultTypes {
  Comment = 'COMMENT',
  Group = 'GROUP',
  Post = 'POST',
  Resource = 'RESOURCE',
  User = 'USER'
}

export type SendFeedbackEmailInput = {
  feedback?: InputMaybe<Scalars['String']['input']>;
  feeling?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  url: Scalars['String']['input'];
};

export enum SeriesSelection {
  AllEvents = 'ALL_EVENTS',
  FollowingEvents = 'FOLLOWING_EVENTS',
  ThisAndFollowingEvents = 'THIS_AND_FOLLOWING_EVENTS',
  ThisEvent = 'THIS_EVENT'
}

export type SetOrganizationCustomDomainInput = {
  domain: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type StripeAccount = {
  __typename?: 'StripeAccount';
  createdAt: Scalars['DateTime']['output'];
  disabledReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  organizationPushEvent: PushEvent;
  userPushEvent: PushEvent;
};


export type SubscriptionOrganizationPushEventArgs = {
  id: Scalars['ID']['input'];
};

export type SuspendUserInput = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type TemporaryPost = Node & {
  __typename?: 'TemporaryPost';
  group?: Maybe<Group>;
  id: Scalars['ID']['output'];
  media: Array<PostMedia>;
  token: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
};

export type ToggleAutoMessageNewMembersInput = {
  organizationId: Scalars['ID']['input'];
};

export type ToggleCommentAsBestAnswerInput = {
  id: Scalars['ID']['input'];
};

export type ToggleEventNotificationsInput = {
  eventId: Scalars['ID']['input'];
};

export type ToggleEventPostCreationPolicyInput = {
  eventId: Scalars['ID']['input'];
};

export type ToggleFollowPostInput = {
  postId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type ToggleGroupAutoAddMembersInput = {
  addExistingMembers?: InputMaybe<Scalars['Boolean']['input']>;
  groupId: Scalars['String']['input'];
};

export type ToggleGroupEnableNewPostNotificationsForNewMembersInput = {
  groupId: Scalars['String']['input'];
};

export type ToggleGroupIncludePostsOnHomeInput = {
  groupId: Scalars['String']['input'];
};

export type ToggleHideFromProfilePostInput = {
  postId: Scalars['ID']['input'];
};

export type ToggleLessonCompletedInput = {
  lessonId: Scalars['ID']['input'];
};

export type ToggleNewMemberIntroductionsInput = {
  organizationId: Scalars['ID']['input'];
};

export type ToggleOrganizationFeatureInput = {
  feature: FeatureEntitlement;
  organizationId: Scalars['ID']['input'];
};

export type ToggleOrganizationMembershipOnboardingTaskInput = {
  organizationMembershipId: Scalars['ID']['input'];
  task: OrganizationMembershipOnboardingTasks;
};

export type TogglePinnedPostInput = {
  postId: Scalars['ID']['input'];
};

export type TogglePublicCommunityInput = {
  organizationId: Scalars['ID']['input'];
};

export type ToggleShowAuthorOnPostInput = {
  postId: Scalars['ID']['input'];
};

export type ToggleShowCommentsAndReactionsOnPostInput = {
  postId: Scalars['ID']['input'];
};

export type ToggleWelcomeGroupInput = {
  organizationId: Scalars['ID']['input'];
};

export type ToggleWelcomePostInput = {
  organizationId: Scalars['ID']['input'];
};

export type UnassignRoleInput = {
  organizationId: Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type UnfurlUrlInput = {
  url: Scalars['String']['input'];
};

export type UpdateAutoMessageNewMembersMessageInput = {
  message: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type UpdateCommentInput = {
  content: Scalars['JSON']['input'];
  id: Scalars['ID']['input'];
  mediaIds: Array<Scalars['ID']['input']>;
  resources: Array<ResourceInput>;
};

export type UpdateDirectMessageUserThreadSettingsInput = {
  markedUnread?: InputMaybe<Scalars['Boolean']['input']>;
  mutedNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  threadId: Scalars['String']['input'];
};

export type UpdateEventInput = {
  coverImageId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['JSON']['input']>;
  endAt: Scalars['DateTime']['input'];
  eventId: Scalars['ID']['input'];
  location?: InputMaybe<LocationInput>;
  meetingUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  recurringCronExpression?: InputMaybe<Scalars['String']['input']>;
  recurringOccurrencesLimit?: InputMaybe<Scalars['Int']['input']>;
  seriesSelection?: InputMaybe<SeriesSelection>;
  startAt: Scalars['DateTime']['input'];
  timeZone: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGroupInput = {
  coverImageId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['JSON']['input']>;
  emoji?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  postCreationPolicy?: InputMaybe<PostCreationPolicy>;
  questionCronExpression?: InputMaybe<Scalars['String']['input']>;
  questionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  questionText?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateImageCoordinatesInput = {
  height: Scalars['Int']['input'];
  imageId: Scalars['ID']['input'];
  width: Scalars['Int']['input'];
  x: Scalars['Int']['input'];
  y: Scalars['Int']['input'];
};

export type UpdateMembershipInput = {
  allowNewOccurrenceNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  allowNewPostNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  groupId: Scalars['ID']['input'];
};

export type UpdateMenuItemInput = {
  itemId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UpdateMenuSectionInput = {
  name: Scalars['String']['input'];
  sectionId: Scalars['ID']['input'];
};

export type UpdateNewMemberIntroductionMessageInput = {
  message: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type UpdateOrganizationRoleInput = {
  color?: InputMaybe<OrganizationRoleColor>;
  label: Scalars['String']['input'];
  roleId: Scalars['ID']['input'];
};

export type UpdateTemporaryPostInput = {
  mediaIds: Array<Scalars['ID']['input']>;
  temporaryPostId: Scalars['ID']['input'];
};

export type UpdateUserDigestNotificationFrequencyInput = {
  frequency: DigestNotificationFrequency;
  userId: Scalars['ID']['input'];
};

export type UpdateUserInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  avatarBgColor?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  coverImageId?: InputMaybe<Scalars['String']['input']>;
  currentCity?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hometown?: InputMaybe<Scalars['String']['input']>;
  instagramHandle?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  profileImageId?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserLanguageInput = {
  language?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type UpdateUserNotificationPreferencesInput = {
  allowAccountNotifications: Scalars['Boolean']['input'];
  allowCommentNotifications: Scalars['Boolean']['input'];
  allowDirectMessageNotifications: Scalars['Boolean']['input'];
  allowGroupNotifications: Scalars['Boolean']['input'];
  allowMentionNotifications: Scalars['Boolean']['input'];
  allowNewMemberRequestNotifications: Scalars['Boolean']['input'];
  allowNewPostNotifications: Scalars['Boolean']['input'];
  allowPollNotifications: Scalars['Boolean']['input'];
  allowReactionNotifications: Scalars['Boolean']['input'];
  allowReminderNotifications: Scalars['Boolean']['input'];
  allowUserJoinedNotifications: Scalars['Boolean']['input'];
  userNotificationPreferencesId: Scalars['ID']['input'];
};

export type UpdateUserOnboardingInput = {
  organizationId: Scalars['ID']['input'];
};

export type UpdateWidgetSizeVariantInput = {
  organizationId: Scalars['ID']['input'];
  widgetSizeVariant: WidgetSizeVariant;
};

export type Upsell = Node & {
  __typename?: 'Upsell';
  createdAt: Scalars['DateTime']['output'];
  hidden: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  type: UpsellTypes;
};

export type UpsellError = {
  __typename?: 'UpsellError';
  message: Scalars['String']['output'];
};

export type UpsellPayload = Upsell | UpsellError;

export enum UpsellTypes {
  Trial = 'TRIAL'
}

export type UpsertMemberSubscriptionPlanInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  groupIds: Array<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  priceMonthlyUsd: Scalars['Int']['input'];
};

export type UpsertPostInput = {
  aspectRatio: Scalars['Float']['input'];
  colorTheme: PostColorTheme;
  content?: InputMaybe<Scalars['JSON']['input']>;
  groupId: Scalars['ID']['input'];
  isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  mediaIds: Array<Scalars['ID']['input']>;
  pollOptions?: InputMaybe<Array<Scalars['String']['input']>>;
  postId?: InputMaybe<Scalars['ID']['input']>;
  resources: Array<ResourceInput>;
  scheduledAt?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<PostTypes>;
};

export type User = Node & {
  __typename?: 'User';
  avatar: Scalars['String']['output'];
  avatarBgColor: Scalars['String']['output'];
  billingDetails?: Maybe<BillingDetails>;
  bio?: Maybe<Scalars['String']['output']>;
  claimedAccount: Scalars['Boolean']['output'];
  coverImageId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currentCity?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  groupMemberships?: Maybe<Array<GroupMembership>>;
  hometown?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instagramHandle?: Maybe<Scalars['String']['output']>;
  instagramProfileImage?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  memberSubscriptions: Array<MemberSubscription>;
  name: Scalars['String']['output'];
  organizationMembership?: Maybe<OrganizationMembership>;
  organizationMemberships: Array<OrganizationMembership>;
  position?: Maybe<Scalars['String']['output']>;
  preferences: UserPreferences;
  profileImageId?: Maybe<Scalars['String']['output']>;
  profileVideo?: Maybe<Video>;
  timeZone?: Maybe<Scalars['String']['output']>;
  userInvites: Array<UserInvite>;
  username: Scalars['String']['output'];
};


export type UserGroupMembershipsArgs = {
  organizationId: Scalars['String']['input'];
};


export type UserOrganizationMembershipArgs = {
  organizationId: Scalars['String']['input'];
};

export type UserActivityCounts = Node & {
  __typename?: 'UserActivityCounts';
  id: Scalars['ID']['output'];
  numGroups: Scalars['Float']['output'];
  numPosts: Scalars['Float']['output'];
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges: Array<UserObjectEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserInvite = Node & {
  __typename?: 'UserInvite';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inviteCode: Scalars['String']['output'];
  organization: Organization;
};

export type UserNotificationPreferences = Node & {
  __typename?: 'UserNotificationPreferences';
  allowAccountNotifications: Scalars['Boolean']['output'];
  allowCommentNotifications: Scalars['Boolean']['output'];
  allowDirectMessageNotifications: Scalars['Boolean']['output'];
  allowGroupNotifications: Scalars['Boolean']['output'];
  allowMentionNotifications: Scalars['Boolean']['output'];
  allowNewMemberRequestNotifications: Scalars['Boolean']['output'];
  allowNewPostNotifications: Scalars['Boolean']['output'];
  allowPollNotifications: Scalars['Boolean']['output'];
  allowReactionNotifications: Scalars['Boolean']['output'];
  allowReminderNotifications: Scalars['Boolean']['output'];
  allowUserJoinedNotifications: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  type: NotificationPreferencesType;
};

export type UserObjectEdge = {
  __typename?: 'UserObjectEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type UserPreferences = Node & {
  __typename?: 'UserPreferences';
  allowCookies?: Maybe<Scalars['Boolean']['output']>;
  allowEmailNotifications?: Maybe<Scalars['Boolean']['output']>;
  digestNotificationFrequency: DigestNotificationFrequency;
  id: Scalars['ID']['output'];
  isProfileSetup: Scalars['Boolean']['output'];
  questionsOnboardingCompletedAt?: Maybe<Scalars['DateTime']['output']>;
  showQuestionsOnboarding: Scalars['Boolean']['output'];
};

export type Username = {
  __typename?: 'Username';
  username: Scalars['String']['output'];
};

export type UsernameError = {
  __typename?: 'UsernameError';
  message: Scalars['String']['output'];
};

export type UsernamePayload = Username | UsernameError;

export type Video = Node & {
  __typename?: 'Video';
  aspect?: Maybe<Scalars['Float']['output']>;
  assetId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  mirror: Scalars['Boolean']['output'];
  playbackId?: Maybe<Scalars['String']['output']>;
  previewPlaybackId?: Maybe<Scalars['String']['output']>;
  previewStatus?: Maybe<VideoStatus>;
  status: VideoStatus;
  type: VideoType;
};

export type VideoPresignedUpload = {
  __typename?: 'VideoPresignedUpload';
  id: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum VideoStatus {
  Ready = 'READY',
  UploadStarted = 'UPLOAD_STARTED'
}

export enum VideoType {
  Hello = 'HELLO',
  Profile = 'PROFILE'
}

export enum ViewLevels {
  Detail = 'DETAIL',
  Preview = 'PREVIEW'
}

export enum ViewLocations {
  GroupDetail = 'GROUP_DETAIL',
  Home = 'HOME',
  PostDetail = 'POST_DETAIL',
  Unseen = 'UNSEEN'
}

export type WaitlistEntry = Node & {
  __typename?: 'WaitlistEntry';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  source: WaitlistEntrySource;
};

export type WaitlistEntryError = {
  __typename?: 'WaitlistEntryError';
  errorCode: WaitlistEntryErrorCode;
};

export enum WaitlistEntryErrorCode {
  AlreadyOnList = 'ALREADY_ON_LIST'
}

export type WaitlistEntryPayload = WaitlistEntry | WaitlistEntryError;

export enum WaitlistEntrySource {
  Landing = 'LANDING',
  LandingQuestions = 'LANDING_QUESTIONS',
  Signup = 'SIGNUP',
  TryFrond = 'TRY_FROND'
}

export enum WidgetSizeVariant {
  Medium = 'MEDIUM',
  Small = 'SMALL'
}

export type OrganizationAdminFieldsFragment = { __typename?: 'Organization', id: string, shortId: string, name: string, domain?: string | null, createdAt: string, createdBy?: { __typename?: 'User', email: string, username: string } | null };

export type OrganizationsQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
}>;


export type OrganizationsQuery = { __typename?: 'Query', organizations: { __typename?: 'OrganizationConnection', totalCount: number, edges: Array<{ __typename?: 'OrganizationObjectEdge', cursor: string, node: (
        { __typename?: 'Organization' }
        & OrganizationAdminFieldsFragment
      ) }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } } };

export type AddOrganizationMutationVariables = Exact<{
  input: AddOrganizationInput;
}>;


export type AddOrganizationMutation = { __typename?: 'Mutation', addOrganization: (
    { __typename?: 'Organization' }
    & OrganizationAdminFieldsFragment
  ) };

export type SendDigestToUserMutationVariables = Exact<{
  userEmail: Scalars['String']['input'];
}>;


export type SendDigestToUserMutation = { __typename?: 'Mutation', sendDigestToUser?: { __typename?: 'Organization', id: string } | null };

export type SendNotificationsDigestToUserMutationVariables = Exact<{
  userEmail: Scalars['String']['input'];
}>;


export type SendNotificationsDigestToUserMutation = { __typename?: 'Mutation', sendNotificationsDigestToUser?: { __typename?: 'Organization', id: string } | null };

export type DeleteOrganizationMutationVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type DeleteOrganizationMutation = { __typename?: 'Mutation', deleteOrganization: { __typename?: 'Organization', id: string } };

export type DuplicateOrganizationMutationVariables = Exact<{
  input: DuplicateOrganizationInput;
}>;


export type DuplicateOrganizationMutation = { __typename?: 'Mutation', duplicateOrganization: (
    { __typename?: 'Organization' }
    & OrganizationCoreFragment
  ) };

export type DeleteUserMutationVariables = Exact<{
  userEmail: Scalars['String']['input'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'User', id: string } };

export type GetDemoOrganizationMutationVariables = Exact<{ [key: string]: never; }>;


export type GetDemoOrganizationMutation = { __typename?: 'Mutation', getDemoOrganization: (
    { __typename?: 'Organization' }
    & OrganizationCoreFragment
  ) };

export type ViewerQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerQuery = { __typename?: 'Query', viewer: { __typename?: 'User', id: string, createdAt: string, username: string, email: string, name: string, profileImageId?: string | null, coverImageId?: string | null, firstName: string, lastName: string, bio?: string | null, claimedAccount: boolean, position?: string | null, avatar: string, avatarBgColor: string, timeZone?: string | null, userInvites: Array<{ __typename?: 'UserInvite', id: string, inviteCode: string, organization: (
        { __typename?: 'Organization' }
        & OrganizationCoreFragment
      ) }>, memberSubscriptions: Array<{ __typename?: 'MemberSubscription', id: string, stripeCancelAtPeriodEnd: boolean, expiresAt: string, subscriptionPlan: { __typename?: 'MemberSubscriptionPlan', id: string } }>, organizationMemberships: Array<{ __typename?: 'OrganizationMembership', id: string, active: boolean, createdAt: string, isCreator: boolean, hideFrondIntroPost: boolean, completedOnboardingTasks: Array<OrganizationMembershipOnboardingTasks>, roles: Array<{ __typename?: 'OrganizationRole', id: string, label: string, color: OrganizationRoleColor, isAdmin: boolean, memberSubscriptionPlan?: { __typename?: 'MemberSubscriptionPlan', id: string } | null }>, organization: (
        { __typename?: 'Organization' }
        & OrganizationCoreFragment
      ) }>, preferences: { __typename?: 'UserPreferences', id: string, showQuestionsOnboarding: boolean, questionsOnboardingCompletedAt?: string | null, isProfileSetup: boolean, allowCookies?: boolean | null, allowEmailNotifications?: boolean | null, digestNotificationFrequency: DigestNotificationFrequency } } };

export type UserProfileFragment = { __typename?: 'User', id: string, username: string, name: string, profileImageId?: string | null, firstName: string, lastName: string, linkedinUrl?: string | null, instagramHandle?: string | null, instagramProfileImage?: string | null, avatarBgColor: string, avatar: string, currentCity?: string | null, hometown?: string | null, timeZone?: string | null, bio?: string | null, claimedAccount: boolean, coverImageId?: string | null, createdAt: string, profileVideo?: { __typename?: 'Video', playbackId?: string | null, aspect?: number | null } | null, preferences: { __typename?: 'UserPreferences', id: string, showQuestionsOnboarding: boolean, isProfileSetup: boolean, allowCookies?: boolean | null, allowEmailNotifications?: boolean | null } };

export type UserQueryVariables = Exact<{
  username: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
}>;


export type UserQuery = { __typename?: 'Query', user?: (
    { __typename?: 'User', organizationMembership?: { __typename?: 'OrganizationMembership', id: string, createdAt: string, roles: Array<{ __typename?: 'OrganizationRole', id: string, label: string, color: OrganizationRoleColor, isAdmin: boolean }> } | null }
    & UserProfileFragment
  ) | null };

export type UserGroupMembershipsQueryVariables = Exact<{
  username: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
}>;


export type UserGroupMembershipsQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, username: string, groupMemberships?: Array<{ __typename?: 'GroupMembership', id: string, group: { __typename?: 'Group', id: string, name: string, emoji: string } }> | null } | null };

export type AllUsersQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
}>;


export type AllUsersQuery = { __typename?: 'Query', allUsers: { __typename?: 'UserConnection', totalCount: number, edges: Array<{ __typename?: 'UserObjectEdge', cursor: string, node: (
        { __typename?: 'User', email: string, organizationMemberships: Array<{ __typename?: 'OrganizationMembership', id: string, roles: Array<{ __typename?: 'OrganizationRole', id: string, label: string, color: OrganizationRoleColor, isAdmin: boolean }>, organization: (
            { __typename?: 'Organization' }
            & OrganizationCoreFragment
          ) }> }
        & UserProfileFragment
      ) }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: (
    { __typename?: 'User' }
    & UserProfileFragment
  ) };

export type UsersQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  groupId?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
}>;


export type UsersQuery = { __typename?: 'Query', users: { __typename?: 'UserConnection', totalCount: number, edges: Array<{ __typename?: 'UserObjectEdge', cursor: string, node: { __typename?: 'User', id: string, username: string, name: string, firstName: string, profileImageId?: string | null, avatarBgColor: string, avatar: string, createdAt: string, organizationMembership?: { __typename?: 'OrganizationMembership', id: string, createdAt: string, roles: Array<{ __typename?: 'OrganizationRole', id: string, label: string, color: OrganizationRoleColor, isAdmin: boolean, memberSubscriptionPlan?: { __typename?: 'MemberSubscriptionPlan', id: string, name: string } | null }> } | null } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } };

export type CreateOrDeleteReactionMutationVariables = Exact<{
  input: CreateOrDeleteReactionInput;
}>;


export type CreateOrDeleteReactionMutation = { __typename?: 'Mutation', createOrDeleteReaction: { __typename: 'Reaction', id: string, type: ReactionTypes, hasReacted: boolean, numReactions: number, emojiUnicode: string, createdByUsers: Array<{ __typename?: 'User', id: string, name: string, firstName: string, lastName: string, username: string, profileImageId?: string | null, avatar: string, avatarBgColor: string }> } };

export type CommentCoreFragment = { __typename: 'Comment', id: string, type: CommentTypes, content: { [key: string]: any }, contentVersion: number, isBestAnswer: boolean, createdAt: string, createdBy: (
    { __typename?: 'User' }
    & UserPreviewFragment
  ), reactions: Array<{ __typename?: 'Reaction', id: string, type: ReactionTypes, hasReacted: boolean, numReactions: number, emojiUnicode: string, createdByUsers: Array<(
      { __typename?: 'User' }
      & UserPreviewFragment
    )> }>, resources: Array<{ __typename?: 'Resource', id: string, description?: string | null, domain?: string | null, favicon?: string | null, imageUrl?: string | null, logo?: string | null, logoTitle?: string | null, title?: string | null, url: string, expanded: boolean }>, media: Array<{ __typename?: 'Giphy', id: string, aspectRatio: number, giphyId: string, giphyUrl: string, giphyMp4Url: string, width: number, height: number } | { __typename?: 'Image', id: string, aspectRatio: number, publicId: string, width: number, height: number }> };

export type CommentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type CommentQuery = { __typename?: 'Query', comment?: (
    { __typename?: 'Comment' }
    & CommentCoreFragment
  ) | null };

export type CreateCommentMutationVariables = Exact<{
  input: CreateCommentInput;
}>;


export type CreateCommentMutation = { __typename?: 'Mutation', createComment: (
    { __typename?: 'Comment' }
    & CommentCoreFragment
  ) };

export type UpdateCommentMutationVariables = Exact<{
  input: UpdateCommentInput;
}>;


export type UpdateCommentMutation = { __typename?: 'Mutation', updateComment: (
    { __typename?: 'Comment' }
    & CommentCoreFragment
  ) };

export type ToggleCommentAsBestAnswerMutationVariables = Exact<{
  input: ToggleCommentAsBestAnswerInput;
}>;


export type ToggleCommentAsBestAnswerMutation = { __typename?: 'Mutation', toggleCommentAsBestAnswer: (
    { __typename?: 'Comment' }
    & CommentCoreFragment
  ) };

export type DeleteCommentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteCommentMutation = { __typename?: 'Mutation', deleteComment: { __typename?: 'Comment', id: string } };

export type SendFeedbackEmailMutationVariables = Exact<{
  input: SendFeedbackEmailInput;
}>;


export type SendFeedbackEmailMutation = { __typename?: 'Mutation', sendFeedbackEmail: { __typename?: 'User', id: string } };

export type CommentConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type CommentConfigQuery = { __typename?: 'Query', commentConfig: { __typename?: 'CommentConfig', maxCommentLength: number } };

export type OrganizationCoreFragment = { __typename?: 'Organization', id: string, shortId: string, legacyShortId?: string | null, color: string, name: string, domain?: string | null, logoId?: string | null, description?: string | null, isGoogleWorkspace: boolean, isSetup: boolean, numMembers: number, numGroups: number, createdAt: string, groupCreationPolicy: GroupCreationPolicy, joinPolicy: JoinPolicy, addMemberPolicy: AddMemberPolicy, widgetSizeVariant: WidgetSizeVariant, enableNewMemberIntroductions: boolean, newMemberIntroductionMessage: string, enableAutoMessageNewMembers: boolean, autoMessageNewMembersMessage: string, autoMessageNewMembersFromId?: string | null, isPublic: boolean, hasCoursesEnabled: boolean, hasEventsEnabled: boolean, hasInsightsEnabled: boolean, templateType: OrganizationTemplateType, adminSubscription?: { __typename?: 'AdminSubscription', subscriptionPlan: AdminSubscriptionPlanId } | null };

export type OrganizationExtendedFragment = (
  { __typename?: 'Organization', memberSubscriptionPlan?: { __typename?: 'MemberSubscriptionPlan', id: string, active: boolean, name: string, description?: string | null, priceMonthlyUsd: number, pendingBalance: number, subscriberCount: number, totalRevenue: number, groupEntitlements: Array<{ __typename?: 'Group', id: string, name: string, description?: { [key: string]: any } | null, emoji: string, numPosts: number }> } | null, customDomain?: { __typename?: 'CustomDomain', id: string, status: CustomDomainStatus, name: string } | null }
  & OrganizationCoreFragment
);

export type OrganizationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type OrganizationQuery = { __typename?: 'Query', organization?: (
    { __typename?: 'Organization' }
    & OrganizationExtendedFragment
  ) | null };

export type OrganizationByDomainQueryVariables = Exact<{
  domain: Scalars['String']['input'];
}>;


export type OrganizationByDomainQuery = { __typename?: 'Query', organizationByDomain?: { __typename?: 'Organization', id: string, shortId: string } | null };

export type OrganizationByShortIdQueryVariables = Exact<{
  shortId: Scalars['ID']['input'];
}>;


export type OrganizationByShortIdQuery = { __typename?: 'Query', organizationByShortId?: (
    { __typename?: 'Organization' }
    & OrganizationExtendedFragment
  ) | null };

export type OrganizationActiveMembersQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type OrganizationActiveMembersQuery = { __typename?: 'Query', organization?: { __typename?: 'Organization', id: string, activeMembers: Array<{ __typename?: 'User', id: string, username: string, firstName: string, lastName: string, profileImageId?: string | null, avatar: string, avatarBgColor: string }> } | null };

export type CustomDomainVerificationInfoQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type CustomDomainVerificationInfoQuery = { __typename?: 'Query', customDomainVerificationInfo: { __typename?: 'CustomDomainVerificationInfo', apexName: string, name: string, verified: boolean, verification: Array<{ __typename?: 'CustomDomainVerification', type: string, domain: string, value: string, reason: string }> } };

export type UserActivityCountsQueryVariables = Exact<{
  username: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
}>;


export type UserActivityCountsQuery = { __typename?: 'Query', userActivityCounts?: { __typename?: 'UserActivityCounts', numGroups: number, numPosts: number } | null };

export type OrganizationInviteQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type OrganizationInviteQuery = { __typename?: 'Query', organizationInvite: { __typename?: 'OrganizationInvite', inviteCode: string, expiresAt?: string | null } };

export type UpdateUserDigestNotificationFrequencyMutationVariables = Exact<{
  input: UpdateUserDigestNotificationFrequencyInput;
}>;


export type UpdateUserDigestNotificationFrequencyMutation = { __typename?: 'Mutation', updateUserDigestNotificationFrequency: { __typename?: 'UserPreferences', id: string, digestNotificationFrequency: DigestNotificationFrequency } };

export type MyResourceFragment = { __typename?: 'Resource', id: string, description?: string | null, domain?: string | null, imageUrl?: string | null, title?: string | null, url: string, logo?: string | null, favicon?: string | null, expanded: boolean };

export type UnfurlUrlMutationVariables = Exact<{
  input: UnfurlUrlInput;
}>;


export type UnfurlUrlMutation = { __typename?: 'Mutation', unfurlUrl: (
    { __typename?: 'Resource' }
    & MyResourceFragment
  ) | { __typename?: 'ResourceError', message: string } };

export type CreateImageMutationVariables = Exact<{
  input: CreateImageInput;
}>;


export type CreateImageMutation = { __typename?: 'Mutation', createImage: { __typename?: 'Image', id: string, aspectRatio: number, publicId: string, height: number, width: number } };

export type CreateTemporaryImageMutationVariables = Exact<{
  input: CreateTemporaryImageInput;
}>;


export type CreateTemporaryImageMutation = { __typename?: 'Mutation', createTemporaryImage: { __typename?: 'Image', id: string, aspectRatio: number, publicId: string, height: number, width: number } };

export type CreateGiphyMutationVariables = Exact<{
  input: CreateGiphyInput;
}>;


export type CreateGiphyMutation = { __typename?: 'Mutation', createGiphy: { __typename?: 'Giphy', id: string, aspectRatio: number, height: number, width: number, giphyMp4Url: string, giphyUrl: string, giphyId: string } };

export type OrganizationPublicFieldsFragment = (
  { __typename?: 'Organization', activeMembers: Array<{ __typename?: 'User', id: string, name: string, firstName: string, lastName: string, username: string, profileImageId?: string | null, avatar: string, avatarBgColor: string }> }
  & OrganizationCoreFragment
);

export type PublicOrganizationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PublicOrganizationQuery = { __typename?: 'Query', organization?: (
    { __typename?: 'Organization' }
    & OrganizationPublicFieldsFragment
  ) | null };

export type PublicOrganizationShortIdsQueryVariables = Exact<{ [key: string]: never; }>;


export type PublicOrganizationShortIdsQuery = { __typename?: 'Query', publicOrganizationShortIds: Array<string> };

export type VisitedPublicOrganizationsQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type VisitedPublicOrganizationsQuery = { __typename?: 'Query', visitedPublicOrganizations: Array<(
    { __typename?: 'Organization' }
    & OrganizationPublicFieldsFragment
  )> };

export type ReorderOrganizationMembershipMutationVariables = Exact<{
  input: ReorderOrganizationMembershipInput;
}>;


export type ReorderOrganizationMembershipMutation = { __typename?: 'Mutation', reorderOrganizationMembership: { __typename?: 'OrganizationMembership', id: string } };

export type AddOrganizationVisitMutationVariables = Exact<{
  input: AddOrganizationVisitInput;
}>;


export type AddOrganizationVisitMutation = { __typename?: 'Mutation', addOrganizationVisit: (
    { __typename?: 'Organization' }
    & OrganizationPublicFieldsFragment
  ) };

export type HideOrganizationVisitMutationVariables = Exact<{
  input: HideOrganizationVisitInput;
}>;


export type HideOrganizationVisitMutation = { __typename?: 'Mutation', hideOrganizationVisit: (
    { __typename?: 'Organization' }
    & OrganizationPublicFieldsFragment
  ) };

export type UserPreviewFragment = { __typename?: 'User', id: string, name: string, firstName: string, lastName: string, username: string, profileImageId?: string | null, avatar: string, avatarBgColor: string, coverImageId?: string | null };

export type ResourceCoreFragment = { __typename?: 'Resource', id: string, description?: string | null, domain?: string | null, favicon?: string | null, imageUrl?: string | null, logo?: string | null, logoTitle?: string | null, title?: string | null, url: string };

export type PostCoreFragment = { __typename: 'Post', id: string, type: PostTypes, bgColor?: string | null, deleted?: boolean | null, content?: { [key: string]: any } | null, createdAt: string, updatedAt: string, colorTheme: PostColorTheme, aspectRatio: number, pinned: boolean, showAuthor: boolean, showCommentsAndReactions: boolean, title?: string | null, hideFromProfile: boolean, isDraft: boolean, scheduledAt?: string | null, resource?: (
    { __typename?: 'Resource' }
    & ResourceCoreFragment
  ) | null, resources: Array<{ __typename?: 'Resource', id: string, description?: string | null, domain?: string | null, favicon?: string | null, imageUrl?: string | null, logo?: string | null, logoTitle?: string | null, title?: string | null, url: string, expanded: boolean }>, media: Array<{ __typename?: 'Giphy', id: string, aspectRatio: number, giphyId: string, giphyUrl: string, giphyMp4Url: string, width: number, height: number } | { __typename?: 'Image', id: string, aspectRatio: number, publicId: string, width: number, height: number, coordinates?: { __typename?: 'Coordinates', x: number, y: number, width: number, height: number } | null } | { __typename?: 'Video', id: string, aspect?: number | null, playbackId?: string | null, previewPlaybackId?: string | null, previewStatus?: VideoStatus | null, mirror: boolean, status: VideoStatus, type: VideoType, assetId?: string | null, createdAt: string }>, poll?: (
    { __typename?: 'Poll' }
    & PollCoreFragment
  ) | null, createdBy: (
    { __typename?: 'User' }
    & UserPreviewFragment
  ), organization: (
    { __typename?: 'Organization' }
    & OrganizationCoreFragment
  ) };

export type PollCoreFragment = { __typename?: 'Poll', id: string, options: Array<{ __typename?: 'PollOption', id: string, name: string, votes: Array<{ __typename?: 'PollVote', createdBy: (
        { __typename?: 'User' }
        & UserPreviewFragment
      ) }> }> };

export type PostFeedFragment = (
  { __typename?: 'Post', numOfAnonymousViews: number, isBookmarked: boolean, group: (
    { __typename?: 'Group' }
    & GroupCoreFragment
  ), comments: Array<(
    { __typename?: 'Comment' }
    & CommentCoreFragment
  )>, reactions: Array<{ __typename?: 'Reaction', id: string, type: ReactionTypes, hasReacted: boolean, numReactions: number, emojiUnicode: string, createdByUsers: Array<(
      { __typename?: 'User' }
      & UserPreviewFragment
    )> }>, revisions: Array<{ __typename?: 'PostRevision', id: string, createdAt: string }>, views: Array<{ __typename?: 'PostView', id: string, user: (
      { __typename?: 'User' }
      & UserPreviewFragment
    ) }>, completions: Array<{ __typename?: 'LessonCompletion', user: (
      { __typename?: 'User' }
      & UserPreviewFragment
    ) }> }
  & PostCoreFragment
);

export type GroupCoreFragment = { __typename: 'Group', id: string, name: string, deleted?: boolean | null, description?: { [key: string]: any } | null, lastActivityAt: string, questionEnabled?: boolean | null, isPrivate?: boolean | null, questionText?: string | null, questionCronExpression?: string | null, enableNewPostNotificationsForNewMembers: boolean, timeZone?: string | null, hasPosts: boolean, numPosts: number, numMembers: number, emoji: string, coverImageId?: string | null, hidden: boolean, createdAt: string, postCreationPolicy: PostCreationPolicy, autoAddMembers: boolean, sortIndex: number, hasUnseenPosts?: boolean | null, includePostsOnHome: boolean, isDraft: boolean, groupType: GroupTypes, createdBy: (
    { __typename?: 'User' }
    & UserPreviewFragment
  ), organization: (
    { __typename?: 'Organization' }
    & OrganizationCoreFragment
  ), unlockedBy?: { __typename?: 'MemberSubscriptionPlan', id: string, active: boolean } | null };

export type GroupWithMembersFragment = (
  { __typename?: 'Group', membership?: { __typename?: 'GroupMembership', id: string, allowNewOccurrenceNotifications?: boolean | null, allowNewPostNotifications?: boolean | null } | null, createdBy: (
    { __typename?: 'User' }
    & UserPreviewFragment
  ), members: Array<(
    { __typename?: 'User' }
    & UserPreviewFragment
  )> }
  & GroupCoreFragment
);

export type UserGroupNotificationPreferencesFragment = { __typename?: 'Group', membership?: { __typename?: 'GroupMembership', allowNewOccurrenceNotifications?: boolean | null, allowNewPostNotifications?: boolean | null } | null };

export type GroupSidebarFragment = (
  { __typename?: 'Group', membership?: { __typename?: 'GroupMembership', id: string, allowNewOccurrenceNotifications?: boolean | null, allowNewPostNotifications?: boolean | null } | null }
  & GroupCoreFragment
);

export type PaginatedSidebarGroupsConnectionFragment = { __typename?: 'GroupsConnection', totalCount: number, edges: Array<{ __typename?: 'GroupObjectEdge', cursor: string, node: (
      { __typename?: 'Group' }
      & GroupSidebarFragment
    ) }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } };

export type GroupsQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type GroupsQuery = { __typename?: 'Query', groups: { __typename?: 'GroupsConnection', totalCount: number, edges: Array<{ __typename?: 'GroupObjectEdge', cursor: string, node: (
        { __typename?: 'Group' }
        & GroupWithMembersFragment
      ) }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } };

export type SidebarGroupsQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type SidebarGroupsQuery = { __typename?: 'Query', groups: (
    { __typename?: 'GroupsConnection' }
    & PaginatedSidebarGroupsConnectionFragment
  ) };

export type GroupQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GroupQuery = { __typename?: 'Query', group?: (
    { __typename: 'Group' }
    & GroupWithMembersFragment
  ) | { __typename: 'GroupError', reason: GroupErrorReason, message: string } | null };

export type GroupUnseenPostsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GroupUnseenPostsQuery = { __typename?: 'Query', group?: { __typename: 'Group', id: string, hasUnseenPosts?: boolean | null } | { __typename: 'GroupError', message: string } | null };

export type CreateAndJoinGroupMutationVariables = Exact<{
  input: CreateGroupInput;
}>;


export type CreateAndJoinGroupMutation = { __typename?: 'Mutation', createAndJoinGroup: (
    { __typename?: 'Group' }
    & GroupCoreFragment
  ) | { __typename?: 'GroupError' } };

export type UpdateGroupMutationVariables = Exact<{
  input: UpdateGroupInput;
}>;


export type UpdateGroupMutation = { __typename?: 'Mutation', updateGroup: (
    { __typename?: 'Group' }
    & GroupCoreFragment
  ) | { __typename?: 'GroupError' } };

export type DeleteGroupMutationVariables = Exact<{
  input: DeleteGroupInput;
}>;


export type DeleteGroupMutation = { __typename?: 'Mutation', deleteGroup: (
    { __typename?: 'Group' }
    & GroupCoreFragment
  ) | { __typename?: 'GroupError' } };

export type AddGroupMembersMutationVariables = Exact<{
  input: AddGroupMembersInput;
}>;


export type AddGroupMembersMutation = { __typename?: 'Mutation', addGroupMembers: (
    { __typename?: 'Group' }
    & GroupCoreFragment
  ) | { __typename?: 'GroupError' } };

export type JoinGroupMutationVariables = Exact<{
  input: JoinGroupInput;
}>;


export type JoinGroupMutation = { __typename?: 'Mutation', joinGroup: (
    { __typename?: 'Group' }
    & GroupCoreFragment
  ) | { __typename?: 'GroupError' } };

export type RemoveGroupMemberMutationVariables = Exact<{
  input: RemoveGroupMemberInput;
}>;


export type RemoveGroupMemberMutation = { __typename?: 'Mutation', removeGroupMember: (
    { __typename?: 'Group' }
    & GroupCoreFragment
  ) | { __typename?: 'GroupError' } };

export type AddGroupViewMutationVariables = Exact<{
  input: AddViewInput;
}>;


export type AddGroupViewMutation = { __typename?: 'Mutation', addGroupView: { __typename?: 'Group', id: string } };

export type ReorderGroupsMutationVariables = Exact<{
  input: ReorderGroupsInput;
}>;


export type ReorderGroupsMutation = { __typename?: 'Mutation', reorderGroups: (
    { __typename?: 'GroupsConnection' }
    & PaginatedSidebarGroupsConnectionFragment
  ) };

export type HomeFeedQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
}>;


export type HomeFeedQuery = { __typename?: 'Query', homeFeed?: { __typename?: 'FeedItemConnection', totalCount: number, edges: Array<{ __typename?: 'FeedItemEdge', cursor: string, node: { __typename: 'OrganizationMembership', id: string, createdAt: string, isCreator: boolean } | (
        { __typename: 'Post' }
        & PostFeedFragment
      ) }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } | null };

export type HomeFeedLatestContentQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type HomeFeedLatestContentQuery = { __typename?: 'Query', homeFeedLatestItem?: { __typename: 'OrganizationMembership', id: string, createdAt: string } | { __typename: 'Post', id: string, createdAt: string } | null };

export type UpsertPostMutationVariables = Exact<{
  input: UpsertPostInput;
}>;


export type UpsertPostMutation = { __typename?: 'Mutation', upsertPost: { __typename: 'Post', id: string } | { __typename: 'PostError', message: string } };

export type PostQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PostQuery = { __typename?: 'Query', post?: (
    { __typename: 'Post' }
    & PostFeedFragment
  ) | { __typename: 'PostError', message: string } | null };

export type DeletePostMutationVariables = Exact<{
  input: DeletePostInput;
}>;


export type DeletePostMutation = { __typename?: 'Mutation', deletePost: { __typename: 'Post', id: string } | { __typename: 'PostError', message: string } };

export type CreateTemporaryPostMutationVariables = Exact<{
  input: CreateTemporaryPostInput;
}>;


export type CreateTemporaryPostMutation = { __typename?: 'Mutation', createTemporaryPost: { __typename?: 'TemporaryPost', id: string, token: string, userId: string } };

export type TemporaryPostPollQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type TemporaryPostPollQuery = { __typename?: 'Query', temporaryPost?: { __typename?: 'TemporaryPost', id: string, token: string, media: Array<{ __typename?: 'Giphy', id: string, aspectRatio: number, giphyId: string, giphyUrl: string, giphyMp4Url: string, width: number, height: number } | { __typename?: 'Image', id: string, aspectRatio: number, publicId: string, width: number, height: number } | { __typename?: 'Video', id: string, aspect?: number | null, playbackId?: string | null, previewPlaybackId?: string | null, previewStatus?: VideoStatus | null, mirror: boolean, status: VideoStatus, type: VideoType, assetId?: string | null, createdAt: string }> } | null };

export type TemporaryPostQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type TemporaryPostQuery = { __typename?: 'Query', temporaryPost?: { __typename?: 'TemporaryPost', id: string, token: string, userId: string, group?: (
      { __typename?: 'Group', members: Array<(
        { __typename?: 'User' }
        & UserPreviewFragment
      )> }
      & GroupCoreFragment
    ) | null } | null };

export type UpdateTemporaryPostMutationVariables = Exact<{
  input: UpdateTemporaryPostInput;
}>;


export type UpdateTemporaryPostMutation = { __typename?: 'Mutation', updateTemporaryPost: { __typename?: 'TemporaryPost', id: string, token: string, userId: string, media: Array<{ __typename?: 'Giphy' } | { __typename?: 'Image', id: string } | { __typename?: 'Video', id: string }> } };

export type UpdateMembershipMutationVariables = Exact<{
  input: UpdateMembershipInput;
}>;


export type UpdateMembershipMutation = { __typename?: 'Mutation', updateMembership: { __typename?: 'GroupMembership', id: string, allowNewOccurrenceNotifications?: boolean | null, allowNewPostNotifications?: boolean | null } };

export type GroupsSidebarStateQueryVariables = Exact<{ [key: string]: never; }>;


export type GroupsSidebarStateQuery = { __typename?: 'Query', groupsSidebarState: { __typename?: 'GroupsSidebarState', privateGroupsExpanded?: boolean | null, publicGroupsExpanded?: boolean | null, courseGroupsExpanded?: boolean | null } };

export type GroupsMenuSectionsStateQueryVariables = Exact<{ [key: string]: never; }>;


export type GroupsMenuSectionsStateQuery = { __typename?: 'Query', groupsMenuSectionsState: { __typename?: 'GroupsMenuSectionsState', menuSectionIdsCollapsed?: Array<string | null> | null } };

export type ToggleGroupAutoAddMembersMutationVariables = Exact<{
  input: ToggleGroupAutoAddMembersInput;
}>;


export type ToggleGroupAutoAddMembersMutation = { __typename?: 'Mutation', toggleGroupAutoAddMembers: { __typename?: 'Group', id: string, autoAddMembers: boolean } };

export type ToggleGroupEnableNewPostNotificationsForNewMembersMutationVariables = Exact<{
  input: ToggleGroupEnableNewPostNotificationsForNewMembersInput;
}>;


export type ToggleGroupEnableNewPostNotificationsForNewMembersMutation = { __typename?: 'Mutation', toggleGroupEnableNewPostNotificationsForNewMembers: { __typename?: 'Group', id: string, enableNewPostNotificationsForNewMembers: boolean } };

export type ToggleGroupIncludePostsOnHomeMutationVariables = Exact<{
  input: ToggleGroupIncludePostsOnHomeInput;
}>;


export type ToggleGroupIncludePostsOnHomeMutation = { __typename?: 'Mutation', toggleGroupIncludePostsOnHome: { __typename?: 'Group', id: string, includePostsOnHome: boolean } };

export type RequestToJoinOrganizationMutationVariables = Exact<{
  input: RequestToJoinOrganizationInput;
}>;


export type RequestToJoinOrganizationMutation = { __typename?: 'Mutation', requestToJoinOrganization: { __typename: 'RequestToJoin', id: string } | { __typename: 'RequestToJoinError', message: string } };

export type UserInvitesQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<InvitesOrderByInput>;
}>;


export type UserInvitesQuery = { __typename?: 'Query', userInvites: { __typename?: 'InvitesPaginated', totalCount: number, items: Array<{ __typename?: 'UserInvite', id: string, email: string, inviteCode: string, createdAt: string }> } };

export type UserRequestToJoinOrganizationQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type UserRequestToJoinOrganizationQuery = { __typename?: 'Query', userRequestToJoinOrganization?: { __typename?: 'RequestToJoin', id: string } | null };

export type UserRequestsToJoinOrganizationQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<RequestsOrderByInput>;
}>;


export type UserRequestsToJoinOrganizationQuery = { __typename?: 'Query', userRequestsToJoinOrganization: { __typename?: 'RequestsPaginated', totalCount: number, items: Array<{ __typename?: 'RequestToJoin', id: string, createdAt: string, email: string, status: RequestToJoinStatusTypes, user: { __typename?: 'User', id: string, username: string, name: string, avatarBgColor: string, avatar: string, profileImageId?: string | null } }> } };

export type AcceptRequestToJoinMutationVariables = Exact<{
  input: AcceptRequestToJoinInput;
}>;


export type AcceptRequestToJoinMutation = { __typename?: 'Mutation', acceptRequestToJoin: { __typename: 'RequestToJoin', id: string } | { __typename: 'RequestToJoinError', message: string } };

export type AcceptAllRequestsToJoinMutationVariables = Exact<{
  input: AcceptAllRequestsToJoinInput;
}>;


export type AcceptAllRequestsToJoinMutation = { __typename?: 'Mutation', acceptAllRequestsToJoin: { __typename?: 'Organization', id: string } };

export type RejectRequestToJoinMutationVariables = Exact<{
  input: RejectRequestToJoinInput;
}>;


export type RejectRequestToJoinMutation = { __typename?: 'Mutation', rejectRequestToJoin: { __typename: 'RequestToJoin', id: string } | { __typename: 'RequestToJoinError', message: string } };

export type OrganizationPushEventsSubscriptionVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type OrganizationPushEventsSubscription = { __typename?: 'Subscription', organizationPushEvent: { __typename?: 'PushRefetchHint', id: string, postId?: string | null, groupId?: string | null } };

export type UpdateImageCoordinatesMutationVariables = Exact<{
  input: UpdateImageCoordinatesInput;
}>;


export type UpdateImageCoordinatesMutation = { __typename?: 'Mutation', updateImageCoordinates: { __typename?: 'Image', id: string, coordinates?: { __typename?: 'Coordinates', x: number, y: number, width: number, height: number } | null } };

export type CreateVoteMutationVariables = Exact<{
  input: CreateVoteInput;
}>;


export type CreateVoteMutation = { __typename?: 'Mutation', createVote: (
    { __typename: 'Poll' }
    & PollCoreFragment
  ) | { __typename: 'PollError', message: string } };

export type DeleteVoteMutationVariables = Exact<{
  input: DeleteVoteInput;
}>;


export type DeleteVoteMutation = { __typename?: 'Mutation', deleteVote: (
    { __typename: 'Poll' }
    & PollCoreFragment
  ) | { __typename: 'PollError', message: string } };

export type SearchResultFragment = { __typename?: 'Search', id: string, excerpt: string, createdAt: string, link: string, type: SearchResultTypes, createdBy: (
    { __typename?: 'User' }
    & UserPreviewFragment
  ), node?: (
    { __typename?: 'Group' }
    & GroupCoreFragment
  ) | null };

export type SearchQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  query: Scalars['String']['input'];
}>;


export type SearchQuery = { __typename?: 'Query', search?: Array<(
    { __typename?: 'Search' }
    & SearchResultFragment
  )> | null };

export type PostsByGroupQueryVariables = Exact<{
  groupId: Scalars['ID']['input'];
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type PostsByGroupQuery = { __typename?: 'Query', postsByGroup: { __typename?: 'PostConnection', totalCount: number, edges: Array<{ __typename?: 'PostObjectEdge', cursor: string, node: (
        { __typename?: 'Post' }
        & PostFeedFragment
      ) }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } };

export type DraftPostsQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  isScheduled?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type DraftPostsQuery = { __typename?: 'Query', draftPosts: { __typename?: 'PostConnection', totalCount: number, edges: Array<{ __typename?: 'PostObjectEdge', cursor: string, node: (
        { __typename?: 'Post' }
        & PostFeedFragment
      ) }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } };

export type OrganizationRoleCoreFragment = { __typename?: 'OrganizationRole', id: string, label: string, color: OrganizationRoleColor, isAdmin: boolean, memberSubscriptionPlan?: { __typename?: 'MemberSubscriptionPlan', id: string, name: string } | null };

export type CreateOrganizationRoleMutationVariables = Exact<{
  input: CreateOrganizationRoleInput;
}>;


export type CreateOrganizationRoleMutation = { __typename?: 'Mutation', createOrganizationRole: (
    { __typename: 'OrganizationRole' }
    & OrganizationRoleCoreFragment
  ) };

export type UpdateOrganizationRoleMutationVariables = Exact<{
  input: UpdateOrganizationRoleInput;
}>;


export type UpdateOrganizationRoleMutation = { __typename?: 'Mutation', updateOrganizationRole: (
    { __typename: 'OrganizationRole' }
    & OrganizationRoleCoreFragment
  ) };

export type DeleteOrganizationRoleMutationVariables = Exact<{
  input: DeleteOrganizationRoleInput;
}>;


export type DeleteOrganizationRoleMutation = { __typename?: 'Mutation', deleteOrganizationRole: (
    { __typename: 'OrganizationRole' }
    & OrganizationRoleCoreFragment
  ) };

export type OrganizationRolesQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type OrganizationRolesQuery = { __typename?: 'Query', organizationRoles: Array<(
    { __typename: 'OrganizationRole', users: Array<(
      { __typename?: 'User' }
      & UserPreviewFragment
    )> }
    & OrganizationRoleCoreFragment
  )> };

export type OrganizationRolesByUserIdQueryVariables = Exact<{
  userId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
}>;


export type OrganizationRolesByUserIdQuery = { __typename?: 'Query', organizationRolesByUserId: Array<(
    { __typename: 'OrganizationRole' }
    & OrganizationRoleCoreFragment
  )> };

export type DirectMessageTokenFragmentFragment = { __typename?: 'DirectMessageToken', communicationUserId: string, token: string, expiresOn: string };

export type DirectMessageTokenQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type DirectMessageTokenQuery = { __typename?: 'Query', directMessageToken: (
    { __typename: 'DirectMessageToken' }
    & DirectMessageTokenFragmentFragment
  ) };

export type DirectMessageUserFragmentFragment = { __typename?: 'DirectMessageUser', id: string, communicationUserId: string, user: (
    { __typename?: 'User' }
    & UserPreviewFragment
  ) };

export type DirectMessageUserThreadSettingsFragmentFragment = { __typename?: 'DirectMessageUserThreadSettings', id: string, markedUnread?: boolean | null, mutedNotifications?: boolean | null };

export type DirectMessageUserThreadFragmentFragment = { __typename?: 'DirectMessageUserThread', id: string, threadId: string, lastMessageReceivedOn?: string | null, lastMessage?: string | null, unreadCount?: number | null, updatedAt: string, createdBy: (
    { __typename?: 'DirectMessageUser' }
    & DirectMessageUserFragmentFragment
  ), user: (
    { __typename?: 'DirectMessageUser' }
    & DirectMessageUserFragmentFragment
  ), settings: (
    { __typename?: 'DirectMessageUserThreadSettings' }
    & DirectMessageUserThreadSettingsFragmentFragment
  ) };

export type DirectMessageUserThreadQueryVariables = Exact<{
  username: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
}>;


export type DirectMessageUserThreadQuery = { __typename?: 'Query', directMessageUserThread?: (
    { __typename: 'DirectMessageUserThread' }
    & DirectMessageUserThreadFragmentFragment
  ) | null };

export type WelcomeGroupQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type WelcomeGroupQuery = { __typename?: 'Query', welcomeGroup: { __typename?: 'Group', id: string, name: string, emoji: string, hidden: boolean, groupType: GroupTypes } };

export type ToggleWelcomeGroupMutationVariables = Exact<{
  input: ToggleWelcomeGroupInput;
}>;


export type ToggleWelcomeGroupMutation = { __typename?: 'Mutation', toggleWelcomeGroup: { __typename?: 'Group', id: string, hidden: boolean } };

export type DirectMessageThreadsQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type DirectMessageThreadsQuery = { __typename?: 'Query', directMessageThreads: Array<(
    { __typename: 'DirectMessageUserThread' }
    & DirectMessageUserThreadFragmentFragment
  )> };

export type CreateDirectMessageMutationVariables = Exact<{
  input: CreateDirectMessageInput;
}>;


export type CreateDirectMessageMutation = { __typename?: 'Mutation', createDirectMessage: { __typename?: 'DirectMessageUserThread', id: string } };

export type DeleteDirectMessageThreadMutationVariables = Exact<{
  input: DeleteDirectMessageThreadInput;
}>;


export type DeleteDirectMessageThreadMutation = { __typename?: 'Mutation', deleteDirectMessageThread: { __typename?: 'DirectMessageUserThread', id: string } };

export type DirectMessageFragmentFragment = { __typename?: 'DirectMessage', id: string, messageId: string, createdOn: string, editedOn?: string | null, version: number, reactions: Array<{ __typename?: 'Reaction', id: string, type: ReactionTypes, hasReacted: boolean, numReactions: number, emojiUnicode: string, createdByUsers: Array<(
      { __typename?: 'User' }
      & UserPreviewFragment
    )> }>, resources: Array<{ __typename?: 'Resource', id: string, description?: string | null, domain?: string | null, favicon?: string | null, imageUrl?: string | null, logo?: string | null, logoTitle?: string | null, title?: string | null, url: string, expanded: boolean }>, media: Array<{ __typename?: 'Giphy', id: string, aspectRatio: number, giphyId: string, giphyUrl: string, giphyMp4Url: string, width: number, height: number } | { __typename?: 'Image', id: string, aspectRatio: number, publicId: string, width: number, height: number }> };

export type DirectMessageQueryVariables = Exact<{
  id: Scalars['String']['input'];
  threadId: Scalars['String']['input'];
}>;


export type DirectMessageQuery = { __typename?: 'Query', directMessage: (
    { __typename: 'DirectMessage' }
    & DirectMessageFragmentFragment
  ) };

export type CreateOrDeleteDirectMessageReactionMutationVariables = Exact<{
  input: CreateOrDeleteDirectMessageReactionInput;
}>;


export type CreateOrDeleteDirectMessageReactionMutation = { __typename?: 'Mutation', createOrDeleteDirectMessageReaction: (
    { __typename: 'DirectMessage' }
    & DirectMessageFragmentFragment
  ) };

export type DirectMessageNotificationCountsQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type DirectMessageNotificationCountsQuery = { __typename?: 'Query', directMessageNotificationCounts: { __typename: 'DirectMessageNotificationCounts', unread: number } };

export type UpdateDirectMessageThreadSettingsMutationVariables = Exact<{
  input: UpdateDirectMessageUserThreadSettingsInput;
}>;


export type UpdateDirectMessageThreadSettingsMutation = { __typename?: 'Mutation', updateDirectMessageThreadSettings: (
    { __typename: 'DirectMessageUserThreadSettings' }
    & DirectMessageUserThreadSettingsFragmentFragment
  ) };

export type MarkDirectMessageReadMutationVariables = Exact<{
  input: MarkDirectMessageReadInput;
}>;


export type MarkDirectMessageReadMutation = { __typename?: 'Mutation', markDirectMessageRead: { __typename?: 'DirectMessageUserThread', id: string } };

export type EventCoreFragment = { __typename: 'Event', id: string, name: string, description?: { [key: string]: any } | null, coverImageId?: string | null, emoji: string, createdAt: string, startAt: string, endAt: string, timeZone?: string | null, url?: string | null, meetingUrl?: string | null, deleted?: boolean | null, numMembers: number, numPosts: number, postCreationPolicy: PostCreationPolicy, groupType: GroupTypes, series?: { __typename?: 'EventSeries', id: string, recurringCronExpression: string, recurringOccurrencesLimit?: number | null } | null, location?: { __typename?: 'Location', id: string, placeId: string, type: LocationTypes, displayName: string, lon: number, lat: number } | null, membership?: { __typename?: 'GroupMembership', id: string, allowNewOccurrenceNotifications?: boolean | null, allowNewPostNotifications?: boolean | null, allowEventNotifications?: boolean | null } | null, members: Array<(
    { __typename?: 'User' }
    & UserPreviewFragment
  )>, createdBy: (
    { __typename?: 'User' }
    & UserPreviewFragment
  ) };

export type EventsQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  past?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type EventsQuery = { __typename?: 'Query', events: { __typename?: 'EventsConnection', totalCount: number, edges: Array<{ __typename?: 'EventObjectEdge', cursor: string, node: (
        { __typename?: 'Event' }
        & EventCoreFragment
      ) }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } };

export type PostsByEventQueryVariables = Exact<{
  eventId: Scalars['ID']['input'];
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type PostsByEventQuery = { __typename?: 'Query', postsByEvent: { __typename?: 'PostConnection', totalCount: number, edges: Array<{ __typename?: 'PostObjectEdge', cursor: string, node: (
        { __typename?: 'Post' }
        & PostFeedFragment
      ) }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } };

export type EventQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type EventQuery = { __typename?: 'Query', event: (
    { __typename: 'Event' }
    & EventCoreFragment
  ) | { __typename: 'EventError', message: string } };

export type CreateEventMutationVariables = Exact<{
  input: CreateEventInput;
}>;


export type CreateEventMutation = { __typename?: 'Mutation', createEvent: (
    { __typename?: 'Event' }
    & EventCoreFragment
  ) | { __typename?: 'EventError' } };

export type UpdateEventMutationVariables = Exact<{
  input: UpdateEventInput;
}>;


export type UpdateEventMutation = { __typename?: 'Mutation', updateEvent: (
    { __typename?: 'Event' }
    & EventCoreFragment
  ) | { __typename?: 'EventError' } };

export type DeleteEventMutationVariables = Exact<{
  input: DeleteEventInput;
}>;


export type DeleteEventMutation = { __typename?: 'Mutation', deleteEvent: { __typename?: 'Event', id: string } | { __typename?: 'EventError', message: string } };

export type CourseCoreFragment = { __typename: 'Course', id: string, name: string, description?: { [key: string]: any } | null, coverId?: string | null, deleted?: boolean | null, hidden?: boolean | null, emoji: string, members: Array<(
    { __typename?: 'User' }
    & UserPreviewFragment
  )>, createdBy: (
    { __typename?: 'User' }
    & UserPreviewFragment
  ) };

export type LessonCoreFragment = (
  { __typename?: 'Post', sortIndex?: number | null, completions: Array<{ __typename?: 'LessonCompletion', user: (
      { __typename?: 'User' }
      & UserPreviewFragment
    ) }> }
  & PostCoreFragment
);

export type LessonsQueryVariables = Exact<{
  courseId: Scalars['String']['input'];
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type LessonsQuery = { __typename?: 'Query', lessons: { __typename?: 'PostConnection', totalCount: number, edges: Array<{ __typename?: 'PostObjectEdge', cursor: string, node: (
        { __typename?: 'Post' }
        & LessonCoreFragment
      ) }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } };

export type CreateCourseMutationVariables = Exact<{
  input: CreateCourseInput;
}>;


export type CreateCourseMutation = { __typename?: 'Mutation', createCourse: (
    { __typename?: 'Course' }
    & CourseCoreFragment
  ) | { __typename?: 'CourseError' } };

export type ReorderLessonMutationVariables = Exact<{
  input: ReorderLessonInput;
}>;


export type ReorderLessonMutation = { __typename?: 'Mutation', reorderLesson: (
    { __typename?: 'Post' }
    & LessonCoreFragment
  ) | { __typename?: 'PostError' } };

export type ToggleLessonCompletedMutationVariables = Exact<{
  input: ToggleLessonCompletedInput;
}>;


export type ToggleLessonCompletedMutation = { __typename?: 'Mutation', toggleLessonCompleted: (
    { __typename?: 'Post' }
    & LessonCoreFragment
  ) | { __typename?: 'PostError' } };

export type UpsellCoreFragment = { __typename: 'Upsell', id: string, type: UpsellTypes, hidden: boolean };

export type UpsellsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type UpsellsQuery = { __typename?: 'Query', upsells: Array<(
    { __typename?: 'Upsell' }
    & UpsellCoreFragment
  )> };

export type HideUpsellMutationVariables = Exact<{
  input: HideUpsellInput;
}>;


export type HideUpsellMutation = { __typename?: 'Mutation', hideUpsell: (
    { __typename?: 'Upsell' }
    & UpsellCoreFragment
  ) | { __typename?: 'UpsellError' } };

export type PublishCourseMutationVariables = Exact<{
  input: PublishCourseInput;
}>;


export type PublishCourseMutation = { __typename?: 'Mutation', publishCourse: { __typename?: 'Group', id: string, isDraft: boolean } };

export type LocationByNameQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type LocationByNameQuery = { __typename?: 'Query', locationByName: Array<{ __typename?: 'Location', placeId: string, type: LocationTypes, displayName: string, lat: number, lon: number }> };

export type LocationByPlaceIdQueryVariables = Exact<{
  placeId: Scalars['String']['input'];
}>;


export type LocationByPlaceIdQuery = { __typename?: 'Query', locationByPlaceId: { __typename?: 'Location', placeId: string, type: LocationTypes, displayName: string, lat: number, lon: number } };

export type ToggleEventPostCreationPolicyMutationVariables = Exact<{
  input: ToggleEventPostCreationPolicyInput;
}>;


export type ToggleEventPostCreationPolicyMutation = { __typename?: 'Mutation', toggleEventPostCreationPolicy: (
    { __typename?: 'Event' }
    & EventCoreFragment
  ) | { __typename?: 'EventError' } };

export type ToggleEventNotificationsMutationVariables = Exact<{
  input: ToggleEventNotificationsInput;
}>;


export type ToggleEventNotificationsMutation = { __typename?: 'Mutation', toggleEventNotifications: (
    { __typename?: 'Event' }
    & EventCoreFragment
  ) | { __typename?: 'EventError' } };

export type CourseCompletionQueryVariables = Exact<{
  courseId: Scalars['ID']['input'];
}>;


export type CourseCompletionQuery = { __typename?: 'Query', courseCompletion: { __typename?: 'CourseCompletionPayload', id: string, percentage: number } };

export type ToggleOrganizationMembershipOnboardingTaskMutationVariables = Exact<{
  input: ToggleOrganizationMembershipOnboardingTaskInput;
}>;


export type ToggleOrganizationMembershipOnboardingTaskMutation = { __typename?: 'Mutation', toggleOrganizationMembershipOnboardingTask: { __typename?: 'OrganizationMembership', id: string, completedOnboardingTasks: Array<OrganizationMembershipOnboardingTasks> } };

export type CreateOrDeletePostBookmarkMutationVariables = Exact<{
  input: CreateOrDeletePostBookmarkInput;
}>;


export type CreateOrDeletePostBookmarkMutation = { __typename?: 'Mutation', createOrDeletePostBookmark: { __typename?: 'PostBookmark', post: (
      { __typename?: 'Post' }
      & PostFeedFragment
    ) } };

export type PostBookmarksQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type PostBookmarksQuery = { __typename?: 'Query', postBookmarks: { __typename?: 'PostBookmarkConnection', totalCount: number, edges: Array<{ __typename?: 'PostBookmarkObjectEdge', cursor: string, node: { __typename?: 'PostBookmark', post: (
          { __typename?: 'Post' }
          & PostFeedFragment
        ) } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } };

export type MenuItemCoreFragment = { __typename?: 'MenuItem', id: string, name?: string | null, sortIndex: number, group?: (
    { __typename?: 'Group', membership?: { __typename?: 'GroupMembership', id: string, allowNewOccurrenceNotifications?: boolean | null, allowNewPostNotifications?: boolean | null } | null }
    & GroupCoreFragment
  ) | null, post?: (
    { __typename?: 'Post' }
    & PostCoreFragment
  ) | null, resource?: (
    { __typename?: 'Resource' }
    & ResourceCoreFragment
  ) | null };

export type MenuSectionCoreFragment = { __typename?: 'MenuSection', id: string, type: MenuSectionTypes, name?: string | null, sortIndex: number, menuItems: Array<(
    { __typename?: 'MenuItem' }
    & MenuItemCoreFragment
  )> };

export type MenuSectionsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type MenuSectionsQuery = { __typename?: 'Query', menuSections: Array<(
    { __typename?: 'MenuSection' }
    & MenuSectionCoreFragment
  )> };

export type CreateMenuSectionMutationVariables = Exact<{
  input: CreateMenuSectionInput;
}>;


export type CreateMenuSectionMutation = { __typename?: 'Mutation', createMenuSection: (
    { __typename?: 'MenuSection' }
    & MenuSectionCoreFragment
  ) };

export type UpdateMenuSectionMutationVariables = Exact<{
  input: UpdateMenuSectionInput;
}>;


export type UpdateMenuSectionMutation = { __typename?: 'Mutation', updateMenuSection: (
    { __typename?: 'MenuSection' }
    & MenuSectionCoreFragment
  ) };

export type DeleteMenuSectionMutationVariables = Exact<{
  input: DeleteMenuSectionInput;
}>;


export type DeleteMenuSectionMutation = { __typename?: 'Mutation', deleteMenuSection: (
    { __typename?: 'MenuSection' }
    & MenuSectionCoreFragment
  ) };

export type ReorderMenuSectionsMutationVariables = Exact<{
  input: ReorderMenuSectionsInput;
}>;


export type ReorderMenuSectionsMutation = { __typename?: 'Mutation', reorderMenuSections: Array<(
    { __typename?: 'MenuSection' }
    & MenuSectionCoreFragment
  )> };

export type CreateMenuItemUrlMutationVariables = Exact<{
  input: CreateMenuItemUrlInput;
}>;


export type CreateMenuItemUrlMutation = { __typename?: 'Mutation', createMenuItemUrl: (
    { __typename?: 'MenuItem' }
    & MenuItemCoreFragment
  ) };

export type CreateMenuItemWithPostMutationVariables = Exact<{
  input: CreateMenuItemWithPostInput;
}>;


export type CreateMenuItemWithPostMutation = { __typename?: 'Mutation', createMenuItemWithPost: (
    { __typename?: 'MenuItem' }
    & MenuItemCoreFragment
  ) };

export type UpdateMenuItemMutationVariables = Exact<{
  input: UpdateMenuItemInput;
}>;


export type UpdateMenuItemMutation = { __typename?: 'Mutation', updateMenuItem: (
    { __typename?: 'MenuItem' }
    & MenuItemCoreFragment
  ) };

export type DeleteMenuItemMutationVariables = Exact<{
  input: DeleteMenuItemInput;
}>;


export type DeleteMenuItemMutation = { __typename?: 'Mutation', deleteMenuItem: (
    { __typename?: 'MenuItem' }
    & MenuItemCoreFragment
  ) };

export type ReorderMenuItemsMutationVariables = Exact<{
  input: ReorderMenuItemsInput;
}>;


export type ReorderMenuItemsMutation = { __typename?: 'Mutation', reorderMenuItems: Array<(
    { __typename?: 'MenuItem' }
    & MenuItemCoreFragment
  )> };

export type HelloPageBlockIntroductionCoreFragment = { __typename: 'HelloPageBlockIntroduction', id: string, type: HelloPageBlockTypes, sortIndex: number, title?: string | null, emojiUnicodeOrCustomImageId?: string | null, content?: { [key: string]: any } | null, createdAt: string };

export type HelloPageBlockNoteCoreFragment = { __typename: 'HelloPageBlockNote', id: string, type: HelloPageBlockTypes, sortIndex: number, content?: { [key: string]: any } | null, createdAt: string };

export type HelloPageBlockResourceCoreFragment = { __typename: 'HelloPageBlockResource', id: string, type: HelloPageBlockTypes, sortIndex: number, url?: string | null, title?: string | null, description?: string | null, createdAt: string, logo?: string | null, logoTitle?: string | null };

export type HelloPageBlockEmailCoreFragment = { __typename: 'HelloPageBlockEmail', id: string, type: HelloPageBlockTypes, sortIndex: number, createdAt: string, email?: string | null, title?: string | null, description?: string | null };

export type HelloPageBlockInternalCoreFragment = { __typename: 'HelloPageBlockInternal', id: string, type: HelloPageBlockTypes, sortIndex: number, createdAt: string, internalType: HelloPageBlockInternalTypes };

export type HelloPageBlockVideoMetaFragment = { __typename?: 'HelloPageBlockVideo', id: string, type: HelloPageBlockTypes, sortIndex: number, createdAt: string, user: { __typename?: 'User', id: string, username: string, firstName: string, position?: string | null }, video: { __typename?: 'Video', id: string } };

export type HelloPageBlockVideoCoreFragment = (
  { __typename: 'HelloPageBlockVideo', user: { __typename?: 'User', id: string, username: string, firstName: string }, video: { __typename?: 'Video', id: string, aspect?: number | null, mirror: boolean, playbackId?: string | null } }
  & HelloPageBlockVideoMetaFragment
);

export type HelloPageBlockImageCoreFragment = { __typename: 'HelloPageBlockImage', id: string, type: HelloPageBlockTypes, sortIndex: number, imageId: string };

export type HelloPageMetaFragment = { __typename?: 'HelloPage', id: string, slug: string, createdAt: string, firstName?: string | null, lastName?: string | null, title?: string | null, recipientProfileImageId?: string | null, emojiUnicodeOrCustomImageId?: string | null, template: HelloPageTemplateTypes, createdBy: { __typename?: 'User', id: string, firstName: string } };

export type HelloPageCoreFragment = (
  { __typename?: 'HelloPage', helloPageBlocks: Array<(
    { __typename: 'HelloPageBlockEmail' }
    & HelloPageBlockEmailCoreFragment
  ) | (
    { __typename: 'HelloPageBlockImage' }
    & HelloPageBlockImageCoreFragment
  ) | (
    { __typename: 'HelloPageBlockInternal' }
    & HelloPageBlockInternalCoreFragment
  ) | (
    { __typename: 'HelloPageBlockIntroduction' }
    & HelloPageBlockIntroductionCoreFragment
  ) | (
    { __typename: 'HelloPageBlockNote' }
    & HelloPageBlockNoteCoreFragment
  ) | (
    { __typename: 'HelloPageBlockResource' }
    & HelloPageBlockResourceCoreFragment
  ) | (
    { __typename: 'HelloPageBlockVideo' }
    & HelloPageBlockVideoCoreFragment
  )> }
  & HelloPageMetaFragment
);

export type HelloPageByShortIdQueryVariables = Exact<{
  shortId: Scalars['String']['input'];
}>;


export type HelloPageByShortIdQuery = { __typename?: 'Query', helloPageByShortId?: (
    { __typename?: 'HelloPage' }
    & HelloPageCoreFragment
  ) | null };

export type InboxStateQueryVariables = Exact<{ [key: string]: never; }>;


export type InboxStateQuery = { __typename?: 'Query', inboxState: { __typename?: 'InboxState', showRead?: boolean | null } };

export type OrganizationStatsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type OrganizationStatsQuery = { __typename?: 'Query', organizationStats: { __typename?: 'OrganizationStats', id: string, totalMembers: number, totalMembersInTheLast14Days: number, totalPosts: number, totalPostsInTheLast14Days: number, totalReactions: number, totalReactionsInTheLast14Days: number, totalComments: number, totalCommentsInTheLast14Days: number } };

export type MembersWithHighestPostCountQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type MembersWithHighestPostCountQuery = { __typename?: 'Query', membersWithHighestPostCount: Array<{ __typename?: 'MemberCountStat', id: string, count: number, user: (
      { __typename?: 'User' }
      & UserPreviewFragment
    ) }> };

export type MembersWithHighestCommentCountQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type MembersWithHighestCommentCountQuery = { __typename?: 'Query', membersWithHighestCommentCount: Array<{ __typename?: 'MemberCountStat', id: string, count: number, user: (
      { __typename?: 'User' }
      & UserPreviewFragment
    ) }> };

export type MembersWithHighestReactionCountQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type MembersWithHighestReactionCountQuery = { __typename?: 'Query', membersWithHighestReactionCount: Array<{ __typename?: 'MemberCountStat', id: string, count: number, user: (
      { __typename?: 'User' }
      & UserPreviewFragment
    ) }> };

export type MostRecentOrganizationActivityQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type MostRecentOrganizationActivityQuery = { __typename?: 'Query', mostRecentOrganizationActivity: Array<{ __typename?: 'MemberActivityStat', id: string, entityId: string, type: ActivityTypes, activityDate: string, user: (
      { __typename?: 'User' }
      & UserPreviewFragment
    ) }> };

export type NewMembershipsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type NewMembershipsQuery = { __typename?: 'Query', newMemberships: Array<{ __typename?: 'OrganizationMembership', id: string, createdAt: string, user: (
      { __typename?: 'User' }
      & UserPreviewFragment
    ) }> };

export type AddEntryToWaitlistMutationVariables = Exact<{
  input: AddEntryToWaitlistInput;
}>;


export type AddEntryToWaitlistMutation = { __typename?: 'Mutation', addEntryToWaitlist: { __typename?: 'WaitlistEntry', id: string } | { __typename?: 'WaitlistEntryError', errorCode: WaitlistEntryErrorCode } };

export type NotificationFragmentFragment = { __typename?: 'Notification', id: string, type: NotificationTypes, emojiUnicode?: string | null, createdAt: string, content: { [key: string]: any }, link: string, imageUrl?: string | null, eventName: NotificationEventNames, quoteContent?: { [key: string]: any } | null, read: boolean, user?: { __typename?: 'User', id: string, profileImageId?: string | null, avatarBgColor: string, avatar: string, username: string } | null };

export type NotificationsQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type NotificationsQuery = { __typename?: 'Query', notifications: { __typename?: 'NotificationConnection', totalCount: number, edges: Array<{ __typename?: 'NotificationObjectEdge', cursor: string, node: (
        { __typename?: 'Notification' }
        & NotificationFragmentFragment
      ) }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } };

export type NotificationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type NotificationQuery = { __typename?: 'Query', notification: (
    { __typename?: 'Notification', otherUser?: { __typename?: 'User', id: string, profileImageId?: string | null, avatarBgColor: string, avatar: string, username: string, firstName: string, email: string } | null, admin?: { __typename?: 'User', id: string, profileImageId?: string | null, avatarBgColor: string, avatar: string, username: string, firstName: string } | null, role?: { __typename?: 'OrganizationRole', id: string, label: string } | null, memberSubscription?: { __typename?: 'MemberSubscription', id: string, expiresAt: string } | null, memberSubscriptionPlan?: { __typename?: 'MemberSubscriptionPlan', id: string, name: string, priceMonthlyUsd: number } | null, group?: { __typename?: 'Group', id: string, type: GroupTypes, name: string } | null, requestToJoins: Array<{ __typename?: 'RequestToJoin', id: string, email: string, createdAt: string, status: RequestToJoinStatusTypes, user: { __typename?: 'User', id: string, profileImageId?: string | null, avatarBgColor: string, avatar: string, username: string, firstName: string } }> }
    & NotificationFragmentFragment
  ) };

export type UserPushEventsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserPushEventsSubscription = { __typename?: 'Subscription', userPushEvent: { __typename?: 'PushRefetchHint', id: string, organizationId: string, postId?: string | null, groupId?: string | null } };

export type NotificationCountsQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type NotificationCountsQuery = { __typename?: 'Query', notificationCounts: { __typename?: 'NotificationCounts', unread: number } };

export type MarkAllNotificationsReadMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type MarkAllNotificationsReadMutation = { __typename?: 'Mutation', markAllNotificationsRead: { __typename?: 'NotificationCounts', unread: number } };

export type MarkNotificationReadMutationVariables = Exact<{
  input: ReadNotificationInput;
}>;


export type MarkNotificationReadMutation = { __typename?: 'Mutation', markNotificationRead: (
    { __typename?: 'Notification' }
    & NotificationFragmentFragment
  ) };

export type CreateUserFcmPushSubscriptionMutationVariables = Exact<{
  input: CreateUserFcmPushSubscriptionInput;
}>;


export type CreateUserFcmPushSubscriptionMutation = { __typename?: 'Mutation', createUserFCMPushSubscription: { __typename?: 'User', id: string } };

export type ProfileSetupMutationVariables = Exact<{
  input: ProfileSetupInput;
}>;


export type ProfileSetupMutation = { __typename?: 'Mutation', profileSetup: { __typename?: 'User', id: string, username: string, email: string, name: string, profileImageId?: string | null, firstName: string, lastName: string, linkedinUrl?: string | null, instagramHandle?: string | null, hometown?: string | null, currentCity?: string | null, bio?: string | null, claimedAccount: boolean, avatarBgColor: string, avatar: string } };

export type UpdateUserOnboardingMutationVariables = Exact<{
  input: UpdateUserOnboardingInput;
}>;


export type UpdateUserOnboardingMutation = { __typename?: 'Mutation', updateUserOnboarding: { __typename?: 'User', id: string, username: string } };

export type AddPostViewMutationVariables = Exact<{
  input: AddViewInput;
}>;


export type AddPostViewMutation = { __typename?: 'Mutation', addPostView: { __typename?: 'Post', id: string } };

export type TogglePinnedPostMutationVariables = Exact<{
  input: TogglePinnedPostInput;
}>;


export type TogglePinnedPostMutation = { __typename?: 'Mutation', togglePinnedPost: { __typename?: 'Post', id: string, pinned: boolean } };

export type ToggleHideFromProfilePostMutationVariables = Exact<{
  input: ToggleHideFromProfilePostInput;
}>;


export type ToggleHideFromProfilePostMutation = { __typename?: 'Mutation', toggleHideFromProfilePost: { __typename?: 'Post', id: string, hideFromProfile: boolean } };

export type ToggleShowAuthorOnPostMutationVariables = Exact<{
  input: ToggleShowAuthorOnPostInput;
}>;


export type ToggleShowAuthorOnPostMutation = { __typename?: 'Mutation', toggleShowAuthorOnPost: { __typename?: 'Post', id: string, showAuthor: boolean } };

export type ToggleShowCommentsAndReactionsOnPostMutationVariables = Exact<{
  input: ToggleShowCommentsAndReactionsOnPostInput;
}>;


export type ToggleShowCommentsAndReactionsOnPostMutation = { __typename?: 'Mutation', toggleShowCommentsAndReactionsOnPost: { __typename?: 'Post', id: string, showCommentsAndReactions: boolean } };

export type WelcomePostQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type WelcomePostQuery = { __typename?: 'Query', welcomePost?: (
    { __typename: 'Post' }
    & PostFeedFragment
  ) | { __typename: 'PostError', message: string } | null };

export type ToggleWelcomePostMutationVariables = Exact<{
  input: ToggleWelcomePostInput;
}>;


export type ToggleWelcomePostMutation = { __typename?: 'Mutation', toggleWelcomePost: { __typename?: 'Post', id: string, deleted?: boolean | null } };

export type DismissWelcomePostMutationVariables = Exact<{
  input: DismissWelcomePostInput;
}>;


export type DismissWelcomePostMutation = { __typename?: 'Mutation', dismissWelcomePost: { __typename?: 'OrganizationMembership', id: string } };

export type NewMemberPostQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;


export type NewMemberPostQuery = { __typename?: 'Query', newMemberPost?: (
    { __typename?: 'Post' }
    & PostFeedFragment
  ) | null };

export type ToggleFollowPostMutationVariables = Exact<{
  input: ToggleFollowPostInput;
}>;


export type ToggleFollowPostMutation = { __typename?: 'Mutation', toggleFollowPost: { __typename?: 'Post', id: string } };

export type IsFollowingPostQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
  postId: Scalars['ID']['input'];
}>;


export type IsFollowingPostQuery = { __typename?: 'Query', isFollowingPost?: { __typename?: 'PostSubscription', id: string, postId: string } | null };

export type MovePostMutationVariables = Exact<{
  input: MovePostInput;
}>;


export type MovePostMutation = { __typename?: 'Mutation', movePost: { __typename?: 'Post', id: string } };

export type PublishDraftPostMutationVariables = Exact<{
  input: PublishDraftPostInput;
}>;


export type PublishDraftPostMutation = { __typename?: 'Mutation', publishDraftPost: { __typename?: 'Post', id: string, isDraft: boolean } };

export type RescheduleDraftPostMutationVariables = Exact<{
  input: RescheduleDraftPostInput;
}>;


export type RescheduleDraftPostMutation = { __typename?: 'Mutation', rescheduleDraftPost: { __typename?: 'Post', id: string, scheduledAt?: string | null } };

export type ShortIdSuggestionQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type ShortIdSuggestionQuery = { __typename?: 'Query', shortIdSuggestion: { __typename: 'OrganizationShortId', shortId: string } | { __typename: 'OrganizationShortIdError', message: string } };

export type UsernameAvailableQueryVariables = Exact<{
  username: Scalars['String']['input'];
}>;


export type UsernameAvailableQuery = { __typename?: 'Query', usernameAvailable: { __typename: 'Username', username: string } | { __typename: 'UsernameError', message: string } };

export type ShortIdAvailableQueryVariables = Exact<{
  shortId: Scalars['String']['input'];
}>;


export type ShortIdAvailableQuery = { __typename?: 'Query', shortIdAvailable: { __typename: 'OrganizationShortId', shortId: string } | { __typename: 'OrganizationShortIdError', message: string } };

export type CreateOrUpdateOrganizationMutationVariables = Exact<{
  input: CreateOrUpdateOrganizationInput;
}>;


export type CreateOrUpdateOrganizationMutation = { __typename?: 'Mutation', createOrUpdateOrganization: (
    { __typename?: 'Organization' }
    & OrganizationCoreFragment
  ) };

export type SetCustomDomainMutationVariables = Exact<{
  input: SetOrganizationCustomDomainInput;
}>;


export type SetCustomDomainMutation = { __typename?: 'Mutation', setCustomDomain: { __typename?: 'Organization', id: string, domain?: string | null } };

export type RemoveCustomDomainMutationVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type RemoveCustomDomainMutation = { __typename?: 'Mutation', removeCustomDomain: { __typename?: 'Organization', id: string, domain?: string | null } };

export type JoinOrganizationMutationVariables = Exact<{
  input: JoinOrganizationInput;
}>;


export type JoinOrganizationMutation = { __typename?: 'Mutation', joinOrganization: { __typename?: 'Organization', id: string, name: string, groupCreationPolicy: GroupCreationPolicy } | { __typename?: 'OrganizationJoinError', message: string } };

export type UpdateUserLanguageMutationVariables = Exact<{
  input: UpdateUserLanguageInput;
}>;


export type UpdateUserLanguageMutation = { __typename?: 'Mutation', updateUserLanguage: { __typename?: 'User', id: string, username: string } };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: string, username: string, name: string, profileImageId?: string | null, avatarBgColor: string, avatar: string, firstName: string, lastName: string, linkedinUrl?: string | null, instagramHandle?: string | null, hometown?: string | null, currentCity?: string | null, bio?: string | null, claimedAccount: boolean } };

export type OrganizationAdministratorsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type OrganizationAdministratorsQuery = { __typename?: 'Query', organization?: { __typename?: 'Organization', id: string, administrators: Array<{ __typename?: 'User', id: string, username: string, firstName: string, lastName: string, profileImageId?: string | null, avatar: string, avatarBgColor: string }> } | null };

export type OrganizationQuotaUsageQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type OrganizationQuotaUsageQuery = { __typename?: 'Query', organization?: { __typename?: 'Organization', id: string, quotaUsage: Array<{ __typename?: 'QuotaUsage', name: FeatureQuota, limit: number, used: number }> } | null };

export type AdminSubscriptionPlanPriceListQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminSubscriptionPlanPriceListQuery = { __typename?: 'Query', adminSubscriptionPlanPriceList: { __typename?: 'PriceList', prices: Array<{ __typename?: 'Price', id: string, unitAmount?: number | null, lookupKey: AdminSubscriptionPlanPriceLookupKey, currency: string }> } };

export type OrganizationEntitlementsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type OrganizationEntitlementsQuery = { __typename?: 'Query', organization?: { __typename?: 'Organization', id: string, entitlements?: { __typename?: 'OrganizationEntitlements', features: Array<FeatureEntitlement>, quotas: Array<{ __typename?: 'QuotaEntitlements', name: FeatureQuota, limit: number }> } | null } | null };

export type OrganizationAdminSubscriptionQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type OrganizationAdminSubscriptionQuery = { __typename?: 'Query', organization?: { __typename?: 'Organization', id: string, adminSubscription?: { __typename?: 'AdminSubscription', stripeSubscriptionStatus: string, stripeCancelAtPeriodEnd: boolean, subscriptionPlan: AdminSubscriptionPlanId, subscriptionPrice: AdminSubscriptionPlanPriceLookupKey, grandfathered: boolean, expiresAt: string } | null } | null };

export type OrganizationMemberSubscriptionPlanQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type OrganizationMemberSubscriptionPlanQuery = { __typename?: 'Query', organization?: { __typename?: 'Organization', id: string, memberSubscriptionPlan?: { __typename?: 'MemberSubscriptionPlan', id: string, name: string, description?: string | null, priceMonthlyUsd: number, active: boolean, stripeAccount?: { __typename?: 'StripeAccount', id: string, disabledReason?: string | null } | null, groupEntitlements: Array<{ __typename?: 'Group', id: string, name: string, description?: { [key: string]: any } | null, emoji: string, numPosts: number, groupType: GroupTypes }> } | null } | null };

export type MemberSubscriptionPlanQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type MemberSubscriptionPlanQuery = { __typename?: 'Query', memberSubscriptionPlan: { __typename?: 'MemberSubscriptionPlan', id: string, subscriberCount: number, pendingBalance: number, priceMonthlyUsd: number, totalRevenue: number, active: boolean, stripeAccount?: { __typename?: 'StripeAccount', id: string, createdAt: string } | null } };

export type ChangeAdminSubscriptionPlanMutationVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  lookupKey: AdminSubscriptionPlanPriceLookupKey;
}>;


export type ChangeAdminSubscriptionPlanMutation = { __typename?: 'Mutation', changeAdminSubscriptionPlan?: { __typename?: 'AdminSubscription', stripeSubscriptionStatus: string } | null };

export type UpsertMemberSubscriptionPlanMutationVariables = Exact<{
  input: UpsertMemberSubscriptionPlanInput;
}>;


export type UpsertMemberSubscriptionPlanMutation = { __typename?: 'Mutation', upsertMemberSubscriptionPlan: { __typename?: 'MemberSubscriptionPlan', id: string } };

export type CancelAdminSubscriptionMutationVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type CancelAdminSubscriptionMutation = { __typename?: 'Mutation', cancelAdminSubscription?: { __typename?: 'AdminSubscription', stripeSubscriptionStatus: string } | null };

export type CancelMemberSubscriptionMutationVariables = Exact<{
  memberSubscriptionId: Scalars['ID']['input'];
}>;


export type CancelMemberSubscriptionMutation = { __typename?: 'Mutation', cancelMemberSubscription?: { __typename?: 'MemberSubscription', id: string } | null };

export type ReactivateMemberSubscriptionMutationVariables = Exact<{
  memberSubscriptionId: Scalars['ID']['input'];
}>;


export type ReactivateMemberSubscriptionMutation = { __typename?: 'Mutation', reactivateMemberSubscription?: { __typename?: 'MemberSubscription', id: string } | null };

export type CreateAdminPlanCheckoutSessionMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  lookupKey: AdminSubscriptionPlanPriceLookupKey;
}>;


export type CreateAdminPlanCheckoutSessionMutation = { __typename?: 'Mutation', createAdminPlanCheckoutSession: { __typename?: 'CheckoutSession', url: string } };

export type CreateMemberPlanCheckoutSessionMutationVariables = Exact<{
  memberSubscriptionPlanId: Scalars['String']['input'];
  returnToGroupId?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateMemberPlanCheckoutSessionMutation = { __typename?: 'Mutation', createMemberPlanCheckoutSession: { __typename?: 'CheckoutSession', url: string } };

export type CreateAdminPlanPortalSessionMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  returnUrl: Scalars['String']['input'];
}>;


export type CreateAdminPlanPortalSessionMutation = { __typename?: 'Mutation', createAdminPlanPortalSession: { __typename?: 'CheckoutSession', url: string } };

export type BillingDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type BillingDetailsQuery = { __typename?: 'Query', viewer: { __typename?: 'User', id: string, username: string, billingDetails?: { __typename?: 'BillingDetails', address: string, name: string, email: string, cardBrand?: string | null, cardLast4?: string | null } | null } };

export type CreateExpressDashboardSessionMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type CreateExpressDashboardSessionMutation = { __typename?: 'Mutation', createExpressDashboardSession: { __typename?: 'CheckoutSession', url: string } };

export type CreateMemberPlanPortalSessionMutationVariables = Exact<{
  returnUrl: Scalars['String']['input'];
}>;


export type CreateMemberPlanPortalSessionMutation = { __typename?: 'Mutation', createMemberPlanPortalSession: { __typename?: 'CheckoutSession', url: string } };

export type CreateStripeAccountLinkSessionMutationVariables = Exact<{
  memberSubscriptionPlanId: Scalars['String']['input'];
}>;


export type CreateStripeAccountLinkSessionMutation = { __typename?: 'Mutation', createStripeAccountLinkSession: { __typename?: 'CheckoutSession', url: string } };

export type DeactivateMemberSubscriptionPlanMutationVariables = Exact<{
  memberSubscriptionPlanId: Scalars['String']['input'];
}>;


export type DeactivateMemberSubscriptionPlanMutation = { __typename?: 'Mutation', deactivateMemberSubscriptionPlan: { __typename?: 'MemberSubscriptionPlan', id: string, active: boolean } };

export type ReactivateMemberSubscriptionPlanMutationVariables = Exact<{
  memberSubscriptionPlanId: Scalars['String']['input'];
}>;


export type ReactivateMemberSubscriptionPlanMutation = { __typename?: 'Mutation', reactivateMemberSubscriptionPlan: { __typename?: 'MemberSubscriptionPlan', id: string, active: boolean } };

export type OrganizationByInviteCodeQueryVariables = Exact<{
  inviteCode: Scalars['ID']['input'];
}>;


export type OrganizationByInviteCodeQuery = { __typename?: 'Query', organizationByInviteCode?: { __typename?: 'Organization', id: string, shortId: string, color: string, name: string, description?: string | null, logoId?: string | null, isSetup: boolean, isGoogleWorkspace: boolean, createdAt: string, numMembers: number, numGroups: number, groupCreationPolicy: GroupCreationPolicy, joinPolicy: JoinPolicy, addMemberPolicy: AddMemberPolicy, enableNewMemberIntroductions: boolean, newMemberIntroductionMessage: string, isPublic: boolean, widgetSizeVariant: WidgetSizeVariant, hasCoursesEnabled: boolean, hasEventsEnabled: boolean, hasInsightsEnabled: boolean, templateType: OrganizationTemplateType } | null };

export type MembersQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<MembersOrderByInput>;
}>;


export type MembersQuery = { __typename?: 'Query', members: { __typename?: 'MembersPaginated', totalCount: number, items: Array<{ __typename?: 'OrganizationMembership', id: string, active: boolean, isCreator: boolean, createdAt: string, email: string, roles: Array<{ __typename?: 'OrganizationRole', id: string, label: string, color: OrganizationRoleColor, isAdmin: boolean, memberSubscriptionPlan?: { __typename?: 'MemberSubscriptionPlan', id: string, name: string } | null }>, user: { __typename?: 'User', id: string, name: string, firstName: string, username: string, avatarBgColor: string, avatar: string, profileImageId?: string | null, memberSubscriptions: Array<{ __typename?: 'MemberSubscription', id: string, subscriptionPlan: { __typename?: 'MemberSubscriptionPlan', id: string } }> } }> } };

export type SuspendUserMutationVariables = Exact<{
  input: SuspendUserInput;
}>;


export type SuspendUserMutation = { __typename?: 'Mutation', suspendUser: { __typename?: 'User', id: string, username: string } };

export type DemoteUserMutationVariables = Exact<{
  input: DemoteUserInput;
}>;


export type DemoteUserMutation = { __typename?: 'Mutation', demoteUser: { __typename?: 'User', id: string, username: string } };

export type LeaveOrganizationMutationVariables = Exact<{
  input: LeaveOrganizationInput;
}>;


export type LeaveOrganizationMutation = { __typename?: 'Mutation', leaveOrganization: { __typename?: 'User', id: string, username: string } };

export type PromoteUserMutationVariables = Exact<{
  input: PromoteUserInput;
}>;


export type PromoteUserMutation = { __typename?: 'Mutation', promoteUser: { __typename?: 'User', id: string, username: string } };

export type AssignRoleMutationVariables = Exact<{
  input: AssignRoleInput;
}>;


export type AssignRoleMutation = { __typename?: 'Mutation', assignRole: { __typename?: 'User', id: string, username: string } };

export type UnassignRoleMutationVariables = Exact<{
  input: UnassignRoleInput;
}>;


export type UnassignRoleMutation = { __typename?: 'Mutation', unassignRole: { __typename?: 'User', id: string, username: string } };

export type InviteUsersMutationVariables = Exact<{
  input: InviteUsersInput;
}>;


export type InviteUsersMutation = { __typename?: 'Mutation', inviteUsers: Array<{ __typename: 'RequestToJoin', id: string } | { __typename: 'RequestToJoinError', message: string } | { __typename: 'UserInvite', id: string, email: string, inviteCode: string }> };

export type RevokeUserInvitesMutationVariables = Exact<{
  input: RevokeUserInvitesInput;
}>;


export type RevokeUserInvitesMutation = { __typename?: 'Mutation', revokeUserInvites: Array<{ __typename?: 'UserInvite', id: string, email: string }> };

export type ResendUserInviteMutationVariables = Exact<{
  input: ResendUserInviteInput;
}>;


export type ResendUserInviteMutation = { __typename?: 'Mutation', resendUserInvite: { __typename?: 'UserInvite', id: string, email: string } };

export type ResetOrganizationInviteMutationVariables = Exact<{
  input: ResetOrganizationInviteInput;
}>;


export type ResetOrganizationInviteMutation = { __typename?: 'Mutation', resetOrganizationInvite: { __typename?: 'OrganizationInvite', id: string } };

export type RestoreUserMutationVariables = Exact<{
  input: RestoreUserInput;
}>;


export type RestoreUserMutation = { __typename?: 'Mutation', restoreUser: { __typename?: 'User', id: string, username: string } };

export type NotificationPreferencesQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type NotificationPreferencesQuery = { __typename?: 'Query', notificationPreferences: Array<{ __typename?: 'UserNotificationPreferences', id: string, type: NotificationPreferencesType, allowNewPostNotifications: boolean, allowCommentNotifications: boolean, allowMentionNotifications: boolean, allowReactionNotifications: boolean, allowReminderNotifications: boolean, allowPollNotifications: boolean, allowGroupNotifications: boolean, allowAccountNotifications: boolean, allowNewMemberRequestNotifications: boolean, allowUserJoinedNotifications: boolean, allowDirectMessageNotifications: boolean }> };

export type CreateUserWebPushSubscriptionMutationVariables = Exact<{
  input: CreateUserWebPushSubscriptionInput;
}>;


export type CreateUserWebPushSubscriptionMutation = { __typename?: 'Mutation', createUserWebPushSubscription: { __typename?: 'User', id: string, username: string } };

export type UpdateUserNotificationPreferencesMutationVariables = Exact<{
  input: UpdateUserNotificationPreferencesInput;
}>;


export type UpdateUserNotificationPreferencesMutation = { __typename?: 'Mutation', updateUserNotificationPreferences: { __typename?: 'UserNotificationPreferences', id: string, type: NotificationPreferencesType, allowNewPostNotifications: boolean, allowCommentNotifications: boolean, allowMentionNotifications: boolean, allowReactionNotifications: boolean, allowReminderNotifications: boolean, allowPollNotifications: boolean, allowGroupNotifications: boolean, allowAccountNotifications: boolean, allowNewMemberRequestNotifications: boolean, allowUserJoinedNotifications: boolean, allowDirectMessageNotifications: boolean } };

export type UpdateNewMemberIntroductionMessageMutationVariables = Exact<{
  input: UpdateNewMemberIntroductionMessageInput;
}>;


export type UpdateNewMemberIntroductionMessageMutation = { __typename?: 'Mutation', updateNewMemberIntroductionMessage: { __typename?: 'Organization', id: string, newMemberIntroductionMessage: string } };

export type ToggleNewMemberIntroductionsMutationVariables = Exact<{
  input: ToggleNewMemberIntroductionsInput;
}>;


export type ToggleNewMemberIntroductionsMutation = { __typename?: 'Mutation', toggleNewMemberIntroductions: { __typename?: 'Organization', id: string, enableNewMemberIntroductions: boolean } };

export type ToggleAutoMessageNewMembersMutationVariables = Exact<{
  input: ToggleAutoMessageNewMembersInput;
}>;


export type ToggleAutoMessageNewMembersMutation = { __typename?: 'Mutation', toggleAutoMessageNewMembers: { __typename?: 'Organization', id: string, enableAutoMessageNewMembers: boolean, autoMessageNewMembersFromId?: string | null } };

export type UpdateAutoMessageNewMembersMessageMutationVariables = Exact<{
  input: UpdateAutoMessageNewMembersMessageInput;
}>;


export type UpdateAutoMessageNewMembersMessageMutation = { __typename?: 'Mutation', updateAutoMessageNewMembersMessage: { __typename?: 'Organization', id: string, autoMessageNewMembersMessage: string, autoMessageNewMembersFromId?: string | null } };

export type TogglePublicCommunityMutationVariables = Exact<{
  input: TogglePublicCommunityInput;
}>;


export type TogglePublicCommunityMutation = { __typename?: 'Mutation', togglePublicCommunity: { __typename?: 'Organization', id: string, isPublic: boolean, joinPolicy: JoinPolicy, addMemberPolicy: AddMemberPolicy } };

export type CheckIfWidgetIsDetectedQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type CheckIfWidgetIsDetectedQuery = { __typename?: 'Query', checkIfWidgetIsDetected?: { __typename?: 'EmbedLoad', id: string, url: string } | null };

export type UpdateWidgetSizeVariantMutationVariables = Exact<{
  input: UpdateWidgetSizeVariantInput;
}>;


export type UpdateWidgetSizeVariantMutation = { __typename?: 'Mutation', updateWidgetSizeVariant: { __typename?: 'Organization', id: string, widgetSizeVariant: WidgetSizeVariant } };

export type DismissFrondIntroPostMutationVariables = Exact<{
  input: DismissFrondIntroPostInput;
}>;


export type DismissFrondIntroPostMutation = { __typename?: 'Mutation', dismissFrondIntroPost: { __typename?: 'OrganizationMembership', id: string, hideFrondIntroPost: boolean } };

export type ToggleOrganizationFeatureMutationVariables = Exact<{
  input: ToggleOrganizationFeatureInput;
}>;


export type ToggleOrganizationFeatureMutation = { __typename?: 'Mutation', toggleOrganizationFeature: { __typename?: 'Organization', id: string, hasCoursesEnabled: boolean, hasEventsEnabled: boolean, hasInsightsEnabled: boolean } };

export type CreateVideoPresignedUploadMutationVariables = Exact<{
  input: CreateVideoPresignedUploadInput;
}>;


export type CreateVideoPresignedUploadMutation = { __typename?: 'Mutation', createVideoPresignedUpload: { __typename?: 'VideoPresignedUpload', url: string, id: string } };

export type CreateTemporaryVideoPresignedUploadMutationVariables = Exact<{
  input: CreateTemporaryVideoPresignedUploadInput;
}>;


export type CreateTemporaryVideoPresignedUploadMutation = { __typename?: 'Mutation', createTemporaryVideoPresignedUpload: { __typename?: 'VideoPresignedUpload', url: string, id: string } };

export type VideoStatusQueryVariables = Exact<{
  videoId: Scalars['ID']['input'];
}>;


export type VideoStatusQuery = { __typename?: 'Query', video?: { __typename?: 'Video', id: string, aspect?: number | null, playbackId?: string | null, previewPlaybackId?: string | null, previewStatus?: VideoStatus | null, mirror: boolean, status: VideoStatus, type: VideoType, assetId?: string | null, createdAt: string } | null };

export const OrganizationAdminFieldsFragmentDoc = gql`
    fragment OrganizationAdminFields on Organization {
  id
  shortId
  name
  domain
  createdBy {
    email
    username
  }
  createdAt
}
    `;
export const UserProfileFragmentDoc = gql`
    fragment UserProfile on User {
  id
  username
  name
  profileImageId
  firstName
  lastName
  linkedinUrl
  instagramHandle
  instagramProfileImage
  avatarBgColor
  avatar
  currentCity
  hometown
  timeZone
  bio
  claimedAccount
  coverImageId
  createdAt
  profileVideo {
    playbackId
    aspect
  }
  preferences {
    id
    showQuestionsOnboarding
    isProfileSetup
    allowCookies
    allowEmailNotifications
  }
}
    `;
export const OrganizationCoreFragmentDoc = gql`
    fragment OrganizationCore on Organization {
  id
  shortId
  legacyShortId
  color
  name
  domain
  logoId
  description
  isGoogleWorkspace
  isSetup
  numMembers
  numGroups
  createdAt
  groupCreationPolicy
  joinPolicy
  addMemberPolicy
  widgetSizeVariant
  enableNewMemberIntroductions
  newMemberIntroductionMessage
  enableAutoMessageNewMembers
  autoMessageNewMembersMessage
  autoMessageNewMembersFromId
  isPublic
  hasCoursesEnabled
  hasEventsEnabled
  hasInsightsEnabled
  templateType
  adminSubscription {
    subscriptionPlan
  }
}
    `;
export const OrganizationExtendedFragmentDoc = gql`
    fragment OrganizationExtended on Organization {
  ...OrganizationCore
  memberSubscriptionPlan {
    id
    active
    name
    description
    priceMonthlyUsd
    pendingBalance
    subscriberCount
    totalRevenue
    groupEntitlements {
      id
      name
      description
      emoji
      numPosts
    }
  }
  customDomain {
    id
    status
    name
  }
}
    ${OrganizationCoreFragmentDoc}`;
export const MyResourceFragmentDoc = gql`
    fragment MyResource on Resource {
  id
  description
  domain
  imageUrl
  title
  url
  logo
  favicon
  expanded
}
    `;
export const OrganizationPublicFieldsFragmentDoc = gql`
    fragment OrganizationPublicFields on Organization {
  ...OrganizationCore
  activeMembers {
    id
    name
    firstName
    lastName
    username
    profileImageId
    avatar
    avatarBgColor
  }
}
    ${OrganizationCoreFragmentDoc}`;
export const ResourceCoreFragmentDoc = gql`
    fragment ResourceCore on Resource {
  id
  description
  domain
  favicon
  imageUrl
  logo
  logoTitle
  title
  url
}
    `;
export const UserPreviewFragmentDoc = gql`
    fragment UserPreview on User {
  id
  name
  firstName
  lastName
  username
  profileImageId
  avatar
  avatarBgColor
  coverImageId
}
    `;
export const PollCoreFragmentDoc = gql`
    fragment PollCore on Poll {
  id
  options {
    id
    name
    votes {
      createdBy {
        ...UserPreview
      }
    }
  }
}
    ${UserPreviewFragmentDoc}`;
export const PostCoreFragmentDoc = gql`
    fragment PostCore on Post {
  __typename
  id
  type
  bgColor
  deleted
  content
  createdAt
  updatedAt
  colorTheme
  aspectRatio
  pinned
  showAuthor
  showCommentsAndReactions
  title
  hideFromProfile
  isDraft
  scheduledAt
  resource {
    ...ResourceCore
  }
  resources {
    id
    description
    domain
    favicon
    imageUrl
    logo
    logoTitle
    title
    url
    expanded
  }
  media {
    ... on Image {
      id
      aspectRatio
      publicId
      width
      height
      coordinates {
        x
        y
        width
        height
      }
    }
    ... on Giphy {
      id
      aspectRatio
      giphyId
      giphyUrl
      giphyMp4Url
      width
      height
    }
    ... on Video {
      id
      aspect
      playbackId
      previewPlaybackId
      previewStatus
      mirror
      status
      type
      assetId
      createdAt
    }
  }
  poll {
    ...PollCore
  }
  createdBy {
    ...UserPreview
  }
  organization {
    ...OrganizationCore
  }
}
    ${ResourceCoreFragmentDoc}
${PollCoreFragmentDoc}
${UserPreviewFragmentDoc}
${OrganizationCoreFragmentDoc}`;
export const GroupCoreFragmentDoc = gql`
    fragment GroupCore on Group {
  __typename
  id
  name
  deleted
  description
  lastActivityAt
  questionEnabled
  isPrivate
  questionText
  questionCronExpression
  enableNewPostNotificationsForNewMembers
  timeZone
  hasPosts
  numPosts
  numMembers
  emoji
  coverImageId
  groupType: type
  hidden
  createdAt
  postCreationPolicy
  autoAddMembers
  sortIndex
  hasUnseenPosts
  includePostsOnHome
  isDraft
  createdBy {
    ...UserPreview
  }
  organization {
    ...OrganizationCore
  }
  unlockedBy {
    id
    active
  }
}
    ${UserPreviewFragmentDoc}
${OrganizationCoreFragmentDoc}`;
export const CommentCoreFragmentDoc = gql`
    fragment CommentCore on Comment {
  __typename
  id
  type
  content
  contentVersion
  isBestAnswer
  createdAt
  createdBy {
    ...UserPreview
  }
  reactions {
    id
    type
    hasReacted
    numReactions
    emojiUnicode
    createdByUsers {
      ...UserPreview
    }
  }
  resources {
    id
    description
    domain
    favicon
    imageUrl
    logo
    logoTitle
    title
    url
    expanded
  }
  media {
    ... on Image {
      id
      aspectRatio
      publicId
      width
      height
    }
    ... on Giphy {
      id
      aspectRatio
      giphyId
      giphyUrl
      giphyMp4Url
      width
      height
    }
  }
}
    ${UserPreviewFragmentDoc}`;
export const PostFeedFragmentDoc = gql`
    fragment PostFeed on Post {
  ...PostCore
  group {
    ...GroupCore
  }
  comments {
    ...CommentCore
  }
  reactions {
    id
    type
    hasReacted
    numReactions
    emojiUnicode
    createdByUsers {
      ...UserPreview
    }
  }
  revisions {
    id
    createdAt
  }
  numOfAnonymousViews
  views {
    id
    user {
      ...UserPreview
    }
  }
  completions {
    user {
      ...UserPreview
    }
  }
  isBookmarked
}
    ${PostCoreFragmentDoc}
${GroupCoreFragmentDoc}
${CommentCoreFragmentDoc}
${UserPreviewFragmentDoc}`;
export const GroupWithMembersFragmentDoc = gql`
    fragment GroupWithMembers on Group {
  ...GroupCore
  membership {
    id
    allowNewOccurrenceNotifications
    allowNewPostNotifications
  }
  createdBy {
    ...UserPreview
  }
  members {
    ...UserPreview
  }
}
    ${GroupCoreFragmentDoc}
${UserPreviewFragmentDoc}`;
export const UserGroupNotificationPreferencesFragmentDoc = gql`
    fragment UserGroupNotificationPreferences on Group {
  membership {
    allowNewOccurrenceNotifications
    allowNewPostNotifications
  }
}
    `;
export const GroupSidebarFragmentDoc = gql`
    fragment GroupSidebar on Group {
  ...GroupCore
  membership {
    id
    allowNewOccurrenceNotifications
    allowNewPostNotifications
  }
}
    ${GroupCoreFragmentDoc}`;
export const PaginatedSidebarGroupsConnectionFragmentDoc = gql`
    fragment PaginatedSidebarGroupsConnection on GroupsConnection {
  edges {
    node {
      ...GroupSidebar
    }
    cursor
  }
  totalCount
  pageInfo {
    endCursor
    hasNextPage
  }
}
    ${GroupSidebarFragmentDoc}`;
export const SearchResultFragmentDoc = gql`
    fragment SearchResult on Search {
  id
  excerpt
  createdAt
  link
  type
  createdBy {
    ...UserPreview
  }
  node {
    ... on Group {
      ...GroupCore
    }
  }
}
    ${UserPreviewFragmentDoc}
${GroupCoreFragmentDoc}`;
export const OrganizationRoleCoreFragmentDoc = gql`
    fragment OrganizationRoleCore on OrganizationRole {
  id
  label
  color
  isAdmin
  memberSubscriptionPlan {
    id
    name
  }
}
    `;
export const DirectMessageTokenFragmentFragmentDoc = gql`
    fragment DirectMessageTokenFragment on DirectMessageToken {
  communicationUserId
  token
  expiresOn
}
    `;
export const DirectMessageUserFragmentFragmentDoc = gql`
    fragment DirectMessageUserFragment on DirectMessageUser {
  id
  communicationUserId
  user {
    ...UserPreview
  }
}
    ${UserPreviewFragmentDoc}`;
export const DirectMessageUserThreadSettingsFragmentFragmentDoc = gql`
    fragment DirectMessageUserThreadSettingsFragment on DirectMessageUserThreadSettings {
  id
  markedUnread
  mutedNotifications
}
    `;
export const DirectMessageUserThreadFragmentFragmentDoc = gql`
    fragment DirectMessageUserThreadFragment on DirectMessageUserThread {
  id
  threadId
  lastMessageReceivedOn
  lastMessage
  unreadCount
  updatedAt
  createdBy {
    ...DirectMessageUserFragment
  }
  user {
    ...DirectMessageUserFragment
  }
  settings {
    ...DirectMessageUserThreadSettingsFragment
  }
}
    ${DirectMessageUserFragmentFragmentDoc}
${DirectMessageUserThreadSettingsFragmentFragmentDoc}`;
export const DirectMessageFragmentFragmentDoc = gql`
    fragment DirectMessageFragment on DirectMessage {
  id
  messageId
  createdOn
  editedOn
  version
  reactions {
    id
    type
    hasReacted
    numReactions
    emojiUnicode
    createdByUsers {
      ...UserPreview
    }
  }
  resources {
    id
    description
    domain
    favicon
    imageUrl
    logo
    logoTitle
    title
    url
    expanded
  }
  media {
    ... on Image {
      id
      aspectRatio
      publicId
      width
      height
    }
    ... on Giphy {
      id
      aspectRatio
      giphyId
      giphyUrl
      giphyMp4Url
      width
      height
    }
  }
}
    ${UserPreviewFragmentDoc}`;
export const EventCoreFragmentDoc = gql`
    fragment EventCore on Event {
  __typename
  id
  name
  description
  coverImageId
  emoji
  createdAt
  startAt
  endAt
  timeZone
  url
  groupType: type
  meetingUrl
  deleted
  numMembers
  numPosts
  postCreationPolicy
  series {
    id
    recurringCronExpression
    recurringOccurrencesLimit
  }
  location {
    id
    placeId
    type
    displayName
    lon
    lat
  }
  membership {
    id
    allowNewOccurrenceNotifications
    allowNewPostNotifications
    allowEventNotifications
  }
  members {
    ...UserPreview
  }
  createdBy {
    ...UserPreview
  }
}
    ${UserPreviewFragmentDoc}`;
export const CourseCoreFragmentDoc = gql`
    fragment CourseCore on Course {
  __typename
  id
  name
  description
  coverId
  deleted
  hidden
  emoji
  members {
    ...UserPreview
  }
  createdBy {
    ...UserPreview
  }
}
    ${UserPreviewFragmentDoc}`;
export const LessonCoreFragmentDoc = gql`
    fragment LessonCore on Post {
  ...PostCore
  sortIndex
  completions {
    user {
      ...UserPreview
    }
  }
}
    ${PostCoreFragmentDoc}
${UserPreviewFragmentDoc}`;
export const UpsellCoreFragmentDoc = gql`
    fragment UpsellCore on Upsell {
  __typename
  id
  type
  hidden
}
    `;
export const MenuItemCoreFragmentDoc = gql`
    fragment MenuItemCore on MenuItem {
  id
  name
  sortIndex
  group {
    ...GroupCore
    membership {
      id
      allowNewOccurrenceNotifications
      allowNewPostNotifications
    }
  }
  post {
    ...PostCore
  }
  resource {
    ...ResourceCore
  }
}
    ${GroupCoreFragmentDoc}
${PostCoreFragmentDoc}
${ResourceCoreFragmentDoc}`;
export const MenuSectionCoreFragmentDoc = gql`
    fragment MenuSectionCore on MenuSection {
  id
  type
  name
  sortIndex
  menuItems {
    ...MenuItemCore
  }
}
    ${MenuItemCoreFragmentDoc}`;
export const HelloPageMetaFragmentDoc = gql`
    fragment HelloPageMeta on HelloPage {
  id
  slug
  createdAt
  firstName
  lastName
  title
  recipientProfileImageId
  emojiUnicodeOrCustomImageId
  template
  createdBy {
    id
    firstName
  }
}
    `;
export const HelloPageBlockIntroductionCoreFragmentDoc = gql`
    fragment HelloPageBlockIntroductionCore on HelloPageBlockIntroduction {
  id
  type
  sortIndex
  title
  emojiUnicodeOrCustomImageId
  content
  createdAt
  __typename
}
    `;
export const HelloPageBlockNoteCoreFragmentDoc = gql`
    fragment HelloPageBlockNoteCore on HelloPageBlockNote {
  id
  type
  sortIndex
  type
  content
  createdAt
  __typename
}
    `;
export const HelloPageBlockResourceCoreFragmentDoc = gql`
    fragment HelloPageBlockResourceCore on HelloPageBlockResource {
  id
  type
  sortIndex
  url
  title
  description
  createdAt
  logo
  logoTitle
  __typename
}
    `;
export const HelloPageBlockVideoMetaFragmentDoc = gql`
    fragment HelloPageBlockVideoMeta on HelloPageBlockVideo {
  id
  type
  sortIndex
  user {
    id
    username
    firstName
    position
  }
  video {
    id
  }
  createdAt
}
    `;
export const HelloPageBlockVideoCoreFragmentDoc = gql`
    fragment HelloPageBlockVideoCore on HelloPageBlockVideo {
  ...HelloPageBlockVideoMeta
  user {
    id
    username
    firstName
  }
  video {
    id
    aspect
    mirror
    playbackId
  }
  __typename
}
    ${HelloPageBlockVideoMetaFragmentDoc}`;
export const HelloPageBlockImageCoreFragmentDoc = gql`
    fragment HelloPageBlockImageCore on HelloPageBlockImage {
  id
  type
  sortIndex
  imageId
  __typename
}
    `;
export const HelloPageBlockInternalCoreFragmentDoc = gql`
    fragment HelloPageBlockInternalCore on HelloPageBlockInternal {
  id
  type
  sortIndex
  createdAt
  internalType
  __typename
}
    `;
export const HelloPageBlockEmailCoreFragmentDoc = gql`
    fragment HelloPageBlockEmailCore on HelloPageBlockEmail {
  id
  type
  sortIndex
  createdAt
  email
  title
  description
  __typename
}
    `;
export const HelloPageCoreFragmentDoc = gql`
    fragment HelloPageCore on HelloPage {
  ...HelloPageMeta
  helloPageBlocks {
    __typename
    ... on HelloPageBlockIntroduction {
      ...HelloPageBlockIntroductionCore
    }
    ... on HelloPageBlockNote {
      ...HelloPageBlockNoteCore
    }
    ... on HelloPageBlockResource {
      ...HelloPageBlockResourceCore
    }
    ... on HelloPageBlockVideo {
      ...HelloPageBlockVideoCore
    }
    ... on HelloPageBlockImage {
      ...HelloPageBlockImageCore
    }
    ... on HelloPageBlockInternal {
      ...HelloPageBlockInternalCore
    }
    ... on HelloPageBlockEmail {
      ...HelloPageBlockEmailCore
    }
  }
}
    ${HelloPageMetaFragmentDoc}
${HelloPageBlockIntroductionCoreFragmentDoc}
${HelloPageBlockNoteCoreFragmentDoc}
${HelloPageBlockResourceCoreFragmentDoc}
${HelloPageBlockVideoCoreFragmentDoc}
${HelloPageBlockImageCoreFragmentDoc}
${HelloPageBlockInternalCoreFragmentDoc}
${HelloPageBlockEmailCoreFragmentDoc}`;
export const NotificationFragmentFragmentDoc = gql`
    fragment NotificationFragment on Notification {
  id
  type
  emojiUnicode
  createdAt
  content
  link
  imageUrl
  eventName
  quoteContent
  read
  user {
    id
    profileImageId
    avatarBgColor
    avatar
    username
  }
}
    `;
export const OrganizationsDocument = gql`
    query Organizations($first: Int!, $after: String, $query: String) {
  organizations(first: $first, query: $query, after: $after) {
    edges {
      node {
        ...OrganizationAdminFields
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
  }
}
    ${OrganizationAdminFieldsFragmentDoc}`;

/**
 * __useOrganizationsQuery__
 *
 * To run a query within a React component, call `useOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useOrganizationsQuery(baseOptions: Apollo.QueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables> & ({ variables: OrganizationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, options);
      }
export function useOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, options);
        }
export function useOrganizationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, options);
        }
export type OrganizationsQueryHookResult = ReturnType<typeof useOrganizationsQuery>;
export type OrganizationsLazyQueryHookResult = ReturnType<typeof useOrganizationsLazyQuery>;
export type OrganizationsSuspenseQueryHookResult = ReturnType<typeof useOrganizationsSuspenseQuery>;
export type OrganizationsQueryResult = Apollo.QueryResult<OrganizationsQuery, OrganizationsQueryVariables>;
export function refetchOrganizationsQuery(variables: OrganizationsQueryVariables) {
      return { query: OrganizationsDocument, variables: variables }
    }
export const AddOrganizationDocument = gql`
    mutation AddOrganization($input: AddOrganizationInput!) {
  addOrganization(input: $input) {
    ...OrganizationAdminFields
  }
}
    ${OrganizationAdminFieldsFragmentDoc}`;
export type AddOrganizationMutationFn = Apollo.MutationFunction<AddOrganizationMutation, AddOrganizationMutationVariables>;

/**
 * __useAddOrganizationMutation__
 *
 * To run a mutation, you first call `useAddOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrganizationMutation, { data, loading, error }] = useAddOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<AddOrganizationMutation, AddOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrganizationMutation, AddOrganizationMutationVariables>(AddOrganizationDocument, options);
      }
export type AddOrganizationMutationHookResult = ReturnType<typeof useAddOrganizationMutation>;
export type AddOrganizationMutationResult = Apollo.MutationResult<AddOrganizationMutation>;
export type AddOrganizationMutationOptions = Apollo.BaseMutationOptions<AddOrganizationMutation, AddOrganizationMutationVariables>;
export const SendDigestToUserDocument = gql`
    mutation SendDigestToUser($userEmail: String!) {
  sendDigestToUser(userEmail: $userEmail) {
    id
  }
}
    `;
export type SendDigestToUserMutationFn = Apollo.MutationFunction<SendDigestToUserMutation, SendDigestToUserMutationVariables>;

/**
 * __useSendDigestToUserMutation__
 *
 * To run a mutation, you first call `useSendDigestToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendDigestToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendDigestToUserMutation, { data, loading, error }] = useSendDigestToUserMutation({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useSendDigestToUserMutation(baseOptions?: Apollo.MutationHookOptions<SendDigestToUserMutation, SendDigestToUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendDigestToUserMutation, SendDigestToUserMutationVariables>(SendDigestToUserDocument, options);
      }
export type SendDigestToUserMutationHookResult = ReturnType<typeof useSendDigestToUserMutation>;
export type SendDigestToUserMutationResult = Apollo.MutationResult<SendDigestToUserMutation>;
export type SendDigestToUserMutationOptions = Apollo.BaseMutationOptions<SendDigestToUserMutation, SendDigestToUserMutationVariables>;
export const SendNotificationsDigestToUserDocument = gql`
    mutation SendNotificationsDigestToUser($userEmail: String!) {
  sendNotificationsDigestToUser(userEmail: $userEmail) {
    id
  }
}
    `;
export type SendNotificationsDigestToUserMutationFn = Apollo.MutationFunction<SendNotificationsDigestToUserMutation, SendNotificationsDigestToUserMutationVariables>;

/**
 * __useSendNotificationsDigestToUserMutation__
 *
 * To run a mutation, you first call `useSendNotificationsDigestToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendNotificationsDigestToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendNotificationsDigestToUserMutation, { data, loading, error }] = useSendNotificationsDigestToUserMutation({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useSendNotificationsDigestToUserMutation(baseOptions?: Apollo.MutationHookOptions<SendNotificationsDigestToUserMutation, SendNotificationsDigestToUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendNotificationsDigestToUserMutation, SendNotificationsDigestToUserMutationVariables>(SendNotificationsDigestToUserDocument, options);
      }
export type SendNotificationsDigestToUserMutationHookResult = ReturnType<typeof useSendNotificationsDigestToUserMutation>;
export type SendNotificationsDigestToUserMutationResult = Apollo.MutationResult<SendNotificationsDigestToUserMutation>;
export type SendNotificationsDigestToUserMutationOptions = Apollo.BaseMutationOptions<SendNotificationsDigestToUserMutation, SendNotificationsDigestToUserMutationVariables>;
export const DeleteOrganizationDocument = gql`
    mutation DeleteOrganization($organizationId: ID!) {
  deleteOrganization(organizationId: $organizationId) {
    id
  }
}
    `;
export type DeleteOrganizationMutationFn = Apollo.MutationFunction<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationMutation, { data, loading, error }] = useDeleteOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDeleteOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>(DeleteOrganizationDocument, options);
      }
export type DeleteOrganizationMutationHookResult = ReturnType<typeof useDeleteOrganizationMutation>;
export type DeleteOrganizationMutationResult = Apollo.MutationResult<DeleteOrganizationMutation>;
export type DeleteOrganizationMutationOptions = Apollo.BaseMutationOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;
export const DuplicateOrganizationDocument = gql`
    mutation DuplicateOrganization($input: DuplicateOrganizationInput!) {
  duplicateOrganization(input: $input) {
    ...OrganizationCore
  }
}
    ${OrganizationCoreFragmentDoc}`;
export type DuplicateOrganizationMutationFn = Apollo.MutationFunction<DuplicateOrganizationMutation, DuplicateOrganizationMutationVariables>;

/**
 * __useDuplicateOrganizationMutation__
 *
 * To run a mutation, you first call `useDuplicateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateOrganizationMutation, { data, loading, error }] = useDuplicateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateOrganizationMutation, DuplicateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateOrganizationMutation, DuplicateOrganizationMutationVariables>(DuplicateOrganizationDocument, options);
      }
export type DuplicateOrganizationMutationHookResult = ReturnType<typeof useDuplicateOrganizationMutation>;
export type DuplicateOrganizationMutationResult = Apollo.MutationResult<DuplicateOrganizationMutation>;
export type DuplicateOrganizationMutationOptions = Apollo.BaseMutationOptions<DuplicateOrganizationMutation, DuplicateOrganizationMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($userEmail: String!) {
  deleteUser(userEmail: $userEmail) {
    id
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const GetDemoOrganizationDocument = gql`
    mutation GetDemoOrganization {
  getDemoOrganization {
    ...OrganizationCore
  }
}
    ${OrganizationCoreFragmentDoc}`;
export type GetDemoOrganizationMutationFn = Apollo.MutationFunction<GetDemoOrganizationMutation, GetDemoOrganizationMutationVariables>;

/**
 * __useGetDemoOrganizationMutation__
 *
 * To run a mutation, you first call `useGetDemoOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetDemoOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getDemoOrganizationMutation, { data, loading, error }] = useGetDemoOrganizationMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetDemoOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<GetDemoOrganizationMutation, GetDemoOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetDemoOrganizationMutation, GetDemoOrganizationMutationVariables>(GetDemoOrganizationDocument, options);
      }
export type GetDemoOrganizationMutationHookResult = ReturnType<typeof useGetDemoOrganizationMutation>;
export type GetDemoOrganizationMutationResult = Apollo.MutationResult<GetDemoOrganizationMutation>;
export type GetDemoOrganizationMutationOptions = Apollo.BaseMutationOptions<GetDemoOrganizationMutation, GetDemoOrganizationMutationVariables>;
export const ViewerDocument = gql`
    query Viewer {
  viewer {
    id
    createdAt
    username
    email
    name
    profileImageId
    coverImageId
    firstName
    lastName
    bio
    claimedAccount
    position
    avatar
    avatarBgColor
    timeZone
    userInvites {
      id
      inviteCode
      organization {
        ...OrganizationCore
      }
    }
    memberSubscriptions {
      id
      stripeCancelAtPeriodEnd
      expiresAt
      subscriptionPlan {
        id
      }
    }
    organizationMemberships {
      id
      active
      createdAt
      isCreator
      hideFrondIntroPost
      completedOnboardingTasks
      roles {
        id
        label
        color
        isAdmin
        memberSubscriptionPlan {
          id
        }
      }
      organization {
        ...OrganizationCore
      }
    }
    preferences {
      id
      showQuestionsOnboarding
      questionsOnboardingCompletedAt
      isProfileSetup
      allowCookies
      allowEmailNotifications
      digestNotificationFrequency
    }
  }
}
    ${OrganizationCoreFragmentDoc}`;

/**
 * __useViewerQuery__
 *
 * To run a query within a React component, call `useViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerQuery(baseOptions?: Apollo.QueryHookOptions<ViewerQuery, ViewerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewerQuery, ViewerQueryVariables>(ViewerDocument, options);
      }
export function useViewerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewerQuery, ViewerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewerQuery, ViewerQueryVariables>(ViewerDocument, options);
        }
export function useViewerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ViewerQuery, ViewerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ViewerQuery, ViewerQueryVariables>(ViewerDocument, options);
        }
export type ViewerQueryHookResult = ReturnType<typeof useViewerQuery>;
export type ViewerLazyQueryHookResult = ReturnType<typeof useViewerLazyQuery>;
export type ViewerSuspenseQueryHookResult = ReturnType<typeof useViewerSuspenseQuery>;
export type ViewerQueryResult = Apollo.QueryResult<ViewerQuery, ViewerQueryVariables>;
export function refetchViewerQuery(variables?: ViewerQueryVariables) {
      return { query: ViewerDocument, variables: variables }
    }
export const UserDocument = gql`
    query User($username: String!, $organizationId: String!) {
  user(username: $username, organizationId: $organizationId) {
    ...UserProfile
    organizationMembership(organizationId: $organizationId) {
      id
      createdAt
      roles {
        id
        label
        color
        isAdmin
      }
    }
  }
}
    ${UserProfileFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      username: // value for 'username'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables> & ({ variables: UserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export function useUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<typeof useUserSuspenseQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export function refetchUserQuery(variables: UserQueryVariables) {
      return { query: UserDocument, variables: variables }
    }
export const UserGroupMembershipsDocument = gql`
    query UserGroupMemberships($username: String!, $organizationId: String!) {
  user(username: $username, organizationId: $organizationId) {
    id
    username
    groupMemberships(organizationId: $organizationId) {
      id
      group {
        id
        name
        emoji
      }
    }
  }
}
    `;

/**
 * __useUserGroupMembershipsQuery__
 *
 * To run a query within a React component, call `useUserGroupMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupMembershipsQuery({
 *   variables: {
 *      username: // value for 'username'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useUserGroupMembershipsQuery(baseOptions: Apollo.QueryHookOptions<UserGroupMembershipsQuery, UserGroupMembershipsQueryVariables> & ({ variables: UserGroupMembershipsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGroupMembershipsQuery, UserGroupMembershipsQueryVariables>(UserGroupMembershipsDocument, options);
      }
export function useUserGroupMembershipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGroupMembershipsQuery, UserGroupMembershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGroupMembershipsQuery, UserGroupMembershipsQueryVariables>(UserGroupMembershipsDocument, options);
        }
export function useUserGroupMembershipsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserGroupMembershipsQuery, UserGroupMembershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserGroupMembershipsQuery, UserGroupMembershipsQueryVariables>(UserGroupMembershipsDocument, options);
        }
export type UserGroupMembershipsQueryHookResult = ReturnType<typeof useUserGroupMembershipsQuery>;
export type UserGroupMembershipsLazyQueryHookResult = ReturnType<typeof useUserGroupMembershipsLazyQuery>;
export type UserGroupMembershipsSuspenseQueryHookResult = ReturnType<typeof useUserGroupMembershipsSuspenseQuery>;
export type UserGroupMembershipsQueryResult = Apollo.QueryResult<UserGroupMembershipsQuery, UserGroupMembershipsQueryVariables>;
export function refetchUserGroupMembershipsQuery(variables: UserGroupMembershipsQueryVariables) {
      return { query: UserGroupMembershipsDocument, variables: variables }
    }
export const AllUsersDocument = gql`
    query AllUsers($first: Int!, $after: String, $query: String) {
  allUsers(first: $first, query: $query, after: $after) {
    edges {
      node {
        ...UserProfile
        email
        organizationMemberships {
          id
          roles {
            id
            label
            color
            isAdmin
          }
          organization {
            ...OrganizationCore
          }
        }
      }
      cursor
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${UserProfileFragmentDoc}
${OrganizationCoreFragmentDoc}`;

/**
 * __useAllUsersQuery__
 *
 * To run a query within a React component, call `useAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllUsersQuery(baseOptions: Apollo.QueryHookOptions<AllUsersQuery, AllUsersQueryVariables> & ({ variables: AllUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
      }
export function useAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
        }
export function useAllUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
        }
export type AllUsersQueryHookResult = ReturnType<typeof useAllUsersQuery>;
export type AllUsersLazyQueryHookResult = ReturnType<typeof useAllUsersLazyQuery>;
export type AllUsersSuspenseQueryHookResult = ReturnType<typeof useAllUsersSuspenseQuery>;
export type AllUsersQueryResult = Apollo.QueryResult<AllUsersQuery, AllUsersQueryVariables>;
export function refetchAllUsersQuery(variables: AllUsersQueryVariables) {
      return { query: AllUsersDocument, variables: variables }
    }
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    ...UserProfile
  }
}
    ${UserProfileFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UsersDocument = gql`
    query Users($organizationId: String!, $groupId: String, $first: Int!, $after: String, $query: String) {
  users(
    organizationId: $organizationId
    groupId: $groupId
    first: $first
    after: $after
    query: $query
  ) {
    edges {
      cursor
      node {
        ... on User {
          id
          username
          name
          firstName
          profileImageId
          avatarBgColor
          avatar
          createdAt
          organizationMembership(organizationId: $organizationId) {
            id
            createdAt
            roles {
              id
              label
              color
              isAdmin
              memberSubscriptionPlan {
                id
                name
              }
            }
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
    totalCount
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      groupId: // value for 'groupId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useUsersQuery(baseOptions: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables> & ({ variables: UsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export function useUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersSuspenseQueryHookResult = ReturnType<typeof useUsersSuspenseQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export function refetchUsersQuery(variables: UsersQueryVariables) {
      return { query: UsersDocument, variables: variables }
    }
export const CreateOrDeleteReactionDocument = gql`
    mutation CreateOrDeleteReaction($input: CreateOrDeleteReactionInput!) {
  createOrDeleteReaction(input: $input) {
    __typename
    id
    type
    hasReacted
    numReactions
    emojiUnicode
    createdByUsers {
      id
      name
      firstName
      lastName
      username
      profileImageId
      avatar
      avatarBgColor
    }
  }
}
    `;
export type CreateOrDeleteReactionMutationFn = Apollo.MutationFunction<CreateOrDeleteReactionMutation, CreateOrDeleteReactionMutationVariables>;

/**
 * __useCreateOrDeleteReactionMutation__
 *
 * To run a mutation, you first call `useCreateOrDeleteReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrDeleteReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrDeleteReactionMutation, { data, loading, error }] = useCreateOrDeleteReactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrDeleteReactionMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrDeleteReactionMutation, CreateOrDeleteReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrDeleteReactionMutation, CreateOrDeleteReactionMutationVariables>(CreateOrDeleteReactionDocument, options);
      }
export type CreateOrDeleteReactionMutationHookResult = ReturnType<typeof useCreateOrDeleteReactionMutation>;
export type CreateOrDeleteReactionMutationResult = Apollo.MutationResult<CreateOrDeleteReactionMutation>;
export type CreateOrDeleteReactionMutationOptions = Apollo.BaseMutationOptions<CreateOrDeleteReactionMutation, CreateOrDeleteReactionMutationVariables>;
export const CommentDocument = gql`
    query Comment($id: ID!) {
  comment(id: $id) {
    ...CommentCore
  }
}
    ${CommentCoreFragmentDoc}`;

/**
 * __useCommentQuery__
 *
 * To run a query within a React component, call `useCommentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommentQuery(baseOptions: Apollo.QueryHookOptions<CommentQuery, CommentQueryVariables> & ({ variables: CommentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommentQuery, CommentQueryVariables>(CommentDocument, options);
      }
export function useCommentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentQuery, CommentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommentQuery, CommentQueryVariables>(CommentDocument, options);
        }
export function useCommentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CommentQuery, CommentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CommentQuery, CommentQueryVariables>(CommentDocument, options);
        }
export type CommentQueryHookResult = ReturnType<typeof useCommentQuery>;
export type CommentLazyQueryHookResult = ReturnType<typeof useCommentLazyQuery>;
export type CommentSuspenseQueryHookResult = ReturnType<typeof useCommentSuspenseQuery>;
export type CommentQueryResult = Apollo.QueryResult<CommentQuery, CommentQueryVariables>;
export function refetchCommentQuery(variables: CommentQueryVariables) {
      return { query: CommentDocument, variables: variables }
    }
export const CreateCommentDocument = gql`
    mutation CreateComment($input: CreateCommentInput!) {
  createComment(input: $input) {
    ...CommentCore
  }
}
    ${CommentCoreFragmentDoc}`;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<CreateCommentMutation, CreateCommentMutationVariables>;
export const UpdateCommentDocument = gql`
    mutation UpdateComment($input: UpdateCommentInput!) {
  updateComment(input: $input) {
    ...CommentCore
  }
}
    ${CommentCoreFragmentDoc}`;
export type UpdateCommentMutationFn = Apollo.MutationFunction<UpdateCommentMutation, UpdateCommentMutationVariables>;

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommentMutation, UpdateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommentMutation, UpdateCommentMutationVariables>(UpdateCommentDocument, options);
      }
export type UpdateCommentMutationHookResult = ReturnType<typeof useUpdateCommentMutation>;
export type UpdateCommentMutationResult = Apollo.MutationResult<UpdateCommentMutation>;
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<UpdateCommentMutation, UpdateCommentMutationVariables>;
export const ToggleCommentAsBestAnswerDocument = gql`
    mutation ToggleCommentAsBestAnswer($input: ToggleCommentAsBestAnswerInput!) {
  toggleCommentAsBestAnswer(input: $input) {
    ...CommentCore
  }
}
    ${CommentCoreFragmentDoc}`;
export type ToggleCommentAsBestAnswerMutationFn = Apollo.MutationFunction<ToggleCommentAsBestAnswerMutation, ToggleCommentAsBestAnswerMutationVariables>;

/**
 * __useToggleCommentAsBestAnswerMutation__
 *
 * To run a mutation, you first call `useToggleCommentAsBestAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleCommentAsBestAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleCommentAsBestAnswerMutation, { data, loading, error }] = useToggleCommentAsBestAnswerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleCommentAsBestAnswerMutation(baseOptions?: Apollo.MutationHookOptions<ToggleCommentAsBestAnswerMutation, ToggleCommentAsBestAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleCommentAsBestAnswerMutation, ToggleCommentAsBestAnswerMutationVariables>(ToggleCommentAsBestAnswerDocument, options);
      }
export type ToggleCommentAsBestAnswerMutationHookResult = ReturnType<typeof useToggleCommentAsBestAnswerMutation>;
export type ToggleCommentAsBestAnswerMutationResult = Apollo.MutationResult<ToggleCommentAsBestAnswerMutation>;
export type ToggleCommentAsBestAnswerMutationOptions = Apollo.BaseMutationOptions<ToggleCommentAsBestAnswerMutation, ToggleCommentAsBestAnswerMutationVariables>;
export const DeleteCommentDocument = gql`
    mutation DeleteComment($id: ID!) {
  deleteComment(id: $id) {
    id
  }
}
    `;
export type DeleteCommentMutationFn = Apollo.MutationFunction<DeleteCommentMutation, DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(DeleteCommentDocument, options);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<DeleteCommentMutation, DeleteCommentMutationVariables>;
export const SendFeedbackEmailDocument = gql`
    mutation SendFeedbackEmail($input: SendFeedbackEmailInput!) {
  sendFeedbackEmail(input: $input) {
    id
  }
}
    `;
export type SendFeedbackEmailMutationFn = Apollo.MutationFunction<SendFeedbackEmailMutation, SendFeedbackEmailMutationVariables>;

/**
 * __useSendFeedbackEmailMutation__
 *
 * To run a mutation, you first call `useSendFeedbackEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendFeedbackEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendFeedbackEmailMutation, { data, loading, error }] = useSendFeedbackEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendFeedbackEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendFeedbackEmailMutation, SendFeedbackEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendFeedbackEmailMutation, SendFeedbackEmailMutationVariables>(SendFeedbackEmailDocument, options);
      }
export type SendFeedbackEmailMutationHookResult = ReturnType<typeof useSendFeedbackEmailMutation>;
export type SendFeedbackEmailMutationResult = Apollo.MutationResult<SendFeedbackEmailMutation>;
export type SendFeedbackEmailMutationOptions = Apollo.BaseMutationOptions<SendFeedbackEmailMutation, SendFeedbackEmailMutationVariables>;
export const CommentConfigDocument = gql`
    query CommentConfig {
  commentConfig {
    maxCommentLength
  }
}
    `;

/**
 * __useCommentConfigQuery__
 *
 * To run a query within a React component, call `useCommentConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useCommentConfigQuery(baseOptions?: Apollo.QueryHookOptions<CommentConfigQuery, CommentConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommentConfigQuery, CommentConfigQueryVariables>(CommentConfigDocument, options);
      }
export function useCommentConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentConfigQuery, CommentConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommentConfigQuery, CommentConfigQueryVariables>(CommentConfigDocument, options);
        }
export function useCommentConfigSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CommentConfigQuery, CommentConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CommentConfigQuery, CommentConfigQueryVariables>(CommentConfigDocument, options);
        }
export type CommentConfigQueryHookResult = ReturnType<typeof useCommentConfigQuery>;
export type CommentConfigLazyQueryHookResult = ReturnType<typeof useCommentConfigLazyQuery>;
export type CommentConfigSuspenseQueryHookResult = ReturnType<typeof useCommentConfigSuspenseQuery>;
export type CommentConfigQueryResult = Apollo.QueryResult<CommentConfigQuery, CommentConfigQueryVariables>;
export function refetchCommentConfigQuery(variables?: CommentConfigQueryVariables) {
      return { query: CommentConfigDocument, variables: variables }
    }
export const OrganizationDocument = gql`
    query Organization($id: ID!) {
  organization(id: $id) {
    ...OrganizationExtended
  }
}
    ${OrganizationExtendedFragmentDoc}`;

/**
 * __useOrganizationQuery__
 *
 * To run a query within a React component, call `useOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationQuery(baseOptions: Apollo.QueryHookOptions<OrganizationQuery, OrganizationQueryVariables> & ({ variables: OrganizationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, options);
      }
export function useOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationQuery, OrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, options);
        }
export function useOrganizationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizationQuery, OrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, options);
        }
export type OrganizationQueryHookResult = ReturnType<typeof useOrganizationQuery>;
export type OrganizationLazyQueryHookResult = ReturnType<typeof useOrganizationLazyQuery>;
export type OrganizationSuspenseQueryHookResult = ReturnType<typeof useOrganizationSuspenseQuery>;
export type OrganizationQueryResult = Apollo.QueryResult<OrganizationQuery, OrganizationQueryVariables>;
export function refetchOrganizationQuery(variables: OrganizationQueryVariables) {
      return { query: OrganizationDocument, variables: variables }
    }
export const OrganizationByDomainDocument = gql`
    query OrganizationByDomain($domain: String!) {
  organizationByDomain(domain: $domain) {
    id
    shortId
  }
}
    `;

/**
 * __useOrganizationByDomainQuery__
 *
 * To run a query within a React component, call `useOrganizationByDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationByDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationByDomainQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useOrganizationByDomainQuery(baseOptions: Apollo.QueryHookOptions<OrganizationByDomainQuery, OrganizationByDomainQueryVariables> & ({ variables: OrganizationByDomainQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationByDomainQuery, OrganizationByDomainQueryVariables>(OrganizationByDomainDocument, options);
      }
export function useOrganizationByDomainLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationByDomainQuery, OrganizationByDomainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationByDomainQuery, OrganizationByDomainQueryVariables>(OrganizationByDomainDocument, options);
        }
export function useOrganizationByDomainSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizationByDomainQuery, OrganizationByDomainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizationByDomainQuery, OrganizationByDomainQueryVariables>(OrganizationByDomainDocument, options);
        }
export type OrganizationByDomainQueryHookResult = ReturnType<typeof useOrganizationByDomainQuery>;
export type OrganizationByDomainLazyQueryHookResult = ReturnType<typeof useOrganizationByDomainLazyQuery>;
export type OrganizationByDomainSuspenseQueryHookResult = ReturnType<typeof useOrganizationByDomainSuspenseQuery>;
export type OrganizationByDomainQueryResult = Apollo.QueryResult<OrganizationByDomainQuery, OrganizationByDomainQueryVariables>;
export function refetchOrganizationByDomainQuery(variables: OrganizationByDomainQueryVariables) {
      return { query: OrganizationByDomainDocument, variables: variables }
    }
export const OrganizationByShortIdDocument = gql`
    query OrganizationByShortId($shortId: ID!) {
  organizationByShortId(shortId: $shortId) {
    ...OrganizationExtended
  }
}
    ${OrganizationExtendedFragmentDoc}`;

/**
 * __useOrganizationByShortIdQuery__
 *
 * To run a query within a React component, call `useOrganizationByShortIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationByShortIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationByShortIdQuery({
 *   variables: {
 *      shortId: // value for 'shortId'
 *   },
 * });
 */
export function useOrganizationByShortIdQuery(baseOptions: Apollo.QueryHookOptions<OrganizationByShortIdQuery, OrganizationByShortIdQueryVariables> & ({ variables: OrganizationByShortIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationByShortIdQuery, OrganizationByShortIdQueryVariables>(OrganizationByShortIdDocument, options);
      }
export function useOrganizationByShortIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationByShortIdQuery, OrganizationByShortIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationByShortIdQuery, OrganizationByShortIdQueryVariables>(OrganizationByShortIdDocument, options);
        }
export function useOrganizationByShortIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizationByShortIdQuery, OrganizationByShortIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizationByShortIdQuery, OrganizationByShortIdQueryVariables>(OrganizationByShortIdDocument, options);
        }
export type OrganizationByShortIdQueryHookResult = ReturnType<typeof useOrganizationByShortIdQuery>;
export type OrganizationByShortIdLazyQueryHookResult = ReturnType<typeof useOrganizationByShortIdLazyQuery>;
export type OrganizationByShortIdSuspenseQueryHookResult = ReturnType<typeof useOrganizationByShortIdSuspenseQuery>;
export type OrganizationByShortIdQueryResult = Apollo.QueryResult<OrganizationByShortIdQuery, OrganizationByShortIdQueryVariables>;
export function refetchOrganizationByShortIdQuery(variables: OrganizationByShortIdQueryVariables) {
      return { query: OrganizationByShortIdDocument, variables: variables }
    }
export const OrganizationActiveMembersDocument = gql`
    query OrganizationActiveMembers($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    activeMembers {
      id
      username
      firstName
      lastName
      profileImageId
      avatar
      avatarBgColor
    }
  }
}
    `;

/**
 * __useOrganizationActiveMembersQuery__
 *
 * To run a query within a React component, call `useOrganizationActiveMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationActiveMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationActiveMembersQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationActiveMembersQuery(baseOptions: Apollo.QueryHookOptions<OrganizationActiveMembersQuery, OrganizationActiveMembersQueryVariables> & ({ variables: OrganizationActiveMembersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationActiveMembersQuery, OrganizationActiveMembersQueryVariables>(OrganizationActiveMembersDocument, options);
      }
export function useOrganizationActiveMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationActiveMembersQuery, OrganizationActiveMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationActiveMembersQuery, OrganizationActiveMembersQueryVariables>(OrganizationActiveMembersDocument, options);
        }
export function useOrganizationActiveMembersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizationActiveMembersQuery, OrganizationActiveMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizationActiveMembersQuery, OrganizationActiveMembersQueryVariables>(OrganizationActiveMembersDocument, options);
        }
export type OrganizationActiveMembersQueryHookResult = ReturnType<typeof useOrganizationActiveMembersQuery>;
export type OrganizationActiveMembersLazyQueryHookResult = ReturnType<typeof useOrganizationActiveMembersLazyQuery>;
export type OrganizationActiveMembersSuspenseQueryHookResult = ReturnType<typeof useOrganizationActiveMembersSuspenseQuery>;
export type OrganizationActiveMembersQueryResult = Apollo.QueryResult<OrganizationActiveMembersQuery, OrganizationActiveMembersQueryVariables>;
export function refetchOrganizationActiveMembersQuery(variables: OrganizationActiveMembersQueryVariables) {
      return { query: OrganizationActiveMembersDocument, variables: variables }
    }
export const CustomDomainVerificationInfoDocument = gql`
    query CustomDomainVerificationInfo($organizationId: String!) {
  customDomainVerificationInfo(organizationId: $organizationId) {
    apexName
    name
    verified
    verification {
      type
      domain
      value
      reason
    }
  }
}
    `;

/**
 * __useCustomDomainVerificationInfoQuery__
 *
 * To run a query within a React component, call `useCustomDomainVerificationInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomDomainVerificationInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomDomainVerificationInfoQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCustomDomainVerificationInfoQuery(baseOptions: Apollo.QueryHookOptions<CustomDomainVerificationInfoQuery, CustomDomainVerificationInfoQueryVariables> & ({ variables: CustomDomainVerificationInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomDomainVerificationInfoQuery, CustomDomainVerificationInfoQueryVariables>(CustomDomainVerificationInfoDocument, options);
      }
export function useCustomDomainVerificationInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomDomainVerificationInfoQuery, CustomDomainVerificationInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomDomainVerificationInfoQuery, CustomDomainVerificationInfoQueryVariables>(CustomDomainVerificationInfoDocument, options);
        }
export function useCustomDomainVerificationInfoSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CustomDomainVerificationInfoQuery, CustomDomainVerificationInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CustomDomainVerificationInfoQuery, CustomDomainVerificationInfoQueryVariables>(CustomDomainVerificationInfoDocument, options);
        }
export type CustomDomainVerificationInfoQueryHookResult = ReturnType<typeof useCustomDomainVerificationInfoQuery>;
export type CustomDomainVerificationInfoLazyQueryHookResult = ReturnType<typeof useCustomDomainVerificationInfoLazyQuery>;
export type CustomDomainVerificationInfoSuspenseQueryHookResult = ReturnType<typeof useCustomDomainVerificationInfoSuspenseQuery>;
export type CustomDomainVerificationInfoQueryResult = Apollo.QueryResult<CustomDomainVerificationInfoQuery, CustomDomainVerificationInfoQueryVariables>;
export function refetchCustomDomainVerificationInfoQuery(variables: CustomDomainVerificationInfoQueryVariables) {
      return { query: CustomDomainVerificationInfoDocument, variables: variables }
    }
export const UserActivityCountsDocument = gql`
    query UserActivityCounts($username: String!, $organizationId: String!) {
  userActivityCounts(username: $username, organizationId: $organizationId) {
    numGroups
    numPosts
  }
}
    `;

/**
 * __useUserActivityCountsQuery__
 *
 * To run a query within a React component, call `useUserActivityCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserActivityCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserActivityCountsQuery({
 *   variables: {
 *      username: // value for 'username'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useUserActivityCountsQuery(baseOptions: Apollo.QueryHookOptions<UserActivityCountsQuery, UserActivityCountsQueryVariables> & ({ variables: UserActivityCountsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserActivityCountsQuery, UserActivityCountsQueryVariables>(UserActivityCountsDocument, options);
      }
export function useUserActivityCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserActivityCountsQuery, UserActivityCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserActivityCountsQuery, UserActivityCountsQueryVariables>(UserActivityCountsDocument, options);
        }
export function useUserActivityCountsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserActivityCountsQuery, UserActivityCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserActivityCountsQuery, UserActivityCountsQueryVariables>(UserActivityCountsDocument, options);
        }
export type UserActivityCountsQueryHookResult = ReturnType<typeof useUserActivityCountsQuery>;
export type UserActivityCountsLazyQueryHookResult = ReturnType<typeof useUserActivityCountsLazyQuery>;
export type UserActivityCountsSuspenseQueryHookResult = ReturnType<typeof useUserActivityCountsSuspenseQuery>;
export type UserActivityCountsQueryResult = Apollo.QueryResult<UserActivityCountsQuery, UserActivityCountsQueryVariables>;
export function refetchUserActivityCountsQuery(variables: UserActivityCountsQueryVariables) {
      return { query: UserActivityCountsDocument, variables: variables }
    }
export const OrganizationInviteDocument = gql`
    query OrganizationInvite($organizationId: String!) {
  organizationInvite(organizationId: $organizationId) {
    inviteCode
    expiresAt
  }
}
    `;

/**
 * __useOrganizationInviteQuery__
 *
 * To run a query within a React component, call `useOrganizationInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationInviteQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationInviteQuery(baseOptions: Apollo.QueryHookOptions<OrganizationInviteQuery, OrganizationInviteQueryVariables> & ({ variables: OrganizationInviteQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationInviteQuery, OrganizationInviteQueryVariables>(OrganizationInviteDocument, options);
      }
export function useOrganizationInviteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationInviteQuery, OrganizationInviteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationInviteQuery, OrganizationInviteQueryVariables>(OrganizationInviteDocument, options);
        }
export function useOrganizationInviteSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizationInviteQuery, OrganizationInviteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizationInviteQuery, OrganizationInviteQueryVariables>(OrganizationInviteDocument, options);
        }
export type OrganizationInviteQueryHookResult = ReturnType<typeof useOrganizationInviteQuery>;
export type OrganizationInviteLazyQueryHookResult = ReturnType<typeof useOrganizationInviteLazyQuery>;
export type OrganizationInviteSuspenseQueryHookResult = ReturnType<typeof useOrganizationInviteSuspenseQuery>;
export type OrganizationInviteQueryResult = Apollo.QueryResult<OrganizationInviteQuery, OrganizationInviteQueryVariables>;
export function refetchOrganizationInviteQuery(variables: OrganizationInviteQueryVariables) {
      return { query: OrganizationInviteDocument, variables: variables }
    }
export const UpdateUserDigestNotificationFrequencyDocument = gql`
    mutation UpdateUserDigestNotificationFrequency($input: UpdateUserDigestNotificationFrequencyInput!) {
  updateUserDigestNotificationFrequency(input: $input) {
    id
    digestNotificationFrequency
  }
}
    `;
export type UpdateUserDigestNotificationFrequencyMutationFn = Apollo.MutationFunction<UpdateUserDigestNotificationFrequencyMutation, UpdateUserDigestNotificationFrequencyMutationVariables>;

/**
 * __useUpdateUserDigestNotificationFrequencyMutation__
 *
 * To run a mutation, you first call `useUpdateUserDigestNotificationFrequencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserDigestNotificationFrequencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserDigestNotificationFrequencyMutation, { data, loading, error }] = useUpdateUserDigestNotificationFrequencyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserDigestNotificationFrequencyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserDigestNotificationFrequencyMutation, UpdateUserDigestNotificationFrequencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserDigestNotificationFrequencyMutation, UpdateUserDigestNotificationFrequencyMutationVariables>(UpdateUserDigestNotificationFrequencyDocument, options);
      }
export type UpdateUserDigestNotificationFrequencyMutationHookResult = ReturnType<typeof useUpdateUserDigestNotificationFrequencyMutation>;
export type UpdateUserDigestNotificationFrequencyMutationResult = Apollo.MutationResult<UpdateUserDigestNotificationFrequencyMutation>;
export type UpdateUserDigestNotificationFrequencyMutationOptions = Apollo.BaseMutationOptions<UpdateUserDigestNotificationFrequencyMutation, UpdateUserDigestNotificationFrequencyMutationVariables>;
export const UnfurlUrlDocument = gql`
    mutation UnfurlUrl($input: UnfurlUrlInput!) {
  unfurlUrl(input: $input) {
    ...MyResource
    ... on ResourceError {
      message
    }
  }
}
    ${MyResourceFragmentDoc}`;
export type UnfurlUrlMutationFn = Apollo.MutationFunction<UnfurlUrlMutation, UnfurlUrlMutationVariables>;

/**
 * __useUnfurlUrlMutation__
 *
 * To run a mutation, you first call `useUnfurlUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfurlUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfurlUrlMutation, { data, loading, error }] = useUnfurlUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnfurlUrlMutation(baseOptions?: Apollo.MutationHookOptions<UnfurlUrlMutation, UnfurlUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnfurlUrlMutation, UnfurlUrlMutationVariables>(UnfurlUrlDocument, options);
      }
export type UnfurlUrlMutationHookResult = ReturnType<typeof useUnfurlUrlMutation>;
export type UnfurlUrlMutationResult = Apollo.MutationResult<UnfurlUrlMutation>;
export type UnfurlUrlMutationOptions = Apollo.BaseMutationOptions<UnfurlUrlMutation, UnfurlUrlMutationVariables>;
export const CreateImageDocument = gql`
    mutation CreateImage($input: CreateImageInput!) {
  createImage(input: $input) {
    id
    aspectRatio
    publicId
    height
    width
  }
}
    `;
export type CreateImageMutationFn = Apollo.MutationFunction<CreateImageMutation, CreateImageMutationVariables>;

/**
 * __useCreateImageMutation__
 *
 * To run a mutation, you first call `useCreateImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createImageMutation, { data, loading, error }] = useCreateImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateImageMutation(baseOptions?: Apollo.MutationHookOptions<CreateImageMutation, CreateImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateImageMutation, CreateImageMutationVariables>(CreateImageDocument, options);
      }
export type CreateImageMutationHookResult = ReturnType<typeof useCreateImageMutation>;
export type CreateImageMutationResult = Apollo.MutationResult<CreateImageMutation>;
export type CreateImageMutationOptions = Apollo.BaseMutationOptions<CreateImageMutation, CreateImageMutationVariables>;
export const CreateTemporaryImageDocument = gql`
    mutation CreateTemporaryImage($input: CreateTemporaryImageInput!) {
  createTemporaryImage(input: $input) {
    id
    aspectRatio
    publicId
    height
    width
  }
}
    `;
export type CreateTemporaryImageMutationFn = Apollo.MutationFunction<CreateTemporaryImageMutation, CreateTemporaryImageMutationVariables>;

/**
 * __useCreateTemporaryImageMutation__
 *
 * To run a mutation, you first call `useCreateTemporaryImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemporaryImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemporaryImageMutation, { data, loading, error }] = useCreateTemporaryImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTemporaryImageMutation(baseOptions?: Apollo.MutationHookOptions<CreateTemporaryImageMutation, CreateTemporaryImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTemporaryImageMutation, CreateTemporaryImageMutationVariables>(CreateTemporaryImageDocument, options);
      }
export type CreateTemporaryImageMutationHookResult = ReturnType<typeof useCreateTemporaryImageMutation>;
export type CreateTemporaryImageMutationResult = Apollo.MutationResult<CreateTemporaryImageMutation>;
export type CreateTemporaryImageMutationOptions = Apollo.BaseMutationOptions<CreateTemporaryImageMutation, CreateTemporaryImageMutationVariables>;
export const CreateGiphyDocument = gql`
    mutation CreateGiphy($input: CreateGiphyInput!) {
  createGiphy(input: $input) {
    id
    aspectRatio
    height
    width
    giphyMp4Url
    giphyUrl
    giphyId
  }
}
    `;
export type CreateGiphyMutationFn = Apollo.MutationFunction<CreateGiphyMutation, CreateGiphyMutationVariables>;

/**
 * __useCreateGiphyMutation__
 *
 * To run a mutation, you first call `useCreateGiphyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGiphyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGiphyMutation, { data, loading, error }] = useCreateGiphyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGiphyMutation(baseOptions?: Apollo.MutationHookOptions<CreateGiphyMutation, CreateGiphyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGiphyMutation, CreateGiphyMutationVariables>(CreateGiphyDocument, options);
      }
export type CreateGiphyMutationHookResult = ReturnType<typeof useCreateGiphyMutation>;
export type CreateGiphyMutationResult = Apollo.MutationResult<CreateGiphyMutation>;
export type CreateGiphyMutationOptions = Apollo.BaseMutationOptions<CreateGiphyMutation, CreateGiphyMutationVariables>;
export const PublicOrganizationDocument = gql`
    query PublicOrganization($id: ID!) {
  organization(id: $id) {
    ...OrganizationPublicFields
  }
}
    ${OrganizationPublicFieldsFragmentDoc}`;

/**
 * __usePublicOrganizationQuery__
 *
 * To run a query within a React component, call `usePublicOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublicOrganizationQuery(baseOptions: Apollo.QueryHookOptions<PublicOrganizationQuery, PublicOrganizationQueryVariables> & ({ variables: PublicOrganizationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicOrganizationQuery, PublicOrganizationQueryVariables>(PublicOrganizationDocument, options);
      }
export function usePublicOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicOrganizationQuery, PublicOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicOrganizationQuery, PublicOrganizationQueryVariables>(PublicOrganizationDocument, options);
        }
export function usePublicOrganizationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PublicOrganizationQuery, PublicOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PublicOrganizationQuery, PublicOrganizationQueryVariables>(PublicOrganizationDocument, options);
        }
export type PublicOrganizationQueryHookResult = ReturnType<typeof usePublicOrganizationQuery>;
export type PublicOrganizationLazyQueryHookResult = ReturnType<typeof usePublicOrganizationLazyQuery>;
export type PublicOrganizationSuspenseQueryHookResult = ReturnType<typeof usePublicOrganizationSuspenseQuery>;
export type PublicOrganizationQueryResult = Apollo.QueryResult<PublicOrganizationQuery, PublicOrganizationQueryVariables>;
export function refetchPublicOrganizationQuery(variables: PublicOrganizationQueryVariables) {
      return { query: PublicOrganizationDocument, variables: variables }
    }
export const PublicOrganizationShortIdsDocument = gql`
    query PublicOrganizationShortIds {
  publicOrganizationShortIds
}
    `;

/**
 * __usePublicOrganizationShortIdsQuery__
 *
 * To run a query within a React component, call `usePublicOrganizationShortIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicOrganizationShortIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicOrganizationShortIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublicOrganizationShortIdsQuery(baseOptions?: Apollo.QueryHookOptions<PublicOrganizationShortIdsQuery, PublicOrganizationShortIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicOrganizationShortIdsQuery, PublicOrganizationShortIdsQueryVariables>(PublicOrganizationShortIdsDocument, options);
      }
export function usePublicOrganizationShortIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicOrganizationShortIdsQuery, PublicOrganizationShortIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicOrganizationShortIdsQuery, PublicOrganizationShortIdsQueryVariables>(PublicOrganizationShortIdsDocument, options);
        }
export function usePublicOrganizationShortIdsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PublicOrganizationShortIdsQuery, PublicOrganizationShortIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PublicOrganizationShortIdsQuery, PublicOrganizationShortIdsQueryVariables>(PublicOrganizationShortIdsDocument, options);
        }
export type PublicOrganizationShortIdsQueryHookResult = ReturnType<typeof usePublicOrganizationShortIdsQuery>;
export type PublicOrganizationShortIdsLazyQueryHookResult = ReturnType<typeof usePublicOrganizationShortIdsLazyQuery>;
export type PublicOrganizationShortIdsSuspenseQueryHookResult = ReturnType<typeof usePublicOrganizationShortIdsSuspenseQuery>;
export type PublicOrganizationShortIdsQueryResult = Apollo.QueryResult<PublicOrganizationShortIdsQuery, PublicOrganizationShortIdsQueryVariables>;
export function refetchPublicOrganizationShortIdsQuery(variables?: PublicOrganizationShortIdsQueryVariables) {
      return { query: PublicOrganizationShortIdsDocument, variables: variables }
    }
export const VisitedPublicOrganizationsDocument = gql`
    query VisitedPublicOrganizations($userId: ID!) {
  visitedPublicOrganizations(userId: $userId) {
    ...OrganizationPublicFields
  }
}
    ${OrganizationPublicFieldsFragmentDoc}`;

/**
 * __useVisitedPublicOrganizationsQuery__
 *
 * To run a query within a React component, call `useVisitedPublicOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitedPublicOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitedPublicOrganizationsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useVisitedPublicOrganizationsQuery(baseOptions: Apollo.QueryHookOptions<VisitedPublicOrganizationsQuery, VisitedPublicOrganizationsQueryVariables> & ({ variables: VisitedPublicOrganizationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisitedPublicOrganizationsQuery, VisitedPublicOrganizationsQueryVariables>(VisitedPublicOrganizationsDocument, options);
      }
export function useVisitedPublicOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisitedPublicOrganizationsQuery, VisitedPublicOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisitedPublicOrganizationsQuery, VisitedPublicOrganizationsQueryVariables>(VisitedPublicOrganizationsDocument, options);
        }
export function useVisitedPublicOrganizationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VisitedPublicOrganizationsQuery, VisitedPublicOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VisitedPublicOrganizationsQuery, VisitedPublicOrganizationsQueryVariables>(VisitedPublicOrganizationsDocument, options);
        }
export type VisitedPublicOrganizationsQueryHookResult = ReturnType<typeof useVisitedPublicOrganizationsQuery>;
export type VisitedPublicOrganizationsLazyQueryHookResult = ReturnType<typeof useVisitedPublicOrganizationsLazyQuery>;
export type VisitedPublicOrganizationsSuspenseQueryHookResult = ReturnType<typeof useVisitedPublicOrganizationsSuspenseQuery>;
export type VisitedPublicOrganizationsQueryResult = Apollo.QueryResult<VisitedPublicOrganizationsQuery, VisitedPublicOrganizationsQueryVariables>;
export function refetchVisitedPublicOrganizationsQuery(variables: VisitedPublicOrganizationsQueryVariables) {
      return { query: VisitedPublicOrganizationsDocument, variables: variables }
    }
export const ReorderOrganizationMembershipDocument = gql`
    mutation ReorderOrganizationMembership($input: ReorderOrganizationMembershipInput!) {
  reorderOrganizationMembership(input: $input) {
    id
  }
}
    `;
export type ReorderOrganizationMembershipMutationFn = Apollo.MutationFunction<ReorderOrganizationMembershipMutation, ReorderOrganizationMembershipMutationVariables>;

/**
 * __useReorderOrganizationMembershipMutation__
 *
 * To run a mutation, you first call `useReorderOrganizationMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderOrganizationMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderOrganizationMembershipMutation, { data, loading, error }] = useReorderOrganizationMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReorderOrganizationMembershipMutation(baseOptions?: Apollo.MutationHookOptions<ReorderOrganizationMembershipMutation, ReorderOrganizationMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderOrganizationMembershipMutation, ReorderOrganizationMembershipMutationVariables>(ReorderOrganizationMembershipDocument, options);
      }
export type ReorderOrganizationMembershipMutationHookResult = ReturnType<typeof useReorderOrganizationMembershipMutation>;
export type ReorderOrganizationMembershipMutationResult = Apollo.MutationResult<ReorderOrganizationMembershipMutation>;
export type ReorderOrganizationMembershipMutationOptions = Apollo.BaseMutationOptions<ReorderOrganizationMembershipMutation, ReorderOrganizationMembershipMutationVariables>;
export const AddOrganizationVisitDocument = gql`
    mutation AddOrganizationVisit($input: AddOrganizationVisitInput!) {
  addOrganizationVisit(input: $input) {
    ...OrganizationPublicFields
  }
}
    ${OrganizationPublicFieldsFragmentDoc}`;
export type AddOrganizationVisitMutationFn = Apollo.MutationFunction<AddOrganizationVisitMutation, AddOrganizationVisitMutationVariables>;

/**
 * __useAddOrganizationVisitMutation__
 *
 * To run a mutation, you first call `useAddOrganizationVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrganizationVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrganizationVisitMutation, { data, loading, error }] = useAddOrganizationVisitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrganizationVisitMutation(baseOptions?: Apollo.MutationHookOptions<AddOrganizationVisitMutation, AddOrganizationVisitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrganizationVisitMutation, AddOrganizationVisitMutationVariables>(AddOrganizationVisitDocument, options);
      }
export type AddOrganizationVisitMutationHookResult = ReturnType<typeof useAddOrganizationVisitMutation>;
export type AddOrganizationVisitMutationResult = Apollo.MutationResult<AddOrganizationVisitMutation>;
export type AddOrganizationVisitMutationOptions = Apollo.BaseMutationOptions<AddOrganizationVisitMutation, AddOrganizationVisitMutationVariables>;
export const HideOrganizationVisitDocument = gql`
    mutation HideOrganizationVisit($input: HideOrganizationVisitInput!) {
  hideOrganizationVisit(input: $input) {
    ...OrganizationPublicFields
  }
}
    ${OrganizationPublicFieldsFragmentDoc}`;
export type HideOrganizationVisitMutationFn = Apollo.MutationFunction<HideOrganizationVisitMutation, HideOrganizationVisitMutationVariables>;

/**
 * __useHideOrganizationVisitMutation__
 *
 * To run a mutation, you first call `useHideOrganizationVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHideOrganizationVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hideOrganizationVisitMutation, { data, loading, error }] = useHideOrganizationVisitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHideOrganizationVisitMutation(baseOptions?: Apollo.MutationHookOptions<HideOrganizationVisitMutation, HideOrganizationVisitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HideOrganizationVisitMutation, HideOrganizationVisitMutationVariables>(HideOrganizationVisitDocument, options);
      }
export type HideOrganizationVisitMutationHookResult = ReturnType<typeof useHideOrganizationVisitMutation>;
export type HideOrganizationVisitMutationResult = Apollo.MutationResult<HideOrganizationVisitMutation>;
export type HideOrganizationVisitMutationOptions = Apollo.BaseMutationOptions<HideOrganizationVisitMutation, HideOrganizationVisitMutationVariables>;
export const GroupsDocument = gql`
    query Groups($organizationId: String!, $first: Int!, $after: String) {
  groups(organizationId: $organizationId, first: $first, after: $after) {
    edges {
      node {
        ...GroupWithMembers
      }
      cursor
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${GroupWithMembersFragmentDoc}`;

/**
 * __useGroupsQuery__
 *
 * To run a query within a React component, call `useGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGroupsQuery(baseOptions: Apollo.QueryHookOptions<GroupsQuery, GroupsQueryVariables> & ({ variables: GroupsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
      }
export function useGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
        }
export function useGroupsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
        }
export type GroupsQueryHookResult = ReturnType<typeof useGroupsQuery>;
export type GroupsLazyQueryHookResult = ReturnType<typeof useGroupsLazyQuery>;
export type GroupsSuspenseQueryHookResult = ReturnType<typeof useGroupsSuspenseQuery>;
export type GroupsQueryResult = Apollo.QueryResult<GroupsQuery, GroupsQueryVariables>;
export function refetchGroupsQuery(variables: GroupsQueryVariables) {
      return { query: GroupsDocument, variables: variables }
    }
export const SidebarGroupsDocument = gql`
    query SidebarGroups($organizationId: String!, $first: Int!, $after: String) {
  groups(organizationId: $organizationId, first: $first, after: $after) {
    ...PaginatedSidebarGroupsConnection
  }
}
    ${PaginatedSidebarGroupsConnectionFragmentDoc}`;

/**
 * __useSidebarGroupsQuery__
 *
 * To run a query within a React component, call `useSidebarGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSidebarGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSidebarGroupsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSidebarGroupsQuery(baseOptions: Apollo.QueryHookOptions<SidebarGroupsQuery, SidebarGroupsQueryVariables> & ({ variables: SidebarGroupsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SidebarGroupsQuery, SidebarGroupsQueryVariables>(SidebarGroupsDocument, options);
      }
export function useSidebarGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SidebarGroupsQuery, SidebarGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SidebarGroupsQuery, SidebarGroupsQueryVariables>(SidebarGroupsDocument, options);
        }
export function useSidebarGroupsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SidebarGroupsQuery, SidebarGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SidebarGroupsQuery, SidebarGroupsQueryVariables>(SidebarGroupsDocument, options);
        }
export type SidebarGroupsQueryHookResult = ReturnType<typeof useSidebarGroupsQuery>;
export type SidebarGroupsLazyQueryHookResult = ReturnType<typeof useSidebarGroupsLazyQuery>;
export type SidebarGroupsSuspenseQueryHookResult = ReturnType<typeof useSidebarGroupsSuspenseQuery>;
export type SidebarGroupsQueryResult = Apollo.QueryResult<SidebarGroupsQuery, SidebarGroupsQueryVariables>;
export function refetchSidebarGroupsQuery(variables: SidebarGroupsQueryVariables) {
      return { query: SidebarGroupsDocument, variables: variables }
    }
export const GroupDocument = gql`
    query Group($id: ID!) {
  group(id: $id) {
    __typename
    ... on Group {
      ...GroupWithMembers
    }
    ... on GroupError {
      reason
      message
    }
  }
}
    ${GroupWithMembersFragmentDoc}`;

/**
 * __useGroupQuery__
 *
 * To run a query within a React component, call `useGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGroupQuery(baseOptions: Apollo.QueryHookOptions<GroupQuery, GroupQueryVariables> & ({ variables: GroupQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupQuery, GroupQueryVariables>(GroupDocument, options);
      }
export function useGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupQuery, GroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupQuery, GroupQueryVariables>(GroupDocument, options);
        }
export function useGroupSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GroupQuery, GroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GroupQuery, GroupQueryVariables>(GroupDocument, options);
        }
export type GroupQueryHookResult = ReturnType<typeof useGroupQuery>;
export type GroupLazyQueryHookResult = ReturnType<typeof useGroupLazyQuery>;
export type GroupSuspenseQueryHookResult = ReturnType<typeof useGroupSuspenseQuery>;
export type GroupQueryResult = Apollo.QueryResult<GroupQuery, GroupQueryVariables>;
export function refetchGroupQuery(variables: GroupQueryVariables) {
      return { query: GroupDocument, variables: variables }
    }
export const GroupUnseenPostsDocument = gql`
    query GroupUnseenPosts($id: ID!) {
  group(id: $id) {
    __typename
    ... on Group {
      id
      hasUnseenPosts
    }
    ... on GroupError {
      message
    }
  }
}
    `;

/**
 * __useGroupUnseenPostsQuery__
 *
 * To run a query within a React component, call `useGroupUnseenPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupUnseenPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupUnseenPostsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGroupUnseenPostsQuery(baseOptions: Apollo.QueryHookOptions<GroupUnseenPostsQuery, GroupUnseenPostsQueryVariables> & ({ variables: GroupUnseenPostsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupUnseenPostsQuery, GroupUnseenPostsQueryVariables>(GroupUnseenPostsDocument, options);
      }
export function useGroupUnseenPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupUnseenPostsQuery, GroupUnseenPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupUnseenPostsQuery, GroupUnseenPostsQueryVariables>(GroupUnseenPostsDocument, options);
        }
export function useGroupUnseenPostsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GroupUnseenPostsQuery, GroupUnseenPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GroupUnseenPostsQuery, GroupUnseenPostsQueryVariables>(GroupUnseenPostsDocument, options);
        }
export type GroupUnseenPostsQueryHookResult = ReturnType<typeof useGroupUnseenPostsQuery>;
export type GroupUnseenPostsLazyQueryHookResult = ReturnType<typeof useGroupUnseenPostsLazyQuery>;
export type GroupUnseenPostsSuspenseQueryHookResult = ReturnType<typeof useGroupUnseenPostsSuspenseQuery>;
export type GroupUnseenPostsQueryResult = Apollo.QueryResult<GroupUnseenPostsQuery, GroupUnseenPostsQueryVariables>;
export function refetchGroupUnseenPostsQuery(variables: GroupUnseenPostsQueryVariables) {
      return { query: GroupUnseenPostsDocument, variables: variables }
    }
export const CreateAndJoinGroupDocument = gql`
    mutation CreateAndJoinGroup($input: CreateGroupInput!) {
  createAndJoinGroup(input: $input) {
    ...GroupCore
  }
}
    ${GroupCoreFragmentDoc}`;
export type CreateAndJoinGroupMutationFn = Apollo.MutationFunction<CreateAndJoinGroupMutation, CreateAndJoinGroupMutationVariables>;

/**
 * __useCreateAndJoinGroupMutation__
 *
 * To run a mutation, you first call `useCreateAndJoinGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAndJoinGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAndJoinGroupMutation, { data, loading, error }] = useCreateAndJoinGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAndJoinGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateAndJoinGroupMutation, CreateAndJoinGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAndJoinGroupMutation, CreateAndJoinGroupMutationVariables>(CreateAndJoinGroupDocument, options);
      }
export type CreateAndJoinGroupMutationHookResult = ReturnType<typeof useCreateAndJoinGroupMutation>;
export type CreateAndJoinGroupMutationResult = Apollo.MutationResult<CreateAndJoinGroupMutation>;
export type CreateAndJoinGroupMutationOptions = Apollo.BaseMutationOptions<CreateAndJoinGroupMutation, CreateAndJoinGroupMutationVariables>;
export const UpdateGroupDocument = gql`
    mutation UpdateGroup($input: UpdateGroupInput!) {
  updateGroup(input: $input) {
    ...GroupCore
  }
}
    ${GroupCoreFragmentDoc}`;
export type UpdateGroupMutationFn = Apollo.MutationFunction<UpdateGroupMutation, UpdateGroupMutationVariables>;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGroupMutation, UpdateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(UpdateGroupDocument, options);
      }
export type UpdateGroupMutationHookResult = ReturnType<typeof useUpdateGroupMutation>;
export type UpdateGroupMutationResult = Apollo.MutationResult<UpdateGroupMutation>;
export type UpdateGroupMutationOptions = Apollo.BaseMutationOptions<UpdateGroupMutation, UpdateGroupMutationVariables>;
export const DeleteGroupDocument = gql`
    mutation DeleteGroup($input: DeleteGroupInput!) {
  deleteGroup(input: $input) {
    ...GroupCore
  }
}
    ${GroupCoreFragmentDoc}`;
export type DeleteGroupMutationFn = Apollo.MutationFunction<DeleteGroupMutation, DeleteGroupMutationVariables>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGroupMutation, DeleteGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(DeleteGroupDocument, options);
      }
export type DeleteGroupMutationHookResult = ReturnType<typeof useDeleteGroupMutation>;
export type DeleteGroupMutationResult = Apollo.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = Apollo.BaseMutationOptions<DeleteGroupMutation, DeleteGroupMutationVariables>;
export const AddGroupMembersDocument = gql`
    mutation AddGroupMembers($input: AddGroupMembersInput!) {
  addGroupMembers(input: $input) {
    ...GroupCore
  }
}
    ${GroupCoreFragmentDoc}`;
export type AddGroupMembersMutationFn = Apollo.MutationFunction<AddGroupMembersMutation, AddGroupMembersMutationVariables>;

/**
 * __useAddGroupMembersMutation__
 *
 * To run a mutation, you first call `useAddGroupMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupMembersMutation, { data, loading, error }] = useAddGroupMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddGroupMembersMutation(baseOptions?: Apollo.MutationHookOptions<AddGroupMembersMutation, AddGroupMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddGroupMembersMutation, AddGroupMembersMutationVariables>(AddGroupMembersDocument, options);
      }
export type AddGroupMembersMutationHookResult = ReturnType<typeof useAddGroupMembersMutation>;
export type AddGroupMembersMutationResult = Apollo.MutationResult<AddGroupMembersMutation>;
export type AddGroupMembersMutationOptions = Apollo.BaseMutationOptions<AddGroupMembersMutation, AddGroupMembersMutationVariables>;
export const JoinGroupDocument = gql`
    mutation JoinGroup($input: JoinGroupInput!) {
  joinGroup(input: $input) {
    ...GroupCore
  }
}
    ${GroupCoreFragmentDoc}`;
export type JoinGroupMutationFn = Apollo.MutationFunction<JoinGroupMutation, JoinGroupMutationVariables>;

/**
 * __useJoinGroupMutation__
 *
 * To run a mutation, you first call `useJoinGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinGroupMutation, { data, loading, error }] = useJoinGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinGroupMutation(baseOptions?: Apollo.MutationHookOptions<JoinGroupMutation, JoinGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinGroupMutation, JoinGroupMutationVariables>(JoinGroupDocument, options);
      }
export type JoinGroupMutationHookResult = ReturnType<typeof useJoinGroupMutation>;
export type JoinGroupMutationResult = Apollo.MutationResult<JoinGroupMutation>;
export type JoinGroupMutationOptions = Apollo.BaseMutationOptions<JoinGroupMutation, JoinGroupMutationVariables>;
export const RemoveGroupMemberDocument = gql`
    mutation RemoveGroupMember($input: RemoveGroupMemberInput!) {
  removeGroupMember(input: $input) {
    ...GroupCore
  }
}
    ${GroupCoreFragmentDoc}`;
export type RemoveGroupMemberMutationFn = Apollo.MutationFunction<RemoveGroupMemberMutation, RemoveGroupMemberMutationVariables>;

/**
 * __useRemoveGroupMemberMutation__
 *
 * To run a mutation, you first call `useRemoveGroupMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGroupMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGroupMemberMutation, { data, loading, error }] = useRemoveGroupMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveGroupMemberMutation(baseOptions?: Apollo.MutationHookOptions<RemoveGroupMemberMutation, RemoveGroupMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveGroupMemberMutation, RemoveGroupMemberMutationVariables>(RemoveGroupMemberDocument, options);
      }
export type RemoveGroupMemberMutationHookResult = ReturnType<typeof useRemoveGroupMemberMutation>;
export type RemoveGroupMemberMutationResult = Apollo.MutationResult<RemoveGroupMemberMutation>;
export type RemoveGroupMemberMutationOptions = Apollo.BaseMutationOptions<RemoveGroupMemberMutation, RemoveGroupMemberMutationVariables>;
export const AddGroupViewDocument = gql`
    mutation AddGroupView($input: AddViewInput!) {
  addGroupView(input: $input) {
    id
  }
}
    `;
export type AddGroupViewMutationFn = Apollo.MutationFunction<AddGroupViewMutation, AddGroupViewMutationVariables>;

/**
 * __useAddGroupViewMutation__
 *
 * To run a mutation, you first call `useAddGroupViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupViewMutation, { data, loading, error }] = useAddGroupViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddGroupViewMutation(baseOptions?: Apollo.MutationHookOptions<AddGroupViewMutation, AddGroupViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddGroupViewMutation, AddGroupViewMutationVariables>(AddGroupViewDocument, options);
      }
export type AddGroupViewMutationHookResult = ReturnType<typeof useAddGroupViewMutation>;
export type AddGroupViewMutationResult = Apollo.MutationResult<AddGroupViewMutation>;
export type AddGroupViewMutationOptions = Apollo.BaseMutationOptions<AddGroupViewMutation, AddGroupViewMutationVariables>;
export const ReorderGroupsDocument = gql`
    mutation ReorderGroups($input: ReorderGroupsInput!) {
  reorderGroups(input: $input) {
    ...PaginatedSidebarGroupsConnection
  }
}
    ${PaginatedSidebarGroupsConnectionFragmentDoc}`;
export type ReorderGroupsMutationFn = Apollo.MutationFunction<ReorderGroupsMutation, ReorderGroupsMutationVariables>;

/**
 * __useReorderGroupsMutation__
 *
 * To run a mutation, you first call `useReorderGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderGroupsMutation, { data, loading, error }] = useReorderGroupsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReorderGroupsMutation(baseOptions?: Apollo.MutationHookOptions<ReorderGroupsMutation, ReorderGroupsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderGroupsMutation, ReorderGroupsMutationVariables>(ReorderGroupsDocument, options);
      }
export type ReorderGroupsMutationHookResult = ReturnType<typeof useReorderGroupsMutation>;
export type ReorderGroupsMutationResult = Apollo.MutationResult<ReorderGroupsMutation>;
export type ReorderGroupsMutationOptions = Apollo.BaseMutationOptions<ReorderGroupsMutation, ReorderGroupsMutationVariables>;
export const HomeFeedDocument = gql`
    query HomeFeed($first: Int!, $organizationId: String!, $after: String, $username: String, $tag: String) {
  homeFeed(
    first: $first
    organizationId: $organizationId
    after: $after
    username: $username
    tag: $tag
  ) {
    edges {
      node {
        ... on Post {
          __typename
          ...PostFeed
        }
        ... on OrganizationMembership {
          __typename
          id
          createdAt
          isCreator
        }
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
    totalCount
  }
}
    ${PostFeedFragmentDoc}`;

/**
 * __useHomeFeedQuery__
 *
 * To run a query within a React component, call `useHomeFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeFeedQuery({
 *   variables: {
 *      first: // value for 'first'
 *      organizationId: // value for 'organizationId'
 *      after: // value for 'after'
 *      username: // value for 'username'
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useHomeFeedQuery(baseOptions: Apollo.QueryHookOptions<HomeFeedQuery, HomeFeedQueryVariables> & ({ variables: HomeFeedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeFeedQuery, HomeFeedQueryVariables>(HomeFeedDocument, options);
      }
export function useHomeFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeFeedQuery, HomeFeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeFeedQuery, HomeFeedQueryVariables>(HomeFeedDocument, options);
        }
export function useHomeFeedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HomeFeedQuery, HomeFeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HomeFeedQuery, HomeFeedQueryVariables>(HomeFeedDocument, options);
        }
export type HomeFeedQueryHookResult = ReturnType<typeof useHomeFeedQuery>;
export type HomeFeedLazyQueryHookResult = ReturnType<typeof useHomeFeedLazyQuery>;
export type HomeFeedSuspenseQueryHookResult = ReturnType<typeof useHomeFeedSuspenseQuery>;
export type HomeFeedQueryResult = Apollo.QueryResult<HomeFeedQuery, HomeFeedQueryVariables>;
export function refetchHomeFeedQuery(variables: HomeFeedQueryVariables) {
      return { query: HomeFeedDocument, variables: variables }
    }
export const HomeFeedLatestContentDocument = gql`
    query HomeFeedLatestContent($organizationId: String!) {
  homeFeedLatestItem(organizationId: $organizationId) {
    __typename
    ... on Post {
      id
      createdAt
    }
    ... on OrganizationMembership {
      id
      createdAt
    }
  }
}
    `;

/**
 * __useHomeFeedLatestContentQuery__
 *
 * To run a query within a React component, call `useHomeFeedLatestContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeFeedLatestContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeFeedLatestContentQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useHomeFeedLatestContentQuery(baseOptions: Apollo.QueryHookOptions<HomeFeedLatestContentQuery, HomeFeedLatestContentQueryVariables> & ({ variables: HomeFeedLatestContentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeFeedLatestContentQuery, HomeFeedLatestContentQueryVariables>(HomeFeedLatestContentDocument, options);
      }
export function useHomeFeedLatestContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeFeedLatestContentQuery, HomeFeedLatestContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeFeedLatestContentQuery, HomeFeedLatestContentQueryVariables>(HomeFeedLatestContentDocument, options);
        }
export function useHomeFeedLatestContentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HomeFeedLatestContentQuery, HomeFeedLatestContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HomeFeedLatestContentQuery, HomeFeedLatestContentQueryVariables>(HomeFeedLatestContentDocument, options);
        }
export type HomeFeedLatestContentQueryHookResult = ReturnType<typeof useHomeFeedLatestContentQuery>;
export type HomeFeedLatestContentLazyQueryHookResult = ReturnType<typeof useHomeFeedLatestContentLazyQuery>;
export type HomeFeedLatestContentSuspenseQueryHookResult = ReturnType<typeof useHomeFeedLatestContentSuspenseQuery>;
export type HomeFeedLatestContentQueryResult = Apollo.QueryResult<HomeFeedLatestContentQuery, HomeFeedLatestContentQueryVariables>;
export function refetchHomeFeedLatestContentQuery(variables: HomeFeedLatestContentQueryVariables) {
      return { query: HomeFeedLatestContentDocument, variables: variables }
    }
export const UpsertPostDocument = gql`
    mutation UpsertPost($input: UpsertPostInput!) {
  upsertPost(input: $input) {
    __typename
    ... on Post {
      id
    }
    ... on PostError {
      message
    }
  }
}
    `;
export type UpsertPostMutationFn = Apollo.MutationFunction<UpsertPostMutation, UpsertPostMutationVariables>;

/**
 * __useUpsertPostMutation__
 *
 * To run a mutation, you first call `useUpsertPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPostMutation, { data, loading, error }] = useUpsertPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertPostMutation(baseOptions?: Apollo.MutationHookOptions<UpsertPostMutation, UpsertPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertPostMutation, UpsertPostMutationVariables>(UpsertPostDocument, options);
      }
export type UpsertPostMutationHookResult = ReturnType<typeof useUpsertPostMutation>;
export type UpsertPostMutationResult = Apollo.MutationResult<UpsertPostMutation>;
export type UpsertPostMutationOptions = Apollo.BaseMutationOptions<UpsertPostMutation, UpsertPostMutationVariables>;
export const PostDocument = gql`
    query Post($id: ID!) {
  post(id: $id) {
    __typename
    ... on Post {
      ...PostFeed
    }
    ... on PostError {
      message
    }
  }
}
    ${PostFeedFragmentDoc}`;

/**
 * __usePostQuery__
 *
 * To run a query within a React component, call `usePostQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePostQuery(baseOptions: Apollo.QueryHookOptions<PostQuery, PostQueryVariables> & ({ variables: PostQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostQuery, PostQueryVariables>(PostDocument, options);
      }
export function usePostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostQuery, PostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostQuery, PostQueryVariables>(PostDocument, options);
        }
export function usePostSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PostQuery, PostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PostQuery, PostQueryVariables>(PostDocument, options);
        }
export type PostQueryHookResult = ReturnType<typeof usePostQuery>;
export type PostLazyQueryHookResult = ReturnType<typeof usePostLazyQuery>;
export type PostSuspenseQueryHookResult = ReturnType<typeof usePostSuspenseQuery>;
export type PostQueryResult = Apollo.QueryResult<PostQuery, PostQueryVariables>;
export function refetchPostQuery(variables: PostQueryVariables) {
      return { query: PostDocument, variables: variables }
    }
export const DeletePostDocument = gql`
    mutation DeletePost($input: DeletePostInput!) {
  deletePost(input: $input) {
    __typename
    ... on Post {
      id
    }
    ... on PostError {
      message
    }
  }
}
    `;
export type DeletePostMutationFn = Apollo.MutationFunction<DeletePostMutation, DeletePostMutationVariables>;

/**
 * __useDeletePostMutation__
 *
 * To run a mutation, you first call `useDeletePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostMutation, { data, loading, error }] = useDeletePostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePostMutation(baseOptions?: Apollo.MutationHookOptions<DeletePostMutation, DeletePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePostMutation, DeletePostMutationVariables>(DeletePostDocument, options);
      }
export type DeletePostMutationHookResult = ReturnType<typeof useDeletePostMutation>;
export type DeletePostMutationResult = Apollo.MutationResult<DeletePostMutation>;
export type DeletePostMutationOptions = Apollo.BaseMutationOptions<DeletePostMutation, DeletePostMutationVariables>;
export const CreateTemporaryPostDocument = gql`
    mutation CreateTemporaryPost($input: CreateTemporaryPostInput!) {
  createTemporaryPost(input: $input) {
    id
    token
    userId
  }
}
    `;
export type CreateTemporaryPostMutationFn = Apollo.MutationFunction<CreateTemporaryPostMutation, CreateTemporaryPostMutationVariables>;

/**
 * __useCreateTemporaryPostMutation__
 *
 * To run a mutation, you first call `useCreateTemporaryPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemporaryPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemporaryPostMutation, { data, loading, error }] = useCreateTemporaryPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTemporaryPostMutation(baseOptions?: Apollo.MutationHookOptions<CreateTemporaryPostMutation, CreateTemporaryPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTemporaryPostMutation, CreateTemporaryPostMutationVariables>(CreateTemporaryPostDocument, options);
      }
export type CreateTemporaryPostMutationHookResult = ReturnType<typeof useCreateTemporaryPostMutation>;
export type CreateTemporaryPostMutationResult = Apollo.MutationResult<CreateTemporaryPostMutation>;
export type CreateTemporaryPostMutationOptions = Apollo.BaseMutationOptions<CreateTemporaryPostMutation, CreateTemporaryPostMutationVariables>;
export const TemporaryPostPollDocument = gql`
    query TemporaryPostPoll($token: String!) {
  temporaryPost(token: $token) {
    id
    token
    media {
      ... on Video {
        id
        aspect
        playbackId
        previewPlaybackId
        previewStatus
        mirror
        status
        type
        assetId
        createdAt
      }
      ... on Image {
        id
        aspectRatio
        publicId
        width
        height
      }
      ... on Giphy {
        id
        aspectRatio
        giphyId
        giphyUrl
        giphyMp4Url
        width
        height
      }
    }
  }
}
    `;

/**
 * __useTemporaryPostPollQuery__
 *
 * To run a query within a React component, call `useTemporaryPostPollQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemporaryPostPollQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemporaryPostPollQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useTemporaryPostPollQuery(baseOptions: Apollo.QueryHookOptions<TemporaryPostPollQuery, TemporaryPostPollQueryVariables> & ({ variables: TemporaryPostPollQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemporaryPostPollQuery, TemporaryPostPollQueryVariables>(TemporaryPostPollDocument, options);
      }
export function useTemporaryPostPollLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemporaryPostPollQuery, TemporaryPostPollQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemporaryPostPollQuery, TemporaryPostPollQueryVariables>(TemporaryPostPollDocument, options);
        }
export function useTemporaryPostPollSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TemporaryPostPollQuery, TemporaryPostPollQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TemporaryPostPollQuery, TemporaryPostPollQueryVariables>(TemporaryPostPollDocument, options);
        }
export type TemporaryPostPollQueryHookResult = ReturnType<typeof useTemporaryPostPollQuery>;
export type TemporaryPostPollLazyQueryHookResult = ReturnType<typeof useTemporaryPostPollLazyQuery>;
export type TemporaryPostPollSuspenseQueryHookResult = ReturnType<typeof useTemporaryPostPollSuspenseQuery>;
export type TemporaryPostPollQueryResult = Apollo.QueryResult<TemporaryPostPollQuery, TemporaryPostPollQueryVariables>;
export function refetchTemporaryPostPollQuery(variables: TemporaryPostPollQueryVariables) {
      return { query: TemporaryPostPollDocument, variables: variables }
    }
export const TemporaryPostDocument = gql`
    query TemporaryPost($token: String!) {
  temporaryPost(token: $token) {
    id
    token
    userId
    group {
      ...GroupCore
      members {
        ... on User {
          ...UserPreview
        }
      }
    }
  }
}
    ${GroupCoreFragmentDoc}
${UserPreviewFragmentDoc}`;

/**
 * __useTemporaryPostQuery__
 *
 * To run a query within a React component, call `useTemporaryPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemporaryPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemporaryPostQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useTemporaryPostQuery(baseOptions: Apollo.QueryHookOptions<TemporaryPostQuery, TemporaryPostQueryVariables> & ({ variables: TemporaryPostQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemporaryPostQuery, TemporaryPostQueryVariables>(TemporaryPostDocument, options);
      }
export function useTemporaryPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemporaryPostQuery, TemporaryPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemporaryPostQuery, TemporaryPostQueryVariables>(TemporaryPostDocument, options);
        }
export function useTemporaryPostSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TemporaryPostQuery, TemporaryPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TemporaryPostQuery, TemporaryPostQueryVariables>(TemporaryPostDocument, options);
        }
export type TemporaryPostQueryHookResult = ReturnType<typeof useTemporaryPostQuery>;
export type TemporaryPostLazyQueryHookResult = ReturnType<typeof useTemporaryPostLazyQuery>;
export type TemporaryPostSuspenseQueryHookResult = ReturnType<typeof useTemporaryPostSuspenseQuery>;
export type TemporaryPostQueryResult = Apollo.QueryResult<TemporaryPostQuery, TemporaryPostQueryVariables>;
export function refetchTemporaryPostQuery(variables: TemporaryPostQueryVariables) {
      return { query: TemporaryPostDocument, variables: variables }
    }
export const UpdateTemporaryPostDocument = gql`
    mutation UpdateTemporaryPost($input: UpdateTemporaryPostInput!) {
  updateTemporaryPost(input: $input) {
    id
    token
    media {
      ... on Video {
        id
      }
      ... on Image {
        id
      }
    }
    userId
  }
}
    `;
export type UpdateTemporaryPostMutationFn = Apollo.MutationFunction<UpdateTemporaryPostMutation, UpdateTemporaryPostMutationVariables>;

/**
 * __useUpdateTemporaryPostMutation__
 *
 * To run a mutation, you first call `useUpdateTemporaryPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemporaryPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemporaryPostMutation, { data, loading, error }] = useUpdateTemporaryPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTemporaryPostMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTemporaryPostMutation, UpdateTemporaryPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTemporaryPostMutation, UpdateTemporaryPostMutationVariables>(UpdateTemporaryPostDocument, options);
      }
export type UpdateTemporaryPostMutationHookResult = ReturnType<typeof useUpdateTemporaryPostMutation>;
export type UpdateTemporaryPostMutationResult = Apollo.MutationResult<UpdateTemporaryPostMutation>;
export type UpdateTemporaryPostMutationOptions = Apollo.BaseMutationOptions<UpdateTemporaryPostMutation, UpdateTemporaryPostMutationVariables>;
export const UpdateMembershipDocument = gql`
    mutation UpdateMembership($input: UpdateMembershipInput!) {
  updateMembership(input: $input) {
    id
    allowNewOccurrenceNotifications
    allowNewPostNotifications
  }
}
    `;
export type UpdateMembershipMutationFn = Apollo.MutationFunction<UpdateMembershipMutation, UpdateMembershipMutationVariables>;

/**
 * __useUpdateMembershipMutation__
 *
 * To run a mutation, you first call `useUpdateMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembershipMutation, { data, loading, error }] = useUpdateMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembershipMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMembershipMutation, UpdateMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMembershipMutation, UpdateMembershipMutationVariables>(UpdateMembershipDocument, options);
      }
export type UpdateMembershipMutationHookResult = ReturnType<typeof useUpdateMembershipMutation>;
export type UpdateMembershipMutationResult = Apollo.MutationResult<UpdateMembershipMutation>;
export type UpdateMembershipMutationOptions = Apollo.BaseMutationOptions<UpdateMembershipMutation, UpdateMembershipMutationVariables>;
export const GroupsSidebarStateDocument = gql`
    query GroupsSidebarState {
  groupsSidebarState @client {
    privateGroupsExpanded @client
    publicGroupsExpanded @client
    courseGroupsExpanded @client
  }
}
    `;

/**
 * __useGroupsSidebarStateQuery__
 *
 * To run a query within a React component, call `useGroupsSidebarStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsSidebarStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsSidebarStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupsSidebarStateQuery(baseOptions?: Apollo.QueryHookOptions<GroupsSidebarStateQuery, GroupsSidebarStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupsSidebarStateQuery, GroupsSidebarStateQueryVariables>(GroupsSidebarStateDocument, options);
      }
export function useGroupsSidebarStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupsSidebarStateQuery, GroupsSidebarStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupsSidebarStateQuery, GroupsSidebarStateQueryVariables>(GroupsSidebarStateDocument, options);
        }
export function useGroupsSidebarStateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GroupsSidebarStateQuery, GroupsSidebarStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GroupsSidebarStateQuery, GroupsSidebarStateQueryVariables>(GroupsSidebarStateDocument, options);
        }
export type GroupsSidebarStateQueryHookResult = ReturnType<typeof useGroupsSidebarStateQuery>;
export type GroupsSidebarStateLazyQueryHookResult = ReturnType<typeof useGroupsSidebarStateLazyQuery>;
export type GroupsSidebarStateSuspenseQueryHookResult = ReturnType<typeof useGroupsSidebarStateSuspenseQuery>;
export type GroupsSidebarStateQueryResult = Apollo.QueryResult<GroupsSidebarStateQuery, GroupsSidebarStateQueryVariables>;
export function refetchGroupsSidebarStateQuery(variables?: GroupsSidebarStateQueryVariables) {
      return { query: GroupsSidebarStateDocument, variables: variables }
    }
export const GroupsMenuSectionsStateDocument = gql`
    query GroupsMenuSectionsState {
  groupsMenuSectionsState @client {
    menuSectionIdsCollapsed @client
  }
}
    `;

/**
 * __useGroupsMenuSectionsStateQuery__
 *
 * To run a query within a React component, call `useGroupsMenuSectionsStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsMenuSectionsStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsMenuSectionsStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupsMenuSectionsStateQuery(baseOptions?: Apollo.QueryHookOptions<GroupsMenuSectionsStateQuery, GroupsMenuSectionsStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupsMenuSectionsStateQuery, GroupsMenuSectionsStateQueryVariables>(GroupsMenuSectionsStateDocument, options);
      }
export function useGroupsMenuSectionsStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupsMenuSectionsStateQuery, GroupsMenuSectionsStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupsMenuSectionsStateQuery, GroupsMenuSectionsStateQueryVariables>(GroupsMenuSectionsStateDocument, options);
        }
export function useGroupsMenuSectionsStateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GroupsMenuSectionsStateQuery, GroupsMenuSectionsStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GroupsMenuSectionsStateQuery, GroupsMenuSectionsStateQueryVariables>(GroupsMenuSectionsStateDocument, options);
        }
export type GroupsMenuSectionsStateQueryHookResult = ReturnType<typeof useGroupsMenuSectionsStateQuery>;
export type GroupsMenuSectionsStateLazyQueryHookResult = ReturnType<typeof useGroupsMenuSectionsStateLazyQuery>;
export type GroupsMenuSectionsStateSuspenseQueryHookResult = ReturnType<typeof useGroupsMenuSectionsStateSuspenseQuery>;
export type GroupsMenuSectionsStateQueryResult = Apollo.QueryResult<GroupsMenuSectionsStateQuery, GroupsMenuSectionsStateQueryVariables>;
export function refetchGroupsMenuSectionsStateQuery(variables?: GroupsMenuSectionsStateQueryVariables) {
      return { query: GroupsMenuSectionsStateDocument, variables: variables }
    }
export const ToggleGroupAutoAddMembersDocument = gql`
    mutation ToggleGroupAutoAddMembers($input: ToggleGroupAutoAddMembersInput!) {
  toggleGroupAutoAddMembers(input: $input) {
    id
    autoAddMembers
  }
}
    `;
export type ToggleGroupAutoAddMembersMutationFn = Apollo.MutationFunction<ToggleGroupAutoAddMembersMutation, ToggleGroupAutoAddMembersMutationVariables>;

/**
 * __useToggleGroupAutoAddMembersMutation__
 *
 * To run a mutation, you first call `useToggleGroupAutoAddMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleGroupAutoAddMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleGroupAutoAddMembersMutation, { data, loading, error }] = useToggleGroupAutoAddMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleGroupAutoAddMembersMutation(baseOptions?: Apollo.MutationHookOptions<ToggleGroupAutoAddMembersMutation, ToggleGroupAutoAddMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleGroupAutoAddMembersMutation, ToggleGroupAutoAddMembersMutationVariables>(ToggleGroupAutoAddMembersDocument, options);
      }
export type ToggleGroupAutoAddMembersMutationHookResult = ReturnType<typeof useToggleGroupAutoAddMembersMutation>;
export type ToggleGroupAutoAddMembersMutationResult = Apollo.MutationResult<ToggleGroupAutoAddMembersMutation>;
export type ToggleGroupAutoAddMembersMutationOptions = Apollo.BaseMutationOptions<ToggleGroupAutoAddMembersMutation, ToggleGroupAutoAddMembersMutationVariables>;
export const ToggleGroupEnableNewPostNotificationsForNewMembersDocument = gql`
    mutation ToggleGroupEnableNewPostNotificationsForNewMembers($input: ToggleGroupEnableNewPostNotificationsForNewMembersInput!) {
  toggleGroupEnableNewPostNotificationsForNewMembers(input: $input) {
    id
    enableNewPostNotificationsForNewMembers
  }
}
    `;
export type ToggleGroupEnableNewPostNotificationsForNewMembersMutationFn = Apollo.MutationFunction<ToggleGroupEnableNewPostNotificationsForNewMembersMutation, ToggleGroupEnableNewPostNotificationsForNewMembersMutationVariables>;

/**
 * __useToggleGroupEnableNewPostNotificationsForNewMembersMutation__
 *
 * To run a mutation, you first call `useToggleGroupEnableNewPostNotificationsForNewMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleGroupEnableNewPostNotificationsForNewMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleGroupEnableNewPostNotificationsForNewMembersMutation, { data, loading, error }] = useToggleGroupEnableNewPostNotificationsForNewMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleGroupEnableNewPostNotificationsForNewMembersMutation(baseOptions?: Apollo.MutationHookOptions<ToggleGroupEnableNewPostNotificationsForNewMembersMutation, ToggleGroupEnableNewPostNotificationsForNewMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleGroupEnableNewPostNotificationsForNewMembersMutation, ToggleGroupEnableNewPostNotificationsForNewMembersMutationVariables>(ToggleGroupEnableNewPostNotificationsForNewMembersDocument, options);
      }
export type ToggleGroupEnableNewPostNotificationsForNewMembersMutationHookResult = ReturnType<typeof useToggleGroupEnableNewPostNotificationsForNewMembersMutation>;
export type ToggleGroupEnableNewPostNotificationsForNewMembersMutationResult = Apollo.MutationResult<ToggleGroupEnableNewPostNotificationsForNewMembersMutation>;
export type ToggleGroupEnableNewPostNotificationsForNewMembersMutationOptions = Apollo.BaseMutationOptions<ToggleGroupEnableNewPostNotificationsForNewMembersMutation, ToggleGroupEnableNewPostNotificationsForNewMembersMutationVariables>;
export const ToggleGroupIncludePostsOnHomeDocument = gql`
    mutation ToggleGroupIncludePostsOnHome($input: ToggleGroupIncludePostsOnHomeInput!) {
  toggleGroupIncludePostsOnHome(input: $input) {
    id
    includePostsOnHome
  }
}
    `;
export type ToggleGroupIncludePostsOnHomeMutationFn = Apollo.MutationFunction<ToggleGroupIncludePostsOnHomeMutation, ToggleGroupIncludePostsOnHomeMutationVariables>;

/**
 * __useToggleGroupIncludePostsOnHomeMutation__
 *
 * To run a mutation, you first call `useToggleGroupIncludePostsOnHomeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleGroupIncludePostsOnHomeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleGroupIncludePostsOnHomeMutation, { data, loading, error }] = useToggleGroupIncludePostsOnHomeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleGroupIncludePostsOnHomeMutation(baseOptions?: Apollo.MutationHookOptions<ToggleGroupIncludePostsOnHomeMutation, ToggleGroupIncludePostsOnHomeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleGroupIncludePostsOnHomeMutation, ToggleGroupIncludePostsOnHomeMutationVariables>(ToggleGroupIncludePostsOnHomeDocument, options);
      }
export type ToggleGroupIncludePostsOnHomeMutationHookResult = ReturnType<typeof useToggleGroupIncludePostsOnHomeMutation>;
export type ToggleGroupIncludePostsOnHomeMutationResult = Apollo.MutationResult<ToggleGroupIncludePostsOnHomeMutation>;
export type ToggleGroupIncludePostsOnHomeMutationOptions = Apollo.BaseMutationOptions<ToggleGroupIncludePostsOnHomeMutation, ToggleGroupIncludePostsOnHomeMutationVariables>;
export const RequestToJoinOrganizationDocument = gql`
    mutation RequestToJoinOrganization($input: RequestToJoinOrganizationInput!) {
  requestToJoinOrganization(input: $input) {
    __typename
    ... on RequestToJoin {
      id
    }
    ... on RequestToJoinError {
      message
    }
  }
}
    `;
export type RequestToJoinOrganizationMutationFn = Apollo.MutationFunction<RequestToJoinOrganizationMutation, RequestToJoinOrganizationMutationVariables>;

/**
 * __useRequestToJoinOrganizationMutation__
 *
 * To run a mutation, you first call `useRequestToJoinOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestToJoinOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestToJoinOrganizationMutation, { data, loading, error }] = useRequestToJoinOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestToJoinOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<RequestToJoinOrganizationMutation, RequestToJoinOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestToJoinOrganizationMutation, RequestToJoinOrganizationMutationVariables>(RequestToJoinOrganizationDocument, options);
      }
export type RequestToJoinOrganizationMutationHookResult = ReturnType<typeof useRequestToJoinOrganizationMutation>;
export type RequestToJoinOrganizationMutationResult = Apollo.MutationResult<RequestToJoinOrganizationMutation>;
export type RequestToJoinOrganizationMutationOptions = Apollo.BaseMutationOptions<RequestToJoinOrganizationMutation, RequestToJoinOrganizationMutationVariables>;
export const UserInvitesDocument = gql`
    query UserInvites($organizationId: String!, $offset: Int, $limit: Int, $query: String, $orderBy: InvitesOrderByInput) {
  userInvites(
    organizationId: $organizationId
    offset: $offset
    limit: $limit
    query: $query
    orderBy: $orderBy
  ) {
    items {
      id
      email
      inviteCode
      createdAt
    }
    totalCount
  }
}
    `;

/**
 * __useUserInvitesQuery__
 *
 * To run a query within a React component, call `useUserInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInvitesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      query: // value for 'query'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useUserInvitesQuery(baseOptions: Apollo.QueryHookOptions<UserInvitesQuery, UserInvitesQueryVariables> & ({ variables: UserInvitesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserInvitesQuery, UserInvitesQueryVariables>(UserInvitesDocument, options);
      }
export function useUserInvitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserInvitesQuery, UserInvitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserInvitesQuery, UserInvitesQueryVariables>(UserInvitesDocument, options);
        }
export function useUserInvitesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserInvitesQuery, UserInvitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserInvitesQuery, UserInvitesQueryVariables>(UserInvitesDocument, options);
        }
export type UserInvitesQueryHookResult = ReturnType<typeof useUserInvitesQuery>;
export type UserInvitesLazyQueryHookResult = ReturnType<typeof useUserInvitesLazyQuery>;
export type UserInvitesSuspenseQueryHookResult = ReturnType<typeof useUserInvitesSuspenseQuery>;
export type UserInvitesQueryResult = Apollo.QueryResult<UserInvitesQuery, UserInvitesQueryVariables>;
export function refetchUserInvitesQuery(variables: UserInvitesQueryVariables) {
      return { query: UserInvitesDocument, variables: variables }
    }
export const UserRequestToJoinOrganizationDocument = gql`
    query UserRequestToJoinOrganization($organizationId: String!) {
  userRequestToJoinOrganization(organizationId: $organizationId) {
    id
  }
}
    `;

/**
 * __useUserRequestToJoinOrganizationQuery__
 *
 * To run a query within a React component, call `useUserRequestToJoinOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRequestToJoinOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRequestToJoinOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useUserRequestToJoinOrganizationQuery(baseOptions: Apollo.QueryHookOptions<UserRequestToJoinOrganizationQuery, UserRequestToJoinOrganizationQueryVariables> & ({ variables: UserRequestToJoinOrganizationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserRequestToJoinOrganizationQuery, UserRequestToJoinOrganizationQueryVariables>(UserRequestToJoinOrganizationDocument, options);
      }
export function useUserRequestToJoinOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserRequestToJoinOrganizationQuery, UserRequestToJoinOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserRequestToJoinOrganizationQuery, UserRequestToJoinOrganizationQueryVariables>(UserRequestToJoinOrganizationDocument, options);
        }
export function useUserRequestToJoinOrganizationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserRequestToJoinOrganizationQuery, UserRequestToJoinOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserRequestToJoinOrganizationQuery, UserRequestToJoinOrganizationQueryVariables>(UserRequestToJoinOrganizationDocument, options);
        }
export type UserRequestToJoinOrganizationQueryHookResult = ReturnType<typeof useUserRequestToJoinOrganizationQuery>;
export type UserRequestToJoinOrganizationLazyQueryHookResult = ReturnType<typeof useUserRequestToJoinOrganizationLazyQuery>;
export type UserRequestToJoinOrganizationSuspenseQueryHookResult = ReturnType<typeof useUserRequestToJoinOrganizationSuspenseQuery>;
export type UserRequestToJoinOrganizationQueryResult = Apollo.QueryResult<UserRequestToJoinOrganizationQuery, UserRequestToJoinOrganizationQueryVariables>;
export function refetchUserRequestToJoinOrganizationQuery(variables: UserRequestToJoinOrganizationQueryVariables) {
      return { query: UserRequestToJoinOrganizationDocument, variables: variables }
    }
export const UserRequestsToJoinOrganizationDocument = gql`
    query UserRequestsToJoinOrganization($organizationId: String!, $offset: Int, $limit: Int, $query: String, $orderBy: RequestsOrderByInput) {
  userRequestsToJoinOrganization(
    organizationId: $organizationId
    offset: $offset
    limit: $limit
    query: $query
    orderBy: $orderBy
  ) {
    items {
      id
      createdAt
      email
      status
      user {
        id
        username
        name
        avatarBgColor
        avatar
        profileImageId
      }
    }
    totalCount
  }
}
    `;

/**
 * __useUserRequestsToJoinOrganizationQuery__
 *
 * To run a query within a React component, call `useUserRequestsToJoinOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRequestsToJoinOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRequestsToJoinOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      query: // value for 'query'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useUserRequestsToJoinOrganizationQuery(baseOptions: Apollo.QueryHookOptions<UserRequestsToJoinOrganizationQuery, UserRequestsToJoinOrganizationQueryVariables> & ({ variables: UserRequestsToJoinOrganizationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserRequestsToJoinOrganizationQuery, UserRequestsToJoinOrganizationQueryVariables>(UserRequestsToJoinOrganizationDocument, options);
      }
export function useUserRequestsToJoinOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserRequestsToJoinOrganizationQuery, UserRequestsToJoinOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserRequestsToJoinOrganizationQuery, UserRequestsToJoinOrganizationQueryVariables>(UserRequestsToJoinOrganizationDocument, options);
        }
export function useUserRequestsToJoinOrganizationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserRequestsToJoinOrganizationQuery, UserRequestsToJoinOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserRequestsToJoinOrganizationQuery, UserRequestsToJoinOrganizationQueryVariables>(UserRequestsToJoinOrganizationDocument, options);
        }
export type UserRequestsToJoinOrganizationQueryHookResult = ReturnType<typeof useUserRequestsToJoinOrganizationQuery>;
export type UserRequestsToJoinOrganizationLazyQueryHookResult = ReturnType<typeof useUserRequestsToJoinOrganizationLazyQuery>;
export type UserRequestsToJoinOrganizationSuspenseQueryHookResult = ReturnType<typeof useUserRequestsToJoinOrganizationSuspenseQuery>;
export type UserRequestsToJoinOrganizationQueryResult = Apollo.QueryResult<UserRequestsToJoinOrganizationQuery, UserRequestsToJoinOrganizationQueryVariables>;
export function refetchUserRequestsToJoinOrganizationQuery(variables: UserRequestsToJoinOrganizationQueryVariables) {
      return { query: UserRequestsToJoinOrganizationDocument, variables: variables }
    }
export const AcceptRequestToJoinDocument = gql`
    mutation AcceptRequestToJoin($input: AcceptRequestToJoinInput!) {
  acceptRequestToJoin(input: $input) {
    __typename
    ... on RequestToJoin {
      id
    }
    ... on RequestToJoinError {
      message
    }
  }
}
    `;
export type AcceptRequestToJoinMutationFn = Apollo.MutationFunction<AcceptRequestToJoinMutation, AcceptRequestToJoinMutationVariables>;

/**
 * __useAcceptRequestToJoinMutation__
 *
 * To run a mutation, you first call `useAcceptRequestToJoinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptRequestToJoinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptRequestToJoinMutation, { data, loading, error }] = useAcceptRequestToJoinMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptRequestToJoinMutation(baseOptions?: Apollo.MutationHookOptions<AcceptRequestToJoinMutation, AcceptRequestToJoinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptRequestToJoinMutation, AcceptRequestToJoinMutationVariables>(AcceptRequestToJoinDocument, options);
      }
export type AcceptRequestToJoinMutationHookResult = ReturnType<typeof useAcceptRequestToJoinMutation>;
export type AcceptRequestToJoinMutationResult = Apollo.MutationResult<AcceptRequestToJoinMutation>;
export type AcceptRequestToJoinMutationOptions = Apollo.BaseMutationOptions<AcceptRequestToJoinMutation, AcceptRequestToJoinMutationVariables>;
export const AcceptAllRequestsToJoinDocument = gql`
    mutation AcceptAllRequestsToJoin($input: AcceptAllRequestsToJoinInput!) {
  acceptAllRequestsToJoin(input: $input) {
    id
  }
}
    `;
export type AcceptAllRequestsToJoinMutationFn = Apollo.MutationFunction<AcceptAllRequestsToJoinMutation, AcceptAllRequestsToJoinMutationVariables>;

/**
 * __useAcceptAllRequestsToJoinMutation__
 *
 * To run a mutation, you first call `useAcceptAllRequestsToJoinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptAllRequestsToJoinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptAllRequestsToJoinMutation, { data, loading, error }] = useAcceptAllRequestsToJoinMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptAllRequestsToJoinMutation(baseOptions?: Apollo.MutationHookOptions<AcceptAllRequestsToJoinMutation, AcceptAllRequestsToJoinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptAllRequestsToJoinMutation, AcceptAllRequestsToJoinMutationVariables>(AcceptAllRequestsToJoinDocument, options);
      }
export type AcceptAllRequestsToJoinMutationHookResult = ReturnType<typeof useAcceptAllRequestsToJoinMutation>;
export type AcceptAllRequestsToJoinMutationResult = Apollo.MutationResult<AcceptAllRequestsToJoinMutation>;
export type AcceptAllRequestsToJoinMutationOptions = Apollo.BaseMutationOptions<AcceptAllRequestsToJoinMutation, AcceptAllRequestsToJoinMutationVariables>;
export const RejectRequestToJoinDocument = gql`
    mutation RejectRequestToJoin($input: RejectRequestToJoinInput!) {
  rejectRequestToJoin(input: $input) {
    __typename
    ... on RequestToJoin {
      id
    }
    ... on RequestToJoinError {
      message
    }
  }
}
    `;
export type RejectRequestToJoinMutationFn = Apollo.MutationFunction<RejectRequestToJoinMutation, RejectRequestToJoinMutationVariables>;

/**
 * __useRejectRequestToJoinMutation__
 *
 * To run a mutation, you first call `useRejectRequestToJoinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectRequestToJoinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectRequestToJoinMutation, { data, loading, error }] = useRejectRequestToJoinMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectRequestToJoinMutation(baseOptions?: Apollo.MutationHookOptions<RejectRequestToJoinMutation, RejectRequestToJoinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectRequestToJoinMutation, RejectRequestToJoinMutationVariables>(RejectRequestToJoinDocument, options);
      }
export type RejectRequestToJoinMutationHookResult = ReturnType<typeof useRejectRequestToJoinMutation>;
export type RejectRequestToJoinMutationResult = Apollo.MutationResult<RejectRequestToJoinMutation>;
export type RejectRequestToJoinMutationOptions = Apollo.BaseMutationOptions<RejectRequestToJoinMutation, RejectRequestToJoinMutationVariables>;
export const OrganizationPushEventsDocument = gql`
    subscription OrganizationPushEvents($organizationId: ID!) {
  organizationPushEvent(id: $organizationId) {
    ... on PushRefetchHint {
      id
      postId
      groupId
    }
  }
}
    `;

/**
 * __useOrganizationPushEventsSubscription__
 *
 * To run a query within a React component, call `useOrganizationPushEventsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationPushEventsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationPushEventsSubscription({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationPushEventsSubscription(baseOptions: Apollo.SubscriptionHookOptions<OrganizationPushEventsSubscription, OrganizationPushEventsSubscriptionVariables> & ({ variables: OrganizationPushEventsSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OrganizationPushEventsSubscription, OrganizationPushEventsSubscriptionVariables>(OrganizationPushEventsDocument, options);
      }
export type OrganizationPushEventsSubscriptionHookResult = ReturnType<typeof useOrganizationPushEventsSubscription>;
export type OrganizationPushEventsSubscriptionResult = Apollo.SubscriptionResult<OrganizationPushEventsSubscription>;
export const UpdateImageCoordinatesDocument = gql`
    mutation UpdateImageCoordinates($input: UpdateImageCoordinatesInput!) {
  updateImageCoordinates(input: $input) {
    id
    coordinates {
      x
      y
      width
      height
    }
  }
}
    `;
export type UpdateImageCoordinatesMutationFn = Apollo.MutationFunction<UpdateImageCoordinatesMutation, UpdateImageCoordinatesMutationVariables>;

/**
 * __useUpdateImageCoordinatesMutation__
 *
 * To run a mutation, you first call `useUpdateImageCoordinatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateImageCoordinatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateImageCoordinatesMutation, { data, loading, error }] = useUpdateImageCoordinatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateImageCoordinatesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateImageCoordinatesMutation, UpdateImageCoordinatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateImageCoordinatesMutation, UpdateImageCoordinatesMutationVariables>(UpdateImageCoordinatesDocument, options);
      }
export type UpdateImageCoordinatesMutationHookResult = ReturnType<typeof useUpdateImageCoordinatesMutation>;
export type UpdateImageCoordinatesMutationResult = Apollo.MutationResult<UpdateImageCoordinatesMutation>;
export type UpdateImageCoordinatesMutationOptions = Apollo.BaseMutationOptions<UpdateImageCoordinatesMutation, UpdateImageCoordinatesMutationVariables>;
export const CreateVoteDocument = gql`
    mutation CreateVote($input: CreateVoteInput!) {
  createVote(input: $input) {
    __typename
    ... on Poll {
      ...PollCore
    }
    ... on PollError {
      message
    }
  }
}
    ${PollCoreFragmentDoc}`;
export type CreateVoteMutationFn = Apollo.MutationFunction<CreateVoteMutation, CreateVoteMutationVariables>;

/**
 * __useCreateVoteMutation__
 *
 * To run a mutation, you first call `useCreateVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVoteMutation, { data, loading, error }] = useCreateVoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateVoteMutation, CreateVoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVoteMutation, CreateVoteMutationVariables>(CreateVoteDocument, options);
      }
export type CreateVoteMutationHookResult = ReturnType<typeof useCreateVoteMutation>;
export type CreateVoteMutationResult = Apollo.MutationResult<CreateVoteMutation>;
export type CreateVoteMutationOptions = Apollo.BaseMutationOptions<CreateVoteMutation, CreateVoteMutationVariables>;
export const DeleteVoteDocument = gql`
    mutation DeleteVote($input: DeleteVoteInput!) {
  deleteVote(input: $input) {
    __typename
    ... on Poll {
      ...PollCore
    }
    ... on PollError {
      message
    }
  }
}
    ${PollCoreFragmentDoc}`;
export type DeleteVoteMutationFn = Apollo.MutationFunction<DeleteVoteMutation, DeleteVoteMutationVariables>;

/**
 * __useDeleteVoteMutation__
 *
 * To run a mutation, you first call `useDeleteVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVoteMutation, { data, loading, error }] = useDeleteVoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVoteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVoteMutation, DeleteVoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVoteMutation, DeleteVoteMutationVariables>(DeleteVoteDocument, options);
      }
export type DeleteVoteMutationHookResult = ReturnType<typeof useDeleteVoteMutation>;
export type DeleteVoteMutationResult = Apollo.MutationResult<DeleteVoteMutation>;
export type DeleteVoteMutationOptions = Apollo.BaseMutationOptions<DeleteVoteMutation, DeleteVoteMutationVariables>;
export const SearchDocument = gql`
    query Search($organizationId: String!, $query: String!) {
  search(organizationId: $organizationId, query: $query) {
    ...SearchResult
  }
}
    ${SearchResultFragmentDoc}`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchQuery(baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables> & ({ variables: SearchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
      }
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
        }
export function useSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchSuspenseQueryHookResult = ReturnType<typeof useSearchSuspenseQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;
export function refetchSearchQuery(variables: SearchQueryVariables) {
      return { query: SearchDocument, variables: variables }
    }
export const PostsByGroupDocument = gql`
    query PostsByGroup($groupId: ID!, $first: Int!, $after: String) {
  postsByGroup(groupId: $groupId, first: $first, after: $after) {
    edges {
      node {
        ...PostFeed
      }
      cursor
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${PostFeedFragmentDoc}`;

/**
 * __usePostsByGroupQuery__
 *
 * To run a query within a React component, call `usePostsByGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsByGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsByGroupQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePostsByGroupQuery(baseOptions: Apollo.QueryHookOptions<PostsByGroupQuery, PostsByGroupQueryVariables> & ({ variables: PostsByGroupQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostsByGroupQuery, PostsByGroupQueryVariables>(PostsByGroupDocument, options);
      }
export function usePostsByGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostsByGroupQuery, PostsByGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostsByGroupQuery, PostsByGroupQueryVariables>(PostsByGroupDocument, options);
        }
export function usePostsByGroupSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PostsByGroupQuery, PostsByGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PostsByGroupQuery, PostsByGroupQueryVariables>(PostsByGroupDocument, options);
        }
export type PostsByGroupQueryHookResult = ReturnType<typeof usePostsByGroupQuery>;
export type PostsByGroupLazyQueryHookResult = ReturnType<typeof usePostsByGroupLazyQuery>;
export type PostsByGroupSuspenseQueryHookResult = ReturnType<typeof usePostsByGroupSuspenseQuery>;
export type PostsByGroupQueryResult = Apollo.QueryResult<PostsByGroupQuery, PostsByGroupQueryVariables>;
export function refetchPostsByGroupQuery(variables: PostsByGroupQueryVariables) {
      return { query: PostsByGroupDocument, variables: variables }
    }
export const DraftPostsDocument = gql`
    query DraftPosts($first: Int!, $after: String, $organizationId: ID!, $isScheduled: Boolean) {
  draftPosts(
    organizationId: $organizationId
    first: $first
    after: $after
    isScheduled: $isScheduled
  ) {
    edges {
      node {
        ...PostFeed
      }
      cursor
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${PostFeedFragmentDoc}`;

/**
 * __useDraftPostsQuery__
 *
 * To run a query within a React component, call `useDraftPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftPostsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      organizationId: // value for 'organizationId'
 *      isScheduled: // value for 'isScheduled'
 *   },
 * });
 */
export function useDraftPostsQuery(baseOptions: Apollo.QueryHookOptions<DraftPostsQuery, DraftPostsQueryVariables> & ({ variables: DraftPostsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DraftPostsQuery, DraftPostsQueryVariables>(DraftPostsDocument, options);
      }
export function useDraftPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DraftPostsQuery, DraftPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DraftPostsQuery, DraftPostsQueryVariables>(DraftPostsDocument, options);
        }
export function useDraftPostsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DraftPostsQuery, DraftPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DraftPostsQuery, DraftPostsQueryVariables>(DraftPostsDocument, options);
        }
export type DraftPostsQueryHookResult = ReturnType<typeof useDraftPostsQuery>;
export type DraftPostsLazyQueryHookResult = ReturnType<typeof useDraftPostsLazyQuery>;
export type DraftPostsSuspenseQueryHookResult = ReturnType<typeof useDraftPostsSuspenseQuery>;
export type DraftPostsQueryResult = Apollo.QueryResult<DraftPostsQuery, DraftPostsQueryVariables>;
export function refetchDraftPostsQuery(variables: DraftPostsQueryVariables) {
      return { query: DraftPostsDocument, variables: variables }
    }
export const CreateOrganizationRoleDocument = gql`
    mutation CreateOrganizationRole($input: CreateOrganizationRoleInput!) {
  createOrganizationRole(input: $input) {
    __typename
    ...OrganizationRoleCore
  }
}
    ${OrganizationRoleCoreFragmentDoc}`;
export type CreateOrganizationRoleMutationFn = Apollo.MutationFunction<CreateOrganizationRoleMutation, CreateOrganizationRoleMutationVariables>;

/**
 * __useCreateOrganizationRoleMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationRoleMutation, { data, loading, error }] = useCreateOrganizationRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganizationRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganizationRoleMutation, CreateOrganizationRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrganizationRoleMutation, CreateOrganizationRoleMutationVariables>(CreateOrganizationRoleDocument, options);
      }
export type CreateOrganizationRoleMutationHookResult = ReturnType<typeof useCreateOrganizationRoleMutation>;
export type CreateOrganizationRoleMutationResult = Apollo.MutationResult<CreateOrganizationRoleMutation>;
export type CreateOrganizationRoleMutationOptions = Apollo.BaseMutationOptions<CreateOrganizationRoleMutation, CreateOrganizationRoleMutationVariables>;
export const UpdateOrganizationRoleDocument = gql`
    mutation UpdateOrganizationRole($input: UpdateOrganizationRoleInput!) {
  updateOrganizationRole(input: $input) {
    __typename
    ...OrganizationRoleCore
  }
}
    ${OrganizationRoleCoreFragmentDoc}`;
export type UpdateOrganizationRoleMutationFn = Apollo.MutationFunction<UpdateOrganizationRoleMutation, UpdateOrganizationRoleMutationVariables>;

/**
 * __useUpdateOrganizationRoleMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationRoleMutation, { data, loading, error }] = useUpdateOrganizationRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationRoleMutation, UpdateOrganizationRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationRoleMutation, UpdateOrganizationRoleMutationVariables>(UpdateOrganizationRoleDocument, options);
      }
export type UpdateOrganizationRoleMutationHookResult = ReturnType<typeof useUpdateOrganizationRoleMutation>;
export type UpdateOrganizationRoleMutationResult = Apollo.MutationResult<UpdateOrganizationRoleMutation>;
export type UpdateOrganizationRoleMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationRoleMutation, UpdateOrganizationRoleMutationVariables>;
export const DeleteOrganizationRoleDocument = gql`
    mutation DeleteOrganizationRole($input: DeleteOrganizationRoleInput!) {
  deleteOrganizationRole(input: $input) {
    __typename
    ...OrganizationRoleCore
  }
}
    ${OrganizationRoleCoreFragmentDoc}`;
export type DeleteOrganizationRoleMutationFn = Apollo.MutationFunction<DeleteOrganizationRoleMutation, DeleteOrganizationRoleMutationVariables>;

/**
 * __useDeleteOrganizationRoleMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationRoleMutation, { data, loading, error }] = useDeleteOrganizationRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOrganizationRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrganizationRoleMutation, DeleteOrganizationRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrganizationRoleMutation, DeleteOrganizationRoleMutationVariables>(DeleteOrganizationRoleDocument, options);
      }
export type DeleteOrganizationRoleMutationHookResult = ReturnType<typeof useDeleteOrganizationRoleMutation>;
export type DeleteOrganizationRoleMutationResult = Apollo.MutationResult<DeleteOrganizationRoleMutation>;
export type DeleteOrganizationRoleMutationOptions = Apollo.BaseMutationOptions<DeleteOrganizationRoleMutation, DeleteOrganizationRoleMutationVariables>;
export const OrganizationRolesDocument = gql`
    query OrganizationRoles($organizationId: String!) {
  organizationRoles(organizationId: $organizationId) {
    __typename
    ...OrganizationRoleCore
    users {
      ...UserPreview
    }
  }
}
    ${OrganizationRoleCoreFragmentDoc}
${UserPreviewFragmentDoc}`;

/**
 * __useOrganizationRolesQuery__
 *
 * To run a query within a React component, call `useOrganizationRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationRolesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationRolesQuery(baseOptions: Apollo.QueryHookOptions<OrganizationRolesQuery, OrganizationRolesQueryVariables> & ({ variables: OrganizationRolesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationRolesQuery, OrganizationRolesQueryVariables>(OrganizationRolesDocument, options);
      }
export function useOrganizationRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationRolesQuery, OrganizationRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationRolesQuery, OrganizationRolesQueryVariables>(OrganizationRolesDocument, options);
        }
export function useOrganizationRolesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizationRolesQuery, OrganizationRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizationRolesQuery, OrganizationRolesQueryVariables>(OrganizationRolesDocument, options);
        }
export type OrganizationRolesQueryHookResult = ReturnType<typeof useOrganizationRolesQuery>;
export type OrganizationRolesLazyQueryHookResult = ReturnType<typeof useOrganizationRolesLazyQuery>;
export type OrganizationRolesSuspenseQueryHookResult = ReturnType<typeof useOrganizationRolesSuspenseQuery>;
export type OrganizationRolesQueryResult = Apollo.QueryResult<OrganizationRolesQuery, OrganizationRolesQueryVariables>;
export function refetchOrganizationRolesQuery(variables: OrganizationRolesQueryVariables) {
      return { query: OrganizationRolesDocument, variables: variables }
    }
export const OrganizationRolesByUserIdDocument = gql`
    query OrganizationRolesByUserId($userId: String!, $organizationId: String!) {
  organizationRolesByUserId(userId: $userId, organizationId: $organizationId) {
    __typename
    ...OrganizationRoleCore
  }
}
    ${OrganizationRoleCoreFragmentDoc}`;

/**
 * __useOrganizationRolesByUserIdQuery__
 *
 * To run a query within a React component, call `useOrganizationRolesByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationRolesByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationRolesByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationRolesByUserIdQuery(baseOptions: Apollo.QueryHookOptions<OrganizationRolesByUserIdQuery, OrganizationRolesByUserIdQueryVariables> & ({ variables: OrganizationRolesByUserIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationRolesByUserIdQuery, OrganizationRolesByUserIdQueryVariables>(OrganizationRolesByUserIdDocument, options);
      }
export function useOrganizationRolesByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationRolesByUserIdQuery, OrganizationRolesByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationRolesByUserIdQuery, OrganizationRolesByUserIdQueryVariables>(OrganizationRolesByUserIdDocument, options);
        }
export function useOrganizationRolesByUserIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizationRolesByUserIdQuery, OrganizationRolesByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizationRolesByUserIdQuery, OrganizationRolesByUserIdQueryVariables>(OrganizationRolesByUserIdDocument, options);
        }
export type OrganizationRolesByUserIdQueryHookResult = ReturnType<typeof useOrganizationRolesByUserIdQuery>;
export type OrganizationRolesByUserIdLazyQueryHookResult = ReturnType<typeof useOrganizationRolesByUserIdLazyQuery>;
export type OrganizationRolesByUserIdSuspenseQueryHookResult = ReturnType<typeof useOrganizationRolesByUserIdSuspenseQuery>;
export type OrganizationRolesByUserIdQueryResult = Apollo.QueryResult<OrganizationRolesByUserIdQuery, OrganizationRolesByUserIdQueryVariables>;
export function refetchOrganizationRolesByUserIdQuery(variables: OrganizationRolesByUserIdQueryVariables) {
      return { query: OrganizationRolesByUserIdDocument, variables: variables }
    }
export const DirectMessageTokenDocument = gql`
    query DirectMessageToken($organizationId: String!) {
  directMessageToken(organizationId: $organizationId) {
    __typename
    ...DirectMessageTokenFragment
  }
}
    ${DirectMessageTokenFragmentFragmentDoc}`;

/**
 * __useDirectMessageTokenQuery__
 *
 * To run a query within a React component, call `useDirectMessageTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirectMessageTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectMessageTokenQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDirectMessageTokenQuery(baseOptions: Apollo.QueryHookOptions<DirectMessageTokenQuery, DirectMessageTokenQueryVariables> & ({ variables: DirectMessageTokenQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DirectMessageTokenQuery, DirectMessageTokenQueryVariables>(DirectMessageTokenDocument, options);
      }
export function useDirectMessageTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DirectMessageTokenQuery, DirectMessageTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DirectMessageTokenQuery, DirectMessageTokenQueryVariables>(DirectMessageTokenDocument, options);
        }
export function useDirectMessageTokenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DirectMessageTokenQuery, DirectMessageTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DirectMessageTokenQuery, DirectMessageTokenQueryVariables>(DirectMessageTokenDocument, options);
        }
export type DirectMessageTokenQueryHookResult = ReturnType<typeof useDirectMessageTokenQuery>;
export type DirectMessageTokenLazyQueryHookResult = ReturnType<typeof useDirectMessageTokenLazyQuery>;
export type DirectMessageTokenSuspenseQueryHookResult = ReturnType<typeof useDirectMessageTokenSuspenseQuery>;
export type DirectMessageTokenQueryResult = Apollo.QueryResult<DirectMessageTokenQuery, DirectMessageTokenQueryVariables>;
export function refetchDirectMessageTokenQuery(variables: DirectMessageTokenQueryVariables) {
      return { query: DirectMessageTokenDocument, variables: variables }
    }
export const DirectMessageUserThreadDocument = gql`
    query DirectMessageUserThread($username: String!, $organizationId: String!) {
  directMessageUserThread(username: $username, organizationId: $organizationId) {
    __typename
    ...DirectMessageUserThreadFragment
  }
}
    ${DirectMessageUserThreadFragmentFragmentDoc}`;

/**
 * __useDirectMessageUserThreadQuery__
 *
 * To run a query within a React component, call `useDirectMessageUserThreadQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirectMessageUserThreadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectMessageUserThreadQuery({
 *   variables: {
 *      username: // value for 'username'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDirectMessageUserThreadQuery(baseOptions: Apollo.QueryHookOptions<DirectMessageUserThreadQuery, DirectMessageUserThreadQueryVariables> & ({ variables: DirectMessageUserThreadQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DirectMessageUserThreadQuery, DirectMessageUserThreadQueryVariables>(DirectMessageUserThreadDocument, options);
      }
export function useDirectMessageUserThreadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DirectMessageUserThreadQuery, DirectMessageUserThreadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DirectMessageUserThreadQuery, DirectMessageUserThreadQueryVariables>(DirectMessageUserThreadDocument, options);
        }
export function useDirectMessageUserThreadSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DirectMessageUserThreadQuery, DirectMessageUserThreadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DirectMessageUserThreadQuery, DirectMessageUserThreadQueryVariables>(DirectMessageUserThreadDocument, options);
        }
export type DirectMessageUserThreadQueryHookResult = ReturnType<typeof useDirectMessageUserThreadQuery>;
export type DirectMessageUserThreadLazyQueryHookResult = ReturnType<typeof useDirectMessageUserThreadLazyQuery>;
export type DirectMessageUserThreadSuspenseQueryHookResult = ReturnType<typeof useDirectMessageUserThreadSuspenseQuery>;
export type DirectMessageUserThreadQueryResult = Apollo.QueryResult<DirectMessageUserThreadQuery, DirectMessageUserThreadQueryVariables>;
export function refetchDirectMessageUserThreadQuery(variables: DirectMessageUserThreadQueryVariables) {
      return { query: DirectMessageUserThreadDocument, variables: variables }
    }
export const WelcomeGroupDocument = gql`
    query WelcomeGroup($organizationId: String!) {
  welcomeGroup(organizationId: $organizationId) {
    id
    name
    emoji
    groupType: type
    hidden
  }
}
    `;

/**
 * __useWelcomeGroupQuery__
 *
 * To run a query within a React component, call `useWelcomeGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useWelcomeGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWelcomeGroupQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useWelcomeGroupQuery(baseOptions: Apollo.QueryHookOptions<WelcomeGroupQuery, WelcomeGroupQueryVariables> & ({ variables: WelcomeGroupQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WelcomeGroupQuery, WelcomeGroupQueryVariables>(WelcomeGroupDocument, options);
      }
export function useWelcomeGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WelcomeGroupQuery, WelcomeGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WelcomeGroupQuery, WelcomeGroupQueryVariables>(WelcomeGroupDocument, options);
        }
export function useWelcomeGroupSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WelcomeGroupQuery, WelcomeGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WelcomeGroupQuery, WelcomeGroupQueryVariables>(WelcomeGroupDocument, options);
        }
export type WelcomeGroupQueryHookResult = ReturnType<typeof useWelcomeGroupQuery>;
export type WelcomeGroupLazyQueryHookResult = ReturnType<typeof useWelcomeGroupLazyQuery>;
export type WelcomeGroupSuspenseQueryHookResult = ReturnType<typeof useWelcomeGroupSuspenseQuery>;
export type WelcomeGroupQueryResult = Apollo.QueryResult<WelcomeGroupQuery, WelcomeGroupQueryVariables>;
export function refetchWelcomeGroupQuery(variables: WelcomeGroupQueryVariables) {
      return { query: WelcomeGroupDocument, variables: variables }
    }
export const ToggleWelcomeGroupDocument = gql`
    mutation ToggleWelcomeGroup($input: ToggleWelcomeGroupInput!) {
  toggleWelcomeGroup(input: $input) {
    id
    hidden
  }
}
    `;
export type ToggleWelcomeGroupMutationFn = Apollo.MutationFunction<ToggleWelcomeGroupMutation, ToggleWelcomeGroupMutationVariables>;

/**
 * __useToggleWelcomeGroupMutation__
 *
 * To run a mutation, you first call `useToggleWelcomeGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleWelcomeGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleWelcomeGroupMutation, { data, loading, error }] = useToggleWelcomeGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleWelcomeGroupMutation(baseOptions?: Apollo.MutationHookOptions<ToggleWelcomeGroupMutation, ToggleWelcomeGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleWelcomeGroupMutation, ToggleWelcomeGroupMutationVariables>(ToggleWelcomeGroupDocument, options);
      }
export type ToggleWelcomeGroupMutationHookResult = ReturnType<typeof useToggleWelcomeGroupMutation>;
export type ToggleWelcomeGroupMutationResult = Apollo.MutationResult<ToggleWelcomeGroupMutation>;
export type ToggleWelcomeGroupMutationOptions = Apollo.BaseMutationOptions<ToggleWelcomeGroupMutation, ToggleWelcomeGroupMutationVariables>;
export const DirectMessageThreadsDocument = gql`
    query DirectMessageThreads($organizationId: String!) {
  directMessageThreads(organizationId: $organizationId) {
    __typename
    ...DirectMessageUserThreadFragment
  }
}
    ${DirectMessageUserThreadFragmentFragmentDoc}`;

/**
 * __useDirectMessageThreadsQuery__
 *
 * To run a query within a React component, call `useDirectMessageThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirectMessageThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectMessageThreadsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDirectMessageThreadsQuery(baseOptions: Apollo.QueryHookOptions<DirectMessageThreadsQuery, DirectMessageThreadsQueryVariables> & ({ variables: DirectMessageThreadsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DirectMessageThreadsQuery, DirectMessageThreadsQueryVariables>(DirectMessageThreadsDocument, options);
      }
export function useDirectMessageThreadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DirectMessageThreadsQuery, DirectMessageThreadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DirectMessageThreadsQuery, DirectMessageThreadsQueryVariables>(DirectMessageThreadsDocument, options);
        }
export function useDirectMessageThreadsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DirectMessageThreadsQuery, DirectMessageThreadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DirectMessageThreadsQuery, DirectMessageThreadsQueryVariables>(DirectMessageThreadsDocument, options);
        }
export type DirectMessageThreadsQueryHookResult = ReturnType<typeof useDirectMessageThreadsQuery>;
export type DirectMessageThreadsLazyQueryHookResult = ReturnType<typeof useDirectMessageThreadsLazyQuery>;
export type DirectMessageThreadsSuspenseQueryHookResult = ReturnType<typeof useDirectMessageThreadsSuspenseQuery>;
export type DirectMessageThreadsQueryResult = Apollo.QueryResult<DirectMessageThreadsQuery, DirectMessageThreadsQueryVariables>;
export function refetchDirectMessageThreadsQuery(variables: DirectMessageThreadsQueryVariables) {
      return { query: DirectMessageThreadsDocument, variables: variables }
    }
export const CreateDirectMessageDocument = gql`
    mutation CreateDirectMessage($input: CreateDirectMessageInput!) {
  createDirectMessage(input: $input) {
    id
  }
}
    `;
export type CreateDirectMessageMutationFn = Apollo.MutationFunction<CreateDirectMessageMutation, CreateDirectMessageMutationVariables>;

/**
 * __useCreateDirectMessageMutation__
 *
 * To run a mutation, you first call `useCreateDirectMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDirectMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDirectMessageMutation, { data, loading, error }] = useCreateDirectMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDirectMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateDirectMessageMutation, CreateDirectMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDirectMessageMutation, CreateDirectMessageMutationVariables>(CreateDirectMessageDocument, options);
      }
export type CreateDirectMessageMutationHookResult = ReturnType<typeof useCreateDirectMessageMutation>;
export type CreateDirectMessageMutationResult = Apollo.MutationResult<CreateDirectMessageMutation>;
export type CreateDirectMessageMutationOptions = Apollo.BaseMutationOptions<CreateDirectMessageMutation, CreateDirectMessageMutationVariables>;
export const DeleteDirectMessageThreadDocument = gql`
    mutation DeleteDirectMessageThread($input: DeleteDirectMessageThreadInput!) {
  deleteDirectMessageThread(input: $input) {
    id
  }
}
    `;
export type DeleteDirectMessageThreadMutationFn = Apollo.MutationFunction<DeleteDirectMessageThreadMutation, DeleteDirectMessageThreadMutationVariables>;

/**
 * __useDeleteDirectMessageThreadMutation__
 *
 * To run a mutation, you first call `useDeleteDirectMessageThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDirectMessageThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDirectMessageThreadMutation, { data, loading, error }] = useDeleteDirectMessageThreadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDirectMessageThreadMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDirectMessageThreadMutation, DeleteDirectMessageThreadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDirectMessageThreadMutation, DeleteDirectMessageThreadMutationVariables>(DeleteDirectMessageThreadDocument, options);
      }
export type DeleteDirectMessageThreadMutationHookResult = ReturnType<typeof useDeleteDirectMessageThreadMutation>;
export type DeleteDirectMessageThreadMutationResult = Apollo.MutationResult<DeleteDirectMessageThreadMutation>;
export type DeleteDirectMessageThreadMutationOptions = Apollo.BaseMutationOptions<DeleteDirectMessageThreadMutation, DeleteDirectMessageThreadMutationVariables>;
export const DirectMessageDocument = gql`
    query DirectMessage($id: String!, $threadId: String!) {
  directMessage(id: $id, threadId: $threadId) {
    __typename
    ...DirectMessageFragment
  }
}
    ${DirectMessageFragmentFragmentDoc}`;

/**
 * __useDirectMessageQuery__
 *
 * To run a query within a React component, call `useDirectMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirectMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectMessageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useDirectMessageQuery(baseOptions: Apollo.QueryHookOptions<DirectMessageQuery, DirectMessageQueryVariables> & ({ variables: DirectMessageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DirectMessageQuery, DirectMessageQueryVariables>(DirectMessageDocument, options);
      }
export function useDirectMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DirectMessageQuery, DirectMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DirectMessageQuery, DirectMessageQueryVariables>(DirectMessageDocument, options);
        }
export function useDirectMessageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DirectMessageQuery, DirectMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DirectMessageQuery, DirectMessageQueryVariables>(DirectMessageDocument, options);
        }
export type DirectMessageQueryHookResult = ReturnType<typeof useDirectMessageQuery>;
export type DirectMessageLazyQueryHookResult = ReturnType<typeof useDirectMessageLazyQuery>;
export type DirectMessageSuspenseQueryHookResult = ReturnType<typeof useDirectMessageSuspenseQuery>;
export type DirectMessageQueryResult = Apollo.QueryResult<DirectMessageQuery, DirectMessageQueryVariables>;
export function refetchDirectMessageQuery(variables: DirectMessageQueryVariables) {
      return { query: DirectMessageDocument, variables: variables }
    }
export const CreateOrDeleteDirectMessageReactionDocument = gql`
    mutation CreateOrDeleteDirectMessageReaction($input: CreateOrDeleteDirectMessageReactionInput!) {
  createOrDeleteDirectMessageReaction(input: $input) {
    __typename
    ...DirectMessageFragment
  }
}
    ${DirectMessageFragmentFragmentDoc}`;
export type CreateOrDeleteDirectMessageReactionMutationFn = Apollo.MutationFunction<CreateOrDeleteDirectMessageReactionMutation, CreateOrDeleteDirectMessageReactionMutationVariables>;

/**
 * __useCreateOrDeleteDirectMessageReactionMutation__
 *
 * To run a mutation, you first call `useCreateOrDeleteDirectMessageReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrDeleteDirectMessageReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrDeleteDirectMessageReactionMutation, { data, loading, error }] = useCreateOrDeleteDirectMessageReactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrDeleteDirectMessageReactionMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrDeleteDirectMessageReactionMutation, CreateOrDeleteDirectMessageReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrDeleteDirectMessageReactionMutation, CreateOrDeleteDirectMessageReactionMutationVariables>(CreateOrDeleteDirectMessageReactionDocument, options);
      }
export type CreateOrDeleteDirectMessageReactionMutationHookResult = ReturnType<typeof useCreateOrDeleteDirectMessageReactionMutation>;
export type CreateOrDeleteDirectMessageReactionMutationResult = Apollo.MutationResult<CreateOrDeleteDirectMessageReactionMutation>;
export type CreateOrDeleteDirectMessageReactionMutationOptions = Apollo.BaseMutationOptions<CreateOrDeleteDirectMessageReactionMutation, CreateOrDeleteDirectMessageReactionMutationVariables>;
export const DirectMessageNotificationCountsDocument = gql`
    query DirectMessageNotificationCounts($organizationId: String!) {
  directMessageNotificationCounts(organizationId: $organizationId) {
    __typename
    unread
  }
}
    `;

/**
 * __useDirectMessageNotificationCountsQuery__
 *
 * To run a query within a React component, call `useDirectMessageNotificationCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirectMessageNotificationCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectMessageNotificationCountsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDirectMessageNotificationCountsQuery(baseOptions: Apollo.QueryHookOptions<DirectMessageNotificationCountsQuery, DirectMessageNotificationCountsQueryVariables> & ({ variables: DirectMessageNotificationCountsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DirectMessageNotificationCountsQuery, DirectMessageNotificationCountsQueryVariables>(DirectMessageNotificationCountsDocument, options);
      }
export function useDirectMessageNotificationCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DirectMessageNotificationCountsQuery, DirectMessageNotificationCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DirectMessageNotificationCountsQuery, DirectMessageNotificationCountsQueryVariables>(DirectMessageNotificationCountsDocument, options);
        }
export function useDirectMessageNotificationCountsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DirectMessageNotificationCountsQuery, DirectMessageNotificationCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DirectMessageNotificationCountsQuery, DirectMessageNotificationCountsQueryVariables>(DirectMessageNotificationCountsDocument, options);
        }
export type DirectMessageNotificationCountsQueryHookResult = ReturnType<typeof useDirectMessageNotificationCountsQuery>;
export type DirectMessageNotificationCountsLazyQueryHookResult = ReturnType<typeof useDirectMessageNotificationCountsLazyQuery>;
export type DirectMessageNotificationCountsSuspenseQueryHookResult = ReturnType<typeof useDirectMessageNotificationCountsSuspenseQuery>;
export type DirectMessageNotificationCountsQueryResult = Apollo.QueryResult<DirectMessageNotificationCountsQuery, DirectMessageNotificationCountsQueryVariables>;
export function refetchDirectMessageNotificationCountsQuery(variables: DirectMessageNotificationCountsQueryVariables) {
      return { query: DirectMessageNotificationCountsDocument, variables: variables }
    }
export const UpdateDirectMessageThreadSettingsDocument = gql`
    mutation UpdateDirectMessageThreadSettings($input: UpdateDirectMessageUserThreadSettingsInput!) {
  updateDirectMessageThreadSettings(input: $input) {
    __typename
    ...DirectMessageUserThreadSettingsFragment
  }
}
    ${DirectMessageUserThreadSettingsFragmentFragmentDoc}`;
export type UpdateDirectMessageThreadSettingsMutationFn = Apollo.MutationFunction<UpdateDirectMessageThreadSettingsMutation, UpdateDirectMessageThreadSettingsMutationVariables>;

/**
 * __useUpdateDirectMessageThreadSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateDirectMessageThreadSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDirectMessageThreadSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDirectMessageThreadSettingsMutation, { data, loading, error }] = useUpdateDirectMessageThreadSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDirectMessageThreadSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDirectMessageThreadSettingsMutation, UpdateDirectMessageThreadSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDirectMessageThreadSettingsMutation, UpdateDirectMessageThreadSettingsMutationVariables>(UpdateDirectMessageThreadSettingsDocument, options);
      }
export type UpdateDirectMessageThreadSettingsMutationHookResult = ReturnType<typeof useUpdateDirectMessageThreadSettingsMutation>;
export type UpdateDirectMessageThreadSettingsMutationResult = Apollo.MutationResult<UpdateDirectMessageThreadSettingsMutation>;
export type UpdateDirectMessageThreadSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateDirectMessageThreadSettingsMutation, UpdateDirectMessageThreadSettingsMutationVariables>;
export const MarkDirectMessageReadDocument = gql`
    mutation MarkDirectMessageRead($input: MarkDirectMessageReadInput!) {
  markDirectMessageRead(input: $input) {
    id
  }
}
    `;
export type MarkDirectMessageReadMutationFn = Apollo.MutationFunction<MarkDirectMessageReadMutation, MarkDirectMessageReadMutationVariables>;

/**
 * __useMarkDirectMessageReadMutation__
 *
 * To run a mutation, you first call `useMarkDirectMessageReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkDirectMessageReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markDirectMessageReadMutation, { data, loading, error }] = useMarkDirectMessageReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkDirectMessageReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkDirectMessageReadMutation, MarkDirectMessageReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkDirectMessageReadMutation, MarkDirectMessageReadMutationVariables>(MarkDirectMessageReadDocument, options);
      }
export type MarkDirectMessageReadMutationHookResult = ReturnType<typeof useMarkDirectMessageReadMutation>;
export type MarkDirectMessageReadMutationResult = Apollo.MutationResult<MarkDirectMessageReadMutation>;
export type MarkDirectMessageReadMutationOptions = Apollo.BaseMutationOptions<MarkDirectMessageReadMutation, MarkDirectMessageReadMutationVariables>;
export const EventsDocument = gql`
    query Events($organizationId: String!, $first: Int!, $after: String, $past: Boolean) {
  events(
    organizationId: $organizationId
    first: $first
    after: $after
    past: $past
  ) {
    edges {
      node {
        ...EventCore
      }
      cursor
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${EventCoreFragmentDoc}`;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      past: // value for 'past'
 *   },
 * });
 */
export function useEventsQuery(baseOptions: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables> & ({ variables: EventsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
      }
export function useEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
        }
export function useEventsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsSuspenseQueryHookResult = ReturnType<typeof useEventsSuspenseQuery>;
export type EventsQueryResult = Apollo.QueryResult<EventsQuery, EventsQueryVariables>;
export function refetchEventsQuery(variables: EventsQueryVariables) {
      return { query: EventsDocument, variables: variables }
    }
export const PostsByEventDocument = gql`
    query PostsByEvent($eventId: ID!, $first: Int!, $after: String) {
  postsByEvent(eventId: $eventId, first: $first, after: $after) {
    edges {
      node {
        ...PostFeed
      }
      cursor
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${PostFeedFragmentDoc}`;

/**
 * __usePostsByEventQuery__
 *
 * To run a query within a React component, call `usePostsByEventQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsByEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsByEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePostsByEventQuery(baseOptions: Apollo.QueryHookOptions<PostsByEventQuery, PostsByEventQueryVariables> & ({ variables: PostsByEventQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostsByEventQuery, PostsByEventQueryVariables>(PostsByEventDocument, options);
      }
export function usePostsByEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostsByEventQuery, PostsByEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostsByEventQuery, PostsByEventQueryVariables>(PostsByEventDocument, options);
        }
export function usePostsByEventSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PostsByEventQuery, PostsByEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PostsByEventQuery, PostsByEventQueryVariables>(PostsByEventDocument, options);
        }
export type PostsByEventQueryHookResult = ReturnType<typeof usePostsByEventQuery>;
export type PostsByEventLazyQueryHookResult = ReturnType<typeof usePostsByEventLazyQuery>;
export type PostsByEventSuspenseQueryHookResult = ReturnType<typeof usePostsByEventSuspenseQuery>;
export type PostsByEventQueryResult = Apollo.QueryResult<PostsByEventQuery, PostsByEventQueryVariables>;
export function refetchPostsByEventQuery(variables: PostsByEventQueryVariables) {
      return { query: PostsByEventDocument, variables: variables }
    }
export const EventDocument = gql`
    query Event($id: String!) {
  event(id: $id) {
    __typename
    ... on Event {
      ...EventCore
    }
    ... on EventError {
      message
    }
  }
}
    ${EventCoreFragmentDoc}`;

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventQuery(baseOptions: Apollo.QueryHookOptions<EventQuery, EventQueryVariables> & ({ variables: EventQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventQuery, EventQueryVariables>(EventDocument, options);
      }
export function useEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventQuery, EventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventQuery, EventQueryVariables>(EventDocument, options);
        }
export function useEventSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EventQuery, EventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EventQuery, EventQueryVariables>(EventDocument, options);
        }
export type EventQueryHookResult = ReturnType<typeof useEventQuery>;
export type EventLazyQueryHookResult = ReturnType<typeof useEventLazyQuery>;
export type EventSuspenseQueryHookResult = ReturnType<typeof useEventSuspenseQuery>;
export type EventQueryResult = Apollo.QueryResult<EventQuery, EventQueryVariables>;
export function refetchEventQuery(variables: EventQueryVariables) {
      return { query: EventDocument, variables: variables }
    }
export const CreateEventDocument = gql`
    mutation CreateEvent($input: CreateEventInput!) {
  createEvent(input: $input) {
    ...EventCore
  }
}
    ${EventCoreFragmentDoc}`;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const UpdateEventDocument = gql`
    mutation UpdateEvent($input: UpdateEventInput!) {
  updateEvent(input: $input) {
    ...EventCore
  }
}
    ${EventCoreFragmentDoc}`;
export type UpdateEventMutationFn = Apollo.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, options);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const DeleteEventDocument = gql`
    mutation DeleteEvent($input: DeleteEventInput!) {
  deleteEvent(input: $input) {
    ... on Event {
      id
    }
    ... on EventError {
      message
    }
  }
}
    `;
export type DeleteEventMutationFn = Apollo.MutationFunction<DeleteEventMutation, DeleteEventMutationVariables>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument, options);
      }
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = Apollo.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<DeleteEventMutation, DeleteEventMutationVariables>;
export const LessonsDocument = gql`
    query Lessons($courseId: String!, $first: Int!, $after: String) {
  lessons(courseId: $courseId, first: $first, after: $after) {
    edges {
      node {
        ...LessonCore
      }
      cursor
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${LessonCoreFragmentDoc}`;

/**
 * __useLessonsQuery__
 *
 * To run a query within a React component, call `useLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonsQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useLessonsQuery(baseOptions: Apollo.QueryHookOptions<LessonsQuery, LessonsQueryVariables> & ({ variables: LessonsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LessonsQuery, LessonsQueryVariables>(LessonsDocument, options);
      }
export function useLessonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LessonsQuery, LessonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LessonsQuery, LessonsQueryVariables>(LessonsDocument, options);
        }
export function useLessonsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LessonsQuery, LessonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LessonsQuery, LessonsQueryVariables>(LessonsDocument, options);
        }
export type LessonsQueryHookResult = ReturnType<typeof useLessonsQuery>;
export type LessonsLazyQueryHookResult = ReturnType<typeof useLessonsLazyQuery>;
export type LessonsSuspenseQueryHookResult = ReturnType<typeof useLessonsSuspenseQuery>;
export type LessonsQueryResult = Apollo.QueryResult<LessonsQuery, LessonsQueryVariables>;
export function refetchLessonsQuery(variables: LessonsQueryVariables) {
      return { query: LessonsDocument, variables: variables }
    }
export const CreateCourseDocument = gql`
    mutation CreateCourse($input: CreateCourseInput!) {
  createCourse(input: $input) {
    ...CourseCore
  }
}
    ${CourseCoreFragmentDoc}`;
export type CreateCourseMutationFn = Apollo.MutationFunction<CreateCourseMutation, CreateCourseMutationVariables>;

/**
 * __useCreateCourseMutation__
 *
 * To run a mutation, you first call `useCreateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseMutation, { data, loading, error }] = useCreateCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCourseMutation(baseOptions?: Apollo.MutationHookOptions<CreateCourseMutation, CreateCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCourseMutation, CreateCourseMutationVariables>(CreateCourseDocument, options);
      }
export type CreateCourseMutationHookResult = ReturnType<typeof useCreateCourseMutation>;
export type CreateCourseMutationResult = Apollo.MutationResult<CreateCourseMutation>;
export type CreateCourseMutationOptions = Apollo.BaseMutationOptions<CreateCourseMutation, CreateCourseMutationVariables>;
export const ReorderLessonDocument = gql`
    mutation ReorderLesson($input: ReorderLessonInput!) {
  reorderLesson(input: $input) {
    ...LessonCore
  }
}
    ${LessonCoreFragmentDoc}`;
export type ReorderLessonMutationFn = Apollo.MutationFunction<ReorderLessonMutation, ReorderLessonMutationVariables>;

/**
 * __useReorderLessonMutation__
 *
 * To run a mutation, you first call `useReorderLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderLessonMutation, { data, loading, error }] = useReorderLessonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReorderLessonMutation(baseOptions?: Apollo.MutationHookOptions<ReorderLessonMutation, ReorderLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderLessonMutation, ReorderLessonMutationVariables>(ReorderLessonDocument, options);
      }
export type ReorderLessonMutationHookResult = ReturnType<typeof useReorderLessonMutation>;
export type ReorderLessonMutationResult = Apollo.MutationResult<ReorderLessonMutation>;
export type ReorderLessonMutationOptions = Apollo.BaseMutationOptions<ReorderLessonMutation, ReorderLessonMutationVariables>;
export const ToggleLessonCompletedDocument = gql`
    mutation ToggleLessonCompleted($input: ToggleLessonCompletedInput!) {
  toggleLessonCompleted(input: $input) {
    ...LessonCore
  }
}
    ${LessonCoreFragmentDoc}`;
export type ToggleLessonCompletedMutationFn = Apollo.MutationFunction<ToggleLessonCompletedMutation, ToggleLessonCompletedMutationVariables>;

/**
 * __useToggleLessonCompletedMutation__
 *
 * To run a mutation, you first call `useToggleLessonCompletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleLessonCompletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleLessonCompletedMutation, { data, loading, error }] = useToggleLessonCompletedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleLessonCompletedMutation(baseOptions?: Apollo.MutationHookOptions<ToggleLessonCompletedMutation, ToggleLessonCompletedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleLessonCompletedMutation, ToggleLessonCompletedMutationVariables>(ToggleLessonCompletedDocument, options);
      }
export type ToggleLessonCompletedMutationHookResult = ReturnType<typeof useToggleLessonCompletedMutation>;
export type ToggleLessonCompletedMutationResult = Apollo.MutationResult<ToggleLessonCompletedMutation>;
export type ToggleLessonCompletedMutationOptions = Apollo.BaseMutationOptions<ToggleLessonCompletedMutation, ToggleLessonCompletedMutationVariables>;
export const UpsellsDocument = gql`
    query Upsells($organizationId: ID!) {
  upsells(organizationId: $organizationId) {
    ...UpsellCore
  }
}
    ${UpsellCoreFragmentDoc}`;

/**
 * __useUpsellsQuery__
 *
 * To run a query within a React component, call `useUpsellsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpsellsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpsellsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useUpsellsQuery(baseOptions: Apollo.QueryHookOptions<UpsellsQuery, UpsellsQueryVariables> & ({ variables: UpsellsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpsellsQuery, UpsellsQueryVariables>(UpsellsDocument, options);
      }
export function useUpsellsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpsellsQuery, UpsellsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpsellsQuery, UpsellsQueryVariables>(UpsellsDocument, options);
        }
export function useUpsellsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UpsellsQuery, UpsellsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UpsellsQuery, UpsellsQueryVariables>(UpsellsDocument, options);
        }
export type UpsellsQueryHookResult = ReturnType<typeof useUpsellsQuery>;
export type UpsellsLazyQueryHookResult = ReturnType<typeof useUpsellsLazyQuery>;
export type UpsellsSuspenseQueryHookResult = ReturnType<typeof useUpsellsSuspenseQuery>;
export type UpsellsQueryResult = Apollo.QueryResult<UpsellsQuery, UpsellsQueryVariables>;
export function refetchUpsellsQuery(variables: UpsellsQueryVariables) {
      return { query: UpsellsDocument, variables: variables }
    }
export const HideUpsellDocument = gql`
    mutation HideUpsell($input: HideUpsellInput!) {
  hideUpsell(input: $input) {
    ...UpsellCore
  }
}
    ${UpsellCoreFragmentDoc}`;
export type HideUpsellMutationFn = Apollo.MutationFunction<HideUpsellMutation, HideUpsellMutationVariables>;

/**
 * __useHideUpsellMutation__
 *
 * To run a mutation, you first call `useHideUpsellMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHideUpsellMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hideUpsellMutation, { data, loading, error }] = useHideUpsellMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHideUpsellMutation(baseOptions?: Apollo.MutationHookOptions<HideUpsellMutation, HideUpsellMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HideUpsellMutation, HideUpsellMutationVariables>(HideUpsellDocument, options);
      }
export type HideUpsellMutationHookResult = ReturnType<typeof useHideUpsellMutation>;
export type HideUpsellMutationResult = Apollo.MutationResult<HideUpsellMutation>;
export type HideUpsellMutationOptions = Apollo.BaseMutationOptions<HideUpsellMutation, HideUpsellMutationVariables>;
export const PublishCourseDocument = gql`
    mutation PublishCourse($input: PublishCourseInput!) {
  publishCourse(input: $input) {
    id
    isDraft
  }
}
    `;
export type PublishCourseMutationFn = Apollo.MutationFunction<PublishCourseMutation, PublishCourseMutationVariables>;

/**
 * __usePublishCourseMutation__
 *
 * To run a mutation, you first call `usePublishCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishCourseMutation, { data, loading, error }] = usePublishCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishCourseMutation(baseOptions?: Apollo.MutationHookOptions<PublishCourseMutation, PublishCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishCourseMutation, PublishCourseMutationVariables>(PublishCourseDocument, options);
      }
export type PublishCourseMutationHookResult = ReturnType<typeof usePublishCourseMutation>;
export type PublishCourseMutationResult = Apollo.MutationResult<PublishCourseMutation>;
export type PublishCourseMutationOptions = Apollo.BaseMutationOptions<PublishCourseMutation, PublishCourseMutationVariables>;
export const LocationByNameDocument = gql`
    query LocationByName($name: String!) {
  locationByName(name: $name) {
    placeId
    type
    displayName
    lat
    lon
  }
}
    `;

/**
 * __useLocationByNameQuery__
 *
 * To run a query within a React component, call `useLocationByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useLocationByNameQuery(baseOptions: Apollo.QueryHookOptions<LocationByNameQuery, LocationByNameQueryVariables> & ({ variables: LocationByNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationByNameQuery, LocationByNameQueryVariables>(LocationByNameDocument, options);
      }
export function useLocationByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationByNameQuery, LocationByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationByNameQuery, LocationByNameQueryVariables>(LocationByNameDocument, options);
        }
export function useLocationByNameSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LocationByNameQuery, LocationByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LocationByNameQuery, LocationByNameQueryVariables>(LocationByNameDocument, options);
        }
export type LocationByNameQueryHookResult = ReturnType<typeof useLocationByNameQuery>;
export type LocationByNameLazyQueryHookResult = ReturnType<typeof useLocationByNameLazyQuery>;
export type LocationByNameSuspenseQueryHookResult = ReturnType<typeof useLocationByNameSuspenseQuery>;
export type LocationByNameQueryResult = Apollo.QueryResult<LocationByNameQuery, LocationByNameQueryVariables>;
export function refetchLocationByNameQuery(variables: LocationByNameQueryVariables) {
      return { query: LocationByNameDocument, variables: variables }
    }
export const LocationByPlaceIdDocument = gql`
    query LocationByPlaceId($placeId: String!) {
  locationByPlaceId(placeId: $placeId) {
    placeId
    type
    displayName
    lat
    lon
  }
}
    `;

/**
 * __useLocationByPlaceIdQuery__
 *
 * To run a query within a React component, call `useLocationByPlaceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationByPlaceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationByPlaceIdQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function useLocationByPlaceIdQuery(baseOptions: Apollo.QueryHookOptions<LocationByPlaceIdQuery, LocationByPlaceIdQueryVariables> & ({ variables: LocationByPlaceIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationByPlaceIdQuery, LocationByPlaceIdQueryVariables>(LocationByPlaceIdDocument, options);
      }
export function useLocationByPlaceIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationByPlaceIdQuery, LocationByPlaceIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationByPlaceIdQuery, LocationByPlaceIdQueryVariables>(LocationByPlaceIdDocument, options);
        }
export function useLocationByPlaceIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LocationByPlaceIdQuery, LocationByPlaceIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LocationByPlaceIdQuery, LocationByPlaceIdQueryVariables>(LocationByPlaceIdDocument, options);
        }
export type LocationByPlaceIdQueryHookResult = ReturnType<typeof useLocationByPlaceIdQuery>;
export type LocationByPlaceIdLazyQueryHookResult = ReturnType<typeof useLocationByPlaceIdLazyQuery>;
export type LocationByPlaceIdSuspenseQueryHookResult = ReturnType<typeof useLocationByPlaceIdSuspenseQuery>;
export type LocationByPlaceIdQueryResult = Apollo.QueryResult<LocationByPlaceIdQuery, LocationByPlaceIdQueryVariables>;
export function refetchLocationByPlaceIdQuery(variables: LocationByPlaceIdQueryVariables) {
      return { query: LocationByPlaceIdDocument, variables: variables }
    }
export const ToggleEventPostCreationPolicyDocument = gql`
    mutation ToggleEventPostCreationPolicy($input: ToggleEventPostCreationPolicyInput!) {
  toggleEventPostCreationPolicy(input: $input) {
    ...EventCore
  }
}
    ${EventCoreFragmentDoc}`;
export type ToggleEventPostCreationPolicyMutationFn = Apollo.MutationFunction<ToggleEventPostCreationPolicyMutation, ToggleEventPostCreationPolicyMutationVariables>;

/**
 * __useToggleEventPostCreationPolicyMutation__
 *
 * To run a mutation, you first call `useToggleEventPostCreationPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleEventPostCreationPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleEventPostCreationPolicyMutation, { data, loading, error }] = useToggleEventPostCreationPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleEventPostCreationPolicyMutation(baseOptions?: Apollo.MutationHookOptions<ToggleEventPostCreationPolicyMutation, ToggleEventPostCreationPolicyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleEventPostCreationPolicyMutation, ToggleEventPostCreationPolicyMutationVariables>(ToggleEventPostCreationPolicyDocument, options);
      }
export type ToggleEventPostCreationPolicyMutationHookResult = ReturnType<typeof useToggleEventPostCreationPolicyMutation>;
export type ToggleEventPostCreationPolicyMutationResult = Apollo.MutationResult<ToggleEventPostCreationPolicyMutation>;
export type ToggleEventPostCreationPolicyMutationOptions = Apollo.BaseMutationOptions<ToggleEventPostCreationPolicyMutation, ToggleEventPostCreationPolicyMutationVariables>;
export const ToggleEventNotificationsDocument = gql`
    mutation ToggleEventNotifications($input: ToggleEventNotificationsInput!) {
  toggleEventNotifications(input: $input) {
    ...EventCore
  }
}
    ${EventCoreFragmentDoc}`;
export type ToggleEventNotificationsMutationFn = Apollo.MutationFunction<ToggleEventNotificationsMutation, ToggleEventNotificationsMutationVariables>;

/**
 * __useToggleEventNotificationsMutation__
 *
 * To run a mutation, you first call `useToggleEventNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleEventNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleEventNotificationsMutation, { data, loading, error }] = useToggleEventNotificationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleEventNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<ToggleEventNotificationsMutation, ToggleEventNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleEventNotificationsMutation, ToggleEventNotificationsMutationVariables>(ToggleEventNotificationsDocument, options);
      }
export type ToggleEventNotificationsMutationHookResult = ReturnType<typeof useToggleEventNotificationsMutation>;
export type ToggleEventNotificationsMutationResult = Apollo.MutationResult<ToggleEventNotificationsMutation>;
export type ToggleEventNotificationsMutationOptions = Apollo.BaseMutationOptions<ToggleEventNotificationsMutation, ToggleEventNotificationsMutationVariables>;
export const CourseCompletionDocument = gql`
    query CourseCompletion($courseId: ID!) {
  courseCompletion(courseId: $courseId) {
    id
    percentage
  }
}
    `;

/**
 * __useCourseCompletionQuery__
 *
 * To run a query within a React component, call `useCourseCompletionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseCompletionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseCompletionQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useCourseCompletionQuery(baseOptions: Apollo.QueryHookOptions<CourseCompletionQuery, CourseCompletionQueryVariables> & ({ variables: CourseCompletionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CourseCompletionQuery, CourseCompletionQueryVariables>(CourseCompletionDocument, options);
      }
export function useCourseCompletionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CourseCompletionQuery, CourseCompletionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CourseCompletionQuery, CourseCompletionQueryVariables>(CourseCompletionDocument, options);
        }
export function useCourseCompletionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CourseCompletionQuery, CourseCompletionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CourseCompletionQuery, CourseCompletionQueryVariables>(CourseCompletionDocument, options);
        }
export type CourseCompletionQueryHookResult = ReturnType<typeof useCourseCompletionQuery>;
export type CourseCompletionLazyQueryHookResult = ReturnType<typeof useCourseCompletionLazyQuery>;
export type CourseCompletionSuspenseQueryHookResult = ReturnType<typeof useCourseCompletionSuspenseQuery>;
export type CourseCompletionQueryResult = Apollo.QueryResult<CourseCompletionQuery, CourseCompletionQueryVariables>;
export function refetchCourseCompletionQuery(variables: CourseCompletionQueryVariables) {
      return { query: CourseCompletionDocument, variables: variables }
    }
export const ToggleOrganizationMembershipOnboardingTaskDocument = gql`
    mutation ToggleOrganizationMembershipOnboardingTask($input: ToggleOrganizationMembershipOnboardingTaskInput!) {
  toggleOrganizationMembershipOnboardingTask(input: $input) {
    id
    completedOnboardingTasks
  }
}
    `;
export type ToggleOrganizationMembershipOnboardingTaskMutationFn = Apollo.MutationFunction<ToggleOrganizationMembershipOnboardingTaskMutation, ToggleOrganizationMembershipOnboardingTaskMutationVariables>;

/**
 * __useToggleOrganizationMembershipOnboardingTaskMutation__
 *
 * To run a mutation, you first call `useToggleOrganizationMembershipOnboardingTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleOrganizationMembershipOnboardingTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleOrganizationMembershipOnboardingTaskMutation, { data, loading, error }] = useToggleOrganizationMembershipOnboardingTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleOrganizationMembershipOnboardingTaskMutation(baseOptions?: Apollo.MutationHookOptions<ToggleOrganizationMembershipOnboardingTaskMutation, ToggleOrganizationMembershipOnboardingTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleOrganizationMembershipOnboardingTaskMutation, ToggleOrganizationMembershipOnboardingTaskMutationVariables>(ToggleOrganizationMembershipOnboardingTaskDocument, options);
      }
export type ToggleOrganizationMembershipOnboardingTaskMutationHookResult = ReturnType<typeof useToggleOrganizationMembershipOnboardingTaskMutation>;
export type ToggleOrganizationMembershipOnboardingTaskMutationResult = Apollo.MutationResult<ToggleOrganizationMembershipOnboardingTaskMutation>;
export type ToggleOrganizationMembershipOnboardingTaskMutationOptions = Apollo.BaseMutationOptions<ToggleOrganizationMembershipOnboardingTaskMutation, ToggleOrganizationMembershipOnboardingTaskMutationVariables>;
export const CreateOrDeletePostBookmarkDocument = gql`
    mutation CreateOrDeletePostBookmark($input: CreateOrDeletePostBookmarkInput!) {
  createOrDeletePostBookmark(input: $input) {
    post {
      ...PostFeed
    }
  }
}
    ${PostFeedFragmentDoc}`;
export type CreateOrDeletePostBookmarkMutationFn = Apollo.MutationFunction<CreateOrDeletePostBookmarkMutation, CreateOrDeletePostBookmarkMutationVariables>;

/**
 * __useCreateOrDeletePostBookmarkMutation__
 *
 * To run a mutation, you first call `useCreateOrDeletePostBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrDeletePostBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrDeletePostBookmarkMutation, { data, loading, error }] = useCreateOrDeletePostBookmarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrDeletePostBookmarkMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrDeletePostBookmarkMutation, CreateOrDeletePostBookmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrDeletePostBookmarkMutation, CreateOrDeletePostBookmarkMutationVariables>(CreateOrDeletePostBookmarkDocument, options);
      }
export type CreateOrDeletePostBookmarkMutationHookResult = ReturnType<typeof useCreateOrDeletePostBookmarkMutation>;
export type CreateOrDeletePostBookmarkMutationResult = Apollo.MutationResult<CreateOrDeletePostBookmarkMutation>;
export type CreateOrDeletePostBookmarkMutationOptions = Apollo.BaseMutationOptions<CreateOrDeletePostBookmarkMutation, CreateOrDeletePostBookmarkMutationVariables>;
export const PostBookmarksDocument = gql`
    query PostBookmarks($organizationId: ID!, $first: Int!, $after: String) {
  postBookmarks(organizationId: $organizationId, first: $first, after: $after) {
    edges {
      node {
        post {
          ...PostFeed
        }
      }
      cursor
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${PostFeedFragmentDoc}`;

/**
 * __usePostBookmarksQuery__
 *
 * To run a query within a React component, call `usePostBookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostBookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostBookmarksQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePostBookmarksQuery(baseOptions: Apollo.QueryHookOptions<PostBookmarksQuery, PostBookmarksQueryVariables> & ({ variables: PostBookmarksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostBookmarksQuery, PostBookmarksQueryVariables>(PostBookmarksDocument, options);
      }
export function usePostBookmarksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostBookmarksQuery, PostBookmarksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostBookmarksQuery, PostBookmarksQueryVariables>(PostBookmarksDocument, options);
        }
export function usePostBookmarksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PostBookmarksQuery, PostBookmarksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PostBookmarksQuery, PostBookmarksQueryVariables>(PostBookmarksDocument, options);
        }
export type PostBookmarksQueryHookResult = ReturnType<typeof usePostBookmarksQuery>;
export type PostBookmarksLazyQueryHookResult = ReturnType<typeof usePostBookmarksLazyQuery>;
export type PostBookmarksSuspenseQueryHookResult = ReturnType<typeof usePostBookmarksSuspenseQuery>;
export type PostBookmarksQueryResult = Apollo.QueryResult<PostBookmarksQuery, PostBookmarksQueryVariables>;
export function refetchPostBookmarksQuery(variables: PostBookmarksQueryVariables) {
      return { query: PostBookmarksDocument, variables: variables }
    }
export const MenuSectionsDocument = gql`
    query MenuSections($organizationId: ID!) {
  menuSections(organizationId: $organizationId) {
    ...MenuSectionCore
  }
}
    ${MenuSectionCoreFragmentDoc}`;

/**
 * __useMenuSectionsQuery__
 *
 * To run a query within a React component, call `useMenuSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuSectionsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useMenuSectionsQuery(baseOptions: Apollo.QueryHookOptions<MenuSectionsQuery, MenuSectionsQueryVariables> & ({ variables: MenuSectionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenuSectionsQuery, MenuSectionsQueryVariables>(MenuSectionsDocument, options);
      }
export function useMenuSectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuSectionsQuery, MenuSectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenuSectionsQuery, MenuSectionsQueryVariables>(MenuSectionsDocument, options);
        }
export function useMenuSectionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MenuSectionsQuery, MenuSectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MenuSectionsQuery, MenuSectionsQueryVariables>(MenuSectionsDocument, options);
        }
export type MenuSectionsQueryHookResult = ReturnType<typeof useMenuSectionsQuery>;
export type MenuSectionsLazyQueryHookResult = ReturnType<typeof useMenuSectionsLazyQuery>;
export type MenuSectionsSuspenseQueryHookResult = ReturnType<typeof useMenuSectionsSuspenseQuery>;
export type MenuSectionsQueryResult = Apollo.QueryResult<MenuSectionsQuery, MenuSectionsQueryVariables>;
export function refetchMenuSectionsQuery(variables: MenuSectionsQueryVariables) {
      return { query: MenuSectionsDocument, variables: variables }
    }
export const CreateMenuSectionDocument = gql`
    mutation CreateMenuSection($input: CreateMenuSectionInput!) {
  createMenuSection(input: $input) {
    ...MenuSectionCore
  }
}
    ${MenuSectionCoreFragmentDoc}`;
export type CreateMenuSectionMutationFn = Apollo.MutationFunction<CreateMenuSectionMutation, CreateMenuSectionMutationVariables>;

/**
 * __useCreateMenuSectionMutation__
 *
 * To run a mutation, you first call `useCreateMenuSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMenuSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMenuSectionMutation, { data, loading, error }] = useCreateMenuSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMenuSectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateMenuSectionMutation, CreateMenuSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMenuSectionMutation, CreateMenuSectionMutationVariables>(CreateMenuSectionDocument, options);
      }
export type CreateMenuSectionMutationHookResult = ReturnType<typeof useCreateMenuSectionMutation>;
export type CreateMenuSectionMutationResult = Apollo.MutationResult<CreateMenuSectionMutation>;
export type CreateMenuSectionMutationOptions = Apollo.BaseMutationOptions<CreateMenuSectionMutation, CreateMenuSectionMutationVariables>;
export const UpdateMenuSectionDocument = gql`
    mutation UpdateMenuSection($input: UpdateMenuSectionInput!) {
  updateMenuSection(input: $input) {
    ...MenuSectionCore
  }
}
    ${MenuSectionCoreFragmentDoc}`;
export type UpdateMenuSectionMutationFn = Apollo.MutationFunction<UpdateMenuSectionMutation, UpdateMenuSectionMutationVariables>;

/**
 * __useUpdateMenuSectionMutation__
 *
 * To run a mutation, you first call `useUpdateMenuSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMenuSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMenuSectionMutation, { data, loading, error }] = useUpdateMenuSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMenuSectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMenuSectionMutation, UpdateMenuSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMenuSectionMutation, UpdateMenuSectionMutationVariables>(UpdateMenuSectionDocument, options);
      }
export type UpdateMenuSectionMutationHookResult = ReturnType<typeof useUpdateMenuSectionMutation>;
export type UpdateMenuSectionMutationResult = Apollo.MutationResult<UpdateMenuSectionMutation>;
export type UpdateMenuSectionMutationOptions = Apollo.BaseMutationOptions<UpdateMenuSectionMutation, UpdateMenuSectionMutationVariables>;
export const DeleteMenuSectionDocument = gql`
    mutation DeleteMenuSection($input: DeleteMenuSectionInput!) {
  deleteMenuSection(input: $input) {
    ...MenuSectionCore
  }
}
    ${MenuSectionCoreFragmentDoc}`;
export type DeleteMenuSectionMutationFn = Apollo.MutationFunction<DeleteMenuSectionMutation, DeleteMenuSectionMutationVariables>;

/**
 * __useDeleteMenuSectionMutation__
 *
 * To run a mutation, you first call `useDeleteMenuSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMenuSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMenuSectionMutation, { data, loading, error }] = useDeleteMenuSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMenuSectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMenuSectionMutation, DeleteMenuSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMenuSectionMutation, DeleteMenuSectionMutationVariables>(DeleteMenuSectionDocument, options);
      }
export type DeleteMenuSectionMutationHookResult = ReturnType<typeof useDeleteMenuSectionMutation>;
export type DeleteMenuSectionMutationResult = Apollo.MutationResult<DeleteMenuSectionMutation>;
export type DeleteMenuSectionMutationOptions = Apollo.BaseMutationOptions<DeleteMenuSectionMutation, DeleteMenuSectionMutationVariables>;
export const ReorderMenuSectionsDocument = gql`
    mutation ReorderMenuSections($input: ReorderMenuSectionsInput!) {
  reorderMenuSections(input: $input) {
    ...MenuSectionCore
  }
}
    ${MenuSectionCoreFragmentDoc}`;
export type ReorderMenuSectionsMutationFn = Apollo.MutationFunction<ReorderMenuSectionsMutation, ReorderMenuSectionsMutationVariables>;

/**
 * __useReorderMenuSectionsMutation__
 *
 * To run a mutation, you first call `useReorderMenuSectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderMenuSectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderMenuSectionsMutation, { data, loading, error }] = useReorderMenuSectionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReorderMenuSectionsMutation(baseOptions?: Apollo.MutationHookOptions<ReorderMenuSectionsMutation, ReorderMenuSectionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderMenuSectionsMutation, ReorderMenuSectionsMutationVariables>(ReorderMenuSectionsDocument, options);
      }
export type ReorderMenuSectionsMutationHookResult = ReturnType<typeof useReorderMenuSectionsMutation>;
export type ReorderMenuSectionsMutationResult = Apollo.MutationResult<ReorderMenuSectionsMutation>;
export type ReorderMenuSectionsMutationOptions = Apollo.BaseMutationOptions<ReorderMenuSectionsMutation, ReorderMenuSectionsMutationVariables>;
export const CreateMenuItemUrlDocument = gql`
    mutation CreateMenuItemUrl($input: CreateMenuItemUrlInput!) {
  createMenuItemUrl(input: $input) {
    ...MenuItemCore
  }
}
    ${MenuItemCoreFragmentDoc}`;
export type CreateMenuItemUrlMutationFn = Apollo.MutationFunction<CreateMenuItemUrlMutation, CreateMenuItemUrlMutationVariables>;

/**
 * __useCreateMenuItemUrlMutation__
 *
 * To run a mutation, you first call `useCreateMenuItemUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMenuItemUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMenuItemUrlMutation, { data, loading, error }] = useCreateMenuItemUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMenuItemUrlMutation(baseOptions?: Apollo.MutationHookOptions<CreateMenuItemUrlMutation, CreateMenuItemUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMenuItemUrlMutation, CreateMenuItemUrlMutationVariables>(CreateMenuItemUrlDocument, options);
      }
export type CreateMenuItemUrlMutationHookResult = ReturnType<typeof useCreateMenuItemUrlMutation>;
export type CreateMenuItemUrlMutationResult = Apollo.MutationResult<CreateMenuItemUrlMutation>;
export type CreateMenuItemUrlMutationOptions = Apollo.BaseMutationOptions<CreateMenuItemUrlMutation, CreateMenuItemUrlMutationVariables>;
export const CreateMenuItemWithPostDocument = gql`
    mutation CreateMenuItemWithPost($input: CreateMenuItemWithPostInput!) {
  createMenuItemWithPost(input: $input) {
    ...MenuItemCore
  }
}
    ${MenuItemCoreFragmentDoc}`;
export type CreateMenuItemWithPostMutationFn = Apollo.MutationFunction<CreateMenuItemWithPostMutation, CreateMenuItemWithPostMutationVariables>;

/**
 * __useCreateMenuItemWithPostMutation__
 *
 * To run a mutation, you first call `useCreateMenuItemWithPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMenuItemWithPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMenuItemWithPostMutation, { data, loading, error }] = useCreateMenuItemWithPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMenuItemWithPostMutation(baseOptions?: Apollo.MutationHookOptions<CreateMenuItemWithPostMutation, CreateMenuItemWithPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMenuItemWithPostMutation, CreateMenuItemWithPostMutationVariables>(CreateMenuItemWithPostDocument, options);
      }
export type CreateMenuItemWithPostMutationHookResult = ReturnType<typeof useCreateMenuItemWithPostMutation>;
export type CreateMenuItemWithPostMutationResult = Apollo.MutationResult<CreateMenuItemWithPostMutation>;
export type CreateMenuItemWithPostMutationOptions = Apollo.BaseMutationOptions<CreateMenuItemWithPostMutation, CreateMenuItemWithPostMutationVariables>;
export const UpdateMenuItemDocument = gql`
    mutation UpdateMenuItem($input: UpdateMenuItemInput!) {
  updateMenuItem(input: $input) {
    ...MenuItemCore
  }
}
    ${MenuItemCoreFragmentDoc}`;
export type UpdateMenuItemMutationFn = Apollo.MutationFunction<UpdateMenuItemMutation, UpdateMenuItemMutationVariables>;

/**
 * __useUpdateMenuItemMutation__
 *
 * To run a mutation, you first call `useUpdateMenuItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMenuItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMenuItemMutation, { data, loading, error }] = useUpdateMenuItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMenuItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMenuItemMutation, UpdateMenuItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMenuItemMutation, UpdateMenuItemMutationVariables>(UpdateMenuItemDocument, options);
      }
export type UpdateMenuItemMutationHookResult = ReturnType<typeof useUpdateMenuItemMutation>;
export type UpdateMenuItemMutationResult = Apollo.MutationResult<UpdateMenuItemMutation>;
export type UpdateMenuItemMutationOptions = Apollo.BaseMutationOptions<UpdateMenuItemMutation, UpdateMenuItemMutationVariables>;
export const DeleteMenuItemDocument = gql`
    mutation DeleteMenuItem($input: DeleteMenuItemInput!) {
  deleteMenuItem(input: $input) {
    ...MenuItemCore
  }
}
    ${MenuItemCoreFragmentDoc}`;
export type DeleteMenuItemMutationFn = Apollo.MutationFunction<DeleteMenuItemMutation, DeleteMenuItemMutationVariables>;

/**
 * __useDeleteMenuItemMutation__
 *
 * To run a mutation, you first call `useDeleteMenuItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMenuItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMenuItemMutation, { data, loading, error }] = useDeleteMenuItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMenuItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMenuItemMutation, DeleteMenuItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMenuItemMutation, DeleteMenuItemMutationVariables>(DeleteMenuItemDocument, options);
      }
export type DeleteMenuItemMutationHookResult = ReturnType<typeof useDeleteMenuItemMutation>;
export type DeleteMenuItemMutationResult = Apollo.MutationResult<DeleteMenuItemMutation>;
export type DeleteMenuItemMutationOptions = Apollo.BaseMutationOptions<DeleteMenuItemMutation, DeleteMenuItemMutationVariables>;
export const ReorderMenuItemsDocument = gql`
    mutation ReorderMenuItems($input: ReorderMenuItemsInput!) {
  reorderMenuItems(input: $input) {
    ...MenuItemCore
  }
}
    ${MenuItemCoreFragmentDoc}`;
export type ReorderMenuItemsMutationFn = Apollo.MutationFunction<ReorderMenuItemsMutation, ReorderMenuItemsMutationVariables>;

/**
 * __useReorderMenuItemsMutation__
 *
 * To run a mutation, you first call `useReorderMenuItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderMenuItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderMenuItemsMutation, { data, loading, error }] = useReorderMenuItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReorderMenuItemsMutation(baseOptions?: Apollo.MutationHookOptions<ReorderMenuItemsMutation, ReorderMenuItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderMenuItemsMutation, ReorderMenuItemsMutationVariables>(ReorderMenuItemsDocument, options);
      }
export type ReorderMenuItemsMutationHookResult = ReturnType<typeof useReorderMenuItemsMutation>;
export type ReorderMenuItemsMutationResult = Apollo.MutationResult<ReorderMenuItemsMutation>;
export type ReorderMenuItemsMutationOptions = Apollo.BaseMutationOptions<ReorderMenuItemsMutation, ReorderMenuItemsMutationVariables>;
export const HelloPageByShortIdDocument = gql`
    query HelloPageByShortId($shortId: String!) {
  helloPageByShortId(shortId: $shortId) {
    ...HelloPageCore
  }
}
    ${HelloPageCoreFragmentDoc}`;

/**
 * __useHelloPageByShortIdQuery__
 *
 * To run a query within a React component, call `useHelloPageByShortIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelloPageByShortIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelloPageByShortIdQuery({
 *   variables: {
 *      shortId: // value for 'shortId'
 *   },
 * });
 */
export function useHelloPageByShortIdQuery(baseOptions: Apollo.QueryHookOptions<HelloPageByShortIdQuery, HelloPageByShortIdQueryVariables> & ({ variables: HelloPageByShortIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HelloPageByShortIdQuery, HelloPageByShortIdQueryVariables>(HelloPageByShortIdDocument, options);
      }
export function useHelloPageByShortIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HelloPageByShortIdQuery, HelloPageByShortIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HelloPageByShortIdQuery, HelloPageByShortIdQueryVariables>(HelloPageByShortIdDocument, options);
        }
export function useHelloPageByShortIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HelloPageByShortIdQuery, HelloPageByShortIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HelloPageByShortIdQuery, HelloPageByShortIdQueryVariables>(HelloPageByShortIdDocument, options);
        }
export type HelloPageByShortIdQueryHookResult = ReturnType<typeof useHelloPageByShortIdQuery>;
export type HelloPageByShortIdLazyQueryHookResult = ReturnType<typeof useHelloPageByShortIdLazyQuery>;
export type HelloPageByShortIdSuspenseQueryHookResult = ReturnType<typeof useHelloPageByShortIdSuspenseQuery>;
export type HelloPageByShortIdQueryResult = Apollo.QueryResult<HelloPageByShortIdQuery, HelloPageByShortIdQueryVariables>;
export function refetchHelloPageByShortIdQuery(variables: HelloPageByShortIdQueryVariables) {
      return { query: HelloPageByShortIdDocument, variables: variables }
    }
export const InboxStateDocument = gql`
    query InboxState {
  inboxState @client {
    showRead @client
  }
}
    `;

/**
 * __useInboxStateQuery__
 *
 * To run a query within a React component, call `useInboxStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useInboxStateQuery(baseOptions?: Apollo.QueryHookOptions<InboxStateQuery, InboxStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InboxStateQuery, InboxStateQueryVariables>(InboxStateDocument, options);
      }
export function useInboxStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InboxStateQuery, InboxStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InboxStateQuery, InboxStateQueryVariables>(InboxStateDocument, options);
        }
export function useInboxStateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InboxStateQuery, InboxStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InboxStateQuery, InboxStateQueryVariables>(InboxStateDocument, options);
        }
export type InboxStateQueryHookResult = ReturnType<typeof useInboxStateQuery>;
export type InboxStateLazyQueryHookResult = ReturnType<typeof useInboxStateLazyQuery>;
export type InboxStateSuspenseQueryHookResult = ReturnType<typeof useInboxStateSuspenseQuery>;
export type InboxStateQueryResult = Apollo.QueryResult<InboxStateQuery, InboxStateQueryVariables>;
export function refetchInboxStateQuery(variables?: InboxStateQueryVariables) {
      return { query: InboxStateDocument, variables: variables }
    }
export const OrganizationStatsDocument = gql`
    query OrganizationStats($organizationId: ID!) {
  organizationStats(organizationId: $organizationId) {
    id
    totalMembers
    totalMembersInTheLast14Days
    totalPosts
    totalPostsInTheLast14Days
    totalReactions
    totalReactionsInTheLast14Days
    totalComments
    totalCommentsInTheLast14Days
  }
}
    `;

/**
 * __useOrganizationStatsQuery__
 *
 * To run a query within a React component, call `useOrganizationStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationStatsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationStatsQuery(baseOptions: Apollo.QueryHookOptions<OrganizationStatsQuery, OrganizationStatsQueryVariables> & ({ variables: OrganizationStatsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationStatsQuery, OrganizationStatsQueryVariables>(OrganizationStatsDocument, options);
      }
export function useOrganizationStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationStatsQuery, OrganizationStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationStatsQuery, OrganizationStatsQueryVariables>(OrganizationStatsDocument, options);
        }
export function useOrganizationStatsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizationStatsQuery, OrganizationStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizationStatsQuery, OrganizationStatsQueryVariables>(OrganizationStatsDocument, options);
        }
export type OrganizationStatsQueryHookResult = ReturnType<typeof useOrganizationStatsQuery>;
export type OrganizationStatsLazyQueryHookResult = ReturnType<typeof useOrganizationStatsLazyQuery>;
export type OrganizationStatsSuspenseQueryHookResult = ReturnType<typeof useOrganizationStatsSuspenseQuery>;
export type OrganizationStatsQueryResult = Apollo.QueryResult<OrganizationStatsQuery, OrganizationStatsQueryVariables>;
export function refetchOrganizationStatsQuery(variables: OrganizationStatsQueryVariables) {
      return { query: OrganizationStatsDocument, variables: variables }
    }
export const MembersWithHighestPostCountDocument = gql`
    query MembersWithHighestPostCount($organizationId: ID!) {
  membersWithHighestPostCount(organizationId: $organizationId) {
    id
    count
    user {
      ...UserPreview
    }
  }
}
    ${UserPreviewFragmentDoc}`;

/**
 * __useMembersWithHighestPostCountQuery__
 *
 * To run a query within a React component, call `useMembersWithHighestPostCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersWithHighestPostCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersWithHighestPostCountQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useMembersWithHighestPostCountQuery(baseOptions: Apollo.QueryHookOptions<MembersWithHighestPostCountQuery, MembersWithHighestPostCountQueryVariables> & ({ variables: MembersWithHighestPostCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembersWithHighestPostCountQuery, MembersWithHighestPostCountQueryVariables>(MembersWithHighestPostCountDocument, options);
      }
export function useMembersWithHighestPostCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembersWithHighestPostCountQuery, MembersWithHighestPostCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembersWithHighestPostCountQuery, MembersWithHighestPostCountQueryVariables>(MembersWithHighestPostCountDocument, options);
        }
export function useMembersWithHighestPostCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MembersWithHighestPostCountQuery, MembersWithHighestPostCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MembersWithHighestPostCountQuery, MembersWithHighestPostCountQueryVariables>(MembersWithHighestPostCountDocument, options);
        }
export type MembersWithHighestPostCountQueryHookResult = ReturnType<typeof useMembersWithHighestPostCountQuery>;
export type MembersWithHighestPostCountLazyQueryHookResult = ReturnType<typeof useMembersWithHighestPostCountLazyQuery>;
export type MembersWithHighestPostCountSuspenseQueryHookResult = ReturnType<typeof useMembersWithHighestPostCountSuspenseQuery>;
export type MembersWithHighestPostCountQueryResult = Apollo.QueryResult<MembersWithHighestPostCountQuery, MembersWithHighestPostCountQueryVariables>;
export function refetchMembersWithHighestPostCountQuery(variables: MembersWithHighestPostCountQueryVariables) {
      return { query: MembersWithHighestPostCountDocument, variables: variables }
    }
export const MembersWithHighestCommentCountDocument = gql`
    query MembersWithHighestCommentCount($organizationId: ID!) {
  membersWithHighestCommentCount(organizationId: $organizationId) {
    id
    count
    user {
      ...UserPreview
    }
  }
}
    ${UserPreviewFragmentDoc}`;

/**
 * __useMembersWithHighestCommentCountQuery__
 *
 * To run a query within a React component, call `useMembersWithHighestCommentCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersWithHighestCommentCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersWithHighestCommentCountQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useMembersWithHighestCommentCountQuery(baseOptions: Apollo.QueryHookOptions<MembersWithHighestCommentCountQuery, MembersWithHighestCommentCountQueryVariables> & ({ variables: MembersWithHighestCommentCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembersWithHighestCommentCountQuery, MembersWithHighestCommentCountQueryVariables>(MembersWithHighestCommentCountDocument, options);
      }
export function useMembersWithHighestCommentCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembersWithHighestCommentCountQuery, MembersWithHighestCommentCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembersWithHighestCommentCountQuery, MembersWithHighestCommentCountQueryVariables>(MembersWithHighestCommentCountDocument, options);
        }
export function useMembersWithHighestCommentCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MembersWithHighestCommentCountQuery, MembersWithHighestCommentCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MembersWithHighestCommentCountQuery, MembersWithHighestCommentCountQueryVariables>(MembersWithHighestCommentCountDocument, options);
        }
export type MembersWithHighestCommentCountQueryHookResult = ReturnType<typeof useMembersWithHighestCommentCountQuery>;
export type MembersWithHighestCommentCountLazyQueryHookResult = ReturnType<typeof useMembersWithHighestCommentCountLazyQuery>;
export type MembersWithHighestCommentCountSuspenseQueryHookResult = ReturnType<typeof useMembersWithHighestCommentCountSuspenseQuery>;
export type MembersWithHighestCommentCountQueryResult = Apollo.QueryResult<MembersWithHighestCommentCountQuery, MembersWithHighestCommentCountQueryVariables>;
export function refetchMembersWithHighestCommentCountQuery(variables: MembersWithHighestCommentCountQueryVariables) {
      return { query: MembersWithHighestCommentCountDocument, variables: variables }
    }
export const MembersWithHighestReactionCountDocument = gql`
    query MembersWithHighestReactionCount($organizationId: ID!) {
  membersWithHighestReactionCount(organizationId: $organizationId) {
    id
    count
    user {
      ...UserPreview
    }
  }
}
    ${UserPreviewFragmentDoc}`;

/**
 * __useMembersWithHighestReactionCountQuery__
 *
 * To run a query within a React component, call `useMembersWithHighestReactionCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersWithHighestReactionCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersWithHighestReactionCountQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useMembersWithHighestReactionCountQuery(baseOptions: Apollo.QueryHookOptions<MembersWithHighestReactionCountQuery, MembersWithHighestReactionCountQueryVariables> & ({ variables: MembersWithHighestReactionCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembersWithHighestReactionCountQuery, MembersWithHighestReactionCountQueryVariables>(MembersWithHighestReactionCountDocument, options);
      }
export function useMembersWithHighestReactionCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembersWithHighestReactionCountQuery, MembersWithHighestReactionCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembersWithHighestReactionCountQuery, MembersWithHighestReactionCountQueryVariables>(MembersWithHighestReactionCountDocument, options);
        }
export function useMembersWithHighestReactionCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MembersWithHighestReactionCountQuery, MembersWithHighestReactionCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MembersWithHighestReactionCountQuery, MembersWithHighestReactionCountQueryVariables>(MembersWithHighestReactionCountDocument, options);
        }
export type MembersWithHighestReactionCountQueryHookResult = ReturnType<typeof useMembersWithHighestReactionCountQuery>;
export type MembersWithHighestReactionCountLazyQueryHookResult = ReturnType<typeof useMembersWithHighestReactionCountLazyQuery>;
export type MembersWithHighestReactionCountSuspenseQueryHookResult = ReturnType<typeof useMembersWithHighestReactionCountSuspenseQuery>;
export type MembersWithHighestReactionCountQueryResult = Apollo.QueryResult<MembersWithHighestReactionCountQuery, MembersWithHighestReactionCountQueryVariables>;
export function refetchMembersWithHighestReactionCountQuery(variables: MembersWithHighestReactionCountQueryVariables) {
      return { query: MembersWithHighestReactionCountDocument, variables: variables }
    }
export const MostRecentOrganizationActivityDocument = gql`
    query MostRecentOrganizationActivity($organizationId: ID!) {
  mostRecentOrganizationActivity(organizationId: $organizationId) {
    id
    entityId
    type
    activityDate
    user {
      ...UserPreview
    }
  }
}
    ${UserPreviewFragmentDoc}`;

/**
 * __useMostRecentOrganizationActivityQuery__
 *
 * To run a query within a React component, call `useMostRecentOrganizationActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useMostRecentOrganizationActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMostRecentOrganizationActivityQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useMostRecentOrganizationActivityQuery(baseOptions: Apollo.QueryHookOptions<MostRecentOrganizationActivityQuery, MostRecentOrganizationActivityQueryVariables> & ({ variables: MostRecentOrganizationActivityQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MostRecentOrganizationActivityQuery, MostRecentOrganizationActivityQueryVariables>(MostRecentOrganizationActivityDocument, options);
      }
export function useMostRecentOrganizationActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MostRecentOrganizationActivityQuery, MostRecentOrganizationActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MostRecentOrganizationActivityQuery, MostRecentOrganizationActivityQueryVariables>(MostRecentOrganizationActivityDocument, options);
        }
export function useMostRecentOrganizationActivitySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MostRecentOrganizationActivityQuery, MostRecentOrganizationActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MostRecentOrganizationActivityQuery, MostRecentOrganizationActivityQueryVariables>(MostRecentOrganizationActivityDocument, options);
        }
export type MostRecentOrganizationActivityQueryHookResult = ReturnType<typeof useMostRecentOrganizationActivityQuery>;
export type MostRecentOrganizationActivityLazyQueryHookResult = ReturnType<typeof useMostRecentOrganizationActivityLazyQuery>;
export type MostRecentOrganizationActivitySuspenseQueryHookResult = ReturnType<typeof useMostRecentOrganizationActivitySuspenseQuery>;
export type MostRecentOrganizationActivityQueryResult = Apollo.QueryResult<MostRecentOrganizationActivityQuery, MostRecentOrganizationActivityQueryVariables>;
export function refetchMostRecentOrganizationActivityQuery(variables: MostRecentOrganizationActivityQueryVariables) {
      return { query: MostRecentOrganizationActivityDocument, variables: variables }
    }
export const NewMembershipsDocument = gql`
    query NewMemberships($organizationId: ID!) {
  newMemberships(organizationId: $organizationId) {
    id
    createdAt
    user {
      ...UserPreview
    }
  }
}
    ${UserPreviewFragmentDoc}`;

/**
 * __useNewMembershipsQuery__
 *
 * To run a query within a React component, call `useNewMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewMembershipsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useNewMembershipsQuery(baseOptions: Apollo.QueryHookOptions<NewMembershipsQuery, NewMembershipsQueryVariables> & ({ variables: NewMembershipsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewMembershipsQuery, NewMembershipsQueryVariables>(NewMembershipsDocument, options);
      }
export function useNewMembershipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewMembershipsQuery, NewMembershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewMembershipsQuery, NewMembershipsQueryVariables>(NewMembershipsDocument, options);
        }
export function useNewMembershipsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NewMembershipsQuery, NewMembershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NewMembershipsQuery, NewMembershipsQueryVariables>(NewMembershipsDocument, options);
        }
export type NewMembershipsQueryHookResult = ReturnType<typeof useNewMembershipsQuery>;
export type NewMembershipsLazyQueryHookResult = ReturnType<typeof useNewMembershipsLazyQuery>;
export type NewMembershipsSuspenseQueryHookResult = ReturnType<typeof useNewMembershipsSuspenseQuery>;
export type NewMembershipsQueryResult = Apollo.QueryResult<NewMembershipsQuery, NewMembershipsQueryVariables>;
export function refetchNewMembershipsQuery(variables: NewMembershipsQueryVariables) {
      return { query: NewMembershipsDocument, variables: variables }
    }
export const AddEntryToWaitlistDocument = gql`
    mutation AddEntryToWaitlist($input: AddEntryToWaitlistInput!) {
  addEntryToWaitlist(input: $input) {
    ... on WaitlistEntry {
      id
    }
    ... on WaitlistEntryError {
      errorCode
    }
  }
}
    `;
export type AddEntryToWaitlistMutationFn = Apollo.MutationFunction<AddEntryToWaitlistMutation, AddEntryToWaitlistMutationVariables>;

/**
 * __useAddEntryToWaitlistMutation__
 *
 * To run a mutation, you first call `useAddEntryToWaitlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEntryToWaitlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEntryToWaitlistMutation, { data, loading, error }] = useAddEntryToWaitlistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddEntryToWaitlistMutation(baseOptions?: Apollo.MutationHookOptions<AddEntryToWaitlistMutation, AddEntryToWaitlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEntryToWaitlistMutation, AddEntryToWaitlistMutationVariables>(AddEntryToWaitlistDocument, options);
      }
export type AddEntryToWaitlistMutationHookResult = ReturnType<typeof useAddEntryToWaitlistMutation>;
export type AddEntryToWaitlistMutationResult = Apollo.MutationResult<AddEntryToWaitlistMutation>;
export type AddEntryToWaitlistMutationOptions = Apollo.BaseMutationOptions<AddEntryToWaitlistMutation, AddEntryToWaitlistMutationVariables>;
export const NotificationsDocument = gql`
    query Notifications($organizationId: String!, $first: Int!, $after: String) {
  notifications(organizationId: $organizationId, first: $first, after: $after) {
    edges {
      cursor
      node {
        ...NotificationFragment
      }
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${NotificationFragmentFragmentDoc}`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables> & ({ variables: NotificationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export function useNotificationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsSuspenseQueryHookResult = ReturnType<typeof useNotificationsSuspenseQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export function refetchNotificationsQuery(variables: NotificationsQueryVariables) {
      return { query: NotificationsDocument, variables: variables }
    }
export const NotificationDocument = gql`
    query Notification($id: String!) {
  notification(id: $id) {
    ...NotificationFragment
    otherUser {
      id
      profileImageId
      avatarBgColor
      avatar
      username
      firstName
      email
    }
    admin {
      id
      profileImageId
      avatarBgColor
      avatar
      username
      firstName
    }
    role {
      id
      label
    }
    memberSubscription {
      id
      expiresAt
    }
    memberSubscriptionPlan {
      id
      name
      priceMonthlyUsd
    }
    group {
      id
      type
      name
    }
    requestToJoins {
      id
      email
      createdAt
      status
      user {
        id
        profileImageId
        avatarBgColor
        avatar
        username
        firstName
      }
    }
  }
}
    ${NotificationFragmentFragmentDoc}`;

/**
 * __useNotificationQuery__
 *
 * To run a query within a React component, call `useNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotificationQuery(baseOptions: Apollo.QueryHookOptions<NotificationQuery, NotificationQueryVariables> & ({ variables: NotificationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationQuery, NotificationQueryVariables>(NotificationDocument, options);
      }
export function useNotificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationQuery, NotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationQuery, NotificationQueryVariables>(NotificationDocument, options);
        }
export function useNotificationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NotificationQuery, NotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NotificationQuery, NotificationQueryVariables>(NotificationDocument, options);
        }
export type NotificationQueryHookResult = ReturnType<typeof useNotificationQuery>;
export type NotificationLazyQueryHookResult = ReturnType<typeof useNotificationLazyQuery>;
export type NotificationSuspenseQueryHookResult = ReturnType<typeof useNotificationSuspenseQuery>;
export type NotificationQueryResult = Apollo.QueryResult<NotificationQuery, NotificationQueryVariables>;
export function refetchNotificationQuery(variables: NotificationQueryVariables) {
      return { query: NotificationDocument, variables: variables }
    }
export const UserPushEventsDocument = gql`
    subscription UserPushEvents {
  userPushEvent {
    ... on PushRefetchHint {
      id
      organizationId
      postId
      groupId
      postId
    }
  }
}
    `;

/**
 * __useUserPushEventsSubscription__
 *
 * To run a query within a React component, call `useUserPushEventsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserPushEventsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPushEventsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useUserPushEventsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UserPushEventsSubscription, UserPushEventsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UserPushEventsSubscription, UserPushEventsSubscriptionVariables>(UserPushEventsDocument, options);
      }
export type UserPushEventsSubscriptionHookResult = ReturnType<typeof useUserPushEventsSubscription>;
export type UserPushEventsSubscriptionResult = Apollo.SubscriptionResult<UserPushEventsSubscription>;
export const NotificationCountsDocument = gql`
    query NotificationCounts($organizationId: String!) {
  notificationCounts(organizationId: $organizationId) {
    unread
  }
}
    `;

/**
 * __useNotificationCountsQuery__
 *
 * To run a query within a React component, call `useNotificationCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationCountsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useNotificationCountsQuery(baseOptions: Apollo.QueryHookOptions<NotificationCountsQuery, NotificationCountsQueryVariables> & ({ variables: NotificationCountsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationCountsQuery, NotificationCountsQueryVariables>(NotificationCountsDocument, options);
      }
export function useNotificationCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationCountsQuery, NotificationCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationCountsQuery, NotificationCountsQueryVariables>(NotificationCountsDocument, options);
        }
export function useNotificationCountsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NotificationCountsQuery, NotificationCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NotificationCountsQuery, NotificationCountsQueryVariables>(NotificationCountsDocument, options);
        }
export type NotificationCountsQueryHookResult = ReturnType<typeof useNotificationCountsQuery>;
export type NotificationCountsLazyQueryHookResult = ReturnType<typeof useNotificationCountsLazyQuery>;
export type NotificationCountsSuspenseQueryHookResult = ReturnType<typeof useNotificationCountsSuspenseQuery>;
export type NotificationCountsQueryResult = Apollo.QueryResult<NotificationCountsQuery, NotificationCountsQueryVariables>;
export function refetchNotificationCountsQuery(variables: NotificationCountsQueryVariables) {
      return { query: NotificationCountsDocument, variables: variables }
    }
export const MarkAllNotificationsReadDocument = gql`
    mutation MarkAllNotificationsRead($organizationId: String!) {
  markAllNotificationsRead(organizationId: $organizationId) {
    unread
  }
}
    `;
export type MarkAllNotificationsReadMutationFn = Apollo.MutationFunction<MarkAllNotificationsReadMutation, MarkAllNotificationsReadMutationVariables>;

/**
 * __useMarkAllNotificationsReadMutation__
 *
 * To run a mutation, you first call `useMarkAllNotificationsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllNotificationsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllNotificationsReadMutation, { data, loading, error }] = useMarkAllNotificationsReadMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useMarkAllNotificationsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkAllNotificationsReadMutation, MarkAllNotificationsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkAllNotificationsReadMutation, MarkAllNotificationsReadMutationVariables>(MarkAllNotificationsReadDocument, options);
      }
export type MarkAllNotificationsReadMutationHookResult = ReturnType<typeof useMarkAllNotificationsReadMutation>;
export type MarkAllNotificationsReadMutationResult = Apollo.MutationResult<MarkAllNotificationsReadMutation>;
export type MarkAllNotificationsReadMutationOptions = Apollo.BaseMutationOptions<MarkAllNotificationsReadMutation, MarkAllNotificationsReadMutationVariables>;
export const MarkNotificationReadDocument = gql`
    mutation MarkNotificationRead($input: ReadNotificationInput!) {
  markNotificationRead(input: $input) {
    ...NotificationFragment
  }
}
    ${NotificationFragmentFragmentDoc}`;
export type MarkNotificationReadMutationFn = Apollo.MutationFunction<MarkNotificationReadMutation, MarkNotificationReadMutationVariables>;

/**
 * __useMarkNotificationReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationReadMutation, { data, loading, error }] = useMarkNotificationReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkNotificationReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkNotificationReadMutation, MarkNotificationReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkNotificationReadMutation, MarkNotificationReadMutationVariables>(MarkNotificationReadDocument, options);
      }
export type MarkNotificationReadMutationHookResult = ReturnType<typeof useMarkNotificationReadMutation>;
export type MarkNotificationReadMutationResult = Apollo.MutationResult<MarkNotificationReadMutation>;
export type MarkNotificationReadMutationOptions = Apollo.BaseMutationOptions<MarkNotificationReadMutation, MarkNotificationReadMutationVariables>;
export const CreateUserFcmPushSubscriptionDocument = gql`
    mutation CreateUserFCMPushSubscription($input: CreateUserFCMPushSubscriptionInput!) {
  createUserFCMPushSubscription(input: $input) {
    id
  }
}
    `;
export type CreateUserFcmPushSubscriptionMutationFn = Apollo.MutationFunction<CreateUserFcmPushSubscriptionMutation, CreateUserFcmPushSubscriptionMutationVariables>;

/**
 * __useCreateUserFcmPushSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateUserFcmPushSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserFcmPushSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserFcmPushSubscriptionMutation, { data, loading, error }] = useCreateUserFcmPushSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserFcmPushSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserFcmPushSubscriptionMutation, CreateUserFcmPushSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserFcmPushSubscriptionMutation, CreateUserFcmPushSubscriptionMutationVariables>(CreateUserFcmPushSubscriptionDocument, options);
      }
export type CreateUserFcmPushSubscriptionMutationHookResult = ReturnType<typeof useCreateUserFcmPushSubscriptionMutation>;
export type CreateUserFcmPushSubscriptionMutationResult = Apollo.MutationResult<CreateUserFcmPushSubscriptionMutation>;
export type CreateUserFcmPushSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateUserFcmPushSubscriptionMutation, CreateUserFcmPushSubscriptionMutationVariables>;
export const ProfileSetupDocument = gql`
    mutation ProfileSetup($input: ProfileSetupInput!) {
  profileSetup(input: $input) {
    id
    username
    email
    name
    profileImageId
    firstName
    lastName
    linkedinUrl
    instagramHandle
    hometown
    currentCity
    bio
    claimedAccount
    avatarBgColor
    avatar
  }
}
    `;
export type ProfileSetupMutationFn = Apollo.MutationFunction<ProfileSetupMutation, ProfileSetupMutationVariables>;

/**
 * __useProfileSetupMutation__
 *
 * To run a mutation, you first call `useProfileSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileSetupMutation, { data, loading, error }] = useProfileSetupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfileSetupMutation(baseOptions?: Apollo.MutationHookOptions<ProfileSetupMutation, ProfileSetupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProfileSetupMutation, ProfileSetupMutationVariables>(ProfileSetupDocument, options);
      }
export type ProfileSetupMutationHookResult = ReturnType<typeof useProfileSetupMutation>;
export type ProfileSetupMutationResult = Apollo.MutationResult<ProfileSetupMutation>;
export type ProfileSetupMutationOptions = Apollo.BaseMutationOptions<ProfileSetupMutation, ProfileSetupMutationVariables>;
export const UpdateUserOnboardingDocument = gql`
    mutation UpdateUserOnboarding($input: UpdateUserOnboardingInput!) {
  updateUserOnboarding(input: $input) {
    id
    username
  }
}
    `;
export type UpdateUserOnboardingMutationFn = Apollo.MutationFunction<UpdateUserOnboardingMutation, UpdateUserOnboardingMutationVariables>;

/**
 * __useUpdateUserOnboardingMutation__
 *
 * To run a mutation, you first call `useUpdateUserOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserOnboardingMutation, { data, loading, error }] = useUpdateUserOnboardingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserOnboardingMutation, UpdateUserOnboardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserOnboardingMutation, UpdateUserOnboardingMutationVariables>(UpdateUserOnboardingDocument, options);
      }
export type UpdateUserOnboardingMutationHookResult = ReturnType<typeof useUpdateUserOnboardingMutation>;
export type UpdateUserOnboardingMutationResult = Apollo.MutationResult<UpdateUserOnboardingMutation>;
export type UpdateUserOnboardingMutationOptions = Apollo.BaseMutationOptions<UpdateUserOnboardingMutation, UpdateUserOnboardingMutationVariables>;
export const AddPostViewDocument = gql`
    mutation AddPostView($input: AddViewInput!) {
  addPostView(input: $input) {
    id
  }
}
    `;
export type AddPostViewMutationFn = Apollo.MutationFunction<AddPostViewMutation, AddPostViewMutationVariables>;

/**
 * __useAddPostViewMutation__
 *
 * To run a mutation, you first call `useAddPostViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPostViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPostViewMutation, { data, loading, error }] = useAddPostViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPostViewMutation(baseOptions?: Apollo.MutationHookOptions<AddPostViewMutation, AddPostViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPostViewMutation, AddPostViewMutationVariables>(AddPostViewDocument, options);
      }
export type AddPostViewMutationHookResult = ReturnType<typeof useAddPostViewMutation>;
export type AddPostViewMutationResult = Apollo.MutationResult<AddPostViewMutation>;
export type AddPostViewMutationOptions = Apollo.BaseMutationOptions<AddPostViewMutation, AddPostViewMutationVariables>;
export const TogglePinnedPostDocument = gql`
    mutation TogglePinnedPost($input: TogglePinnedPostInput!) {
  togglePinnedPost(input: $input) {
    id
    pinned
  }
}
    `;
export type TogglePinnedPostMutationFn = Apollo.MutationFunction<TogglePinnedPostMutation, TogglePinnedPostMutationVariables>;

/**
 * __useTogglePinnedPostMutation__
 *
 * To run a mutation, you first call `useTogglePinnedPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTogglePinnedPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [togglePinnedPostMutation, { data, loading, error }] = useTogglePinnedPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTogglePinnedPostMutation(baseOptions?: Apollo.MutationHookOptions<TogglePinnedPostMutation, TogglePinnedPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TogglePinnedPostMutation, TogglePinnedPostMutationVariables>(TogglePinnedPostDocument, options);
      }
export type TogglePinnedPostMutationHookResult = ReturnType<typeof useTogglePinnedPostMutation>;
export type TogglePinnedPostMutationResult = Apollo.MutationResult<TogglePinnedPostMutation>;
export type TogglePinnedPostMutationOptions = Apollo.BaseMutationOptions<TogglePinnedPostMutation, TogglePinnedPostMutationVariables>;
export const ToggleHideFromProfilePostDocument = gql`
    mutation ToggleHideFromProfilePost($input: ToggleHideFromProfilePostInput!) {
  toggleHideFromProfilePost(input: $input) {
    id
    hideFromProfile
  }
}
    `;
export type ToggleHideFromProfilePostMutationFn = Apollo.MutationFunction<ToggleHideFromProfilePostMutation, ToggleHideFromProfilePostMutationVariables>;

/**
 * __useToggleHideFromProfilePostMutation__
 *
 * To run a mutation, you first call `useToggleHideFromProfilePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleHideFromProfilePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleHideFromProfilePostMutation, { data, loading, error }] = useToggleHideFromProfilePostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleHideFromProfilePostMutation(baseOptions?: Apollo.MutationHookOptions<ToggleHideFromProfilePostMutation, ToggleHideFromProfilePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleHideFromProfilePostMutation, ToggleHideFromProfilePostMutationVariables>(ToggleHideFromProfilePostDocument, options);
      }
export type ToggleHideFromProfilePostMutationHookResult = ReturnType<typeof useToggleHideFromProfilePostMutation>;
export type ToggleHideFromProfilePostMutationResult = Apollo.MutationResult<ToggleHideFromProfilePostMutation>;
export type ToggleHideFromProfilePostMutationOptions = Apollo.BaseMutationOptions<ToggleHideFromProfilePostMutation, ToggleHideFromProfilePostMutationVariables>;
export const ToggleShowAuthorOnPostDocument = gql`
    mutation ToggleShowAuthorOnPost($input: ToggleShowAuthorOnPostInput!) {
  toggleShowAuthorOnPost(input: $input) {
    id
    showAuthor
  }
}
    `;
export type ToggleShowAuthorOnPostMutationFn = Apollo.MutationFunction<ToggleShowAuthorOnPostMutation, ToggleShowAuthorOnPostMutationVariables>;

/**
 * __useToggleShowAuthorOnPostMutation__
 *
 * To run a mutation, you first call `useToggleShowAuthorOnPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleShowAuthorOnPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleShowAuthorOnPostMutation, { data, loading, error }] = useToggleShowAuthorOnPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleShowAuthorOnPostMutation(baseOptions?: Apollo.MutationHookOptions<ToggleShowAuthorOnPostMutation, ToggleShowAuthorOnPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleShowAuthorOnPostMutation, ToggleShowAuthorOnPostMutationVariables>(ToggleShowAuthorOnPostDocument, options);
      }
export type ToggleShowAuthorOnPostMutationHookResult = ReturnType<typeof useToggleShowAuthorOnPostMutation>;
export type ToggleShowAuthorOnPostMutationResult = Apollo.MutationResult<ToggleShowAuthorOnPostMutation>;
export type ToggleShowAuthorOnPostMutationOptions = Apollo.BaseMutationOptions<ToggleShowAuthorOnPostMutation, ToggleShowAuthorOnPostMutationVariables>;
export const ToggleShowCommentsAndReactionsOnPostDocument = gql`
    mutation ToggleShowCommentsAndReactionsOnPost($input: ToggleShowCommentsAndReactionsOnPostInput!) {
  toggleShowCommentsAndReactionsOnPost(input: $input) {
    id
    showCommentsAndReactions
  }
}
    `;
export type ToggleShowCommentsAndReactionsOnPostMutationFn = Apollo.MutationFunction<ToggleShowCommentsAndReactionsOnPostMutation, ToggleShowCommentsAndReactionsOnPostMutationVariables>;

/**
 * __useToggleShowCommentsAndReactionsOnPostMutation__
 *
 * To run a mutation, you first call `useToggleShowCommentsAndReactionsOnPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleShowCommentsAndReactionsOnPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleShowCommentsAndReactionsOnPostMutation, { data, loading, error }] = useToggleShowCommentsAndReactionsOnPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleShowCommentsAndReactionsOnPostMutation(baseOptions?: Apollo.MutationHookOptions<ToggleShowCommentsAndReactionsOnPostMutation, ToggleShowCommentsAndReactionsOnPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleShowCommentsAndReactionsOnPostMutation, ToggleShowCommentsAndReactionsOnPostMutationVariables>(ToggleShowCommentsAndReactionsOnPostDocument, options);
      }
export type ToggleShowCommentsAndReactionsOnPostMutationHookResult = ReturnType<typeof useToggleShowCommentsAndReactionsOnPostMutation>;
export type ToggleShowCommentsAndReactionsOnPostMutationResult = Apollo.MutationResult<ToggleShowCommentsAndReactionsOnPostMutation>;
export type ToggleShowCommentsAndReactionsOnPostMutationOptions = Apollo.BaseMutationOptions<ToggleShowCommentsAndReactionsOnPostMutation, ToggleShowCommentsAndReactionsOnPostMutationVariables>;
export const WelcomePostDocument = gql`
    query WelcomePost($organizationId: ID!) {
  welcomePost(organizationId: $organizationId) {
    __typename
    ... on Post {
      ...PostFeed
    }
    ... on PostError {
      message
    }
  }
}
    ${PostFeedFragmentDoc}`;

/**
 * __useWelcomePostQuery__
 *
 * To run a query within a React component, call `useWelcomePostQuery` and pass it any options that fit your needs.
 * When your component renders, `useWelcomePostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWelcomePostQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useWelcomePostQuery(baseOptions: Apollo.QueryHookOptions<WelcomePostQuery, WelcomePostQueryVariables> & ({ variables: WelcomePostQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WelcomePostQuery, WelcomePostQueryVariables>(WelcomePostDocument, options);
      }
export function useWelcomePostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WelcomePostQuery, WelcomePostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WelcomePostQuery, WelcomePostQueryVariables>(WelcomePostDocument, options);
        }
export function useWelcomePostSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WelcomePostQuery, WelcomePostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WelcomePostQuery, WelcomePostQueryVariables>(WelcomePostDocument, options);
        }
export type WelcomePostQueryHookResult = ReturnType<typeof useWelcomePostQuery>;
export type WelcomePostLazyQueryHookResult = ReturnType<typeof useWelcomePostLazyQuery>;
export type WelcomePostSuspenseQueryHookResult = ReturnType<typeof useWelcomePostSuspenseQuery>;
export type WelcomePostQueryResult = Apollo.QueryResult<WelcomePostQuery, WelcomePostQueryVariables>;
export function refetchWelcomePostQuery(variables: WelcomePostQueryVariables) {
      return { query: WelcomePostDocument, variables: variables }
    }
export const ToggleWelcomePostDocument = gql`
    mutation ToggleWelcomePost($input: ToggleWelcomePostInput!) {
  toggleWelcomePost(input: $input) {
    id
    deleted
  }
}
    `;
export type ToggleWelcomePostMutationFn = Apollo.MutationFunction<ToggleWelcomePostMutation, ToggleWelcomePostMutationVariables>;

/**
 * __useToggleWelcomePostMutation__
 *
 * To run a mutation, you first call `useToggleWelcomePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleWelcomePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleWelcomePostMutation, { data, loading, error }] = useToggleWelcomePostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleWelcomePostMutation(baseOptions?: Apollo.MutationHookOptions<ToggleWelcomePostMutation, ToggleWelcomePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleWelcomePostMutation, ToggleWelcomePostMutationVariables>(ToggleWelcomePostDocument, options);
      }
export type ToggleWelcomePostMutationHookResult = ReturnType<typeof useToggleWelcomePostMutation>;
export type ToggleWelcomePostMutationResult = Apollo.MutationResult<ToggleWelcomePostMutation>;
export type ToggleWelcomePostMutationOptions = Apollo.BaseMutationOptions<ToggleWelcomePostMutation, ToggleWelcomePostMutationVariables>;
export const DismissWelcomePostDocument = gql`
    mutation DismissWelcomePost($input: DismissWelcomePostInput!) {
  dismissWelcomePost(input: $input) {
    id
  }
}
    `;
export type DismissWelcomePostMutationFn = Apollo.MutationFunction<DismissWelcomePostMutation, DismissWelcomePostMutationVariables>;

/**
 * __useDismissWelcomePostMutation__
 *
 * To run a mutation, you first call `useDismissWelcomePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissWelcomePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissWelcomePostMutation, { data, loading, error }] = useDismissWelcomePostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDismissWelcomePostMutation(baseOptions?: Apollo.MutationHookOptions<DismissWelcomePostMutation, DismissWelcomePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DismissWelcomePostMutation, DismissWelcomePostMutationVariables>(DismissWelcomePostDocument, options);
      }
export type DismissWelcomePostMutationHookResult = ReturnType<typeof useDismissWelcomePostMutation>;
export type DismissWelcomePostMutationResult = Apollo.MutationResult<DismissWelcomePostMutation>;
export type DismissWelcomePostMutationOptions = Apollo.BaseMutationOptions<DismissWelcomePostMutation, DismissWelcomePostMutationVariables>;
export const NewMemberPostDocument = gql`
    query NewMemberPost($organizationId: ID!, $userId: ID!) {
  newMemberPost(organizationId: $organizationId, userId: $userId) {
    ...PostFeed
  }
}
    ${PostFeedFragmentDoc}`;

/**
 * __useNewMemberPostQuery__
 *
 * To run a query within a React component, call `useNewMemberPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewMemberPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewMemberPostQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useNewMemberPostQuery(baseOptions: Apollo.QueryHookOptions<NewMemberPostQuery, NewMemberPostQueryVariables> & ({ variables: NewMemberPostQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewMemberPostQuery, NewMemberPostQueryVariables>(NewMemberPostDocument, options);
      }
export function useNewMemberPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewMemberPostQuery, NewMemberPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewMemberPostQuery, NewMemberPostQueryVariables>(NewMemberPostDocument, options);
        }
export function useNewMemberPostSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NewMemberPostQuery, NewMemberPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NewMemberPostQuery, NewMemberPostQueryVariables>(NewMemberPostDocument, options);
        }
export type NewMemberPostQueryHookResult = ReturnType<typeof useNewMemberPostQuery>;
export type NewMemberPostLazyQueryHookResult = ReturnType<typeof useNewMemberPostLazyQuery>;
export type NewMemberPostSuspenseQueryHookResult = ReturnType<typeof useNewMemberPostSuspenseQuery>;
export type NewMemberPostQueryResult = Apollo.QueryResult<NewMemberPostQuery, NewMemberPostQueryVariables>;
export function refetchNewMemberPostQuery(variables: NewMemberPostQueryVariables) {
      return { query: NewMemberPostDocument, variables: variables }
    }
export const ToggleFollowPostDocument = gql`
    mutation ToggleFollowPost($input: ToggleFollowPostInput!) {
  toggleFollowPost(input: $input) {
    id
  }
}
    `;
export type ToggleFollowPostMutationFn = Apollo.MutationFunction<ToggleFollowPostMutation, ToggleFollowPostMutationVariables>;

/**
 * __useToggleFollowPostMutation__
 *
 * To run a mutation, you first call `useToggleFollowPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleFollowPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleFollowPostMutation, { data, loading, error }] = useToggleFollowPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleFollowPostMutation(baseOptions?: Apollo.MutationHookOptions<ToggleFollowPostMutation, ToggleFollowPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleFollowPostMutation, ToggleFollowPostMutationVariables>(ToggleFollowPostDocument, options);
      }
export type ToggleFollowPostMutationHookResult = ReturnType<typeof useToggleFollowPostMutation>;
export type ToggleFollowPostMutationResult = Apollo.MutationResult<ToggleFollowPostMutation>;
export type ToggleFollowPostMutationOptions = Apollo.BaseMutationOptions<ToggleFollowPostMutation, ToggleFollowPostMutationVariables>;
export const IsFollowingPostDocument = gql`
    query IsFollowingPost($userId: ID!, $postId: ID!) {
  isFollowingPost(userId: $userId, postId: $postId) {
    id
    postId
  }
}
    `;

/**
 * __useIsFollowingPostQuery__
 *
 * To run a query within a React component, call `useIsFollowingPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsFollowingPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsFollowingPostQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useIsFollowingPostQuery(baseOptions: Apollo.QueryHookOptions<IsFollowingPostQuery, IsFollowingPostQueryVariables> & ({ variables: IsFollowingPostQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsFollowingPostQuery, IsFollowingPostQueryVariables>(IsFollowingPostDocument, options);
      }
export function useIsFollowingPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsFollowingPostQuery, IsFollowingPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsFollowingPostQuery, IsFollowingPostQueryVariables>(IsFollowingPostDocument, options);
        }
export function useIsFollowingPostSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IsFollowingPostQuery, IsFollowingPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IsFollowingPostQuery, IsFollowingPostQueryVariables>(IsFollowingPostDocument, options);
        }
export type IsFollowingPostQueryHookResult = ReturnType<typeof useIsFollowingPostQuery>;
export type IsFollowingPostLazyQueryHookResult = ReturnType<typeof useIsFollowingPostLazyQuery>;
export type IsFollowingPostSuspenseQueryHookResult = ReturnType<typeof useIsFollowingPostSuspenseQuery>;
export type IsFollowingPostQueryResult = Apollo.QueryResult<IsFollowingPostQuery, IsFollowingPostQueryVariables>;
export function refetchIsFollowingPostQuery(variables: IsFollowingPostQueryVariables) {
      return { query: IsFollowingPostDocument, variables: variables }
    }
export const MovePostDocument = gql`
    mutation MovePost($input: MovePostInput!) {
  movePost(input: $input) {
    id
  }
}
    `;
export type MovePostMutationFn = Apollo.MutationFunction<MovePostMutation, MovePostMutationVariables>;

/**
 * __useMovePostMutation__
 *
 * To run a mutation, you first call `useMovePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMovePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [movePostMutation, { data, loading, error }] = useMovePostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMovePostMutation(baseOptions?: Apollo.MutationHookOptions<MovePostMutation, MovePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MovePostMutation, MovePostMutationVariables>(MovePostDocument, options);
      }
export type MovePostMutationHookResult = ReturnType<typeof useMovePostMutation>;
export type MovePostMutationResult = Apollo.MutationResult<MovePostMutation>;
export type MovePostMutationOptions = Apollo.BaseMutationOptions<MovePostMutation, MovePostMutationVariables>;
export const PublishDraftPostDocument = gql`
    mutation PublishDraftPost($input: PublishDraftPostInput!) {
  publishDraftPost(input: $input) {
    id
    isDraft
  }
}
    `;
export type PublishDraftPostMutationFn = Apollo.MutationFunction<PublishDraftPostMutation, PublishDraftPostMutationVariables>;

/**
 * __usePublishDraftPostMutation__
 *
 * To run a mutation, you first call `usePublishDraftPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishDraftPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishDraftPostMutation, { data, loading, error }] = usePublishDraftPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishDraftPostMutation(baseOptions?: Apollo.MutationHookOptions<PublishDraftPostMutation, PublishDraftPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishDraftPostMutation, PublishDraftPostMutationVariables>(PublishDraftPostDocument, options);
      }
export type PublishDraftPostMutationHookResult = ReturnType<typeof usePublishDraftPostMutation>;
export type PublishDraftPostMutationResult = Apollo.MutationResult<PublishDraftPostMutation>;
export type PublishDraftPostMutationOptions = Apollo.BaseMutationOptions<PublishDraftPostMutation, PublishDraftPostMutationVariables>;
export const RescheduleDraftPostDocument = gql`
    mutation RescheduleDraftPost($input: RescheduleDraftPostInput!) {
  rescheduleDraftPost(input: $input) {
    id
    scheduledAt
  }
}
    `;
export type RescheduleDraftPostMutationFn = Apollo.MutationFunction<RescheduleDraftPostMutation, RescheduleDraftPostMutationVariables>;

/**
 * __useRescheduleDraftPostMutation__
 *
 * To run a mutation, you first call `useRescheduleDraftPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleDraftPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleDraftPostMutation, { data, loading, error }] = useRescheduleDraftPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRescheduleDraftPostMutation(baseOptions?: Apollo.MutationHookOptions<RescheduleDraftPostMutation, RescheduleDraftPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RescheduleDraftPostMutation, RescheduleDraftPostMutationVariables>(RescheduleDraftPostDocument, options);
      }
export type RescheduleDraftPostMutationHookResult = ReturnType<typeof useRescheduleDraftPostMutation>;
export type RescheduleDraftPostMutationResult = Apollo.MutationResult<RescheduleDraftPostMutation>;
export type RescheduleDraftPostMutationOptions = Apollo.BaseMutationOptions<RescheduleDraftPostMutation, RescheduleDraftPostMutationVariables>;
export const ShortIdSuggestionDocument = gql`
    query ShortIdSuggestion($name: String!) {
  shortIdSuggestion(name: $name) {
    __typename
    ... on OrganizationShortId {
      shortId
    }
    ... on OrganizationShortIdError {
      message
    }
  }
}
    `;

/**
 * __useShortIdSuggestionQuery__
 *
 * To run a query within a React component, call `useShortIdSuggestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useShortIdSuggestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShortIdSuggestionQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useShortIdSuggestionQuery(baseOptions: Apollo.QueryHookOptions<ShortIdSuggestionQuery, ShortIdSuggestionQueryVariables> & ({ variables: ShortIdSuggestionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShortIdSuggestionQuery, ShortIdSuggestionQueryVariables>(ShortIdSuggestionDocument, options);
      }
export function useShortIdSuggestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShortIdSuggestionQuery, ShortIdSuggestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShortIdSuggestionQuery, ShortIdSuggestionQueryVariables>(ShortIdSuggestionDocument, options);
        }
export function useShortIdSuggestionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ShortIdSuggestionQuery, ShortIdSuggestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ShortIdSuggestionQuery, ShortIdSuggestionQueryVariables>(ShortIdSuggestionDocument, options);
        }
export type ShortIdSuggestionQueryHookResult = ReturnType<typeof useShortIdSuggestionQuery>;
export type ShortIdSuggestionLazyQueryHookResult = ReturnType<typeof useShortIdSuggestionLazyQuery>;
export type ShortIdSuggestionSuspenseQueryHookResult = ReturnType<typeof useShortIdSuggestionSuspenseQuery>;
export type ShortIdSuggestionQueryResult = Apollo.QueryResult<ShortIdSuggestionQuery, ShortIdSuggestionQueryVariables>;
export function refetchShortIdSuggestionQuery(variables: ShortIdSuggestionQueryVariables) {
      return { query: ShortIdSuggestionDocument, variables: variables }
    }
export const UsernameAvailableDocument = gql`
    query UsernameAvailable($username: String!) {
  usernameAvailable(username: $username) {
    __typename
    ... on Username {
      username
    }
    ... on UsernameError {
      message
    }
  }
}
    `;

/**
 * __useUsernameAvailableQuery__
 *
 * To run a query within a React component, call `useUsernameAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsernameAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsernameAvailableQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUsernameAvailableQuery(baseOptions: Apollo.QueryHookOptions<UsernameAvailableQuery, UsernameAvailableQueryVariables> & ({ variables: UsernameAvailableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsernameAvailableQuery, UsernameAvailableQueryVariables>(UsernameAvailableDocument, options);
      }
export function useUsernameAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsernameAvailableQuery, UsernameAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsernameAvailableQuery, UsernameAvailableQueryVariables>(UsernameAvailableDocument, options);
        }
export function useUsernameAvailableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsernameAvailableQuery, UsernameAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsernameAvailableQuery, UsernameAvailableQueryVariables>(UsernameAvailableDocument, options);
        }
export type UsernameAvailableQueryHookResult = ReturnType<typeof useUsernameAvailableQuery>;
export type UsernameAvailableLazyQueryHookResult = ReturnType<typeof useUsernameAvailableLazyQuery>;
export type UsernameAvailableSuspenseQueryHookResult = ReturnType<typeof useUsernameAvailableSuspenseQuery>;
export type UsernameAvailableQueryResult = Apollo.QueryResult<UsernameAvailableQuery, UsernameAvailableQueryVariables>;
export function refetchUsernameAvailableQuery(variables: UsernameAvailableQueryVariables) {
      return { query: UsernameAvailableDocument, variables: variables }
    }
export const ShortIdAvailableDocument = gql`
    query ShortIdAvailable($shortId: String!) {
  shortIdAvailable(shortId: $shortId) {
    __typename
    ... on OrganizationShortId {
      shortId
    }
    ... on OrganizationShortIdError {
      message
    }
  }
}
    `;

/**
 * __useShortIdAvailableQuery__
 *
 * To run a query within a React component, call `useShortIdAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useShortIdAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShortIdAvailableQuery({
 *   variables: {
 *      shortId: // value for 'shortId'
 *   },
 * });
 */
export function useShortIdAvailableQuery(baseOptions: Apollo.QueryHookOptions<ShortIdAvailableQuery, ShortIdAvailableQueryVariables> & ({ variables: ShortIdAvailableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShortIdAvailableQuery, ShortIdAvailableQueryVariables>(ShortIdAvailableDocument, options);
      }
export function useShortIdAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShortIdAvailableQuery, ShortIdAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShortIdAvailableQuery, ShortIdAvailableQueryVariables>(ShortIdAvailableDocument, options);
        }
export function useShortIdAvailableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ShortIdAvailableQuery, ShortIdAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ShortIdAvailableQuery, ShortIdAvailableQueryVariables>(ShortIdAvailableDocument, options);
        }
export type ShortIdAvailableQueryHookResult = ReturnType<typeof useShortIdAvailableQuery>;
export type ShortIdAvailableLazyQueryHookResult = ReturnType<typeof useShortIdAvailableLazyQuery>;
export type ShortIdAvailableSuspenseQueryHookResult = ReturnType<typeof useShortIdAvailableSuspenseQuery>;
export type ShortIdAvailableQueryResult = Apollo.QueryResult<ShortIdAvailableQuery, ShortIdAvailableQueryVariables>;
export function refetchShortIdAvailableQuery(variables: ShortIdAvailableQueryVariables) {
      return { query: ShortIdAvailableDocument, variables: variables }
    }
export const CreateOrUpdateOrganizationDocument = gql`
    mutation CreateOrUpdateOrganization($input: CreateOrUpdateOrganizationInput!) {
  createOrUpdateOrganization(input: $input) {
    ...OrganizationCore
  }
}
    ${OrganizationCoreFragmentDoc}`;
export type CreateOrUpdateOrganizationMutationFn = Apollo.MutationFunction<CreateOrUpdateOrganizationMutation, CreateOrUpdateOrganizationMutationVariables>;

/**
 * __useCreateOrUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateOrganizationMutation, { data, loading, error }] = useCreateOrUpdateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateOrganizationMutation, CreateOrUpdateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateOrganizationMutation, CreateOrUpdateOrganizationMutationVariables>(CreateOrUpdateOrganizationDocument, options);
      }
export type CreateOrUpdateOrganizationMutationHookResult = ReturnType<typeof useCreateOrUpdateOrganizationMutation>;
export type CreateOrUpdateOrganizationMutationResult = Apollo.MutationResult<CreateOrUpdateOrganizationMutation>;
export type CreateOrUpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateOrganizationMutation, CreateOrUpdateOrganizationMutationVariables>;
export const SetCustomDomainDocument = gql`
    mutation SetCustomDomain($input: SetOrganizationCustomDomainInput!) {
  setCustomDomain(input: $input) {
    id
    domain
  }
}
    `;
export type SetCustomDomainMutationFn = Apollo.MutationFunction<SetCustomDomainMutation, SetCustomDomainMutationVariables>;

/**
 * __useSetCustomDomainMutation__
 *
 * To run a mutation, you first call `useSetCustomDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCustomDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCustomDomainMutation, { data, loading, error }] = useSetCustomDomainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCustomDomainMutation(baseOptions?: Apollo.MutationHookOptions<SetCustomDomainMutation, SetCustomDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCustomDomainMutation, SetCustomDomainMutationVariables>(SetCustomDomainDocument, options);
      }
export type SetCustomDomainMutationHookResult = ReturnType<typeof useSetCustomDomainMutation>;
export type SetCustomDomainMutationResult = Apollo.MutationResult<SetCustomDomainMutation>;
export type SetCustomDomainMutationOptions = Apollo.BaseMutationOptions<SetCustomDomainMutation, SetCustomDomainMutationVariables>;
export const RemoveCustomDomainDocument = gql`
    mutation RemoveCustomDomain($organizationId: ID!) {
  removeCustomDomain(organizationId: $organizationId) {
    id
    domain
  }
}
    `;
export type RemoveCustomDomainMutationFn = Apollo.MutationFunction<RemoveCustomDomainMutation, RemoveCustomDomainMutationVariables>;

/**
 * __useRemoveCustomDomainMutation__
 *
 * To run a mutation, you first call `useRemoveCustomDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCustomDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCustomDomainMutation, { data, loading, error }] = useRemoveCustomDomainMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useRemoveCustomDomainMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCustomDomainMutation, RemoveCustomDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCustomDomainMutation, RemoveCustomDomainMutationVariables>(RemoveCustomDomainDocument, options);
      }
export type RemoveCustomDomainMutationHookResult = ReturnType<typeof useRemoveCustomDomainMutation>;
export type RemoveCustomDomainMutationResult = Apollo.MutationResult<RemoveCustomDomainMutation>;
export type RemoveCustomDomainMutationOptions = Apollo.BaseMutationOptions<RemoveCustomDomainMutation, RemoveCustomDomainMutationVariables>;
export const JoinOrganizationDocument = gql`
    mutation JoinOrganization($input: JoinOrganizationInput!) {
  joinOrganization(input: $input) {
    ... on Organization {
      id
      name
      groupCreationPolicy
    }
    ... on OrganizationJoinError {
      message
    }
  }
}
    `;
export type JoinOrganizationMutationFn = Apollo.MutationFunction<JoinOrganizationMutation, JoinOrganizationMutationVariables>;

/**
 * __useJoinOrganizationMutation__
 *
 * To run a mutation, you first call `useJoinOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinOrganizationMutation, { data, loading, error }] = useJoinOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<JoinOrganizationMutation, JoinOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinOrganizationMutation, JoinOrganizationMutationVariables>(JoinOrganizationDocument, options);
      }
export type JoinOrganizationMutationHookResult = ReturnType<typeof useJoinOrganizationMutation>;
export type JoinOrganizationMutationResult = Apollo.MutationResult<JoinOrganizationMutation>;
export type JoinOrganizationMutationOptions = Apollo.BaseMutationOptions<JoinOrganizationMutation, JoinOrganizationMutationVariables>;
export const UpdateUserLanguageDocument = gql`
    mutation UpdateUserLanguage($input: UpdateUserLanguageInput!) {
  updateUserLanguage(input: $input) {
    id
    username
  }
}
    `;
export type UpdateUserLanguageMutationFn = Apollo.MutationFunction<UpdateUserLanguageMutation, UpdateUserLanguageMutationVariables>;

/**
 * __useUpdateUserLanguageMutation__
 *
 * To run a mutation, you first call `useUpdateUserLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserLanguageMutation, { data, loading, error }] = useUpdateUserLanguageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserLanguageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserLanguageMutation, UpdateUserLanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserLanguageMutation, UpdateUserLanguageMutationVariables>(UpdateUserLanguageDocument, options);
      }
export type UpdateUserLanguageMutationHookResult = ReturnType<typeof useUpdateUserLanguageMutation>;
export type UpdateUserLanguageMutationResult = Apollo.MutationResult<UpdateUserLanguageMutation>;
export type UpdateUserLanguageMutationOptions = Apollo.BaseMutationOptions<UpdateUserLanguageMutation, UpdateUserLanguageMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    username
    name
    profileImageId
    avatarBgColor
    avatar
    firstName
    lastName
    linkedinUrl
    instagramHandle
    hometown
    currentCity
    bio
    claimedAccount
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const OrganizationAdministratorsDocument = gql`
    query OrganizationAdministrators($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    administrators {
      id
      username
      firstName
      lastName
      profileImageId
      avatar
      avatarBgColor
    }
  }
}
    `;

/**
 * __useOrganizationAdministratorsQuery__
 *
 * To run a query within a React component, call `useOrganizationAdministratorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationAdministratorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationAdministratorsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationAdministratorsQuery(baseOptions: Apollo.QueryHookOptions<OrganizationAdministratorsQuery, OrganizationAdministratorsQueryVariables> & ({ variables: OrganizationAdministratorsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationAdministratorsQuery, OrganizationAdministratorsQueryVariables>(OrganizationAdministratorsDocument, options);
      }
export function useOrganizationAdministratorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationAdministratorsQuery, OrganizationAdministratorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationAdministratorsQuery, OrganizationAdministratorsQueryVariables>(OrganizationAdministratorsDocument, options);
        }
export function useOrganizationAdministratorsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizationAdministratorsQuery, OrganizationAdministratorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizationAdministratorsQuery, OrganizationAdministratorsQueryVariables>(OrganizationAdministratorsDocument, options);
        }
export type OrganizationAdministratorsQueryHookResult = ReturnType<typeof useOrganizationAdministratorsQuery>;
export type OrganizationAdministratorsLazyQueryHookResult = ReturnType<typeof useOrganizationAdministratorsLazyQuery>;
export type OrganizationAdministratorsSuspenseQueryHookResult = ReturnType<typeof useOrganizationAdministratorsSuspenseQuery>;
export type OrganizationAdministratorsQueryResult = Apollo.QueryResult<OrganizationAdministratorsQuery, OrganizationAdministratorsQueryVariables>;
export function refetchOrganizationAdministratorsQuery(variables: OrganizationAdministratorsQueryVariables) {
      return { query: OrganizationAdministratorsDocument, variables: variables }
    }
export const OrganizationQuotaUsageDocument = gql`
    query OrganizationQuotaUsage($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    quotaUsage {
      name
      limit
      used
    }
  }
}
    `;

/**
 * __useOrganizationQuotaUsageQuery__
 *
 * To run a query within a React component, call `useOrganizationQuotaUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationQuotaUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationQuotaUsageQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationQuotaUsageQuery(baseOptions: Apollo.QueryHookOptions<OrganizationQuotaUsageQuery, OrganizationQuotaUsageQueryVariables> & ({ variables: OrganizationQuotaUsageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationQuotaUsageQuery, OrganizationQuotaUsageQueryVariables>(OrganizationQuotaUsageDocument, options);
      }
export function useOrganizationQuotaUsageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationQuotaUsageQuery, OrganizationQuotaUsageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationQuotaUsageQuery, OrganizationQuotaUsageQueryVariables>(OrganizationQuotaUsageDocument, options);
        }
export function useOrganizationQuotaUsageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizationQuotaUsageQuery, OrganizationQuotaUsageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizationQuotaUsageQuery, OrganizationQuotaUsageQueryVariables>(OrganizationQuotaUsageDocument, options);
        }
export type OrganizationQuotaUsageQueryHookResult = ReturnType<typeof useOrganizationQuotaUsageQuery>;
export type OrganizationQuotaUsageLazyQueryHookResult = ReturnType<typeof useOrganizationQuotaUsageLazyQuery>;
export type OrganizationQuotaUsageSuspenseQueryHookResult = ReturnType<typeof useOrganizationQuotaUsageSuspenseQuery>;
export type OrganizationQuotaUsageQueryResult = Apollo.QueryResult<OrganizationQuotaUsageQuery, OrganizationQuotaUsageQueryVariables>;
export function refetchOrganizationQuotaUsageQuery(variables: OrganizationQuotaUsageQueryVariables) {
      return { query: OrganizationQuotaUsageDocument, variables: variables }
    }
export const AdminSubscriptionPlanPriceListDocument = gql`
    query AdminSubscriptionPlanPriceList {
  adminSubscriptionPlanPriceList {
    prices {
      id
      unitAmount
      lookupKey
      currency
    }
  }
}
    `;

/**
 * __useAdminSubscriptionPlanPriceListQuery__
 *
 * To run a query within a React component, call `useAdminSubscriptionPlanPriceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSubscriptionPlanPriceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSubscriptionPlanPriceListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminSubscriptionPlanPriceListQuery(baseOptions?: Apollo.QueryHookOptions<AdminSubscriptionPlanPriceListQuery, AdminSubscriptionPlanPriceListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminSubscriptionPlanPriceListQuery, AdminSubscriptionPlanPriceListQueryVariables>(AdminSubscriptionPlanPriceListDocument, options);
      }
export function useAdminSubscriptionPlanPriceListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminSubscriptionPlanPriceListQuery, AdminSubscriptionPlanPriceListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminSubscriptionPlanPriceListQuery, AdminSubscriptionPlanPriceListQueryVariables>(AdminSubscriptionPlanPriceListDocument, options);
        }
export function useAdminSubscriptionPlanPriceListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AdminSubscriptionPlanPriceListQuery, AdminSubscriptionPlanPriceListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminSubscriptionPlanPriceListQuery, AdminSubscriptionPlanPriceListQueryVariables>(AdminSubscriptionPlanPriceListDocument, options);
        }
export type AdminSubscriptionPlanPriceListQueryHookResult = ReturnType<typeof useAdminSubscriptionPlanPriceListQuery>;
export type AdminSubscriptionPlanPriceListLazyQueryHookResult = ReturnType<typeof useAdminSubscriptionPlanPriceListLazyQuery>;
export type AdminSubscriptionPlanPriceListSuspenseQueryHookResult = ReturnType<typeof useAdminSubscriptionPlanPriceListSuspenseQuery>;
export type AdminSubscriptionPlanPriceListQueryResult = Apollo.QueryResult<AdminSubscriptionPlanPriceListQuery, AdminSubscriptionPlanPriceListQueryVariables>;
export function refetchAdminSubscriptionPlanPriceListQuery(variables?: AdminSubscriptionPlanPriceListQueryVariables) {
      return { query: AdminSubscriptionPlanPriceListDocument, variables: variables }
    }
export const OrganizationEntitlementsDocument = gql`
    query OrganizationEntitlements($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    entitlements {
      features
      quotas {
        name
        limit
      }
    }
  }
}
    `;

/**
 * __useOrganizationEntitlementsQuery__
 *
 * To run a query within a React component, call `useOrganizationEntitlementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationEntitlementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationEntitlementsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationEntitlementsQuery(baseOptions: Apollo.QueryHookOptions<OrganizationEntitlementsQuery, OrganizationEntitlementsQueryVariables> & ({ variables: OrganizationEntitlementsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationEntitlementsQuery, OrganizationEntitlementsQueryVariables>(OrganizationEntitlementsDocument, options);
      }
export function useOrganizationEntitlementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationEntitlementsQuery, OrganizationEntitlementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationEntitlementsQuery, OrganizationEntitlementsQueryVariables>(OrganizationEntitlementsDocument, options);
        }
export function useOrganizationEntitlementsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizationEntitlementsQuery, OrganizationEntitlementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizationEntitlementsQuery, OrganizationEntitlementsQueryVariables>(OrganizationEntitlementsDocument, options);
        }
export type OrganizationEntitlementsQueryHookResult = ReturnType<typeof useOrganizationEntitlementsQuery>;
export type OrganizationEntitlementsLazyQueryHookResult = ReturnType<typeof useOrganizationEntitlementsLazyQuery>;
export type OrganizationEntitlementsSuspenseQueryHookResult = ReturnType<typeof useOrganizationEntitlementsSuspenseQuery>;
export type OrganizationEntitlementsQueryResult = Apollo.QueryResult<OrganizationEntitlementsQuery, OrganizationEntitlementsQueryVariables>;
export function refetchOrganizationEntitlementsQuery(variables: OrganizationEntitlementsQueryVariables) {
      return { query: OrganizationEntitlementsDocument, variables: variables }
    }
export const OrganizationAdminSubscriptionDocument = gql`
    query OrganizationAdminSubscription($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    adminSubscription {
      stripeSubscriptionStatus
      stripeCancelAtPeriodEnd
      subscriptionPlan
      subscriptionPrice
      grandfathered
      expiresAt
    }
  }
}
    `;

/**
 * __useOrganizationAdminSubscriptionQuery__
 *
 * To run a query within a React component, call `useOrganizationAdminSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationAdminSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationAdminSubscriptionQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationAdminSubscriptionQuery(baseOptions: Apollo.QueryHookOptions<OrganizationAdminSubscriptionQuery, OrganizationAdminSubscriptionQueryVariables> & ({ variables: OrganizationAdminSubscriptionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationAdminSubscriptionQuery, OrganizationAdminSubscriptionQueryVariables>(OrganizationAdminSubscriptionDocument, options);
      }
export function useOrganizationAdminSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationAdminSubscriptionQuery, OrganizationAdminSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationAdminSubscriptionQuery, OrganizationAdminSubscriptionQueryVariables>(OrganizationAdminSubscriptionDocument, options);
        }
export function useOrganizationAdminSubscriptionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizationAdminSubscriptionQuery, OrganizationAdminSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizationAdminSubscriptionQuery, OrganizationAdminSubscriptionQueryVariables>(OrganizationAdminSubscriptionDocument, options);
        }
export type OrganizationAdminSubscriptionQueryHookResult = ReturnType<typeof useOrganizationAdminSubscriptionQuery>;
export type OrganizationAdminSubscriptionLazyQueryHookResult = ReturnType<typeof useOrganizationAdminSubscriptionLazyQuery>;
export type OrganizationAdminSubscriptionSuspenseQueryHookResult = ReturnType<typeof useOrganizationAdminSubscriptionSuspenseQuery>;
export type OrganizationAdminSubscriptionQueryResult = Apollo.QueryResult<OrganizationAdminSubscriptionQuery, OrganizationAdminSubscriptionQueryVariables>;
export function refetchOrganizationAdminSubscriptionQuery(variables: OrganizationAdminSubscriptionQueryVariables) {
      return { query: OrganizationAdminSubscriptionDocument, variables: variables }
    }
export const OrganizationMemberSubscriptionPlanDocument = gql`
    query OrganizationMemberSubscriptionPlan($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    memberSubscriptionPlan {
      id
      name
      description
      priceMonthlyUsd
      active
      stripeAccount {
        id
        disabledReason
      }
      groupEntitlements {
        id
        name
        description
        emoji
        numPosts
        groupType: type
      }
    }
  }
}
    `;

/**
 * __useOrganizationMemberSubscriptionPlanQuery__
 *
 * To run a query within a React component, call `useOrganizationMemberSubscriptionPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationMemberSubscriptionPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationMemberSubscriptionPlanQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationMemberSubscriptionPlanQuery(baseOptions: Apollo.QueryHookOptions<OrganizationMemberSubscriptionPlanQuery, OrganizationMemberSubscriptionPlanQueryVariables> & ({ variables: OrganizationMemberSubscriptionPlanQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationMemberSubscriptionPlanQuery, OrganizationMemberSubscriptionPlanQueryVariables>(OrganizationMemberSubscriptionPlanDocument, options);
      }
export function useOrganizationMemberSubscriptionPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationMemberSubscriptionPlanQuery, OrganizationMemberSubscriptionPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationMemberSubscriptionPlanQuery, OrganizationMemberSubscriptionPlanQueryVariables>(OrganizationMemberSubscriptionPlanDocument, options);
        }
export function useOrganizationMemberSubscriptionPlanSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizationMemberSubscriptionPlanQuery, OrganizationMemberSubscriptionPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizationMemberSubscriptionPlanQuery, OrganizationMemberSubscriptionPlanQueryVariables>(OrganizationMemberSubscriptionPlanDocument, options);
        }
export type OrganizationMemberSubscriptionPlanQueryHookResult = ReturnType<typeof useOrganizationMemberSubscriptionPlanQuery>;
export type OrganizationMemberSubscriptionPlanLazyQueryHookResult = ReturnType<typeof useOrganizationMemberSubscriptionPlanLazyQuery>;
export type OrganizationMemberSubscriptionPlanSuspenseQueryHookResult = ReturnType<typeof useOrganizationMemberSubscriptionPlanSuspenseQuery>;
export type OrganizationMemberSubscriptionPlanQueryResult = Apollo.QueryResult<OrganizationMemberSubscriptionPlanQuery, OrganizationMemberSubscriptionPlanQueryVariables>;
export function refetchOrganizationMemberSubscriptionPlanQuery(variables: OrganizationMemberSubscriptionPlanQueryVariables) {
      return { query: OrganizationMemberSubscriptionPlanDocument, variables: variables }
    }
export const MemberSubscriptionPlanDocument = gql`
    query MemberSubscriptionPlan($id: ID!) {
  memberSubscriptionPlan(id: $id) {
    id
    subscriberCount
    pendingBalance
    priceMonthlyUsd
    totalRevenue
    active
    stripeAccount {
      id
      createdAt
    }
  }
}
    `;

/**
 * __useMemberSubscriptionPlanQuery__
 *
 * To run a query within a React component, call `useMemberSubscriptionPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberSubscriptionPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberSubscriptionPlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMemberSubscriptionPlanQuery(baseOptions: Apollo.QueryHookOptions<MemberSubscriptionPlanQuery, MemberSubscriptionPlanQueryVariables> & ({ variables: MemberSubscriptionPlanQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MemberSubscriptionPlanQuery, MemberSubscriptionPlanQueryVariables>(MemberSubscriptionPlanDocument, options);
      }
export function useMemberSubscriptionPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MemberSubscriptionPlanQuery, MemberSubscriptionPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MemberSubscriptionPlanQuery, MemberSubscriptionPlanQueryVariables>(MemberSubscriptionPlanDocument, options);
        }
export function useMemberSubscriptionPlanSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MemberSubscriptionPlanQuery, MemberSubscriptionPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MemberSubscriptionPlanQuery, MemberSubscriptionPlanQueryVariables>(MemberSubscriptionPlanDocument, options);
        }
export type MemberSubscriptionPlanQueryHookResult = ReturnType<typeof useMemberSubscriptionPlanQuery>;
export type MemberSubscriptionPlanLazyQueryHookResult = ReturnType<typeof useMemberSubscriptionPlanLazyQuery>;
export type MemberSubscriptionPlanSuspenseQueryHookResult = ReturnType<typeof useMemberSubscriptionPlanSuspenseQuery>;
export type MemberSubscriptionPlanQueryResult = Apollo.QueryResult<MemberSubscriptionPlanQuery, MemberSubscriptionPlanQueryVariables>;
export function refetchMemberSubscriptionPlanQuery(variables: MemberSubscriptionPlanQueryVariables) {
      return { query: MemberSubscriptionPlanDocument, variables: variables }
    }
export const ChangeAdminSubscriptionPlanDocument = gql`
    mutation ChangeAdminSubscriptionPlan($organizationId: ID!, $lookupKey: AdminSubscriptionPlanPriceLookupKey!) {
  changeAdminSubscriptionPlan(
    organizationId: $organizationId
    lookupKey: $lookupKey
  ) {
    stripeSubscriptionStatus
  }
}
    `;
export type ChangeAdminSubscriptionPlanMutationFn = Apollo.MutationFunction<ChangeAdminSubscriptionPlanMutation, ChangeAdminSubscriptionPlanMutationVariables>;

/**
 * __useChangeAdminSubscriptionPlanMutation__
 *
 * To run a mutation, you first call `useChangeAdminSubscriptionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeAdminSubscriptionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeAdminSubscriptionPlanMutation, { data, loading, error }] = useChangeAdminSubscriptionPlanMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      lookupKey: // value for 'lookupKey'
 *   },
 * });
 */
export function useChangeAdminSubscriptionPlanMutation(baseOptions?: Apollo.MutationHookOptions<ChangeAdminSubscriptionPlanMutation, ChangeAdminSubscriptionPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeAdminSubscriptionPlanMutation, ChangeAdminSubscriptionPlanMutationVariables>(ChangeAdminSubscriptionPlanDocument, options);
      }
export type ChangeAdminSubscriptionPlanMutationHookResult = ReturnType<typeof useChangeAdminSubscriptionPlanMutation>;
export type ChangeAdminSubscriptionPlanMutationResult = Apollo.MutationResult<ChangeAdminSubscriptionPlanMutation>;
export type ChangeAdminSubscriptionPlanMutationOptions = Apollo.BaseMutationOptions<ChangeAdminSubscriptionPlanMutation, ChangeAdminSubscriptionPlanMutationVariables>;
export const UpsertMemberSubscriptionPlanDocument = gql`
    mutation UpsertMemberSubscriptionPlan($input: UpsertMemberSubscriptionPlanInput!) {
  upsertMemberSubscriptionPlan(input: $input) {
    id
  }
}
    `;
export type UpsertMemberSubscriptionPlanMutationFn = Apollo.MutationFunction<UpsertMemberSubscriptionPlanMutation, UpsertMemberSubscriptionPlanMutationVariables>;

/**
 * __useUpsertMemberSubscriptionPlanMutation__
 *
 * To run a mutation, you first call `useUpsertMemberSubscriptionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMemberSubscriptionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMemberSubscriptionPlanMutation, { data, loading, error }] = useUpsertMemberSubscriptionPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertMemberSubscriptionPlanMutation(baseOptions?: Apollo.MutationHookOptions<UpsertMemberSubscriptionPlanMutation, UpsertMemberSubscriptionPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertMemberSubscriptionPlanMutation, UpsertMemberSubscriptionPlanMutationVariables>(UpsertMemberSubscriptionPlanDocument, options);
      }
export type UpsertMemberSubscriptionPlanMutationHookResult = ReturnType<typeof useUpsertMemberSubscriptionPlanMutation>;
export type UpsertMemberSubscriptionPlanMutationResult = Apollo.MutationResult<UpsertMemberSubscriptionPlanMutation>;
export type UpsertMemberSubscriptionPlanMutationOptions = Apollo.BaseMutationOptions<UpsertMemberSubscriptionPlanMutation, UpsertMemberSubscriptionPlanMutationVariables>;
export const CancelAdminSubscriptionDocument = gql`
    mutation CancelAdminSubscription($organizationId: ID!) {
  cancelAdminSubscription(organizationId: $organizationId) {
    stripeSubscriptionStatus
  }
}
    `;
export type CancelAdminSubscriptionMutationFn = Apollo.MutationFunction<CancelAdminSubscriptionMutation, CancelAdminSubscriptionMutationVariables>;

/**
 * __useCancelAdminSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelAdminSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAdminSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAdminSubscriptionMutation, { data, loading, error }] = useCancelAdminSubscriptionMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCancelAdminSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelAdminSubscriptionMutation, CancelAdminSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelAdminSubscriptionMutation, CancelAdminSubscriptionMutationVariables>(CancelAdminSubscriptionDocument, options);
      }
export type CancelAdminSubscriptionMutationHookResult = ReturnType<typeof useCancelAdminSubscriptionMutation>;
export type CancelAdminSubscriptionMutationResult = Apollo.MutationResult<CancelAdminSubscriptionMutation>;
export type CancelAdminSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelAdminSubscriptionMutation, CancelAdminSubscriptionMutationVariables>;
export const CancelMemberSubscriptionDocument = gql`
    mutation CancelMemberSubscription($memberSubscriptionId: ID!) {
  cancelMemberSubscription(memberSubscriptionId: $memberSubscriptionId) {
    id
  }
}
    `;
export type CancelMemberSubscriptionMutationFn = Apollo.MutationFunction<CancelMemberSubscriptionMutation, CancelMemberSubscriptionMutationVariables>;

/**
 * __useCancelMemberSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelMemberSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelMemberSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelMemberSubscriptionMutation, { data, loading, error }] = useCancelMemberSubscriptionMutation({
 *   variables: {
 *      memberSubscriptionId: // value for 'memberSubscriptionId'
 *   },
 * });
 */
export function useCancelMemberSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelMemberSubscriptionMutation, CancelMemberSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelMemberSubscriptionMutation, CancelMemberSubscriptionMutationVariables>(CancelMemberSubscriptionDocument, options);
      }
export type CancelMemberSubscriptionMutationHookResult = ReturnType<typeof useCancelMemberSubscriptionMutation>;
export type CancelMemberSubscriptionMutationResult = Apollo.MutationResult<CancelMemberSubscriptionMutation>;
export type CancelMemberSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelMemberSubscriptionMutation, CancelMemberSubscriptionMutationVariables>;
export const ReactivateMemberSubscriptionDocument = gql`
    mutation ReactivateMemberSubscription($memberSubscriptionId: ID!) {
  reactivateMemberSubscription(memberSubscriptionId: $memberSubscriptionId) {
    id
  }
}
    `;
export type ReactivateMemberSubscriptionMutationFn = Apollo.MutationFunction<ReactivateMemberSubscriptionMutation, ReactivateMemberSubscriptionMutationVariables>;

/**
 * __useReactivateMemberSubscriptionMutation__
 *
 * To run a mutation, you first call `useReactivateMemberSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateMemberSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateMemberSubscriptionMutation, { data, loading, error }] = useReactivateMemberSubscriptionMutation({
 *   variables: {
 *      memberSubscriptionId: // value for 'memberSubscriptionId'
 *   },
 * });
 */
export function useReactivateMemberSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateMemberSubscriptionMutation, ReactivateMemberSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateMemberSubscriptionMutation, ReactivateMemberSubscriptionMutationVariables>(ReactivateMemberSubscriptionDocument, options);
      }
export type ReactivateMemberSubscriptionMutationHookResult = ReturnType<typeof useReactivateMemberSubscriptionMutation>;
export type ReactivateMemberSubscriptionMutationResult = Apollo.MutationResult<ReactivateMemberSubscriptionMutation>;
export type ReactivateMemberSubscriptionMutationOptions = Apollo.BaseMutationOptions<ReactivateMemberSubscriptionMutation, ReactivateMemberSubscriptionMutationVariables>;
export const CreateAdminPlanCheckoutSessionDocument = gql`
    mutation CreateAdminPlanCheckoutSession($organizationId: String!, $lookupKey: AdminSubscriptionPlanPriceLookupKey!) {
  createAdminPlanCheckoutSession(
    organizationId: $organizationId
    lookupKey: $lookupKey
  ) {
    url
  }
}
    `;
export type CreateAdminPlanCheckoutSessionMutationFn = Apollo.MutationFunction<CreateAdminPlanCheckoutSessionMutation, CreateAdminPlanCheckoutSessionMutationVariables>;

/**
 * __useCreateAdminPlanCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateAdminPlanCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminPlanCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminPlanCheckoutSessionMutation, { data, loading, error }] = useCreateAdminPlanCheckoutSessionMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      lookupKey: // value for 'lookupKey'
 *   },
 * });
 */
export function useCreateAdminPlanCheckoutSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdminPlanCheckoutSessionMutation, CreateAdminPlanCheckoutSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdminPlanCheckoutSessionMutation, CreateAdminPlanCheckoutSessionMutationVariables>(CreateAdminPlanCheckoutSessionDocument, options);
      }
export type CreateAdminPlanCheckoutSessionMutationHookResult = ReturnType<typeof useCreateAdminPlanCheckoutSessionMutation>;
export type CreateAdminPlanCheckoutSessionMutationResult = Apollo.MutationResult<CreateAdminPlanCheckoutSessionMutation>;
export type CreateAdminPlanCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<CreateAdminPlanCheckoutSessionMutation, CreateAdminPlanCheckoutSessionMutationVariables>;
export const CreateMemberPlanCheckoutSessionDocument = gql`
    mutation CreateMemberPlanCheckoutSession($memberSubscriptionPlanId: String!, $returnToGroupId: String) {
  createMemberPlanCheckoutSession(
    memberSubscriptionPlanId: $memberSubscriptionPlanId
    returnToGroupId: $returnToGroupId
  ) {
    url
  }
}
    `;
export type CreateMemberPlanCheckoutSessionMutationFn = Apollo.MutationFunction<CreateMemberPlanCheckoutSessionMutation, CreateMemberPlanCheckoutSessionMutationVariables>;

/**
 * __useCreateMemberPlanCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateMemberPlanCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberPlanCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberPlanCheckoutSessionMutation, { data, loading, error }] = useCreateMemberPlanCheckoutSessionMutation({
 *   variables: {
 *      memberSubscriptionPlanId: // value for 'memberSubscriptionPlanId'
 *      returnToGroupId: // value for 'returnToGroupId'
 *   },
 * });
 */
export function useCreateMemberPlanCheckoutSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateMemberPlanCheckoutSessionMutation, CreateMemberPlanCheckoutSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMemberPlanCheckoutSessionMutation, CreateMemberPlanCheckoutSessionMutationVariables>(CreateMemberPlanCheckoutSessionDocument, options);
      }
export type CreateMemberPlanCheckoutSessionMutationHookResult = ReturnType<typeof useCreateMemberPlanCheckoutSessionMutation>;
export type CreateMemberPlanCheckoutSessionMutationResult = Apollo.MutationResult<CreateMemberPlanCheckoutSessionMutation>;
export type CreateMemberPlanCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<CreateMemberPlanCheckoutSessionMutation, CreateMemberPlanCheckoutSessionMutationVariables>;
export const CreateAdminPlanPortalSessionDocument = gql`
    mutation CreateAdminPlanPortalSession($organizationId: String!, $returnUrl: String!) {
  createAdminPlanPortalSession(
    organizationId: $organizationId
    returnUrl: $returnUrl
  ) {
    url
  }
}
    `;
export type CreateAdminPlanPortalSessionMutationFn = Apollo.MutationFunction<CreateAdminPlanPortalSessionMutation, CreateAdminPlanPortalSessionMutationVariables>;

/**
 * __useCreateAdminPlanPortalSessionMutation__
 *
 * To run a mutation, you first call `useCreateAdminPlanPortalSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminPlanPortalSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminPlanPortalSessionMutation, { data, loading, error }] = useCreateAdminPlanPortalSessionMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      returnUrl: // value for 'returnUrl'
 *   },
 * });
 */
export function useCreateAdminPlanPortalSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdminPlanPortalSessionMutation, CreateAdminPlanPortalSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdminPlanPortalSessionMutation, CreateAdminPlanPortalSessionMutationVariables>(CreateAdminPlanPortalSessionDocument, options);
      }
export type CreateAdminPlanPortalSessionMutationHookResult = ReturnType<typeof useCreateAdminPlanPortalSessionMutation>;
export type CreateAdminPlanPortalSessionMutationResult = Apollo.MutationResult<CreateAdminPlanPortalSessionMutation>;
export type CreateAdminPlanPortalSessionMutationOptions = Apollo.BaseMutationOptions<CreateAdminPlanPortalSessionMutation, CreateAdminPlanPortalSessionMutationVariables>;
export const BillingDetailsDocument = gql`
    query BillingDetails {
  viewer {
    id
    username
    billingDetails {
      address
      name
      email
      cardBrand
      cardLast4
    }
  }
}
    `;

/**
 * __useBillingDetailsQuery__
 *
 * To run a query within a React component, call `useBillingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBillingDetailsQuery(baseOptions?: Apollo.QueryHookOptions<BillingDetailsQuery, BillingDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BillingDetailsQuery, BillingDetailsQueryVariables>(BillingDetailsDocument, options);
      }
export function useBillingDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillingDetailsQuery, BillingDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BillingDetailsQuery, BillingDetailsQueryVariables>(BillingDetailsDocument, options);
        }
export function useBillingDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BillingDetailsQuery, BillingDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BillingDetailsQuery, BillingDetailsQueryVariables>(BillingDetailsDocument, options);
        }
export type BillingDetailsQueryHookResult = ReturnType<typeof useBillingDetailsQuery>;
export type BillingDetailsLazyQueryHookResult = ReturnType<typeof useBillingDetailsLazyQuery>;
export type BillingDetailsSuspenseQueryHookResult = ReturnType<typeof useBillingDetailsSuspenseQuery>;
export type BillingDetailsQueryResult = Apollo.QueryResult<BillingDetailsQuery, BillingDetailsQueryVariables>;
export function refetchBillingDetailsQuery(variables?: BillingDetailsQueryVariables) {
      return { query: BillingDetailsDocument, variables: variables }
    }
export const CreateExpressDashboardSessionDocument = gql`
    mutation CreateExpressDashboardSession($organizationId: String!) {
  createExpressDashboardSession(organizationId: $organizationId) {
    url
  }
}
    `;
export type CreateExpressDashboardSessionMutationFn = Apollo.MutationFunction<CreateExpressDashboardSessionMutation, CreateExpressDashboardSessionMutationVariables>;

/**
 * __useCreateExpressDashboardSessionMutation__
 *
 * To run a mutation, you first call `useCreateExpressDashboardSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExpressDashboardSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExpressDashboardSessionMutation, { data, loading, error }] = useCreateExpressDashboardSessionMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCreateExpressDashboardSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateExpressDashboardSessionMutation, CreateExpressDashboardSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateExpressDashboardSessionMutation, CreateExpressDashboardSessionMutationVariables>(CreateExpressDashboardSessionDocument, options);
      }
export type CreateExpressDashboardSessionMutationHookResult = ReturnType<typeof useCreateExpressDashboardSessionMutation>;
export type CreateExpressDashboardSessionMutationResult = Apollo.MutationResult<CreateExpressDashboardSessionMutation>;
export type CreateExpressDashboardSessionMutationOptions = Apollo.BaseMutationOptions<CreateExpressDashboardSessionMutation, CreateExpressDashboardSessionMutationVariables>;
export const CreateMemberPlanPortalSessionDocument = gql`
    mutation CreateMemberPlanPortalSession($returnUrl: String!) {
  createMemberPlanPortalSession(returnUrl: $returnUrl) {
    url
  }
}
    `;
export type CreateMemberPlanPortalSessionMutationFn = Apollo.MutationFunction<CreateMemberPlanPortalSessionMutation, CreateMemberPlanPortalSessionMutationVariables>;

/**
 * __useCreateMemberPlanPortalSessionMutation__
 *
 * To run a mutation, you first call `useCreateMemberPlanPortalSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberPlanPortalSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberPlanPortalSessionMutation, { data, loading, error }] = useCreateMemberPlanPortalSessionMutation({
 *   variables: {
 *      returnUrl: // value for 'returnUrl'
 *   },
 * });
 */
export function useCreateMemberPlanPortalSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateMemberPlanPortalSessionMutation, CreateMemberPlanPortalSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMemberPlanPortalSessionMutation, CreateMemberPlanPortalSessionMutationVariables>(CreateMemberPlanPortalSessionDocument, options);
      }
export type CreateMemberPlanPortalSessionMutationHookResult = ReturnType<typeof useCreateMemberPlanPortalSessionMutation>;
export type CreateMemberPlanPortalSessionMutationResult = Apollo.MutationResult<CreateMemberPlanPortalSessionMutation>;
export type CreateMemberPlanPortalSessionMutationOptions = Apollo.BaseMutationOptions<CreateMemberPlanPortalSessionMutation, CreateMemberPlanPortalSessionMutationVariables>;
export const CreateStripeAccountLinkSessionDocument = gql`
    mutation CreateStripeAccountLinkSession($memberSubscriptionPlanId: String!) {
  createStripeAccountLinkSession(
    memberSubscriptionPlanId: $memberSubscriptionPlanId
  ) {
    url
  }
}
    `;
export type CreateStripeAccountLinkSessionMutationFn = Apollo.MutationFunction<CreateStripeAccountLinkSessionMutation, CreateStripeAccountLinkSessionMutationVariables>;

/**
 * __useCreateStripeAccountLinkSessionMutation__
 *
 * To run a mutation, you first call `useCreateStripeAccountLinkSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeAccountLinkSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeAccountLinkSessionMutation, { data, loading, error }] = useCreateStripeAccountLinkSessionMutation({
 *   variables: {
 *      memberSubscriptionPlanId: // value for 'memberSubscriptionPlanId'
 *   },
 * });
 */
export function useCreateStripeAccountLinkSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeAccountLinkSessionMutation, CreateStripeAccountLinkSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripeAccountLinkSessionMutation, CreateStripeAccountLinkSessionMutationVariables>(CreateStripeAccountLinkSessionDocument, options);
      }
export type CreateStripeAccountLinkSessionMutationHookResult = ReturnType<typeof useCreateStripeAccountLinkSessionMutation>;
export type CreateStripeAccountLinkSessionMutationResult = Apollo.MutationResult<CreateStripeAccountLinkSessionMutation>;
export type CreateStripeAccountLinkSessionMutationOptions = Apollo.BaseMutationOptions<CreateStripeAccountLinkSessionMutation, CreateStripeAccountLinkSessionMutationVariables>;
export const DeactivateMemberSubscriptionPlanDocument = gql`
    mutation DeactivateMemberSubscriptionPlan($memberSubscriptionPlanId: String!) {
  deactivateMemberSubscriptionPlan(
    memberSubscriptionPlanId: $memberSubscriptionPlanId
  ) {
    id
    active
  }
}
    `;
export type DeactivateMemberSubscriptionPlanMutationFn = Apollo.MutationFunction<DeactivateMemberSubscriptionPlanMutation, DeactivateMemberSubscriptionPlanMutationVariables>;

/**
 * __useDeactivateMemberSubscriptionPlanMutation__
 *
 * To run a mutation, you first call `useDeactivateMemberSubscriptionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateMemberSubscriptionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateMemberSubscriptionPlanMutation, { data, loading, error }] = useDeactivateMemberSubscriptionPlanMutation({
 *   variables: {
 *      memberSubscriptionPlanId: // value for 'memberSubscriptionPlanId'
 *   },
 * });
 */
export function useDeactivateMemberSubscriptionPlanMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateMemberSubscriptionPlanMutation, DeactivateMemberSubscriptionPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateMemberSubscriptionPlanMutation, DeactivateMemberSubscriptionPlanMutationVariables>(DeactivateMemberSubscriptionPlanDocument, options);
      }
export type DeactivateMemberSubscriptionPlanMutationHookResult = ReturnType<typeof useDeactivateMemberSubscriptionPlanMutation>;
export type DeactivateMemberSubscriptionPlanMutationResult = Apollo.MutationResult<DeactivateMemberSubscriptionPlanMutation>;
export type DeactivateMemberSubscriptionPlanMutationOptions = Apollo.BaseMutationOptions<DeactivateMemberSubscriptionPlanMutation, DeactivateMemberSubscriptionPlanMutationVariables>;
export const ReactivateMemberSubscriptionPlanDocument = gql`
    mutation ReactivateMemberSubscriptionPlan($memberSubscriptionPlanId: String!) {
  reactivateMemberSubscriptionPlan(
    memberSubscriptionPlanId: $memberSubscriptionPlanId
  ) {
    id
    active
  }
}
    `;
export type ReactivateMemberSubscriptionPlanMutationFn = Apollo.MutationFunction<ReactivateMemberSubscriptionPlanMutation, ReactivateMemberSubscriptionPlanMutationVariables>;

/**
 * __useReactivateMemberSubscriptionPlanMutation__
 *
 * To run a mutation, you first call `useReactivateMemberSubscriptionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateMemberSubscriptionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateMemberSubscriptionPlanMutation, { data, loading, error }] = useReactivateMemberSubscriptionPlanMutation({
 *   variables: {
 *      memberSubscriptionPlanId: // value for 'memberSubscriptionPlanId'
 *   },
 * });
 */
export function useReactivateMemberSubscriptionPlanMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateMemberSubscriptionPlanMutation, ReactivateMemberSubscriptionPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateMemberSubscriptionPlanMutation, ReactivateMemberSubscriptionPlanMutationVariables>(ReactivateMemberSubscriptionPlanDocument, options);
      }
export type ReactivateMemberSubscriptionPlanMutationHookResult = ReturnType<typeof useReactivateMemberSubscriptionPlanMutation>;
export type ReactivateMemberSubscriptionPlanMutationResult = Apollo.MutationResult<ReactivateMemberSubscriptionPlanMutation>;
export type ReactivateMemberSubscriptionPlanMutationOptions = Apollo.BaseMutationOptions<ReactivateMemberSubscriptionPlanMutation, ReactivateMemberSubscriptionPlanMutationVariables>;
export const OrganizationByInviteCodeDocument = gql`
    query OrganizationByInviteCode($inviteCode: ID!) {
  organizationByInviteCode(inviteCode: $inviteCode) {
    id
    shortId
    color
    name
    description
    logoId
    isSetup
    isGoogleWorkspace
    createdAt
    numMembers
    numGroups
    groupCreationPolicy
    joinPolicy
    addMemberPolicy
    enableNewMemberIntroductions
    newMemberIntroductionMessage
    isPublic
    widgetSizeVariant
    hasCoursesEnabled
    hasEventsEnabled
    hasInsightsEnabled
    templateType
  }
}
    `;

/**
 * __useOrganizationByInviteCodeQuery__
 *
 * To run a query within a React component, call `useOrganizationByInviteCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationByInviteCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationByInviteCodeQuery({
 *   variables: {
 *      inviteCode: // value for 'inviteCode'
 *   },
 * });
 */
export function useOrganizationByInviteCodeQuery(baseOptions: Apollo.QueryHookOptions<OrganizationByInviteCodeQuery, OrganizationByInviteCodeQueryVariables> & ({ variables: OrganizationByInviteCodeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationByInviteCodeQuery, OrganizationByInviteCodeQueryVariables>(OrganizationByInviteCodeDocument, options);
      }
export function useOrganizationByInviteCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationByInviteCodeQuery, OrganizationByInviteCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationByInviteCodeQuery, OrganizationByInviteCodeQueryVariables>(OrganizationByInviteCodeDocument, options);
        }
export function useOrganizationByInviteCodeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizationByInviteCodeQuery, OrganizationByInviteCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizationByInviteCodeQuery, OrganizationByInviteCodeQueryVariables>(OrganizationByInviteCodeDocument, options);
        }
export type OrganizationByInviteCodeQueryHookResult = ReturnType<typeof useOrganizationByInviteCodeQuery>;
export type OrganizationByInviteCodeLazyQueryHookResult = ReturnType<typeof useOrganizationByInviteCodeLazyQuery>;
export type OrganizationByInviteCodeSuspenseQueryHookResult = ReturnType<typeof useOrganizationByInviteCodeSuspenseQuery>;
export type OrganizationByInviteCodeQueryResult = Apollo.QueryResult<OrganizationByInviteCodeQuery, OrganizationByInviteCodeQueryVariables>;
export function refetchOrganizationByInviteCodeQuery(variables: OrganizationByInviteCodeQueryVariables) {
      return { query: OrganizationByInviteCodeDocument, variables: variables }
    }
export const MembersDocument = gql`
    query Members($organizationId: String!, $offset: Int, $limit: Int, $query: String, $orderBy: MembersOrderByInput) {
  members(
    organizationId: $organizationId
    offset: $offset
    limit: $limit
    query: $query
    orderBy: $orderBy
  ) {
    items {
      id
      active
      isCreator
      createdAt
      email
      roles {
        id
        label
        color
        isAdmin
        memberSubscriptionPlan {
          id
          name
        }
      }
      user {
        id
        name
        firstName
        username
        avatarBgColor
        avatar
        profileImageId
        memberSubscriptions {
          id
          subscriptionPlan {
            id
          }
        }
      }
    }
    totalCount
  }
}
    `;

/**
 * __useMembersQuery__
 *
 * To run a query within a React component, call `useMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      query: // value for 'query'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useMembersQuery(baseOptions: Apollo.QueryHookOptions<MembersQuery, MembersQueryVariables> & ({ variables: MembersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembersQuery, MembersQueryVariables>(MembersDocument, options);
      }
export function useMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembersQuery, MembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembersQuery, MembersQueryVariables>(MembersDocument, options);
        }
export function useMembersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MembersQuery, MembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MembersQuery, MembersQueryVariables>(MembersDocument, options);
        }
export type MembersQueryHookResult = ReturnType<typeof useMembersQuery>;
export type MembersLazyQueryHookResult = ReturnType<typeof useMembersLazyQuery>;
export type MembersSuspenseQueryHookResult = ReturnType<typeof useMembersSuspenseQuery>;
export type MembersQueryResult = Apollo.QueryResult<MembersQuery, MembersQueryVariables>;
export function refetchMembersQuery(variables: MembersQueryVariables) {
      return { query: MembersDocument, variables: variables }
    }
export const SuspendUserDocument = gql`
    mutation SuspendUser($input: SuspendUserInput!) {
  suspendUser(input: $input) {
    id
    username
  }
}
    `;
export type SuspendUserMutationFn = Apollo.MutationFunction<SuspendUserMutation, SuspendUserMutationVariables>;

/**
 * __useSuspendUserMutation__
 *
 * To run a mutation, you first call `useSuspendUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuspendUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suspendUserMutation, { data, loading, error }] = useSuspendUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSuspendUserMutation(baseOptions?: Apollo.MutationHookOptions<SuspendUserMutation, SuspendUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SuspendUserMutation, SuspendUserMutationVariables>(SuspendUserDocument, options);
      }
export type SuspendUserMutationHookResult = ReturnType<typeof useSuspendUserMutation>;
export type SuspendUserMutationResult = Apollo.MutationResult<SuspendUserMutation>;
export type SuspendUserMutationOptions = Apollo.BaseMutationOptions<SuspendUserMutation, SuspendUserMutationVariables>;
export const DemoteUserDocument = gql`
    mutation DemoteUser($input: DemoteUserInput!) {
  demoteUser(input: $input) {
    id
    username
  }
}
    `;
export type DemoteUserMutationFn = Apollo.MutationFunction<DemoteUserMutation, DemoteUserMutationVariables>;

/**
 * __useDemoteUserMutation__
 *
 * To run a mutation, you first call `useDemoteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDemoteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [demoteUserMutation, { data, loading, error }] = useDemoteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDemoteUserMutation(baseOptions?: Apollo.MutationHookOptions<DemoteUserMutation, DemoteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DemoteUserMutation, DemoteUserMutationVariables>(DemoteUserDocument, options);
      }
export type DemoteUserMutationHookResult = ReturnType<typeof useDemoteUserMutation>;
export type DemoteUserMutationResult = Apollo.MutationResult<DemoteUserMutation>;
export type DemoteUserMutationOptions = Apollo.BaseMutationOptions<DemoteUserMutation, DemoteUserMutationVariables>;
export const LeaveOrganizationDocument = gql`
    mutation LeaveOrganization($input: LeaveOrganizationInput!) {
  leaveOrganization(input: $input) {
    id
    username
  }
}
    `;
export type LeaveOrganizationMutationFn = Apollo.MutationFunction<LeaveOrganizationMutation, LeaveOrganizationMutationVariables>;

/**
 * __useLeaveOrganizationMutation__
 *
 * To run a mutation, you first call `useLeaveOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveOrganizationMutation, { data, loading, error }] = useLeaveOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeaveOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<LeaveOrganizationMutation, LeaveOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeaveOrganizationMutation, LeaveOrganizationMutationVariables>(LeaveOrganizationDocument, options);
      }
export type LeaveOrganizationMutationHookResult = ReturnType<typeof useLeaveOrganizationMutation>;
export type LeaveOrganizationMutationResult = Apollo.MutationResult<LeaveOrganizationMutation>;
export type LeaveOrganizationMutationOptions = Apollo.BaseMutationOptions<LeaveOrganizationMutation, LeaveOrganizationMutationVariables>;
export const PromoteUserDocument = gql`
    mutation PromoteUser($input: PromoteUserInput!) {
  promoteUser(input: $input) {
    id
    username
  }
}
    `;
export type PromoteUserMutationFn = Apollo.MutationFunction<PromoteUserMutation, PromoteUserMutationVariables>;

/**
 * __usePromoteUserMutation__
 *
 * To run a mutation, you first call `usePromoteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePromoteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [promoteUserMutation, { data, loading, error }] = usePromoteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePromoteUserMutation(baseOptions?: Apollo.MutationHookOptions<PromoteUserMutation, PromoteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PromoteUserMutation, PromoteUserMutationVariables>(PromoteUserDocument, options);
      }
export type PromoteUserMutationHookResult = ReturnType<typeof usePromoteUserMutation>;
export type PromoteUserMutationResult = Apollo.MutationResult<PromoteUserMutation>;
export type PromoteUserMutationOptions = Apollo.BaseMutationOptions<PromoteUserMutation, PromoteUserMutationVariables>;
export const AssignRoleDocument = gql`
    mutation AssignRole($input: AssignRoleInput!) {
  assignRole(input: $input) {
    id
    username
  }
}
    `;
export type AssignRoleMutationFn = Apollo.MutationFunction<AssignRoleMutation, AssignRoleMutationVariables>;

/**
 * __useAssignRoleMutation__
 *
 * To run a mutation, you first call `useAssignRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignRoleMutation, { data, loading, error }] = useAssignRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignRoleMutation(baseOptions?: Apollo.MutationHookOptions<AssignRoleMutation, AssignRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignRoleMutation, AssignRoleMutationVariables>(AssignRoleDocument, options);
      }
export type AssignRoleMutationHookResult = ReturnType<typeof useAssignRoleMutation>;
export type AssignRoleMutationResult = Apollo.MutationResult<AssignRoleMutation>;
export type AssignRoleMutationOptions = Apollo.BaseMutationOptions<AssignRoleMutation, AssignRoleMutationVariables>;
export const UnassignRoleDocument = gql`
    mutation UnassignRole($input: UnassignRoleInput!) {
  unassignRole(input: $input) {
    id
    username
  }
}
    `;
export type UnassignRoleMutationFn = Apollo.MutationFunction<UnassignRoleMutation, UnassignRoleMutationVariables>;

/**
 * __useUnassignRoleMutation__
 *
 * To run a mutation, you first call `useUnassignRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignRoleMutation, { data, loading, error }] = useUnassignRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnassignRoleMutation(baseOptions?: Apollo.MutationHookOptions<UnassignRoleMutation, UnassignRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnassignRoleMutation, UnassignRoleMutationVariables>(UnassignRoleDocument, options);
      }
export type UnassignRoleMutationHookResult = ReturnType<typeof useUnassignRoleMutation>;
export type UnassignRoleMutationResult = Apollo.MutationResult<UnassignRoleMutation>;
export type UnassignRoleMutationOptions = Apollo.BaseMutationOptions<UnassignRoleMutation, UnassignRoleMutationVariables>;
export const InviteUsersDocument = gql`
    mutation InviteUsers($input: InviteUsersInput!) {
  inviteUsers(input: $input) {
    __typename
    ... on UserInvite {
      id
      email
      inviteCode
    }
    ... on RequestToJoin {
      id
    }
    ... on RequestToJoinError {
      message
    }
  }
}
    `;
export type InviteUsersMutationFn = Apollo.MutationFunction<InviteUsersMutation, InviteUsersMutationVariables>;

/**
 * __useInviteUsersMutation__
 *
 * To run a mutation, you first call `useInviteUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUsersMutation, { data, loading, error }] = useInviteUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUsersMutation(baseOptions?: Apollo.MutationHookOptions<InviteUsersMutation, InviteUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUsersMutation, InviteUsersMutationVariables>(InviteUsersDocument, options);
      }
export type InviteUsersMutationHookResult = ReturnType<typeof useInviteUsersMutation>;
export type InviteUsersMutationResult = Apollo.MutationResult<InviteUsersMutation>;
export type InviteUsersMutationOptions = Apollo.BaseMutationOptions<InviteUsersMutation, InviteUsersMutationVariables>;
export const RevokeUserInvitesDocument = gql`
    mutation RevokeUserInvites($input: RevokeUserInvitesInput!) {
  revokeUserInvites(input: $input) {
    id
    email
  }
}
    `;
export type RevokeUserInvitesMutationFn = Apollo.MutationFunction<RevokeUserInvitesMutation, RevokeUserInvitesMutationVariables>;

/**
 * __useRevokeUserInvitesMutation__
 *
 * To run a mutation, you first call `useRevokeUserInvitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeUserInvitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeUserInvitesMutation, { data, loading, error }] = useRevokeUserInvitesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevokeUserInvitesMutation(baseOptions?: Apollo.MutationHookOptions<RevokeUserInvitesMutation, RevokeUserInvitesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeUserInvitesMutation, RevokeUserInvitesMutationVariables>(RevokeUserInvitesDocument, options);
      }
export type RevokeUserInvitesMutationHookResult = ReturnType<typeof useRevokeUserInvitesMutation>;
export type RevokeUserInvitesMutationResult = Apollo.MutationResult<RevokeUserInvitesMutation>;
export type RevokeUserInvitesMutationOptions = Apollo.BaseMutationOptions<RevokeUserInvitesMutation, RevokeUserInvitesMutationVariables>;
export const ResendUserInviteDocument = gql`
    mutation ResendUserInvite($input: ResendUserInviteInput!) {
  resendUserInvite(input: $input) {
    id
    email
  }
}
    `;
export type ResendUserInviteMutationFn = Apollo.MutationFunction<ResendUserInviteMutation, ResendUserInviteMutationVariables>;

/**
 * __useResendUserInviteMutation__
 *
 * To run a mutation, you first call `useResendUserInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendUserInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendUserInviteMutation, { data, loading, error }] = useResendUserInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendUserInviteMutation(baseOptions?: Apollo.MutationHookOptions<ResendUserInviteMutation, ResendUserInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendUserInviteMutation, ResendUserInviteMutationVariables>(ResendUserInviteDocument, options);
      }
export type ResendUserInviteMutationHookResult = ReturnType<typeof useResendUserInviteMutation>;
export type ResendUserInviteMutationResult = Apollo.MutationResult<ResendUserInviteMutation>;
export type ResendUserInviteMutationOptions = Apollo.BaseMutationOptions<ResendUserInviteMutation, ResendUserInviteMutationVariables>;
export const ResetOrganizationInviteDocument = gql`
    mutation ResetOrganizationInvite($input: ResetOrganizationInviteInput!) {
  resetOrganizationInvite(input: $input) {
    id
  }
}
    `;
export type ResetOrganizationInviteMutationFn = Apollo.MutationFunction<ResetOrganizationInviteMutation, ResetOrganizationInviteMutationVariables>;

/**
 * __useResetOrganizationInviteMutation__
 *
 * To run a mutation, you first call `useResetOrganizationInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetOrganizationInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetOrganizationInviteMutation, { data, loading, error }] = useResetOrganizationInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetOrganizationInviteMutation(baseOptions?: Apollo.MutationHookOptions<ResetOrganizationInviteMutation, ResetOrganizationInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetOrganizationInviteMutation, ResetOrganizationInviteMutationVariables>(ResetOrganizationInviteDocument, options);
      }
export type ResetOrganizationInviteMutationHookResult = ReturnType<typeof useResetOrganizationInviteMutation>;
export type ResetOrganizationInviteMutationResult = Apollo.MutationResult<ResetOrganizationInviteMutation>;
export type ResetOrganizationInviteMutationOptions = Apollo.BaseMutationOptions<ResetOrganizationInviteMutation, ResetOrganizationInviteMutationVariables>;
export const RestoreUserDocument = gql`
    mutation RestoreUser($input: RestoreUserInput!) {
  restoreUser(input: $input) {
    id
    username
  }
}
    `;
export type RestoreUserMutationFn = Apollo.MutationFunction<RestoreUserMutation, RestoreUserMutationVariables>;

/**
 * __useRestoreUserMutation__
 *
 * To run a mutation, you first call `useRestoreUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreUserMutation, { data, loading, error }] = useRestoreUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreUserMutation(baseOptions?: Apollo.MutationHookOptions<RestoreUserMutation, RestoreUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreUserMutation, RestoreUserMutationVariables>(RestoreUserDocument, options);
      }
export type RestoreUserMutationHookResult = ReturnType<typeof useRestoreUserMutation>;
export type RestoreUserMutationResult = Apollo.MutationResult<RestoreUserMutation>;
export type RestoreUserMutationOptions = Apollo.BaseMutationOptions<RestoreUserMutation, RestoreUserMutationVariables>;
export const NotificationPreferencesDocument = gql`
    query NotificationPreferences($userId: ID!) {
  notificationPreferences(userId: $userId) {
    id
    type
    allowNewPostNotifications
    allowCommentNotifications
    allowMentionNotifications
    allowReactionNotifications
    allowReminderNotifications
    allowPollNotifications
    allowGroupNotifications
    allowAccountNotifications
    allowNewMemberRequestNotifications
    allowUserJoinedNotifications
    allowDirectMessageNotifications
  }
}
    `;

/**
 * __useNotificationPreferencesQuery__
 *
 * To run a query within a React component, call `useNotificationPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationPreferencesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useNotificationPreferencesQuery(baseOptions: Apollo.QueryHookOptions<NotificationPreferencesQuery, NotificationPreferencesQueryVariables> & ({ variables: NotificationPreferencesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationPreferencesQuery, NotificationPreferencesQueryVariables>(NotificationPreferencesDocument, options);
      }
export function useNotificationPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationPreferencesQuery, NotificationPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationPreferencesQuery, NotificationPreferencesQueryVariables>(NotificationPreferencesDocument, options);
        }
export function useNotificationPreferencesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NotificationPreferencesQuery, NotificationPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NotificationPreferencesQuery, NotificationPreferencesQueryVariables>(NotificationPreferencesDocument, options);
        }
export type NotificationPreferencesQueryHookResult = ReturnType<typeof useNotificationPreferencesQuery>;
export type NotificationPreferencesLazyQueryHookResult = ReturnType<typeof useNotificationPreferencesLazyQuery>;
export type NotificationPreferencesSuspenseQueryHookResult = ReturnType<typeof useNotificationPreferencesSuspenseQuery>;
export type NotificationPreferencesQueryResult = Apollo.QueryResult<NotificationPreferencesQuery, NotificationPreferencesQueryVariables>;
export function refetchNotificationPreferencesQuery(variables: NotificationPreferencesQueryVariables) {
      return { query: NotificationPreferencesDocument, variables: variables }
    }
export const CreateUserWebPushSubscriptionDocument = gql`
    mutation CreateUserWebPushSubscription($input: CreateUserWebPushSubscriptionInput!) {
  createUserWebPushSubscription(input: $input) {
    id
    username
  }
}
    `;
export type CreateUserWebPushSubscriptionMutationFn = Apollo.MutationFunction<CreateUserWebPushSubscriptionMutation, CreateUserWebPushSubscriptionMutationVariables>;

/**
 * __useCreateUserWebPushSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateUserWebPushSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserWebPushSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserWebPushSubscriptionMutation, { data, loading, error }] = useCreateUserWebPushSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserWebPushSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserWebPushSubscriptionMutation, CreateUserWebPushSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserWebPushSubscriptionMutation, CreateUserWebPushSubscriptionMutationVariables>(CreateUserWebPushSubscriptionDocument, options);
      }
export type CreateUserWebPushSubscriptionMutationHookResult = ReturnType<typeof useCreateUserWebPushSubscriptionMutation>;
export type CreateUserWebPushSubscriptionMutationResult = Apollo.MutationResult<CreateUserWebPushSubscriptionMutation>;
export type CreateUserWebPushSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateUserWebPushSubscriptionMutation, CreateUserWebPushSubscriptionMutationVariables>;
export const UpdateUserNotificationPreferencesDocument = gql`
    mutation UpdateUserNotificationPreferences($input: UpdateUserNotificationPreferencesInput!) {
  updateUserNotificationPreferences(input: $input) {
    id
    type
    allowNewPostNotifications
    allowCommentNotifications
    allowMentionNotifications
    allowReactionNotifications
    allowReminderNotifications
    allowPollNotifications
    allowGroupNotifications
    allowAccountNotifications
    allowNewMemberRequestNotifications
    allowUserJoinedNotifications
    allowDirectMessageNotifications
  }
}
    `;
export type UpdateUserNotificationPreferencesMutationFn = Apollo.MutationFunction<UpdateUserNotificationPreferencesMutation, UpdateUserNotificationPreferencesMutationVariables>;

/**
 * __useUpdateUserNotificationPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateUserNotificationPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserNotificationPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserNotificationPreferencesMutation, { data, loading, error }] = useUpdateUserNotificationPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserNotificationPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserNotificationPreferencesMutation, UpdateUserNotificationPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserNotificationPreferencesMutation, UpdateUserNotificationPreferencesMutationVariables>(UpdateUserNotificationPreferencesDocument, options);
      }
export type UpdateUserNotificationPreferencesMutationHookResult = ReturnType<typeof useUpdateUserNotificationPreferencesMutation>;
export type UpdateUserNotificationPreferencesMutationResult = Apollo.MutationResult<UpdateUserNotificationPreferencesMutation>;
export type UpdateUserNotificationPreferencesMutationOptions = Apollo.BaseMutationOptions<UpdateUserNotificationPreferencesMutation, UpdateUserNotificationPreferencesMutationVariables>;
export const UpdateNewMemberIntroductionMessageDocument = gql`
    mutation UpdateNewMemberIntroductionMessage($input: UpdateNewMemberIntroductionMessageInput!) {
  updateNewMemberIntroductionMessage(input: $input) {
    id
    newMemberIntroductionMessage
  }
}
    `;
export type UpdateNewMemberIntroductionMessageMutationFn = Apollo.MutationFunction<UpdateNewMemberIntroductionMessageMutation, UpdateNewMemberIntroductionMessageMutationVariables>;

/**
 * __useUpdateNewMemberIntroductionMessageMutation__
 *
 * To run a mutation, you first call `useUpdateNewMemberIntroductionMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNewMemberIntroductionMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNewMemberIntroductionMessageMutation, { data, loading, error }] = useUpdateNewMemberIntroductionMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNewMemberIntroductionMessageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNewMemberIntroductionMessageMutation, UpdateNewMemberIntroductionMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNewMemberIntroductionMessageMutation, UpdateNewMemberIntroductionMessageMutationVariables>(UpdateNewMemberIntroductionMessageDocument, options);
      }
export type UpdateNewMemberIntroductionMessageMutationHookResult = ReturnType<typeof useUpdateNewMemberIntroductionMessageMutation>;
export type UpdateNewMemberIntroductionMessageMutationResult = Apollo.MutationResult<UpdateNewMemberIntroductionMessageMutation>;
export type UpdateNewMemberIntroductionMessageMutationOptions = Apollo.BaseMutationOptions<UpdateNewMemberIntroductionMessageMutation, UpdateNewMemberIntroductionMessageMutationVariables>;
export const ToggleNewMemberIntroductionsDocument = gql`
    mutation ToggleNewMemberIntroductions($input: ToggleNewMemberIntroductionsInput!) {
  toggleNewMemberIntroductions(input: $input) {
    id
    enableNewMemberIntroductions
  }
}
    `;
export type ToggleNewMemberIntroductionsMutationFn = Apollo.MutationFunction<ToggleNewMemberIntroductionsMutation, ToggleNewMemberIntroductionsMutationVariables>;

/**
 * __useToggleNewMemberIntroductionsMutation__
 *
 * To run a mutation, you first call `useToggleNewMemberIntroductionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleNewMemberIntroductionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleNewMemberIntroductionsMutation, { data, loading, error }] = useToggleNewMemberIntroductionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleNewMemberIntroductionsMutation(baseOptions?: Apollo.MutationHookOptions<ToggleNewMemberIntroductionsMutation, ToggleNewMemberIntroductionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleNewMemberIntroductionsMutation, ToggleNewMemberIntroductionsMutationVariables>(ToggleNewMemberIntroductionsDocument, options);
      }
export type ToggleNewMemberIntroductionsMutationHookResult = ReturnType<typeof useToggleNewMemberIntroductionsMutation>;
export type ToggleNewMemberIntroductionsMutationResult = Apollo.MutationResult<ToggleNewMemberIntroductionsMutation>;
export type ToggleNewMemberIntroductionsMutationOptions = Apollo.BaseMutationOptions<ToggleNewMemberIntroductionsMutation, ToggleNewMemberIntroductionsMutationVariables>;
export const ToggleAutoMessageNewMembersDocument = gql`
    mutation ToggleAutoMessageNewMembers($input: ToggleAutoMessageNewMembersInput!) {
  toggleAutoMessageNewMembers(input: $input) {
    id
    enableAutoMessageNewMembers
    autoMessageNewMembersFromId
  }
}
    `;
export type ToggleAutoMessageNewMembersMutationFn = Apollo.MutationFunction<ToggleAutoMessageNewMembersMutation, ToggleAutoMessageNewMembersMutationVariables>;

/**
 * __useToggleAutoMessageNewMembersMutation__
 *
 * To run a mutation, you first call `useToggleAutoMessageNewMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleAutoMessageNewMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleAutoMessageNewMembersMutation, { data, loading, error }] = useToggleAutoMessageNewMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleAutoMessageNewMembersMutation(baseOptions?: Apollo.MutationHookOptions<ToggleAutoMessageNewMembersMutation, ToggleAutoMessageNewMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleAutoMessageNewMembersMutation, ToggleAutoMessageNewMembersMutationVariables>(ToggleAutoMessageNewMembersDocument, options);
      }
export type ToggleAutoMessageNewMembersMutationHookResult = ReturnType<typeof useToggleAutoMessageNewMembersMutation>;
export type ToggleAutoMessageNewMembersMutationResult = Apollo.MutationResult<ToggleAutoMessageNewMembersMutation>;
export type ToggleAutoMessageNewMembersMutationOptions = Apollo.BaseMutationOptions<ToggleAutoMessageNewMembersMutation, ToggleAutoMessageNewMembersMutationVariables>;
export const UpdateAutoMessageNewMembersMessageDocument = gql`
    mutation UpdateAutoMessageNewMembersMessage($input: UpdateAutoMessageNewMembersMessageInput!) {
  updateAutoMessageNewMembersMessage(input: $input) {
    id
    autoMessageNewMembersMessage
    autoMessageNewMembersFromId
  }
}
    `;
export type UpdateAutoMessageNewMembersMessageMutationFn = Apollo.MutationFunction<UpdateAutoMessageNewMembersMessageMutation, UpdateAutoMessageNewMembersMessageMutationVariables>;

/**
 * __useUpdateAutoMessageNewMembersMessageMutation__
 *
 * To run a mutation, you first call `useUpdateAutoMessageNewMembersMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAutoMessageNewMembersMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAutoMessageNewMembersMessageMutation, { data, loading, error }] = useUpdateAutoMessageNewMembersMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAutoMessageNewMembersMessageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAutoMessageNewMembersMessageMutation, UpdateAutoMessageNewMembersMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAutoMessageNewMembersMessageMutation, UpdateAutoMessageNewMembersMessageMutationVariables>(UpdateAutoMessageNewMembersMessageDocument, options);
      }
export type UpdateAutoMessageNewMembersMessageMutationHookResult = ReturnType<typeof useUpdateAutoMessageNewMembersMessageMutation>;
export type UpdateAutoMessageNewMembersMessageMutationResult = Apollo.MutationResult<UpdateAutoMessageNewMembersMessageMutation>;
export type UpdateAutoMessageNewMembersMessageMutationOptions = Apollo.BaseMutationOptions<UpdateAutoMessageNewMembersMessageMutation, UpdateAutoMessageNewMembersMessageMutationVariables>;
export const TogglePublicCommunityDocument = gql`
    mutation TogglePublicCommunity($input: TogglePublicCommunityInput!) {
  togglePublicCommunity(input: $input) {
    id
    isPublic
    joinPolicy
    addMemberPolicy
  }
}
    `;
export type TogglePublicCommunityMutationFn = Apollo.MutationFunction<TogglePublicCommunityMutation, TogglePublicCommunityMutationVariables>;

/**
 * __useTogglePublicCommunityMutation__
 *
 * To run a mutation, you first call `useTogglePublicCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTogglePublicCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [togglePublicCommunityMutation, { data, loading, error }] = useTogglePublicCommunityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTogglePublicCommunityMutation(baseOptions?: Apollo.MutationHookOptions<TogglePublicCommunityMutation, TogglePublicCommunityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TogglePublicCommunityMutation, TogglePublicCommunityMutationVariables>(TogglePublicCommunityDocument, options);
      }
export type TogglePublicCommunityMutationHookResult = ReturnType<typeof useTogglePublicCommunityMutation>;
export type TogglePublicCommunityMutationResult = Apollo.MutationResult<TogglePublicCommunityMutation>;
export type TogglePublicCommunityMutationOptions = Apollo.BaseMutationOptions<TogglePublicCommunityMutation, TogglePublicCommunityMutationVariables>;
export const CheckIfWidgetIsDetectedDocument = gql`
    query CheckIfWidgetIsDetected($organizationId: ID!) {
  checkIfWidgetIsDetected(organizationId: $organizationId) {
    id
    url
  }
}
    `;

/**
 * __useCheckIfWidgetIsDetectedQuery__
 *
 * To run a query within a React component, call `useCheckIfWidgetIsDetectedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckIfWidgetIsDetectedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckIfWidgetIsDetectedQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCheckIfWidgetIsDetectedQuery(baseOptions: Apollo.QueryHookOptions<CheckIfWidgetIsDetectedQuery, CheckIfWidgetIsDetectedQueryVariables> & ({ variables: CheckIfWidgetIsDetectedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckIfWidgetIsDetectedQuery, CheckIfWidgetIsDetectedQueryVariables>(CheckIfWidgetIsDetectedDocument, options);
      }
export function useCheckIfWidgetIsDetectedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckIfWidgetIsDetectedQuery, CheckIfWidgetIsDetectedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckIfWidgetIsDetectedQuery, CheckIfWidgetIsDetectedQueryVariables>(CheckIfWidgetIsDetectedDocument, options);
        }
export function useCheckIfWidgetIsDetectedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CheckIfWidgetIsDetectedQuery, CheckIfWidgetIsDetectedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckIfWidgetIsDetectedQuery, CheckIfWidgetIsDetectedQueryVariables>(CheckIfWidgetIsDetectedDocument, options);
        }
export type CheckIfWidgetIsDetectedQueryHookResult = ReturnType<typeof useCheckIfWidgetIsDetectedQuery>;
export type CheckIfWidgetIsDetectedLazyQueryHookResult = ReturnType<typeof useCheckIfWidgetIsDetectedLazyQuery>;
export type CheckIfWidgetIsDetectedSuspenseQueryHookResult = ReturnType<typeof useCheckIfWidgetIsDetectedSuspenseQuery>;
export type CheckIfWidgetIsDetectedQueryResult = Apollo.QueryResult<CheckIfWidgetIsDetectedQuery, CheckIfWidgetIsDetectedQueryVariables>;
export function refetchCheckIfWidgetIsDetectedQuery(variables: CheckIfWidgetIsDetectedQueryVariables) {
      return { query: CheckIfWidgetIsDetectedDocument, variables: variables }
    }
export const UpdateWidgetSizeVariantDocument = gql`
    mutation UpdateWidgetSizeVariant($input: UpdateWidgetSizeVariantInput!) {
  updateWidgetSizeVariant(input: $input) {
    id
    widgetSizeVariant
  }
}
    `;
export type UpdateWidgetSizeVariantMutationFn = Apollo.MutationFunction<UpdateWidgetSizeVariantMutation, UpdateWidgetSizeVariantMutationVariables>;

/**
 * __useUpdateWidgetSizeVariantMutation__
 *
 * To run a mutation, you first call `useUpdateWidgetSizeVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWidgetSizeVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWidgetSizeVariantMutation, { data, loading, error }] = useUpdateWidgetSizeVariantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWidgetSizeVariantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWidgetSizeVariantMutation, UpdateWidgetSizeVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWidgetSizeVariantMutation, UpdateWidgetSizeVariantMutationVariables>(UpdateWidgetSizeVariantDocument, options);
      }
export type UpdateWidgetSizeVariantMutationHookResult = ReturnType<typeof useUpdateWidgetSizeVariantMutation>;
export type UpdateWidgetSizeVariantMutationResult = Apollo.MutationResult<UpdateWidgetSizeVariantMutation>;
export type UpdateWidgetSizeVariantMutationOptions = Apollo.BaseMutationOptions<UpdateWidgetSizeVariantMutation, UpdateWidgetSizeVariantMutationVariables>;
export const DismissFrondIntroPostDocument = gql`
    mutation DismissFrondIntroPost($input: DismissFrondIntroPostInput!) {
  dismissFrondIntroPost(input: $input) {
    id
    hideFrondIntroPost
  }
}
    `;
export type DismissFrondIntroPostMutationFn = Apollo.MutationFunction<DismissFrondIntroPostMutation, DismissFrondIntroPostMutationVariables>;

/**
 * __useDismissFrondIntroPostMutation__
 *
 * To run a mutation, you first call `useDismissFrondIntroPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissFrondIntroPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissFrondIntroPostMutation, { data, loading, error }] = useDismissFrondIntroPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDismissFrondIntroPostMutation(baseOptions?: Apollo.MutationHookOptions<DismissFrondIntroPostMutation, DismissFrondIntroPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DismissFrondIntroPostMutation, DismissFrondIntroPostMutationVariables>(DismissFrondIntroPostDocument, options);
      }
export type DismissFrondIntroPostMutationHookResult = ReturnType<typeof useDismissFrondIntroPostMutation>;
export type DismissFrondIntroPostMutationResult = Apollo.MutationResult<DismissFrondIntroPostMutation>;
export type DismissFrondIntroPostMutationOptions = Apollo.BaseMutationOptions<DismissFrondIntroPostMutation, DismissFrondIntroPostMutationVariables>;
export const ToggleOrganizationFeatureDocument = gql`
    mutation ToggleOrganizationFeature($input: ToggleOrganizationFeatureInput!) {
  toggleOrganizationFeature(input: $input) {
    id
    hasCoursesEnabled
    hasEventsEnabled
    hasInsightsEnabled
  }
}
    `;
export type ToggleOrganizationFeatureMutationFn = Apollo.MutationFunction<ToggleOrganizationFeatureMutation, ToggleOrganizationFeatureMutationVariables>;

/**
 * __useToggleOrganizationFeatureMutation__
 *
 * To run a mutation, you first call `useToggleOrganizationFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleOrganizationFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleOrganizationFeatureMutation, { data, loading, error }] = useToggleOrganizationFeatureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleOrganizationFeatureMutation(baseOptions?: Apollo.MutationHookOptions<ToggleOrganizationFeatureMutation, ToggleOrganizationFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleOrganizationFeatureMutation, ToggleOrganizationFeatureMutationVariables>(ToggleOrganizationFeatureDocument, options);
      }
export type ToggleOrganizationFeatureMutationHookResult = ReturnType<typeof useToggleOrganizationFeatureMutation>;
export type ToggleOrganizationFeatureMutationResult = Apollo.MutationResult<ToggleOrganizationFeatureMutation>;
export type ToggleOrganizationFeatureMutationOptions = Apollo.BaseMutationOptions<ToggleOrganizationFeatureMutation, ToggleOrganizationFeatureMutationVariables>;
export const CreateVideoPresignedUploadDocument = gql`
    mutation CreateVideoPresignedUpload($input: CreateVideoPresignedUploadInput!) {
  createVideoPresignedUpload(input: $input) {
    url
    id
  }
}
    `;
export type CreateVideoPresignedUploadMutationFn = Apollo.MutationFunction<CreateVideoPresignedUploadMutation, CreateVideoPresignedUploadMutationVariables>;

/**
 * __useCreateVideoPresignedUploadMutation__
 *
 * To run a mutation, you first call `useCreateVideoPresignedUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVideoPresignedUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVideoPresignedUploadMutation, { data, loading, error }] = useCreateVideoPresignedUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVideoPresignedUploadMutation(baseOptions?: Apollo.MutationHookOptions<CreateVideoPresignedUploadMutation, CreateVideoPresignedUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVideoPresignedUploadMutation, CreateVideoPresignedUploadMutationVariables>(CreateVideoPresignedUploadDocument, options);
      }
export type CreateVideoPresignedUploadMutationHookResult = ReturnType<typeof useCreateVideoPresignedUploadMutation>;
export type CreateVideoPresignedUploadMutationResult = Apollo.MutationResult<CreateVideoPresignedUploadMutation>;
export type CreateVideoPresignedUploadMutationOptions = Apollo.BaseMutationOptions<CreateVideoPresignedUploadMutation, CreateVideoPresignedUploadMutationVariables>;
export const CreateTemporaryVideoPresignedUploadDocument = gql`
    mutation CreateTemporaryVideoPresignedUpload($input: CreateTemporaryVideoPresignedUploadInput!) {
  createTemporaryVideoPresignedUpload(input: $input) {
    url
    id
  }
}
    `;
export type CreateTemporaryVideoPresignedUploadMutationFn = Apollo.MutationFunction<CreateTemporaryVideoPresignedUploadMutation, CreateTemporaryVideoPresignedUploadMutationVariables>;

/**
 * __useCreateTemporaryVideoPresignedUploadMutation__
 *
 * To run a mutation, you first call `useCreateTemporaryVideoPresignedUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemporaryVideoPresignedUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemporaryVideoPresignedUploadMutation, { data, loading, error }] = useCreateTemporaryVideoPresignedUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTemporaryVideoPresignedUploadMutation(baseOptions?: Apollo.MutationHookOptions<CreateTemporaryVideoPresignedUploadMutation, CreateTemporaryVideoPresignedUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTemporaryVideoPresignedUploadMutation, CreateTemporaryVideoPresignedUploadMutationVariables>(CreateTemporaryVideoPresignedUploadDocument, options);
      }
export type CreateTemporaryVideoPresignedUploadMutationHookResult = ReturnType<typeof useCreateTemporaryVideoPresignedUploadMutation>;
export type CreateTemporaryVideoPresignedUploadMutationResult = Apollo.MutationResult<CreateTemporaryVideoPresignedUploadMutation>;
export type CreateTemporaryVideoPresignedUploadMutationOptions = Apollo.BaseMutationOptions<CreateTemporaryVideoPresignedUploadMutation, CreateTemporaryVideoPresignedUploadMutationVariables>;
export const VideoStatusDocument = gql`
    query VideoStatus($videoId: ID!) {
  video(id: $videoId) {
    id
    aspect
    playbackId
    previewPlaybackId
    previewStatus
    mirror
    status
    type
    assetId
    createdAt
  }
}
    `;

/**
 * __useVideoStatusQuery__
 *
 * To run a query within a React component, call `useVideoStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoStatusQuery({
 *   variables: {
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function useVideoStatusQuery(baseOptions: Apollo.QueryHookOptions<VideoStatusQuery, VideoStatusQueryVariables> & ({ variables: VideoStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VideoStatusQuery, VideoStatusQueryVariables>(VideoStatusDocument, options);
      }
export function useVideoStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideoStatusQuery, VideoStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VideoStatusQuery, VideoStatusQueryVariables>(VideoStatusDocument, options);
        }
export function useVideoStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VideoStatusQuery, VideoStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VideoStatusQuery, VideoStatusQueryVariables>(VideoStatusDocument, options);
        }
export type VideoStatusQueryHookResult = ReturnType<typeof useVideoStatusQuery>;
export type VideoStatusLazyQueryHookResult = ReturnType<typeof useVideoStatusLazyQuery>;
export type VideoStatusSuspenseQueryHookResult = ReturnType<typeof useVideoStatusSuspenseQuery>;
export type VideoStatusQueryResult = Apollo.QueryResult<VideoStatusQuery, VideoStatusQueryVariables>;
export function refetchVideoStatusQuery(variables: VideoStatusQueryVariables) {
      return { query: VideoStatusDocument, variables: variables }
    }