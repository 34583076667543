import {
  getCloudinaryUrl,
  getTimeZoneName,
  MyValue,
  routes,
  theme,
} from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { format } from 'date-fns';
import { useTranslation } from 'next-i18next';
import React, { FC, PropsWithChildren, useState } from 'react';

import {
  EventCoreFragment,
  GroupTypes,
  GroupWithMembersFragment,
  PostCreationPolicy,
  useCourseCompletionQuery,
  UserPreviewFragment,
  UserProfileFragment,
} from '../../../../generated/types-and-hooks';
import { POST_PREVIEW_CARD_WIDTH_PX } from '../../../config/constants';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { useViewerTimeZone } from '../../auth/hooks/useViewer';
import { EventLocation } from '../../events/components/EventLocation';
import { EventPostButton } from '../../events/components/EventPostButton';
import { EventRecurringText } from '../../events/components/EventRecurringText';
import { COVER_IMAGE_ASPECT_RATIO } from '../../groups/components/CoverImage';
import { GroupDescriptionComposer } from '../../groups/components/GroupDescriptionComposer';
import { GroupEditMembersTab } from '../../groups/components/GroupEditModal/GroupEditMembers/GroupEditMembersTab';
import { GroupHeadingTooltip } from '../../groups/components/GroupHeadingTooltip';
import { GroupsModal } from '../../groups/components/GroupsModal';
import { Avatar } from '../components/Avatar';
import { Emoji } from '../components/Emoji';
import { Heading } from '../components/Heading';
import { Icon } from '../components/Icon';
import { Link } from '../components/Link';
import { NextLink } from '../components/NextLink';
import { ProgressBar } from '../components/ProgressBar';
import { Skeleton } from '../components/Skeleton';
import { TabbedModal } from '../components/TabbedModal';
import { Text } from '../components/Text';
import { TimeAgo } from '../components/TimeAgo';
import { UserCommunityRoles } from '../components/UserCommunityRoles';
import { Users } from '../components/Users';
import { getUserDisplayName } from '../utils/user';

type FakeTagType = {
  __typename: 'Tag';
  createdAt: string;
  name: string;
  emoji?: string;
  coverImageId?: string;
  description?: string;
  groupType?: GroupTypes;
};

type DetailLayoutProps = {
  controls?: React.ReactNode;
  item?:
    | UserProfileFragment
    | GroupWithMembersFragment
    | EventCoreFragment
    | FakeTagType
    | null;
  loading?: JSX.Element;
  memberNumPosts?: number;
  numPosts?: number;
  numGroups?: number;
  showLayout?: boolean;
};
export const DetailLayout: FC<PropsWithChildren<DetailLayoutProps>> = ({
  children,
  controls,
  item,
  loading,
  numPosts,
  numGroups,
  showLayout = true,
}) => {
  return (
    <x.div
      gridTemplateColumns={{
        xl: showLayout ? '1fr auto' : '1fr',
      }}
      gridTemplateRows={{ xl: 'min-content' }}
      display={{ xl: 'grid' }}
      minH="full"
    >
      <x.div
        display={{
          _: 'none',
          'lg-2': 'block',
          xl: showLayout ? 'none' : 'block',
        }}
        borderBottom
        borderColor="gray.100"
        py={5}
        px={6}
        position="sticky"
        top={0}
        bg="white"
        zIndex="sticky"
      >
        <DetailLayoutMobileBar
          numPosts={numPosts}
          numGroups={numGroups}
          item={item}
          controls={controls}
        />
      </x.div>
      <x.div
        py={6}
        px={{ md: 6 }}
        mx="auto"
        flex={1}
        maxW={POST_PREVIEW_CARD_WIDTH_PX}
        w="full"
        display="flex"
        flexDirection="column"
        minWidth={{ md: POST_PREVIEW_CARD_WIDTH_PX }}
      >
        {children}
      </x.div>
      <x.div
        display={{
          _: 'none',
          xl: showLayout ? 'block' : 'none',
        }}
        maxW="sm"
        minWidth="2xs"
        borderLeft
        borderColor="gray.100"
      >
        <x.div
          overflow="hidden"
          position="sticky"
          top={0}
          w="full"
          h="100vh"
          overflowY="scroll"
        >
          <x.div
            px={6}
            py={5}
            display="flex"
            justifyContent="flex-end"
            borderBottom="default"
            borderBottomColor="gray.100"
          >
            {item ? controls : <Skeleton height={37} width={100} />}
          </x.div>
          {loading ? (
            loading
          ) : item?.__typename === 'Event' ? (
            <DetailLayoutEvent item={item} />
          ) : (
            <DetailLayoutContent
              numPosts={numPosts}
              numGroups={numGroups}
              controls={controls}
              item={item}
            />
          )}
        </x.div>
      </x.div>
    </x.div>
  );
};

const DetailLayoutMembers: FC<{
  item: GroupWithMembersFragment | EventCoreFragment;
  title?: JSX.Element;
}> = ({ item, title }) => {
  const { t } = useTranslation();
  const [showMembersModal, setShowMembersModal] = useState(false);
  const tabs = {
    members: <GroupEditMembersTab group={item} invitable={false} />,
  };

  return (
    <x.div spaceY={2}>
      <x.button
        onClick={() => setShowMembersModal(true)}
        display="flex"
        spaceX={1}
        padding={0}
        outline="none"
        border="none"
        bg="transparent"
        cursor="pointer"
      >
        {title}
      </x.button>
      {item?.members ? (
        <Users
          users={
            item.members.filter((m) => 'username' in m) as UserPreviewFragment[]
          }
          limit={20}
          showNameTooltips
          onMoreUsersClick={() => setShowMembersModal(true)}
        />
      ) : null}
      {showMembersModal && (
        <TabbedModal
          h={{
            _: '100%',
            sm: '722px',
          }}
          ariaLabel={t('members')}
          isOpen={showMembersModal}
          onDismiss={() => setShowMembersModal(false)}
          title={t('members')}
          tabs={tabs}
        />
      )}
    </x.div>
  );
};

const DetailLayoutEvent: FC<{
  item: EventCoreFragment;
}> = ({ item }) => {
  const { t } = useTranslation('events');
  const viewerTimezone = useViewerTimeZone();

  return (
    <x.div>
      {/* Empty div used to expand the div to the max width while allowing it to collapse to the min width */}
      <x.div w={{ sm: 'sm' }} />
      <x.div pt={8} pb={8} px={6} spaceY={8}>
        <x.div display="flex" flexDirection="column" spaceY={2}>
          <x.div display="flex" alignItems="center" spaceX={2}>
            <Icon name="calendar" size="5" />
            <Text
              variant="md"
              {...(item?.deleted && {
                textDecoration: 'line-through',
              })}
            >
              {format(new Date(item.startAt), 'eeee, LLL dd')}
            </Text>
          </x.div>
          <x.div display="flex" alignItems="center" spaceX={2}>
            <Icon name="square-time" size="5" />
            <Text
              variant="md"
              {...(item?.deleted && {
                textDecoration: 'line-through',
              })}
            >
              {format(new Date(item.startAt), 'p')}
              {' - '}
              {format(new Date(item.endAt), 'p')}{' '}
              {getTimeZoneName(viewerTimezone)}
            </Text>
          </x.div>
          {item.series ? (
            <x.div display="flex" alignItems="center" spaceX={2}>
              <Icon name="reload" size="5" />
              <Text variant="md">
                <EventRecurringText event={item} />
              </Text>
            </x.div>
          ) : null}

          {item.location && <EventLocation variant="sidebar" event={item} />}
          {item.url && (
            <x.div display="flex" alignItems="center" spaceX={2}>
              <Icon name="link" size="5" />
              <Link href={item.url} styleInheritColor minWidth={0}>
                <Text
                  variant="md"
                  {...(item?.deleted && {
                    textDecoration: 'line-through',
                  })}
                  truncate
                >
                  {item.url}
                </Text>
              </Link>
            </x.div>
          )}
        </x.div>
        <DetailLayoutMembers
          item={item}
          title={
            <>
              <Text variant="md-semibold">
                {item.numMembers === 1
                  ? t('events.member_attending', {
                      numMembers: item.numMembers,
                    })
                  : t('events.members_attending', {
                      numMembers: item.numMembers,
                    })}
              </Text>
            </>
          }
        />
        {!item.deleted && <EventPostButton event={item} />}
      </x.div>
    </x.div>
  );
};

const DetailLayoutContent: FC<DetailLayoutProps> = ({
  item,
  numPosts,
  numGroups,
}) => {
  const { t } = useTranslation();
  const isMemberVariant = item && 'username' in item;
  const heading = isMemberVariant ? getUserDisplayName(item) : item?.name;
  const description = isMemberVariant ? item.bio : item?.description;

  let subheading = null;

  if (isMemberVariant) {
    if (heading !== item.username) {
      subheading = item.username;
    }
  } else if (item && !isMemberVariant) {
    if (
      item.__typename === 'Group' &&
      item.groupType === GroupTypes.Course &&
      item.isDraft
    ) {
      subheading = t('draft_mode');
    } else if (item.__typename !== 'Tag') {
      subheading = `${t('created')} ${format(
        new Date(item.createdAt),
        'MMM d, y'
      )}`;
    }
  }

  const isDraft = item?.__typename === 'Group' && item.isDraft;

  return (
    <x.div>
      {item?.__typename !== 'Tag' ? (
        <x.div position="relative">
          {item?.coverImageId ? (
            <x.img
              w="full"
              style={{
                aspectRatio: COVER_IMAGE_ASPECT_RATIO,
              }}
              objectFit="cover"
              src={getCloudinaryUrl({
                id: item.coverImageId,
                width: 400,
              })}
              overflow="hidden"
            />
          ) : (
            <x.div
              w="full"
              style={{
                aspectRatio: COVER_IMAGE_ASPECT_RATIO,
              }}
              bg="brand.50"
            />
          )}
          <x.div w="sm" />
          <x.div
            position="absolute"
            bottom={-24}
            left={24}
            boxShadow="glossyModal"
            borderRadius="lg"
          >
            {isMemberVariant ? (
              <Avatar
                size="lg-3"
                imageId={item.profileImageId}
                avatar={item.avatar}
                bgColor={item.avatarBgColor}
              />
            ) : (
              <x.div
                w={25}
                h={25}
                display="flex"
                justifyContent="center"
                alignItems="center"
                bg="gray.50"
                borderRadius="md"
              >
                {!item ? (
                  <Skeleton height={100} width={100} />
                ) : item.emoji ? (
                  <Emoji emojiUnicode={item.emoji} size={48} />
                ) : null}
              </x.div>
            )}
          </x.div>
        </x.div>
      ) : (
        <x.div w="sm" />
      )}

      <x.div pt={item?.__typename !== 'Tag' ? 14 : 6} pb={8} px={6} spaceY={8}>
        <x.div spaceY={6}>
          <x.div spaceY={3}>
            <x.div spaceY={1}>
              <x.div display="flex" spaceX={2} alignItems="center">
                <Text variant="lg-semibold">
                  {item ? heading : <Skeleton width="40%" />}
                </Text>
                {item?.__typename === 'Group' &&
                ![GroupTypes.Event].includes(item.groupType) ? (
                  <x.div display="flex" spaceX={1}>
                    {item.isPrivate && (
                      <GroupHeadingTooltip
                        maxWidth={340}
                        title={t('private_group')}
                        label={t('private_group_explanation')}
                      >
                        <Icon
                          name="lock"
                          size="5"
                          transitionProperty="color"
                          transitionDuration="faster"
                          color={{
                            _: 'gray.300',
                            hover: 'brand.300',
                          }}
                        />
                      </GroupHeadingTooltip>
                    )}
                    {item.unlockedBy?.active && (
                      <GroupHeadingTooltip
                        maxWidth={340}
                        title={t('group_paywalled')}
                      >
                        <Icon name="badge" size="5" />
                      </GroupHeadingTooltip>
                    )}
                    {item.postCreationPolicy ===
                      PostCreationPolicy.Administrators &&
                      item.groupType !== GroupTypes.Course && (
                        <GroupHeadingTooltip
                          maxWidth={340}
                          title={t('announcement_group')}
                          label={t('announcement_group_explanation')}
                        >
                          <Icon
                            name="loudspeaker"
                            size="5"
                            transitionProperty="color"
                            transitionDuration="faster"
                            color={{
                              _: 'gray.200',
                              hover: 'brand.300',
                            }}
                          />
                        </GroupHeadingTooltip>
                      )}
                  </x.div>
                ) : null}
              </x.div>

              {!item ? (
                <Text variant="sm" color="gray.300">
                  <Skeleton width="25%" />
                </Text>
              ) : subheading ? (
                <Text variant="sm" color="gray.300">
                  {subheading}
                </Text>
              ) : null}
            </x.div>
            {isMemberVariant ? <UserCommunityRoles user={item} /> : null}
          </x.div>

          {!description ? null : isMemberVariant &&
            typeof description === 'string' ? (
            <Text>{description}</Text>
          ) : (
            <GroupDescriptionComposer
              content={description as MyValue}
              initialTruncate={false}
            />
          )}
          {item ? (
            <x.div spaceY={3}>
              <x.div display="flex" spaceX={6}>
                <x.div display="flex" spaceX={1}>
                  <Text variant="md-semibold">{numPosts}</Text>
                  <Text>
                    {numPosts === 1
                      ? t(
                          !isMemberVariant &&
                            item?.groupType === GroupTypes.Course
                            ? 'lesson'
                            : 'post'
                        )
                      : t(
                          !isMemberVariant &&
                            item?.groupType === GroupTypes.Course
                            ? 'lessons'
                            : 'posts'
                        )}
                  </Text>
                </x.div>
                {isMemberVariant ? (
                  <x.div display="flex" spaceX={1}>
                    <Text variant="md-semibold">{numGroups}</Text>
                    <Text>{numGroups === 1 ? t('group') : t('groups')}</Text>
                  </x.div>
                ) : null}
              </x.div>
              {!isMemberVariant &&
              item?.__typename === 'Group' &&
              item.groupType === GroupTypes.Course ? (
                <CourseProgressBar courseId={item.id} />
              ) : null}
            </x.div>
          ) : null}
        </x.div>
        {!isMemberVariant && item && !isDraft && 'members' in item ? (
          <DetailLayoutMembers
            item={item}
            title={
              <>
                <Text variant="md-semibold">
                  {item.members.filter((m) => m.__typename === 'User').length}
                </Text>
                <Text>
                  {item.members.filter((m) => m.__typename === 'User')
                    .length === 1
                    ? t(
                        item.groupType === GroupTypes.Course
                          ? 'participant'
                          : 'member'
                      )
                    : t(
                        item.groupType === GroupTypes.Course
                          ? 'participants'
                          : 'members'
                      )}
                </Text>
              </>
            }
          />
        ) : null}
      </x.div>
    </x.div>
  );
};

export const DetailLayoutMobileBar: FC<DetailLayoutProps> = ({
  item,
  controls,
  numPosts,
  numGroups,
}) => {
  const { t } = useTranslation();
  const { organization } = useOrganization();
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const isMemberVariant = item && 'username' in item;
  const heading = isMemberVariant ? getUserDisplayName(item) : item?.name;
  const viewerTimeZone = useViewerTimeZone();
  const topBarLabelSections = [
    ...(item?.__typename === 'Event'
      ? [
          {
            label: `${format(new Date(item.startAt), 'p')} - ${format(
              new Date(item.endAt),
              'p'
            )} ${getTimeZoneName(viewerTimeZone)}`,
          },
        ]
      : isMemberVariant
        ? [
            {
              label: item.username,
            },
          ]
        : item && 'isDraft' in item && !item.isDraft
          ? [
              {
                label:
                  item.numMembers === 0
                    ? t(
                        item.groupType === GroupTypes.Course
                          ? 'participant_count_zero'
                          : 'member_count_zero'
                      )
                    : item.numMembers === 1
                      ? t(
                          item.groupType === GroupTypes.Course
                            ? 'participant_count_one'
                            : 'member_count_one'
                        )
                      : t(
                          item.groupType === GroupTypes.Course
                            ? 'participant_count_other'
                            : 'member_count_other',
                          {
                            count: item.numMembers,
                          }
                        ),
              },
            ]
          : []),
    ...(item?.__typename === 'Tag'
      ? [
          {
            label: `${numPosts} ${numPosts === 1 ? t('post') : t('posts')}`,
          },
        ]
      : []),
    ...(item?.__typename !== 'Tag'
      ? [
          {
            label: t('about'),
            onClick: () => setShowDetailsModal(true),
          },
        ]
      : []),
  ];

  return (
    <x.div
      display="flex"
      justifyContent="space-between"
      w="full"
      alignItems="center"
    >
      <GroupsModal
        isOpen={showDetailsModal}
        onDismiss={() => setShowDetailsModal(false)}
        variant="about"
        width="368px"
      >
        <x.div borderRadius={{ sm: 'md-lg' }} overflow="hidden">
          {item?.__typename === 'Event' ? (
            <>
              <x.div>
                <x.div position="relative">
                  {item?.coverImageId ? (
                    <x.img
                      w="full"
                      style={{
                        aspectRatio: COVER_IMAGE_ASPECT_RATIO,
                      }}
                      objectFit="cover"
                      src={getCloudinaryUrl({
                        id: item.coverImageId,
                        width: 400,
                      })}
                      overflow="hidden"
                    />
                  ) : (
                    <x.div
                      w="full"
                      style={{
                        aspectRatio: COVER_IMAGE_ASPECT_RATIO,
                      }}
                      bg="brand.50"
                    />
                  )}
                  <x.div
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    w="100px"
                    py={4}
                    boxSizing="border-box"
                    backgroundColor="white"
                    borderRadius="md-lg"
                    boxShadow="sm"
                    position="absolute"
                    top={theme.sizes[6]}
                    left={theme.sizes[6]}
                  >
                    <x.div
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <Heading variant="3xl">
                        {format(new Date(item.startAt), 'dd')}
                      </Heading>
                      <Heading color="gray.400" variant="xl">
                        {format(new Date(item.startAt), 'LLL').toUpperCase()}
                      </Heading>
                    </x.div>
                  </x.div>
                </x.div>
              </x.div>
              <x.div p={6} pb={0}>
                <x.div spaceY={6}>
                  <x.div spaceY={1}>
                    <Text variant="lg-semibold">{heading}</Text>
                    <Text variant="sm" color="gray.300">
                      {t('created')} {<TimeAgo date={item.createdAt} />}
                    </Text>
                  </x.div>
                  {!item.description ? null : isMemberVariant &&
                    typeof item.description === 'string' ? (
                    <Text>{item.description}</Text>
                  ) : (
                    <GroupDescriptionComposer
                      initialTruncate={false}
                      content={item.description as MyValue}
                    />
                  )}
                </x.div>
              </x.div>
              <DetailLayoutEvent item={item} />
            </>
          ) : (
            <DetailLayoutContent
              numPosts={numPosts}
              numGroups={numGroups}
              controls={controls}
              item={item}
            />
          )}
        </x.div>
      </GroupsModal>
      <x.div display="flex">
        <x.div display={{ _: 'none', sm: 'block' }} pr={3}>
          {item?.__typename === 'Event' ? (
            <x.div
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              borderRadius="md"
              backgroundColor="gray.50"
              h={12}
              w={12}
            >
              <Text variant="md-semibold">
                {format(new Date(item.startAt), 'dd')}
              </Text>
              <Text variant="xs-medium" color="gray.400">
                {format(new Date(item.startAt), 'LLL').toUpperCase()}
              </Text>
            </x.div>
          ) : isMemberVariant ? (
            <NextLink
              href={routes.groups
                .organization(organization.shortId)
                .person(item.username)}
            >
              <Avatar
                size="m-3"
                imageId={item.profileImageId}
                avatar={item.avatar}
                bgColor={item.avatarBgColor}
              />
            </NextLink>
          ) : item?.__typename !== 'Tag' ? (
            <x.div
              w={12}
              h={12}
              bg="gray.50"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="md"
            >
              {item?.emoji ? (
                <Emoji emojiUnicode={item.emoji} size={24} />
              ) : null}
            </x.div>
          ) : null}
        </x.div>
        <x.div>
          <x.div display="flex" spaceX={3} alignItems="center">
            <Text variant="lg-semibold">{heading}</Text>
            {isMemberVariant ? (
              <x.div display={{ _: 'none', sm: 'block' }}>
                <UserCommunityRoles variant="small" user={item} />
              </x.div>
            ) : null}
          </x.div>
          <x.div display="flex" alignItems="center" spaceX={2}>
            {topBarLabelSections.map((section, index) => {
              const textContent = (
                <Text
                  variant="sm-medium"
                  {...(!section.onClick &&
                    item?.__typename === 'Event' &&
                    item?.deleted && {
                      textDecoration: 'line-through',
                    })}
                >
                  {section.label}
                </Text>
              );
              return (
                <>
                  {section.onClick ? (
                    <x.button
                      onClick={section.onClick}
                      color={{
                        _: 'gray.300',
                        hover: 'gray.500',
                      }}
                      p={0}
                      appearance="none"
                      outline="none"
                      bg="transparent"
                      border="none"
                      display="block"
                      cursor="pointer"
                      transitionDuration="fast"
                      transitionTimingFunction="ease-in-out"
                    >
                      {textContent}
                    </x.button>
                  ) : (
                    <x.div color="gray.300">{textContent}</x.div>
                  )}
                  {index < topBarLabelSections.length - 1 && (
                    <Text variant="sm-medium" color="gray.300">
                      •
                    </Text>
                  )}
                </>
              );
            })}
          </x.div>
        </x.div>
      </x.div>
      <x.div>{controls}</x.div>
    </x.div>
  );
};

const CourseProgressBar = ({ courseId }: { courseId: string }) => {
  const { t } = useTranslation();
  const { data } = useCourseCompletionQuery({
    variables: {
      courseId,
    },
  });
  if (!data || data.courseCompletion.percentage === 0) return null;

  return (
    <x.div spaceY={2}>
      <ProgressBar
        progress={data.courseCompletion.percentage}
        progressColor="green.200"
        trackColor="green.100"
      />
      <Text variant="sm" color="gray.300">
        {data.courseCompletion.percentage}% {t('completed')}
      </Text>
    </x.div>
  );
};
