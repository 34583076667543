import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: string; output: string; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: { [key: string]: any }; output: { [key: string]: any }; }
};

export type AcceptAllRequestsToJoinInput = {
  organizationId: Scalars['String']['input'];
};

export type AcceptRequestToJoinInput = {
  requestToJoinId: Scalars['String']['input'];
};

export enum ActivityTypes {
  Comment = 'COMMENT',
  Group = 'GROUP',
  GroupMembership = 'GROUP_MEMBERSHIP',
  OrganizationMembership = 'ORGANIZATION_MEMBERSHIP',
  PollVote = 'POLL_VOTE',
  Post = 'POST',
  Reaction = 'REACTION'
}

export type AddEmbedLoadInput = {
  organizationId: Scalars['ID']['input'];
  url: Scalars['String']['input'];
};

export type AddEntryToWaitlistInput = {
  email: Scalars['String']['input'];
  source: WaitlistEntrySource;
};

export type AddGroupMembersInput = {
  groupId: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']>;
};

export enum AddMemberPolicy {
  RequestToJoin = 'REQUEST_TO_JOIN',
  Unrestricted = 'UNRESTRICTED'
}

export type AddNewInvitedOrganizationInput = {
  domain: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type AddOrganizationInput = {
  domain: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type AddOrganizationVisitInput = {
  organizationId: Scalars['ID']['input'];
};

export type AddViewInput = {
  level: ViewLevels;
  location: ViewLocations;
  viewedId: Scalars['ID']['input'];
};

export type AdminSubscription = {
  __typename?: 'AdminSubscription';
  expiresAt: Scalars['DateTime']['output'];
  grandfathered: Scalars['Boolean']['output'];
  stripeCancelAtPeriodEnd: Scalars['Boolean']['output'];
  stripeSubscriptionStatus: Scalars['String']['output'];
  subscriptionPlan: AdminSubscriptionPlanId;
  subscriptionPrice: AdminSubscriptionPlanPriceLookupKey;
};

export enum AdminSubscriptionPlanId {
  Plus = 'PLUS',
  Pro = 'PRO'
}

export enum AdminSubscriptionPlanPriceLookupKey {
  PlusMonthlyUsd = 'PLUS_MONTHLY_USD',
  PlusYearlyUsd = 'PLUS_YEARLY_USD',
  ProMonthlyUsd = 'PRO_MONTHLY_USD',
  ProYearlyUsd = 'PRO_YEARLY_USD'
}

export type AssignRoleInput = {
  organizationId: Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type BillingDetails = {
  __typename?: 'BillingDetails';
  address: Scalars['String']['output'];
  cardBrand?: Maybe<Scalars['String']['output']>;
  cardLast4?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CheckoutSession = {
  __typename?: 'CheckoutSession';
  url: Scalars['String']['output'];
};

export type Comment = Node & {
  __typename?: 'Comment';
  content: Scalars['JSON']['output'];
  contentVersion: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  id: Scalars['ID']['output'];
  isBestAnswer: Scalars['Boolean']['output'];
  media: Array<CommentMedia>;
  reactions: Array<Reaction>;
  resources: Array<Resource>;
  type: CommentTypes;
};

export type CommentConfig = {
  __typename?: 'CommentConfig';
  maxCommentLength: Scalars['Int']['output'];
};

export type CommentMedia = Giphy | Image;

export enum CommentTypes {
  Post = 'POST'
}

export type Coordinates = {
  __typename?: 'Coordinates';
  height: Scalars['Int']['output'];
  width: Scalars['Int']['output'];
  x: Scalars['Int']['output'];
  y: Scalars['Int']['output'];
};

export type Course = Node & {
  __typename?: 'Course';
  coverId?: Maybe<Scalars['String']['output']>;
  createdBy: User;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['JSON']['output']>;
  emoji: Scalars['String']['output'];
  hidden?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  members: Array<User>;
  name: Scalars['String']['output'];
};

export type CourseCompletionPayload = Node & {
  __typename?: 'CourseCompletionPayload';
  id: Scalars['ID']['output'];
  percentage: Scalars['Int']['output'];
};

export type CourseError = {
  __typename?: 'CourseError';
  message: Scalars['String']['output'];
};

export type CourseObjectEdge = {
  __typename?: 'CourseObjectEdge';
  cursor: Scalars['String']['output'];
  node: Course;
};

export type CoursePayload = Course | CourseError;

export type CreateCommentInput = {
  content: Scalars['JSON']['input'];
  mediaIds: Array<Scalars['ID']['input']>;
  parentId: Scalars['ID']['input'];
  resources: Array<ResourceInput>;
  type: CommentTypes;
};

export type CreateCourseInput = {
  coverImageId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['JSON']['input']>;
  emoji: Scalars['String']['input'];
  menuSectionId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type CreateDirectMessageInput = {
  content: Scalars['JSON']['input'];
  mediaIds: Array<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  urls: Array<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type CreateEventInput = {
  coverImageId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['JSON']['input']>;
  endAt: Scalars['DateTime']['input'];
  location?: InputMaybe<LocationInput>;
  meetingUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  recurringCronExpression?: InputMaybe<Scalars['String']['input']>;
  recurringOccurrencesLimit?: InputMaybe<Scalars['Int']['input']>;
  startAt: Scalars['DateTime']['input'];
  timeZone: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CreateGiphyInput = {
  giphyId: Scalars['String']['input'];
  giphyMp4Url: Scalars['String']['input'];
  giphyUrl: Scalars['String']['input'];
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export type CreateGroupInput = {
  coverImageId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['JSON']['input']>;
  emoji: Scalars['String']['input'];
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  menuSectionId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  postCreationPolicy?: InputMaybe<PostCreationPolicy>;
  referenceId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateImageInput = {
  bytes: Scalars['Int']['input'];
  format: Scalars['String']['input'];
  height: Scalars['Int']['input'];
  publicId: Scalars['String']['input'];
  width: Scalars['Int']['input'];
};

export type CreateMenuItemUrlInput = {
  sectionId: Scalars['ID']['input'];
  url: Scalars['String']['input'];
};

export type CreateMenuItemWithPostInput = {
  organizationId: Scalars['ID']['input'];
  postId: Scalars['ID']['input'];
};

export type CreateMenuSectionInput = {
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type CreateOrDeleteDirectMessageReactionInput = {
  emojiUnicode: Scalars['String']['input'];
  messageId: Scalars['String']['input'];
  threadId: Scalars['String']['input'];
};

export type CreateOrDeletePostBookmarkInput = {
  postId: Scalars['ID']['input'];
};

export type CreateOrDeleteReactionInput = {
  emojiUnicode: Scalars['String']['input'];
  parentId: Scalars['ID']['input'];
  type: ReactionTypes;
};

export type CreateOrUpdateOrganizationInput = {
  addMemberPolicy?: InputMaybe<AddMemberPolicy>;
  description?: InputMaybe<Scalars['String']['input']>;
  groupCreationPolicy?: InputMaybe<GroupCreationPolicy>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  joinPolicy?: InputMaybe<JoinPolicy>;
  logoId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  shortId?: InputMaybe<Scalars['String']['input']>;
  templateReferenceId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrganizationRoleInput = {
  color?: InputMaybe<OrganizationRoleColor>;
  label: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type CreatePollInput = {
  options: Array<Scalars['String']['input']>;
  postId: Scalars['String']['input'];
};

export type CreateResourceInput = {
  expanded: Scalars['Boolean']['input'];
  url: Scalars['String']['input'];
};

export type CreateTemporaryImageInput = {
  bytes: Scalars['Int']['input'];
  format: Scalars['String']['input'];
  height: Scalars['Int']['input'];
  publicId: Scalars['String']['input'];
  token: Scalars['String']['input'];
  width: Scalars['Int']['input'];
};

export type CreateTemporaryPostInput = {
  groupId?: InputMaybe<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};

export type CreateTemporaryVideoPresignedUploadInput = {
  mirror: Scalars['Boolean']['input'];
  token: Scalars['String']['input'];
  videoType: VideoType;
};

export type CreateUserApnsPushSubscriptionInput = {
  deviceToken: Scalars['String']['input'];
};

export type CreateUserFcmPushSubscriptionInput = {
  deviceToken: Scalars['String']['input'];
};

export type CreateUserInput = {
  auth0Id?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['ID']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserWebPushSubscriptionInput = {
  endpoint: Scalars['String']['input'];
  keyAuth: Scalars['String']['input'];
  keyP256dh: Scalars['String']['input'];
};

export type CreateVideoPresignedUploadInput = {
  mirror: Scalars['Boolean']['input'];
  videoType: VideoType;
};

export type CreateVoteInput = {
  pollOptionId: Scalars['String']['input'];
};

export type CustomDomain = {
  __typename?: 'CustomDomain';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  status: CustomDomainStatus;
};

export enum CustomDomainStatus {
  Active = 'ACTIVE',
  Misconfigured = 'MISCONFIGURED',
  Unverified = 'UNVERIFIED'
}

export type CustomDomainVerification = {
  __typename?: 'CustomDomainVerification';
  domain: Scalars['String']['output'];
  reason: Scalars['String']['output'];
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type CustomDomainVerificationInfo = {
  __typename?: 'CustomDomainVerificationInfo';
  apexName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  verification: Array<CustomDomainVerification>;
  verified: Scalars['Boolean']['output'];
};

export type DeleteDirectMessageThreadInput = {
  id: Scalars['String']['input'];
};

export type DeleteEventInput = {
  eventId: Scalars['ID']['input'];
  seriesSelection?: InputMaybe<SeriesSelection>;
};

export type DeleteGroupInput = {
  id: Scalars['ID']['input'];
};

export type DeleteImageInput = {
  imageId: Scalars['ID']['input'];
};

export type DeleteMenuItemInput = {
  itemId: Scalars['String']['input'];
};

export type DeleteMenuSectionInput = {
  sectionId: Scalars['ID']['input'];
};

export type DeleteOrganizationRoleInput = {
  roleId: Scalars['ID']['input'];
};

export type DeletePollInput = {
  pollId: Scalars['String']['input'];
};

export type DeletePostInput = {
  id: Scalars['ID']['input'];
};

export type DeleteVoteInput = {
  pollOptionId: Scalars['String']['input'];
};

export type DemoteUserInput = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export enum DigestNotificationFrequency {
  Daily = 'DAILY',
  Disabled = 'DISABLED',
  Weekly = 'WEEKLY'
}

export type DirectMessage = Node & {
  __typename?: 'DirectMessage';
  createdOn: Scalars['DateTime']['output'];
  editedOn?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  media: Array<DirectMessageMedia>;
  messageId: Scalars['String']['output'];
  reactions: Array<Reaction>;
  resources: Array<Resource>;
  version: Scalars['Float']['output'];
};

export type DirectMessageMedia = Giphy | Image;

export type DirectMessageNotificationCounts = {
  __typename?: 'DirectMessageNotificationCounts';
  unread: Scalars['Float']['output'];
};

export type DirectMessageToken = {
  __typename?: 'DirectMessageToken';
  communicationUserId: Scalars['String']['output'];
  expiresOn: Scalars['DateTime']['output'];
  token: Scalars['String']['output'];
};

export type DirectMessageUser = Node & {
  __typename?: 'DirectMessageUser';
  communicationUserId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  user: User;
};

export type DirectMessageUserThread = Node & {
  __typename?: 'DirectMessageUserThread';
  createdBy: DirectMessageUser;
  id: Scalars['ID']['output'];
  lastMessage?: Maybe<Scalars['String']['output']>;
  lastMessageReceivedOn?: Maybe<Scalars['DateTime']['output']>;
  settings: DirectMessageUserThreadSettings;
  threadId: Scalars['String']['output'];
  unreadCount?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: DirectMessageUser;
};

export type DirectMessageUserThreadSettings = Node & {
  __typename?: 'DirectMessageUserThreadSettings';
  id: Scalars['ID']['output'];
  markedUnread?: Maybe<Scalars['Boolean']['output']>;
  mutedNotifications?: Maybe<Scalars['Boolean']['output']>;
};

export type DismissFrondIntroPostInput = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type DismissWelcomePostInput = {
  organizationId: Scalars['ID']['input'];
};

export type DuplicateOrganizationInput = {
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  shortId?: InputMaybe<Scalars['String']['input']>;
};

export type EmbedLoad = {
  __typename?: 'EmbedLoad';
  id: Scalars['String']['output'];
  organizationName: Scalars['String']['output'];
  shortId: Scalars['String']['output'];
  url: Scalars['String']['output'];
  widgetSizeVariant: WidgetSizeVariant;
};

export type Event = Node & {
  __typename?: 'Event';
  coverImageId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['JSON']['output']>;
  emoji: Scalars['String']['output'];
  endAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  location?: Maybe<Location>;
  meetingUrl?: Maybe<Scalars['String']['output']>;
  members: Array<User>;
  membership?: Maybe<GroupMembership>;
  name: Scalars['String']['output'];
  numMembers: Scalars['Int']['output'];
  numPosts: Scalars['Int']['output'];
  postCreationPolicy: PostCreationPolicy;
  series?: Maybe<EventSeries>;
  startAt: Scalars['DateTime']['output'];
  timeZone?: Maybe<Scalars['String']['output']>;
  type: GroupTypes;
  url?: Maybe<Scalars['String']['output']>;
};

export type EventError = {
  __typename?: 'EventError';
  message: Scalars['String']['output'];
};

export type EventObjectEdge = {
  __typename?: 'EventObjectEdge';
  cursor: Scalars['String']['output'];
  node: Event;
};

export type EventPayload = Event | EventError;

export type EventSeries = Node & {
  __typename?: 'EventSeries';
  id: Scalars['ID']['output'];
  recurringCronExpression: Scalars['String']['output'];
  recurringOccurrencesLimit?: Maybe<Scalars['Int']['output']>;
};

export type EventsConnection = {
  __typename?: 'EventsConnection';
  edges: Array<EventObjectEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum FeatureEntitlement {
  Courses = 'COURSES',
  CustomDomains = 'CUSTOM_DOMAINS',
  CustomRoles = 'CUSTOM_ROLES',
  Events = 'EVENTS',
  Insights = 'INSIGHTS',
  Polls = 'POLLS'
}

export enum FeatureQuota {
  Admins = 'ADMINS',
  ImageUploadSize = 'IMAGE_UPLOAD_SIZE',
  Members = 'MEMBERS',
  PaywallPlatformFee = 'PAYWALL_PLATFORM_FEE',
  VideoUploadSize = 'VIDEO_UPLOAD_SIZE'
}

export type FeedItem = OrganizationMembership | Post;

export type FeedItemConnection = {
  __typename?: 'FeedItemConnection';
  edges: Array<FeedItemEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type FeedItemEdge = {
  __typename?: 'FeedItemEdge';
  cursor: Scalars['String']['output'];
  node: FeedItem;
};

export type Giphy = Node & {
  __typename?: 'Giphy';
  aspectRatio: Scalars['Float']['output'];
  giphyId: Scalars['String']['output'];
  giphyMp4Url: Scalars['String']['output'];
  giphyUrl: Scalars['String']['output'];
  height: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  width: Scalars['Int']['output'];
};

export type Group = Node & {
  __typename?: 'Group';
  autoAddMembers: Scalars['Boolean']['output'];
  coverImageId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['JSON']['output']>;
  emoji: Scalars['String']['output'];
  enableNewPostNotificationsForNewMembers: Scalars['Boolean']['output'];
  hasPosts: Scalars['Boolean']['output'];
  hasUnseenPosts?: Maybe<Scalars['Boolean']['output']>;
  hidden: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  includePostsOnHome: Scalars['Boolean']['output'];
  isDraft: Scalars['Boolean']['output'];
  isPrivate?: Maybe<Scalars['Boolean']['output']>;
  lastActivityAt: Scalars['DateTime']['output'];
  members: Array<User>;
  membership?: Maybe<GroupMembership>;
  name: Scalars['String']['output'];
  numMembers: Scalars['Int']['output'];
  numPosts: Scalars['Int']['output'];
  organization: Organization;
  postCreationPolicy: PostCreationPolicy;
  questionCronExpression?: Maybe<Scalars['String']['output']>;
  questionEnabled?: Maybe<Scalars['Boolean']['output']>;
  questionText?: Maybe<Scalars['String']['output']>;
  sortIndex: Scalars['Float']['output'];
  timeZone?: Maybe<Scalars['String']['output']>;
  type: GroupTypes;
  unlockedBy?: Maybe<MemberSubscriptionPlan>;
  updatedAt: Scalars['DateTime']['output'];
  views: Array<GroupView>;
};

export enum GroupCreationPolicy {
  Administrators = 'ADMINISTRATORS',
  Unrestricted = 'UNRESTRICTED'
}

export type GroupError = {
  __typename?: 'GroupError';
  message: Scalars['String']['output'];
  reason: GroupErrorReason;
};

export enum GroupErrorReason {
  NotAllowed = 'NOT_ALLOWED',
  Paywall = 'PAYWALL',
  Private = 'PRIVATE'
}

export type GroupMembership = Node & {
  __typename?: 'GroupMembership';
  allowEventNotifications?: Maybe<Scalars['Boolean']['output']>;
  allowNewOccurrenceNotifications?: Maybe<Scalars['Boolean']['output']>;
  allowNewPostNotifications?: Maybe<Scalars['Boolean']['output']>;
  group: Group;
  id: Scalars['ID']['output'];
  membership?: Maybe<GroupMembership>;
};

export type GroupObjectEdge = {
  __typename?: 'GroupObjectEdge';
  cursor: Scalars['String']['output'];
  node: Group;
};

export type GroupPayload = Group | GroupError;

export enum GroupTypes {
  Course = 'COURSE',
  Event = 'EVENT',
  General = 'GENERAL',
  Regular = 'REGULAR',
  Welcome = 'WELCOME'
}

export type GroupView = Node & {
  __typename?: 'GroupView';
  id: Scalars['ID']['output'];
  level: ViewLevels;
  location?: Maybe<ViewLocations>;
  user: User;
};

export type GroupsConnection = {
  __typename?: 'GroupsConnection';
  edges: Array<GroupObjectEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type GroupsMenuSectionsState = {
  __typename?: 'GroupsMenuSectionsState';
  menuSectionIdsCollapsed?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type GroupsSidebarState = {
  __typename?: 'GroupsSidebarState';
  courseGroupsExpanded?: Maybe<Scalars['Boolean']['output']>;
  privateGroupsExpanded?: Maybe<Scalars['Boolean']['output']>;
  publicGroupsExpanded?: Maybe<Scalars['Boolean']['output']>;
};

export type HelloPage = Node & {
  __typename?: 'HelloPage';
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  emojiUnicodeOrCustomImageId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  helloPageBlocks: Array<HelloPageBlock>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  recipientProfileImageId?: Maybe<Scalars['String']['output']>;
  shortId: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  template: HelloPageTemplateTypes;
  title?: Maybe<Scalars['String']['output']>;
};

export type HelloPageBlock = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  sortIndex: Scalars['Int']['output'];
  type: HelloPageBlockTypes;
};

export type HelloPageBlockEmail = HelloPageBlock & Node & {
  __typename?: 'HelloPageBlockEmail';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  sortIndex: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
  type: HelloPageBlockTypes;
};

export type HelloPageBlockImage = HelloPageBlock & Node & {
  __typename?: 'HelloPageBlockImage';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  imageId: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
  type: HelloPageBlockTypes;
};

export type HelloPageBlockInternal = HelloPageBlock & Node & {
  __typename?: 'HelloPageBlockInternal';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  internalType: HelloPageBlockInternalTypes;
  sortIndex: Scalars['Int']['output'];
  type: HelloPageBlockTypes;
};

export enum HelloPageBlockInternalTypes {
  Email = 'EMAIL',
  Invite = 'INVITE',
  Record = 'RECORD',
  Resource = 'RESOURCE'
}

export type HelloPageBlockIntroduction = HelloPageBlock & Node & {
  __typename?: 'HelloPageBlockIntroduction';
  content?: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['DateTime']['output'];
  emojiUnicodeOrCustomImageId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  sortIndex: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
  type: HelloPageBlockTypes;
};

export type HelloPageBlockNote = HelloPageBlock & Node & {
  __typename?: 'HelloPageBlockNote';
  content?: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  sortIndex: Scalars['Int']['output'];
  type: HelloPageBlockTypes;
};

export type HelloPageBlockResource = HelloPageBlock & Node & {
  __typename?: 'HelloPageBlockResource';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  logoTitle?: Maybe<Scalars['String']['output']>;
  sortIndex: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
  type: HelloPageBlockTypes;
  url?: Maybe<Scalars['String']['output']>;
};

export enum HelloPageBlockTypes {
  Email = 'EMAIL',
  Image = 'IMAGE',
  Internal = 'INTERNAL',
  Introduction = 'INTRODUCTION',
  Note = 'NOTE',
  Resource = 'RESOURCE',
  Video = 'VIDEO'
}

export type HelloPageBlockVideo = HelloPageBlock & Node & {
  __typename?: 'HelloPageBlockVideo';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  sortIndex: Scalars['Int']['output'];
  type: HelloPageBlockTypes;
  user: User;
  video: Video;
};

export type HelloPageObjectEdge = {
  __typename?: 'HelloPageObjectEdge';
  cursor: Scalars['String']['output'];
  node: HelloPage;
};

export enum HelloPageTemplateTypes {
  Birthday = 'BIRTHDAY',
  Blank = 'BLANK',
  Hiring = 'HIRING',
  Welcome = 'WELCOME'
}

export type HideOrganizationVisitInput = {
  organizationId: Scalars['ID']['input'];
};

export type HideUpsellInput = {
  upsellId: Scalars['String']['input'];
};

export type Image = Node & {
  __typename?: 'Image';
  aspectRatio: Scalars['Float']['output'];
  coordinates?: Maybe<Coordinates>;
  height: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  publicId: Scalars['String']['output'];
  width: Scalars['Int']['output'];
};

export type InboxState = {
  __typename?: 'InboxState';
  showRead?: Maybe<Scalars['Boolean']['output']>;
};

export type InviteUsersInput = {
  emails: Array<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
};

export type InviteUsersPayload = RequestToJoin | RequestToJoinError | UserInvite;

export type InvitesOrderByInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InvitesPaginated = {
  __typename?: 'InvitesPaginated';
  items: Array<UserInvite>;
  totalCount: Scalars['Int']['output'];
};

export type JoinGroupInput = {
  groupId: Scalars['ID']['input'];
};

export type JoinOrganizationInput = {
  inviteCode?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export enum JoinPolicy {
  RequestToJoin = 'REQUEST_TO_JOIN',
  Restricted = 'RESTRICTED',
  Unrestricted = 'UNRESTRICTED'
}

export type LeaveOrganizationInput = {
  organizationId: Scalars['ID']['input'];
};

export type LessonCompletion = Node & {
  __typename?: 'LessonCompletion';
  id: Scalars['ID']['output'];
  user: User;
};

export type Location = Node & {
  __typename?: 'Location';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lat: Scalars['Float']['output'];
  lon: Scalars['Float']['output'];
  placeId: Scalars['String']['output'];
  type: LocationTypes;
};

export type LocationInput = {
  displayName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  lat: Scalars['Float']['input'];
  lon: Scalars['Float']['input'];
  placeId: Scalars['String']['input'];
  type: LocationTypes;
};

export enum LocationTypes {
  Custom = 'CUSTOM',
  GooglePlaces = 'GOOGLE_PLACES',
  LocationIq = 'LOCATION_IQ'
}

export type MarkDirectMessageReadInput = {
  messageId: Scalars['String']['input'];
  threadId: Scalars['String']['input'];
};

export type MemberActivityStat = Node & {
  __typename?: 'MemberActivityStat';
  activityDate: Scalars['DateTime']['output'];
  entityId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  type: ActivityTypes;
  user: User;
};

export type MemberCountStat = Node & {
  __typename?: 'MemberCountStat';
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  user: User;
};

export type MemberSubscription = {
  __typename?: 'MemberSubscription';
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  stripeCancelAtPeriodEnd: Scalars['Boolean']['output'];
  subscriptionPlan: MemberSubscriptionPlan;
};

export type MemberSubscriptionPlan = {
  __typename?: 'MemberSubscriptionPlan';
  active: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  groupEntitlements: Array<Group>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  pendingBalance: Scalars['Int']['output'];
  priceMonthlyUsd: Scalars['Float']['output'];
  stripeAccount?: Maybe<StripeAccount>;
  subscriberCount: Scalars['Int']['output'];
  totalRevenue: Scalars['Int']['output'];
};

export type MembersOrderByInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MembersPaginated = {
  __typename?: 'MembersPaginated';
  items: Array<OrganizationMembership>;
  totalCount: Scalars['Int']['output'];
};

export type MenuItem = Node & {
  __typename?: 'MenuItem';
  group?: Maybe<Group>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  post?: Maybe<Post>;
  resource?: Maybe<Resource>;
  sortIndex: Scalars['Float']['output'];
};

export type MenuSection = Node & {
  __typename?: 'MenuSection';
  id: Scalars['ID']['output'];
  menuItems: Array<MenuItem>;
  name?: Maybe<Scalars['String']['output']>;
  sortIndex: Scalars['Float']['output'];
  type: MenuSectionTypes;
};

export enum MenuSectionTypes {
  Courses = 'COURSES',
  Custom = 'CUSTOM',
  Groups = 'GROUPS'
}

export type MovePostInput = {
  groupId: Scalars['ID']['input'];
  postId: Scalars['ID']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptAllRequestsToJoin: Organization;
  acceptRequestToJoin: RequestToJoinPayload;
  addEmbedLoad: EmbedLoad;
  addEntryToWaitlist: WaitlistEntryPayload;
  addGroupMembers: GroupPayload;
  addGroupView: Group;
  addNewInvitedOrganization: Organization;
  addOrganization: Organization;
  addOrganizationVisit: Organization;
  addPostView: Post;
  assignRole: User;
  cancelAdminSubscription?: Maybe<AdminSubscription>;
  cancelMemberSubscription?: Maybe<MemberSubscription>;
  changeAdminSubscriptionPlan?: Maybe<AdminSubscription>;
  createAdminPlanCheckoutSession: CheckoutSession;
  createAdminPlanPortalSession: CheckoutSession;
  createAndJoinGroup: GroupPayload;
  createComment: Comment;
  createCourse: CoursePayload;
  createDirectMessage: DirectMessageUserThread;
  createEvent: EventPayload;
  createExpressDashboardSession: CheckoutSession;
  createGiphy: Giphy;
  createImage: Image;
  createMemberPlanCheckoutSession: CheckoutSession;
  createMemberPlanPortalSession: CheckoutSession;
  createMenuItemUrl: MenuItem;
  createMenuItemWithPost: MenuItem;
  createMenuSection: MenuSection;
  createOrDeleteDirectMessageReaction: DirectMessage;
  createOrDeletePostBookmark: PostBookmark;
  createOrDeleteReaction: Reaction;
  createOrUpdateOrganization: Organization;
  createOrganizationRole: OrganizationRole;
  createPoll: PollPayload;
  createResource: ResourcePayload;
  createStripeAccountLinkSession: CheckoutSession;
  createTemporaryImage: Image;
  createTemporaryPost: TemporaryPost;
  createTemporaryVideoPresignedUpload: VideoPresignedUpload;
  createUser: User;
  createUserAPNSPushSubscription: User;
  createUserFCMPushSubscription: User;
  createUserWebPushSubscription: User;
  createVideoPresignedUpload: VideoPresignedUpload;
  createVote: PollPayload;
  deactivateMemberSubscriptionPlan: MemberSubscriptionPlan;
  deleteComment: Comment;
  deleteDirectMessageThread: DirectMessageUserThread;
  deleteEvent: EventPayload;
  deleteGroup: GroupPayload;
  deleteImage: Image;
  deleteMenuItem: MenuItem;
  deleteMenuSection: MenuSection;
  deleteOrganization: Organization;
  deleteOrganizationRole: OrganizationRole;
  deletePoll: PollPayload;
  deletePost: PostPayload;
  deleteUser: User;
  deleteVote: PollPayload;
  demoteUser: User;
  dismissFrondIntroPost: OrganizationMembership;
  dismissWelcomePost: OrganizationMembership;
  duplicateOrganization: Organization;
  getDemoOrganization: Organization;
  hideOrganizationVisit: Organization;
  hideUpsell: UpsellPayload;
  inviteUsers: Array<InviteUsersPayload>;
  joinGroup: GroupPayload;
  joinOrganization: OrganizationJoinPayload;
  leaveOrganization: User;
  markAllNotificationsRead: NotificationCounts;
  markDirectMessageRead: DirectMessageUserThread;
  markNotificationRead: Notification;
  movePost: Post;
  profileSetup: User;
  promoteUser: User;
  publishCourse: Group;
  publishDraftPost: Post;
  reactivateMemberSubscription?: Maybe<MemberSubscription>;
  reactivateMemberSubscriptionPlan: MemberSubscriptionPlan;
  rejectRequestToJoin: RequestToJoinPayload;
  removeCustomDomain: Organization;
  removeGroupMember: GroupPayload;
  reorderGroups: GroupsConnection;
  reorderLesson: PostPayload;
  reorderMenuItems: Array<MenuItem>;
  reorderMenuSections: Array<MenuSection>;
  reorderOrganizationMembership: OrganizationMembership;
  requestToJoinOrganization: RequestToJoinPayload;
  rescheduleDraftPost: Post;
  resendUserInvite: UserInvite;
  resetOrganizationInvite: OrganizationInvite;
  restoreUser: User;
  revokeUserInvites: Array<UserInvite>;
  sendDigestToUser?: Maybe<Organization>;
  sendFeedbackEmail: User;
  sendNotificationsDigestToUser?: Maybe<Organization>;
  setCustomDomain: Organization;
  suspendUser: User;
  toggleAutoMessageNewMembers: Organization;
  toggleCommentAsBestAnswer: Comment;
  toggleEventNotifications: EventPayload;
  toggleEventPostCreationPolicy: EventPayload;
  toggleFollowPost: Post;
  toggleGroupAutoAddMembers: Group;
  toggleGroupEnableNewPostNotificationsForNewMembers: Group;
  toggleGroupIncludePostsOnHome: Group;
  toggleHideFromProfilePost: Post;
  toggleLessonCompleted: PostPayload;
  toggleNewMemberIntroductions: Organization;
  toggleOrganizationFeature: Organization;
  toggleOrganizationMembershipOnboardingTask: OrganizationMembership;
  togglePinnedPost: Post;
  togglePublicCommunity: Organization;
  toggleShowAuthorOnPost: Post;
  toggleShowCommentsAndReactionsOnPost: Post;
  toggleWelcomeGroup: Group;
  toggleWelcomePost: Post;
  unassignRole: User;
  unfurlUrl: ResourcePayload;
  updateAutoMessageNewMembersMessage: Organization;
  updateComment: Comment;
  updateDirectMessageThreadSettings: DirectMessageUserThreadSettings;
  updateEvent: EventPayload;
  updateGroup: GroupPayload;
  updateImageCoordinates: Image;
  updateMembership: GroupMembership;
  updateMenuItem: MenuItem;
  updateMenuSection: MenuSection;
  updateNewMemberIntroductionMessage: Organization;
  updateOrganizationRole: OrganizationRole;
  updateTemporaryPost: TemporaryPost;
  updateUser: User;
  updateUserDigestNotificationFrequency: UserPreferences;
  updateUserLanguage: User;
  updateUserNotificationPreferences: UserNotificationPreferences;
  updateUserOnboarding: User;
  updateWidgetSizeVariant: Organization;
  upsertMemberSubscriptionPlan: MemberSubscriptionPlan;
  upsertPost: PostPayload;
};


export type MutationAcceptAllRequestsToJoinArgs = {
  input: AcceptAllRequestsToJoinInput;
};


export type MutationAcceptRequestToJoinArgs = {
  input: AcceptRequestToJoinInput;
};


export type MutationAddEmbedLoadArgs = {
  input: AddEmbedLoadInput;
};


export type MutationAddEntryToWaitlistArgs = {
  input: AddEntryToWaitlistInput;
};


export type MutationAddGroupMembersArgs = {
  input: AddGroupMembersInput;
};


export type MutationAddGroupViewArgs = {
  input: AddViewInput;
};


export type MutationAddNewInvitedOrganizationArgs = {
  input: AddNewInvitedOrganizationInput;
};


export type MutationAddOrganizationArgs = {
  input: AddOrganizationInput;
};


export type MutationAddOrganizationVisitArgs = {
  input: AddOrganizationVisitInput;
};


export type MutationAddPostViewArgs = {
  input: AddViewInput;
};


export type MutationAssignRoleArgs = {
  input: AssignRoleInput;
};


export type MutationCancelAdminSubscriptionArgs = {
  organizationId: Scalars['ID']['input'];
};


export type MutationCancelMemberSubscriptionArgs = {
  memberSubscriptionId: Scalars['ID']['input'];
};


export type MutationChangeAdminSubscriptionPlanArgs = {
  lookupKey: AdminSubscriptionPlanPriceLookupKey;
  organizationId: Scalars['ID']['input'];
};


export type MutationCreateAdminPlanCheckoutSessionArgs = {
  lookupKey: AdminSubscriptionPlanPriceLookupKey;
  organizationId: Scalars['String']['input'];
};


export type MutationCreateAdminPlanPortalSessionArgs = {
  organizationId: Scalars['String']['input'];
  returnUrl: Scalars['String']['input'];
};


export type MutationCreateAndJoinGroupArgs = {
  input: CreateGroupInput;
};


export type MutationCreateCommentArgs = {
  input: CreateCommentInput;
};


export type MutationCreateCourseArgs = {
  input: CreateCourseInput;
};


export type MutationCreateDirectMessageArgs = {
  input: CreateDirectMessageInput;
};


export type MutationCreateEventArgs = {
  input: CreateEventInput;
};


export type MutationCreateExpressDashboardSessionArgs = {
  organizationId: Scalars['String']['input'];
};


export type MutationCreateGiphyArgs = {
  input: CreateGiphyInput;
};


export type MutationCreateImageArgs = {
  input: CreateImageInput;
};


export type MutationCreateMemberPlanCheckoutSessionArgs = {
  memberSubscriptionPlanId: Scalars['String']['input'];
  returnToGroupId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateMemberPlanPortalSessionArgs = {
  returnUrl: Scalars['String']['input'];
};


export type MutationCreateMenuItemUrlArgs = {
  input: CreateMenuItemUrlInput;
};


export type MutationCreateMenuItemWithPostArgs = {
  input: CreateMenuItemWithPostInput;
};


export type MutationCreateMenuSectionArgs = {
  input: CreateMenuSectionInput;
};


export type MutationCreateOrDeleteDirectMessageReactionArgs = {
  input: CreateOrDeleteDirectMessageReactionInput;
};


export type MutationCreateOrDeletePostBookmarkArgs = {
  input: CreateOrDeletePostBookmarkInput;
};


export type MutationCreateOrDeleteReactionArgs = {
  input: CreateOrDeleteReactionInput;
};


export type MutationCreateOrUpdateOrganizationArgs = {
  input: CreateOrUpdateOrganizationInput;
};


export type MutationCreateOrganizationRoleArgs = {
  input: CreateOrganizationRoleInput;
};


export type MutationCreatePollArgs = {
  input: CreatePollInput;
};


export type MutationCreateResourceArgs = {
  input: CreateResourceInput;
};


export type MutationCreateStripeAccountLinkSessionArgs = {
  memberSubscriptionPlanId: Scalars['String']['input'];
};


export type MutationCreateTemporaryImageArgs = {
  input: CreateTemporaryImageInput;
};


export type MutationCreateTemporaryPostArgs = {
  input: CreateTemporaryPostInput;
};


export type MutationCreateTemporaryVideoPresignedUploadArgs = {
  input: CreateTemporaryVideoPresignedUploadInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateUserApnsPushSubscriptionArgs = {
  input: CreateUserApnsPushSubscriptionInput;
};


export type MutationCreateUserFcmPushSubscriptionArgs = {
  input: CreateUserFcmPushSubscriptionInput;
};


export type MutationCreateUserWebPushSubscriptionArgs = {
  input: CreateUserWebPushSubscriptionInput;
};


export type MutationCreateVideoPresignedUploadArgs = {
  input: CreateVideoPresignedUploadInput;
};


export type MutationCreateVoteArgs = {
  input: CreateVoteInput;
};


export type MutationDeactivateMemberSubscriptionPlanArgs = {
  memberSubscriptionPlanId: Scalars['String']['input'];
};


export type MutationDeleteCommentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDirectMessageThreadArgs = {
  input: DeleteDirectMessageThreadInput;
};


export type MutationDeleteEventArgs = {
  input: DeleteEventInput;
};


export type MutationDeleteGroupArgs = {
  input: DeleteGroupInput;
};


export type MutationDeleteImageArgs = {
  input: DeleteImageInput;
};


export type MutationDeleteMenuItemArgs = {
  input: DeleteMenuItemInput;
};


export type MutationDeleteMenuSectionArgs = {
  input: DeleteMenuSectionInput;
};


export type MutationDeleteOrganizationArgs = {
  organizationId: Scalars['ID']['input'];
};


export type MutationDeleteOrganizationRoleArgs = {
  input: DeleteOrganizationRoleInput;
};


export type MutationDeletePollArgs = {
  input: DeletePollInput;
};


export type MutationDeletePostArgs = {
  input: DeletePostInput;
};


export type MutationDeleteUserArgs = {
  userEmail: Scalars['String']['input'];
};


export type MutationDeleteVoteArgs = {
  input: DeleteVoteInput;
};


export type MutationDemoteUserArgs = {
  input: DemoteUserInput;
};


export type MutationDismissFrondIntroPostArgs = {
  input: DismissFrondIntroPostInput;
};


export type MutationDismissWelcomePostArgs = {
  input: DismissWelcomePostInput;
};


export type MutationDuplicateOrganizationArgs = {
  input: DuplicateOrganizationInput;
};


export type MutationHideOrganizationVisitArgs = {
  input: HideOrganizationVisitInput;
};


export type MutationHideUpsellArgs = {
  input: HideUpsellInput;
};


export type MutationInviteUsersArgs = {
  input: InviteUsersInput;
};


export type MutationJoinGroupArgs = {
  input: JoinGroupInput;
};


export type MutationJoinOrganizationArgs = {
  input: JoinOrganizationInput;
};


export type MutationLeaveOrganizationArgs = {
  input: LeaveOrganizationInput;
};


export type MutationMarkAllNotificationsReadArgs = {
  organizationId: Scalars['String']['input'];
};


export type MutationMarkDirectMessageReadArgs = {
  input: MarkDirectMessageReadInput;
};


export type MutationMarkNotificationReadArgs = {
  input: ReadNotificationInput;
};


export type MutationMovePostArgs = {
  input: MovePostInput;
};


export type MutationProfileSetupArgs = {
  input: ProfileSetupInput;
};


export type MutationPromoteUserArgs = {
  input: PromoteUserInput;
};


export type MutationPublishCourseArgs = {
  input: PublishCourseInput;
};


export type MutationPublishDraftPostArgs = {
  input: PublishDraftPostInput;
};


export type MutationReactivateMemberSubscriptionArgs = {
  memberSubscriptionId: Scalars['ID']['input'];
};


export type MutationReactivateMemberSubscriptionPlanArgs = {
  memberSubscriptionPlanId: Scalars['String']['input'];
};


export type MutationRejectRequestToJoinArgs = {
  input: RejectRequestToJoinInput;
};


export type MutationRemoveCustomDomainArgs = {
  organizationId: Scalars['ID']['input'];
};


export type MutationRemoveGroupMemberArgs = {
  input: RemoveGroupMemberInput;
};


export type MutationReorderGroupsArgs = {
  input: ReorderGroupsInput;
};


export type MutationReorderLessonArgs = {
  input: ReorderLessonInput;
};


export type MutationReorderMenuItemsArgs = {
  input: ReorderMenuItemsInput;
};


export type MutationReorderMenuSectionsArgs = {
  input: ReorderMenuSectionsInput;
};


export type MutationReorderOrganizationMembershipArgs = {
  input: ReorderOrganizationMembershipInput;
};


export type MutationRequestToJoinOrganizationArgs = {
  input: RequestToJoinOrganizationInput;
};


export type MutationRescheduleDraftPostArgs = {
  input: RescheduleDraftPostInput;
};


export type MutationResendUserInviteArgs = {
  input: ResendUserInviteInput;
};


export type MutationResetOrganizationInviteArgs = {
  input: ResetOrganizationInviteInput;
};


export type MutationRestoreUserArgs = {
  input: RestoreUserInput;
};


export type MutationRevokeUserInvitesArgs = {
  input: RevokeUserInvitesInput;
};


export type MutationSendDigestToUserArgs = {
  userEmail: Scalars['String']['input'];
};


export type MutationSendFeedbackEmailArgs = {
  input: SendFeedbackEmailInput;
};


export type MutationSendNotificationsDigestToUserArgs = {
  userEmail: Scalars['String']['input'];
};


export type MutationSetCustomDomainArgs = {
  input: SetOrganizationCustomDomainInput;
};


export type MutationSuspendUserArgs = {
  input: SuspendUserInput;
};


export type MutationToggleAutoMessageNewMembersArgs = {
  input: ToggleAutoMessageNewMembersInput;
};


export type MutationToggleCommentAsBestAnswerArgs = {
  input: ToggleCommentAsBestAnswerInput;
};


export type MutationToggleEventNotificationsArgs = {
  input: ToggleEventNotificationsInput;
};


export type MutationToggleEventPostCreationPolicyArgs = {
  input: ToggleEventPostCreationPolicyInput;
};


export type MutationToggleFollowPostArgs = {
  input: ToggleFollowPostInput;
};


export type MutationToggleGroupAutoAddMembersArgs = {
  input: ToggleGroupAutoAddMembersInput;
};


export type MutationToggleGroupEnableNewPostNotificationsForNewMembersArgs = {
  input: ToggleGroupEnableNewPostNotificationsForNewMembersInput;
};


export type MutationToggleGroupIncludePostsOnHomeArgs = {
  input: ToggleGroupIncludePostsOnHomeInput;
};


export type MutationToggleHideFromProfilePostArgs = {
  input: ToggleHideFromProfilePostInput;
};


export type MutationToggleLessonCompletedArgs = {
  input: ToggleLessonCompletedInput;
};


export type MutationToggleNewMemberIntroductionsArgs = {
  input: ToggleNewMemberIntroductionsInput;
};


export type MutationToggleOrganizationFeatureArgs = {
  input: ToggleOrganizationFeatureInput;
};


export type MutationToggleOrganizationMembershipOnboardingTaskArgs = {
  input: ToggleOrganizationMembershipOnboardingTaskInput;
};


export type MutationTogglePinnedPostArgs = {
  input: TogglePinnedPostInput;
};


export type MutationTogglePublicCommunityArgs = {
  input: TogglePublicCommunityInput;
};


export type MutationToggleShowAuthorOnPostArgs = {
  input: ToggleShowAuthorOnPostInput;
};


export type MutationToggleShowCommentsAndReactionsOnPostArgs = {
  input: ToggleShowCommentsAndReactionsOnPostInput;
};


export type MutationToggleWelcomeGroupArgs = {
  input: ToggleWelcomeGroupInput;
};


export type MutationToggleWelcomePostArgs = {
  input: ToggleWelcomePostInput;
};


export type MutationUnassignRoleArgs = {
  input: UnassignRoleInput;
};


export type MutationUnfurlUrlArgs = {
  input: UnfurlUrlInput;
};


export type MutationUpdateAutoMessageNewMembersMessageArgs = {
  input: UpdateAutoMessageNewMembersMessageInput;
};


export type MutationUpdateCommentArgs = {
  input: UpdateCommentInput;
};


export type MutationUpdateDirectMessageThreadSettingsArgs = {
  input: UpdateDirectMessageUserThreadSettingsInput;
};


export type MutationUpdateEventArgs = {
  input: UpdateEventInput;
};


export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};


export type MutationUpdateImageCoordinatesArgs = {
  input: UpdateImageCoordinatesInput;
};


export type MutationUpdateMembershipArgs = {
  input: UpdateMembershipInput;
};


export type MutationUpdateMenuItemArgs = {
  input: UpdateMenuItemInput;
};


export type MutationUpdateMenuSectionArgs = {
  input: UpdateMenuSectionInput;
};


export type MutationUpdateNewMemberIntroductionMessageArgs = {
  input: UpdateNewMemberIntroductionMessageInput;
};


export type MutationUpdateOrganizationRoleArgs = {
  input: UpdateOrganizationRoleInput;
};


export type MutationUpdateTemporaryPostArgs = {
  input: UpdateTemporaryPostInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserDigestNotificationFrequencyArgs = {
  input: UpdateUserDigestNotificationFrequencyInput;
};


export type MutationUpdateUserLanguageArgs = {
  input: UpdateUserLanguageInput;
};


export type MutationUpdateUserNotificationPreferencesArgs = {
  input: UpdateUserNotificationPreferencesInput;
};


export type MutationUpdateUserOnboardingArgs = {
  input: UpdateUserOnboardingInput;
};


export type MutationUpdateWidgetSizeVariantArgs = {
  input: UpdateWidgetSizeVariantInput;
};


export type MutationUpsertMemberSubscriptionPlanArgs = {
  input: UpsertMemberSubscriptionPlanInput;
};


export type MutationUpsertPostArgs = {
  input: UpsertPostInput;
};

export type Node = {
  id: Scalars['ID']['output'];
};

export type Notification = Node & {
  __typename?: 'Notification';
  admin?: Maybe<User>;
  content: Scalars['JSON']['output'];
  createdAt: Scalars['DateTime']['output'];
  emojiUnicode?: Maybe<Scalars['String']['output']>;
  eventName: NotificationEventNames;
  group?: Maybe<Group>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  link: Scalars['String']['output'];
  memberSubscription?: Maybe<MemberSubscription>;
  memberSubscriptionPlan?: Maybe<MemberSubscriptionPlan>;
  otherUser?: Maybe<User>;
  quoteContent?: Maybe<Scalars['JSON']['output']>;
  read: Scalars['Boolean']['output'];
  requestToJoins: Array<RequestToJoin>;
  role?: Maybe<OrganizationRole>;
  text: Scalars['String']['output'];
  type: NotificationTypes;
  user?: Maybe<User>;
};

export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  edges: Array<NotificationObjectEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type NotificationCounts = {
  __typename?: 'NotificationCounts';
  unread: Scalars['Float']['output'];
};

export enum NotificationEventNames {
  CommentCreated = 'COMMENT_CREATED',
  CommentMentioned = 'COMMENT_MENTIONED',
  CommentParentMentioned = 'COMMENT_PARENT_MENTIONED',
  CommentReplied = 'COMMENT_REPLIED',
  EventCreated = 'EVENT_CREATED',
  EventDateChanged = 'EVENT_DATE_CHANGED',
  EventDeleted = 'EVENT_DELETED',
  EventLocationChanged = 'EVENT_LOCATION_CHANGED',
  EventReminder = 'EVENT_REMINDER',
  EventTimeChanged = 'EVENT_TIME_CHANGED',
  GroupPostCreated = 'GROUP_POST_CREATED',
  GroupPostUpdated = 'GROUP_POST_UPDATED',
  GroupQuestionOccurrence = 'GROUP_QUESTION_OCCURRENCE',
  MemberSubscriptionCanceled = 'MEMBER_SUBSCRIPTION_CANCELED',
  MemberSubscriptionCreated = 'MEMBER_SUBSCRIPTION_CREATED',
  MemberSubscriptionExpiry = 'MEMBER_SUBSCRIPTION_EXPIRY',
  MemberSubscriptionGifted = 'MEMBER_SUBSCRIPTION_GIFTED',
  MemberSubscriptionPlanPriceChanged = 'MEMBER_SUBSCRIPTION_PLAN_PRICE_CHANGED',
  NewMemberIntroduction = 'NEW_MEMBER_INTRODUCTION',
  ParentMentioned = 'PARENT_MENTIONED',
  PollVoteCreated = 'POLL_VOTE_CREATED',
  ReactionCreated = 'REACTION_CREATED',
  ReactionParentMentioned = 'REACTION_PARENT_MENTIONED',
  RequestToJoin = 'REQUEST_TO_JOIN',
  RequestToJoinInvite = 'REQUEST_TO_JOIN_INVITE',
  UserJoined = 'USER_JOINED',
  UserPromoted = 'USER_PROMOTED',
  UserRoleAssignment = 'USER_ROLE_ASSIGNMENT'
}

export type NotificationObjectEdge = {
  __typename?: 'NotificationObjectEdge';
  cursor: Scalars['String']['output'];
  node: Notification;
};

export enum NotificationPreferencesType {
  Email = 'EMAIL',
  Mobile = 'MOBILE',
  Web = 'WEB'
}

export enum NotificationTypes {
  Group = 'GROUP'
}

export type Organization = Node & {
  __typename?: 'Organization';
  activeMembers: Array<User>;
  addMemberPolicy: AddMemberPolicy;
  adminSubscription?: Maybe<AdminSubscription>;
  administrators: Array<User>;
  autoMessageNewMembersFromId?: Maybe<Scalars['String']['output']>;
  autoMessageNewMembersMessage: Scalars['String']['output'];
  color: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  customDomain?: Maybe<CustomDomain>;
  description?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  enableAutoMessageNewMembers: Scalars['Boolean']['output'];
  enableNewMemberIntroductions: Scalars['Boolean']['output'];
  entitlements?: Maybe<OrganizationEntitlements>;
  groupCreationPolicy: GroupCreationPolicy;
  groups: Array<Group>;
  hasCoursesEnabled: Scalars['Boolean']['output'];
  hasEventsEnabled: Scalars['Boolean']['output'];
  hasInsightsEnabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isGoogleWorkspace: Scalars['Boolean']['output'];
  isPublic: Scalars['Boolean']['output'];
  isSetup: Scalars['Boolean']['output'];
  joinPolicy: JoinPolicy;
  legacyShortId?: Maybe<Scalars['String']['output']>;
  logoId?: Maybe<Scalars['String']['output']>;
  memberSubscriptionPlan?: Maybe<MemberSubscriptionPlan>;
  name: Scalars['String']['output'];
  newMemberIntroductionMessage: Scalars['String']['output'];
  numGroups: Scalars['Int']['output'];
  numMembers: Scalars['Int']['output'];
  quotaUsage: Array<QuotaUsage>;
  quotas?: Maybe<Array<QuotaUsage>>;
  shortId: Scalars['String']['output'];
  templateType: OrganizationTemplateType;
  widgetSizeVariant: WidgetSizeVariant;
};

export type OrganizationConnection = {
  __typename?: 'OrganizationConnection';
  edges: Array<OrganizationObjectEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OrganizationEntitlements = {
  __typename?: 'OrganizationEntitlements';
  features: Array<FeatureEntitlement>;
  quotas: Array<QuotaEntitlements>;
};

export type OrganizationInvite = Node & {
  __typename?: 'OrganizationInvite';
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  inviteCode: Scalars['String']['output'];
};

export type OrganizationJoinError = {
  __typename?: 'OrganizationJoinError';
  message: Scalars['String']['output'];
};

export type OrganizationJoinPayload = Organization | OrganizationJoinError;

export type OrganizationMembership = Node & {
  __typename?: 'OrganizationMembership';
  active: Scalars['Boolean']['output'];
  completedOnboardingTasks: Array<OrganizationMembershipOnboardingTasks>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  hideFrondIntroPost: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isCreator: Scalars['Boolean']['output'];
  organization: Organization;
  roles: Array<OrganizationRole>;
  user: User;
};

export enum OrganizationMembershipOnboardingTasks {
  ActivateTrial = 'ACTIVATE_TRIAL',
  AddAdmins = 'ADD_ADMINS',
  AddCourses = 'ADD_COURSES',
  AddDomain = 'ADD_DOMAIN',
  AddEvents = 'ADD_EVENTS',
  AddMembers = 'ADD_MEMBERS',
  CreateRoles = 'CREATE_ROLES',
  EditPermissions = 'EDIT_PERMISSIONS',
  SetUpNewMemberOnboarding = 'SET_UP_NEW_MEMBER_ONBOARDING',
  SetUpPaywall = 'SET_UP_PAYWALL'
}

export type OrganizationObjectEdge = {
  __typename?: 'OrganizationObjectEdge';
  cursor: Scalars['String']['output'];
  node: Organization;
};

export type OrganizationRole = Node & {
  __typename?: 'OrganizationRole';
  color: OrganizationRoleColor;
  id: Scalars['ID']['output'];
  isAdmin: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  memberSubscriptionPlan?: Maybe<MemberSubscriptionPlan>;
  users: Array<User>;
};

export enum OrganizationRoleColor {
  Blue = 'BLUE',
  Gray = 'GRAY',
  Green = 'GREEN',
  Purple = 'PURPLE',
  Red = 'RED',
  Yellow = 'YELLOW'
}

export type OrganizationShortId = {
  __typename?: 'OrganizationShortId';
  shortId: Scalars['String']['output'];
};

export type OrganizationShortIdError = {
  __typename?: 'OrganizationShortIdError';
  message: Scalars['String']['output'];
};

export type OrganizationShortIdPayload = OrganizationShortId | OrganizationShortIdError;

export type OrganizationStats = Node & {
  __typename?: 'OrganizationStats';
  id: Scalars['ID']['output'];
  totalComments: Scalars['Int']['output'];
  totalCommentsInTheLast14Days: Scalars['Int']['output'];
  totalMembers: Scalars['Int']['output'];
  totalMembersInTheLast14Days: Scalars['Int']['output'];
  totalPosts: Scalars['Int']['output'];
  totalPostsInTheLast14Days: Scalars['Int']['output'];
  totalReactions: Scalars['Int']['output'];
  totalReactionsInTheLast14Days: Scalars['Int']['output'];
};

export enum OrganizationTemplateType {
  BuildTogether = 'BUILD_TOGETHER',
  ClubOrAlumniGroup = 'CLUB_OR_ALUMNI_GROUP',
  ContentCreator = 'CONTENT_CREATOR',
  Personal = 'PERSONAL',
  StartFromScratch = 'START_FROM_SCRATCH',
  Work = 'WORK'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
};

export type Poll = Node & {
  __typename?: 'Poll';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  options: Array<PollOption>;
};

export type PollError = {
  __typename?: 'PollError';
  message: Scalars['String']['output'];
};

export type PollOption = Node & {
  __typename?: 'PollOption';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  votes: Array<PollVote>;
};

export type PollPayload = Poll | PollError;

export type PollVote = Node & {
  __typename?: 'PollVote';
  createdBy: User;
  id: Scalars['ID']['output'];
};

export type Post = Node & {
  __typename?: 'Post';
  aspectRatio: Scalars['Float']['output'];
  bgColor?: Maybe<Scalars['String']['output']>;
  colorTheme: PostColorTheme;
  comments: Array<Comment>;
  completions: Array<LessonCompletion>;
  content?: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  group: Group;
  hideFromProfile: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isBookmarked: Scalars['Boolean']['output'];
  isDraft: Scalars['Boolean']['output'];
  media: Array<PostMedia>;
  numOfAnonymousViews: Scalars['Int']['output'];
  organization: Organization;
  pinned: Scalars['Boolean']['output'];
  poll?: Maybe<Poll>;
  reactions: Array<Reaction>;
  resource?: Maybe<Resource>;
  resources: Array<Resource>;
  revisions: Array<PostRevision>;
  scheduledAt?: Maybe<Scalars['DateTime']['output']>;
  showAuthor: Scalars['Boolean']['output'];
  showCommentsAndReactions: Scalars['Boolean']['output'];
  sortIndex?: Maybe<Scalars['Float']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type: PostTypes;
  updatedAt: Scalars['DateTime']['output'];
  views: Array<PostView>;
};

export type PostBookmark = Node & {
  __typename?: 'PostBookmark';
  id: Scalars['ID']['output'];
  post: Post;
};

export type PostBookmarkConnection = {
  __typename?: 'PostBookmarkConnection';
  edges: Array<PostBookmarkObjectEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PostBookmarkObjectEdge = {
  __typename?: 'PostBookmarkObjectEdge';
  cursor: Scalars['String']['output'];
  node: PostBookmark;
};

export enum PostColorTheme {
  Blue = 'BLUE',
  Gray = 'GRAY',
  Green = 'GREEN',
  Purple = 'PURPLE',
  Red = 'RED',
  Yellow = 'YELLOW'
}

export type PostConnection = {
  __typename?: 'PostConnection';
  edges: Array<PostObjectEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum PostCreationPolicy {
  Administrators = 'ADMINISTRATORS',
  Unrestricted = 'UNRESTRICTED'
}

export type PostError = {
  __typename?: 'PostError';
  message: Scalars['String']['output'];
};

export type PostMedia = Giphy | Image | Video;

export type PostObjectEdge = {
  __typename?: 'PostObjectEdge';
  cursor: Scalars['String']['output'];
  node: Post;
};

export type PostPayload = Post | PostError;

export type PostRevision = Node & {
  __typename?: 'PostRevision';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
};

export type PostSubscription = Node & {
  __typename?: 'PostSubscription';
  id: Scalars['ID']['output'];
  postId: Scalars['ID']['output'];
};

export enum PostTypes {
  Event = 'EVENT',
  Lesson = 'LESSON',
  NewUser = 'NEW_USER',
  Regular = 'REGULAR',
  Welcome = 'WELCOME'
}

export type PostView = Node & {
  __typename?: 'PostView';
  id: Scalars['ID']['output'];
  level: ViewLevels;
  location?: Maybe<ViewLocations>;
  user: User;
};

export type Price = {
  __typename?: 'Price';
  currency: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lookupKey: AdminSubscriptionPlanPriceLookupKey;
  unitAmount?: Maybe<Scalars['Float']['output']>;
};

export type PriceList = {
  __typename?: 'PriceList';
  prices: Array<Price>;
};

export type ProfileSetupInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  avatarBgColor?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  profileImageId?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
  username: Scalars['String']['input'];
};

export type PromoteUserInput = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type PublishCourseInput = {
  courseId: Scalars['ID']['input'];
};

export type PublishDraftPostInput = {
  postId: Scalars['ID']['input'];
};

export type PushEvent = PushRefetchHint;

export type PushRefetchHint = {
  __typename?: 'PushRefetchHint';
  groupId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  postId?: Maybe<Scalars['ID']['output']>;
  userId: Scalars['ID']['output'];
};

export type Query = {
  __typename?: 'Query';
  adminSubscriptionPlanPriceList: PriceList;
  allUsers: UserConnection;
  checkIfWidgetIsDetected?: Maybe<EmbedLoad>;
  comment?: Maybe<Comment>;
  commentConfig: CommentConfig;
  courseCompletion: CourseCompletionPayload;
  customDomainVerificationInfo: CustomDomainVerificationInfo;
  directMessage: DirectMessage;
  directMessageNotificationCounts: DirectMessageNotificationCounts;
  directMessageThreads: Array<DirectMessageUserThread>;
  directMessageToken: DirectMessageToken;
  directMessageUserThread?: Maybe<DirectMessageUserThread>;
  draftPosts: PostConnection;
  event: EventPayload;
  events: EventsConnection;
  group?: Maybe<GroupPayload>;
  groups: GroupsConnection;
  groupsMenuSectionsState: GroupsMenuSectionsState;
  groupsSidebarState: GroupsSidebarState;
  helloPageByShortId?: Maybe<HelloPage>;
  homeFeed?: Maybe<FeedItemConnection>;
  homeFeedLatestItem?: Maybe<FeedItem>;
  inboxState: InboxState;
  isFollowingPost?: Maybe<PostSubscription>;
  lessons: PostConnection;
  locationByName: Array<Location>;
  locationByPlaceId: Location;
  memberSubscriptionPlan: MemberSubscriptionPlan;
  members: MembersPaginated;
  membersWithHighestCommentCount: Array<MemberCountStat>;
  membersWithHighestPostCount: Array<MemberCountStat>;
  membersWithHighestReactionCount: Array<MemberCountStat>;
  menuSections: Array<MenuSection>;
  mostRecentOrganizationActivity: Array<MemberActivityStat>;
  newMemberPost?: Maybe<Post>;
  newMemberships: Array<OrganizationMembership>;
  notification: Notification;
  notificationCounts: NotificationCounts;
  notificationPreferences: Array<UserNotificationPreferences>;
  notifications: NotificationConnection;
  organization?: Maybe<Organization>;
  organizationByDomain?: Maybe<Organization>;
  organizationByInviteCode?: Maybe<Organization>;
  organizationByShortId?: Maybe<Organization>;
  organizationInvite: OrganizationInvite;
  organizationRoles: Array<OrganizationRole>;
  organizationRolesByUserId: Array<OrganizationRole>;
  organizationStats: OrganizationStats;
  organizations: OrganizationConnection;
  post?: Maybe<PostPayload>;
  postBookmarks: PostBookmarkConnection;
  postsByEvent: PostConnection;
  postsByGroup: PostConnection;
  publicOrganizationShortIds: Array<Scalars['String']['output']>;
  search?: Maybe<Array<Search>>;
  shortIdAvailable: OrganizationShortIdPayload;
  shortIdSuggestion: OrganizationShortIdPayload;
  temporaryPost?: Maybe<TemporaryPost>;
  upsells: Array<Upsell>;
  user?: Maybe<User>;
  userActivityCounts?: Maybe<UserActivityCounts>;
  userInvites: InvitesPaginated;
  userRequestToJoinOrganization?: Maybe<RequestToJoin>;
  userRequestsToJoinOrganization: RequestsPaginated;
  usernameAvailable: UsernamePayload;
  users: UserConnection;
  video?: Maybe<Video>;
  viewer: User;
  visitedPublicOrganizations: Array<Organization>;
  welcomeGroup: Group;
  welcomePost?: Maybe<PostPayload>;
};


export type QueryAllUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCheckIfWidgetIsDetectedArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryCommentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCourseCompletionArgs = {
  courseId: Scalars['ID']['input'];
};


export type QueryCustomDomainVerificationInfoArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryDirectMessageArgs = {
  id: Scalars['String']['input'];
  threadId: Scalars['String']['input'];
};


export type QueryDirectMessageNotificationCountsArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryDirectMessageThreadsArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryDirectMessageTokenArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryDirectMessageUserThreadArgs = {
  organizationId: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type QueryDraftPostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  isScheduled?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['ID']['input'];
};


export type QueryEventArgs = {
  id: Scalars['String']['input'];
};


export type QueryEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
  past?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGroupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
};


export type QueryHelloPageByShortIdArgs = {
  shortId: Scalars['String']['input'];
};


export type QueryHomeFeedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
  tag?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};


export type QueryHomeFeedLatestItemArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryIsFollowingPostArgs = {
  postId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryLessonsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  courseId: Scalars['String']['input'];
  first: Scalars['Int']['input'];
};


export type QueryLocationByNameArgs = {
  name: Scalars['String']['input'];
};


export type QueryLocationByPlaceIdArgs = {
  placeId: Scalars['String']['input'];
};


export type QueryMemberSubscriptionPlanArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMembersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MembersOrderByInput>;
  organizationId: Scalars['String']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMembersWithHighestCommentCountArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryMembersWithHighestPostCountArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryMembersWithHighestReactionCountArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryMenuSectionsArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryMostRecentOrganizationActivityArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryNewMemberPostArgs = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryNewMembershipsArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryNotificationArgs = {
  id: Scalars['String']['input'];
};


export type QueryNotificationCountsArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryNotificationPreferencesArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
};


export type QueryOrganizationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrganizationByDomainArgs = {
  domain: Scalars['String']['input'];
};


export type QueryOrganizationByInviteCodeArgs = {
  inviteCode: Scalars['ID']['input'];
};


export type QueryOrganizationByShortIdArgs = {
  shortId: Scalars['ID']['input'];
};


export type QueryOrganizationInviteArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryOrganizationRolesArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryOrganizationRolesByUserIdArgs = {
  organizationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type QueryOrganizationStatsArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryOrganizationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPostArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPostBookmarksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryPostsByEventArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['ID']['input'];
  first: Scalars['Int']['input'];
};


export type QueryPostsByGroupArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  groupId: Scalars['ID']['input'];
};


export type QuerySearchArgs = {
  organizationId: Scalars['String']['input'];
  query: Scalars['String']['input'];
};


export type QueryShortIdAvailableArgs = {
  shortId: Scalars['String']['input'];
};


export type QueryShortIdSuggestionArgs = {
  name: Scalars['String']['input'];
};


export type QueryTemporaryPostArgs = {
  token: Scalars['String']['input'];
};


export type QueryUpsellsArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryUserArgs = {
  organizationId: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type QueryUserActivityCountsArgs = {
  organizationId: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type QueryUserInvitesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<InvitesOrderByInput>;
  organizationId: Scalars['String']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserRequestToJoinOrganizationArgs = {
  groupId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};


export type QueryUserRequestsToJoinOrganizationArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RequestsOrderByInput>;
  organizationId: Scalars['String']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUsernameAvailableArgs = {
  username: Scalars['String']['input'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  groupId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryVideoArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVisitedPublicOrganizationsArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryWelcomeGroupArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryWelcomePostArgs = {
  organizationId: Scalars['ID']['input'];
};

export type QuotaEntitlements = {
  __typename?: 'QuotaEntitlements';
  limit: Scalars['Int']['output'];
  name: FeatureQuota;
};

export type QuotaUsage = {
  __typename?: 'QuotaUsage';
  limit: Scalars['Int']['output'];
  name: FeatureQuota;
  used: Scalars['Int']['output'];
};

export type Reaction = Node & {
  __typename?: 'Reaction';
  createdByUsers: Array<User>;
  emojiUnicode: Scalars['String']['output'];
  hasReacted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  numReactions: Scalars['Int']['output'];
  type: ReactionTypes;
};

export enum ReactionTypes {
  Comment = 'COMMENT',
  DirectMessage = 'DIRECT_MESSAGE',
  Post = 'POST'
}

export type ReadNotificationInput = {
  notificationId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type RejectRequestToJoinInput = {
  requestToJoinId: Scalars['String']['input'];
};

export type RemoveGroupMemberInput = {
  groupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type ReorderGroupsInput = {
  groupIds: Array<Scalars['String']['input']>;
};

export type ReorderLessonInput = {
  lessonId: Scalars['ID']['input'];
  order: Scalars['Float']['input'];
};

export type ReorderMenuItemsInput = {
  itemIds: Array<Scalars['String']['input']>;
  sectionId: Scalars['String']['input'];
};

export type ReorderMenuSectionsInput = {
  sectionIds: Array<Scalars['String']['input']>;
};

export type ReorderOrganizationMembershipInput = {
  order: Scalars['Float']['input'];
  organizationMembershipId: Scalars['ID']['input'];
};

export type RequestToJoin = Node & {
  __typename?: 'RequestToJoin';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  status: RequestToJoinStatusTypes;
  user: User;
};

export type RequestToJoinError = {
  __typename?: 'RequestToJoinError';
  message: Scalars['String']['output'];
};

export type RequestToJoinOrganizationInput = {
  email: Scalars['String']['input'];
  groupId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};

export type RequestToJoinPayload = RequestToJoin | RequestToJoinError;

export enum RequestToJoinStatusTypes {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type RequestsOrderByInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RequestsPaginated = {
  __typename?: 'RequestsPaginated';
  items: Array<RequestToJoin>;
  totalCount: Scalars['Int']['output'];
};

export type RescheduleDraftPostInput = {
  postId: Scalars['ID']['input'];
  scheduledAt: Scalars['DateTime']['input'];
};

export type ResendUserInviteInput = {
  userInviteId: Scalars['ID']['input'];
};

export type ResetOrganizationInviteInput = {
  organizationId: Scalars['ID']['input'];
};

export type Resource = Node & {
  __typename?: 'Resource';
  description?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  expanded: Scalars['Boolean']['output'];
  favicon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  logoTitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type ResourceError = {
  __typename?: 'ResourceError';
  message: Scalars['String']['output'];
};

export type ResourceInput = {
  expanded: Scalars['Boolean']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type ResourcePayload = Resource | ResourceError;

export type RestoreUserInput = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type RevokeUserInvitesInput = {
  email: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type Search = Node & {
  __typename?: 'Search';
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  excerpt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  link: Scalars['String']['output'];
  node?: Maybe<SearchItem>;
  rank: Scalars['Float']['output'];
  type: SearchResultTypes;
};

export type SearchItem = Group;

export enum SearchResultTypes {
  Comment = 'COMMENT',
  Group = 'GROUP',
  Post = 'POST',
  Resource = 'RESOURCE',
  User = 'USER'
}

export type SendFeedbackEmailInput = {
  feedback?: InputMaybe<Scalars['String']['input']>;
  feeling?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  url: Scalars['String']['input'];
};

export enum SeriesSelection {
  AllEvents = 'ALL_EVENTS',
  FollowingEvents = 'FOLLOWING_EVENTS',
  ThisAndFollowingEvents = 'THIS_AND_FOLLOWING_EVENTS',
  ThisEvent = 'THIS_EVENT'
}

export type SetOrganizationCustomDomainInput = {
  domain: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type StripeAccount = {
  __typename?: 'StripeAccount';
  createdAt: Scalars['DateTime']['output'];
  disabledReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  organizationPushEvent: PushEvent;
  userPushEvent: PushEvent;
};


export type SubscriptionOrganizationPushEventArgs = {
  id: Scalars['ID']['input'];
};

export type SuspendUserInput = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type TemporaryPost = Node & {
  __typename?: 'TemporaryPost';
  group?: Maybe<Group>;
  id: Scalars['ID']['output'];
  media: Array<PostMedia>;
  token: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
};

export type ToggleAutoMessageNewMembersInput = {
  organizationId: Scalars['ID']['input'];
};

export type ToggleCommentAsBestAnswerInput = {
  id: Scalars['ID']['input'];
};

export type ToggleEventNotificationsInput = {
  eventId: Scalars['ID']['input'];
};

export type ToggleEventPostCreationPolicyInput = {
  eventId: Scalars['ID']['input'];
};

export type ToggleFollowPostInput = {
  postId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type ToggleGroupAutoAddMembersInput = {
  addExistingMembers?: InputMaybe<Scalars['Boolean']['input']>;
  groupId: Scalars['String']['input'];
};

export type ToggleGroupEnableNewPostNotificationsForNewMembersInput = {
  groupId: Scalars['String']['input'];
};

export type ToggleGroupIncludePostsOnHomeInput = {
  groupId: Scalars['String']['input'];
};

export type ToggleHideFromProfilePostInput = {
  postId: Scalars['ID']['input'];
};

export type ToggleLessonCompletedInput = {
  lessonId: Scalars['ID']['input'];
};

export type ToggleNewMemberIntroductionsInput = {
  organizationId: Scalars['ID']['input'];
};

export type ToggleOrganizationFeatureInput = {
  feature: FeatureEntitlement;
  organizationId: Scalars['ID']['input'];
};

export type ToggleOrganizationMembershipOnboardingTaskInput = {
  organizationMembershipId: Scalars['ID']['input'];
  task: OrganizationMembershipOnboardingTasks;
};

export type TogglePinnedPostInput = {
  postId: Scalars['ID']['input'];
};

export type TogglePublicCommunityInput = {
  organizationId: Scalars['ID']['input'];
};

export type ToggleShowAuthorOnPostInput = {
  postId: Scalars['ID']['input'];
};

export type ToggleShowCommentsAndReactionsOnPostInput = {
  postId: Scalars['ID']['input'];
};

export type ToggleWelcomeGroupInput = {
  organizationId: Scalars['ID']['input'];
};

export type ToggleWelcomePostInput = {
  organizationId: Scalars['ID']['input'];
};

export type UnassignRoleInput = {
  organizationId: Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type UnfurlUrlInput = {
  url: Scalars['String']['input'];
};

export type UpdateAutoMessageNewMembersMessageInput = {
  message: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type UpdateCommentInput = {
  content: Scalars['JSON']['input'];
  id: Scalars['ID']['input'];
  mediaIds: Array<Scalars['ID']['input']>;
  resources: Array<ResourceInput>;
};

export type UpdateDirectMessageUserThreadSettingsInput = {
  markedUnread?: InputMaybe<Scalars['Boolean']['input']>;
  mutedNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  threadId: Scalars['String']['input'];
};

export type UpdateEventInput = {
  coverImageId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['JSON']['input']>;
  endAt: Scalars['DateTime']['input'];
  eventId: Scalars['ID']['input'];
  location?: InputMaybe<LocationInput>;
  meetingUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  recurringCronExpression?: InputMaybe<Scalars['String']['input']>;
  recurringOccurrencesLimit?: InputMaybe<Scalars['Int']['input']>;
  seriesSelection?: InputMaybe<SeriesSelection>;
  startAt: Scalars['DateTime']['input'];
  timeZone: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGroupInput = {
  coverImageId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['JSON']['input']>;
  emoji?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  postCreationPolicy?: InputMaybe<PostCreationPolicy>;
  questionCronExpression?: InputMaybe<Scalars['String']['input']>;
  questionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  questionText?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateImageCoordinatesInput = {
  height: Scalars['Int']['input'];
  imageId: Scalars['ID']['input'];
  width: Scalars['Int']['input'];
  x: Scalars['Int']['input'];
  y: Scalars['Int']['input'];
};

export type UpdateMembershipInput = {
  allowNewOccurrenceNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  allowNewPostNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  groupId: Scalars['ID']['input'];
};

export type UpdateMenuItemInput = {
  itemId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UpdateMenuSectionInput = {
  name: Scalars['String']['input'];
  sectionId: Scalars['ID']['input'];
};

export type UpdateNewMemberIntroductionMessageInput = {
  message: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type UpdateOrganizationRoleInput = {
  color?: InputMaybe<OrganizationRoleColor>;
  label: Scalars['String']['input'];
  roleId: Scalars['ID']['input'];
};

export type UpdateTemporaryPostInput = {
  mediaIds: Array<Scalars['ID']['input']>;
  temporaryPostId: Scalars['ID']['input'];
};

export type UpdateUserDigestNotificationFrequencyInput = {
  frequency: DigestNotificationFrequency;
  userId: Scalars['ID']['input'];
};

export type UpdateUserInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  avatarBgColor?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  coverImageId?: InputMaybe<Scalars['String']['input']>;
  currentCity?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hometown?: InputMaybe<Scalars['String']['input']>;
  instagramHandle?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  profileImageId?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserLanguageInput = {
  language?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type UpdateUserNotificationPreferencesInput = {
  allowAccountNotifications: Scalars['Boolean']['input'];
  allowCommentNotifications: Scalars['Boolean']['input'];
  allowDirectMessageNotifications: Scalars['Boolean']['input'];
  allowGroupNotifications: Scalars['Boolean']['input'];
  allowMentionNotifications: Scalars['Boolean']['input'];
  allowNewMemberRequestNotifications: Scalars['Boolean']['input'];
  allowNewPostNotifications: Scalars['Boolean']['input'];
  allowPollNotifications: Scalars['Boolean']['input'];
  allowReactionNotifications: Scalars['Boolean']['input'];
  allowReminderNotifications: Scalars['Boolean']['input'];
  allowUserJoinedNotifications: Scalars['Boolean']['input'];
  userNotificationPreferencesId: Scalars['ID']['input'];
};

export type UpdateUserOnboardingInput = {
  organizationId: Scalars['ID']['input'];
};

export type UpdateWidgetSizeVariantInput = {
  organizationId: Scalars['ID']['input'];
  widgetSizeVariant: WidgetSizeVariant;
};

export type Upsell = Node & {
  __typename?: 'Upsell';
  createdAt: Scalars['DateTime']['output'];
  hidden: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  type: UpsellTypes;
};

export type UpsellError = {
  __typename?: 'UpsellError';
  message: Scalars['String']['output'];
};

export type UpsellPayload = Upsell | UpsellError;

export enum UpsellTypes {
  Trial = 'TRIAL'
}

export type UpsertMemberSubscriptionPlanInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  groupIds: Array<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  priceMonthlyUsd: Scalars['Int']['input'];
};

export type UpsertPostInput = {
  aspectRatio: Scalars['Float']['input'];
  colorTheme: PostColorTheme;
  content?: InputMaybe<Scalars['JSON']['input']>;
  groupId: Scalars['ID']['input'];
  isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  mediaIds: Array<Scalars['ID']['input']>;
  pollOptions?: InputMaybe<Array<Scalars['String']['input']>>;
  postId?: InputMaybe<Scalars['ID']['input']>;
  resources: Array<ResourceInput>;
  scheduledAt?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<PostTypes>;
};

export type User = Node & {
  __typename?: 'User';
  avatar: Scalars['String']['output'];
  avatarBgColor: Scalars['String']['output'];
  billingDetails?: Maybe<BillingDetails>;
  bio?: Maybe<Scalars['String']['output']>;
  claimedAccount: Scalars['Boolean']['output'];
  coverImageId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currentCity?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  groupMemberships?: Maybe<Array<GroupMembership>>;
  hometown?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instagramHandle?: Maybe<Scalars['String']['output']>;
  instagramProfileImage?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  memberSubscriptions: Array<MemberSubscription>;
  name: Scalars['String']['output'];
  organizationMembership?: Maybe<OrganizationMembership>;
  organizationMemberships: Array<OrganizationMembership>;
  position?: Maybe<Scalars['String']['output']>;
  preferences: UserPreferences;
  profileImageId?: Maybe<Scalars['String']['output']>;
  profileVideo?: Maybe<Video>;
  timeZone?: Maybe<Scalars['String']['output']>;
  userInvites: Array<UserInvite>;
  username: Scalars['String']['output'];
};


export type UserGroupMembershipsArgs = {
  organizationId: Scalars['String']['input'];
};


export type UserOrganizationMembershipArgs = {
  organizationId: Scalars['String']['input'];
};

export type UserActivityCounts = Node & {
  __typename?: 'UserActivityCounts';
  id: Scalars['ID']['output'];
  numGroups: Scalars['Float']['output'];
  numPosts: Scalars['Float']['output'];
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges: Array<UserObjectEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserInvite = Node & {
  __typename?: 'UserInvite';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inviteCode: Scalars['String']['output'];
  organization: Organization;
};

export type UserNotificationPreferences = Node & {
  __typename?: 'UserNotificationPreferences';
  allowAccountNotifications: Scalars['Boolean']['output'];
  allowCommentNotifications: Scalars['Boolean']['output'];
  allowDirectMessageNotifications: Scalars['Boolean']['output'];
  allowGroupNotifications: Scalars['Boolean']['output'];
  allowMentionNotifications: Scalars['Boolean']['output'];
  allowNewMemberRequestNotifications: Scalars['Boolean']['output'];
  allowNewPostNotifications: Scalars['Boolean']['output'];
  allowPollNotifications: Scalars['Boolean']['output'];
  allowReactionNotifications: Scalars['Boolean']['output'];
  allowReminderNotifications: Scalars['Boolean']['output'];
  allowUserJoinedNotifications: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  type: NotificationPreferencesType;
};

export type UserObjectEdge = {
  __typename?: 'UserObjectEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type UserPreferences = Node & {
  __typename?: 'UserPreferences';
  allowCookies?: Maybe<Scalars['Boolean']['output']>;
  allowEmailNotifications?: Maybe<Scalars['Boolean']['output']>;
  digestNotificationFrequency: DigestNotificationFrequency;
  id: Scalars['ID']['output'];
  isProfileSetup: Scalars['Boolean']['output'];
  questionsOnboardingCompletedAt?: Maybe<Scalars['DateTime']['output']>;
  showQuestionsOnboarding: Scalars['Boolean']['output'];
};

export type Username = {
  __typename?: 'Username';
  username: Scalars['String']['output'];
};

export type UsernameError = {
  __typename?: 'UsernameError';
  message: Scalars['String']['output'];
};

export type UsernamePayload = Username | UsernameError;

export type Video = Node & {
  __typename?: 'Video';
  aspect?: Maybe<Scalars['Float']['output']>;
  assetId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  mirror: Scalars['Boolean']['output'];
  playbackId?: Maybe<Scalars['String']['output']>;
  previewPlaybackId?: Maybe<Scalars['String']['output']>;
  previewStatus?: Maybe<VideoStatus>;
  status: VideoStatus;
  type: VideoType;
};

export type VideoPresignedUpload = {
  __typename?: 'VideoPresignedUpload';
  id: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum VideoStatus {
  Ready = 'READY',
  UploadStarted = 'UPLOAD_STARTED'
}

export enum VideoType {
  Hello = 'HELLO',
  Profile = 'PROFILE'
}

export enum ViewLevels {
  Detail = 'DETAIL',
  Preview = 'PREVIEW'
}

export enum ViewLocations {
  GroupDetail = 'GROUP_DETAIL',
  Home = 'HOME',
  PostDetail = 'POST_DETAIL',
  Unseen = 'UNSEEN'
}

export type WaitlistEntry = Node & {
  __typename?: 'WaitlistEntry';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  source: WaitlistEntrySource;
};

export type WaitlistEntryError = {
  __typename?: 'WaitlistEntryError';
  errorCode: WaitlistEntryErrorCode;
};

export enum WaitlistEntryErrorCode {
  AlreadyOnList = 'ALREADY_ON_LIST'
}

export type WaitlistEntryPayload = WaitlistEntry | WaitlistEntryError;

export enum WaitlistEntrySource {
  Landing = 'LANDING',
  LandingQuestions = 'LANDING_QUESTIONS',
  Signup = 'SIGNUP',
  TryFrond = 'TRY_FROND'
}

export enum WidgetSizeVariant {
  Medium = 'MEDIUM',
  Small = 'SMALL'
}

export type OrganizationAdminFieldsFragment = { __typename?: 'Organization', id: string, shortId: string, name: string, domain?: string | null, createdAt: string, createdBy?: { __typename?: 'User', email: string, username: string } | null };

export type OrganizationsQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
}>;


export type OrganizationsQuery = { __typename?: 'Query', organizations: { __typename?: 'OrganizationConnection', totalCount: number, edges: Array<{ __typename?: 'OrganizationObjectEdge', cursor: string, node: (
        { __typename?: 'Organization' }
        & OrganizationAdminFieldsFragment
      ) }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } } };

export type AddOrganizationMutationVariables = Exact<{
  input: AddOrganizationInput;
}>;


export type AddOrganizationMutation = { __typename?: 'Mutation', addOrganization: (
    { __typename?: 'Organization' }
    & OrganizationAdminFieldsFragment
  ) };

export type SendDigestToUserMutationVariables = Exact<{
  userEmail: Scalars['String']['input'];
}>;


export type SendDigestToUserMutation = { __typename?: 'Mutation', sendDigestToUser?: { __typename?: 'Organization', id: string } | null };

export type SendNotificationsDigestToUserMutationVariables = Exact<{
  userEmail: Scalars['String']['input'];
}>;


export type SendNotificationsDigestToUserMutation = { __typename?: 'Mutation', sendNotificationsDigestToUser?: { __typename?: 'Organization', id: string } | null };

export type DeleteOrganizationMutationVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type DeleteOrganizationMutation = { __typename?: 'Mutation', deleteOrganization: { __typename?: 'Organization', id: string } };

export type DuplicateOrganizationMutationVariables = Exact<{
  input: DuplicateOrganizationInput;
}>;


export type DuplicateOrganizationMutation = { __typename?: 'Mutation', duplicateOrganization: (
    { __typename?: 'Organization' }
    & OrganizationCoreFragment
  ) };

export type DeleteUserMutationVariables = Exact<{
  userEmail: Scalars['String']['input'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'User', id: string } };

export type GetDemoOrganizationMutationVariables = Exact<{ [key: string]: never; }>;


export type GetDemoOrganizationMutation = { __typename?: 'Mutation', getDemoOrganization: (
    { __typename?: 'Organization' }
    & OrganizationCoreFragment
  ) };

export type ViewerQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerQuery = { __typename?: 'Query', viewer: { __typename?: 'User', id: string, createdAt: string, username: string, email: string, name: string, profileImageId?: string | null, coverImageId?: string | null, firstName: string, lastName: string, bio?: string | null, claimedAccount: boolean, position?: string | null, avatar: string, avatarBgColor: string, timeZone?: string | null, userInvites: Array<{ __typename?: 'UserInvite', id: string, inviteCode: string, organization: (
        { __typename?: 'Organization' }
        & OrganizationCoreFragment
      ) }>, memberSubscriptions: Array<{ __typename?: 'MemberSubscription', id: string, stripeCancelAtPeriodEnd: boolean, expiresAt: string, subscriptionPlan: { __typename?: 'MemberSubscriptionPlan', id: string } }>, organizationMemberships: Array<{ __typename?: 'OrganizationMembership', id: string, active: boolean, createdAt: string, isCreator: boolean, hideFrondIntroPost: boolean, completedOnboardingTasks: Array<OrganizationMembershipOnboardingTasks>, roles: Array<{ __typename?: 'OrganizationRole', id: string, label: string, color: OrganizationRoleColor, isAdmin: boolean, memberSubscriptionPlan?: { __typename?: 'MemberSubscriptionPlan', id: string } | null }>, organization: (
        { __typename?: 'Organization' }
        & OrganizationCoreFragment
      ) }>, preferences: { __typename?: 'UserPreferences', id: string, showQuestionsOnboarding: boolean, questionsOnboardingCompletedAt?: string | null, isProfileSetup: boolean, allowCookies?: boolean | null, allowEmailNotifications?: boolean | null, digestNotificationFrequency: DigestNotificationFrequency } } };

export type UserProfileFragment = { __typename?: 'User', id: string, username: string, name: string, profileImageId?: string | null, firstName: string, lastName: string, linkedinUrl?: string | null, instagramHandle?: string | null, instagramProfileImage?: string | null, avatarBgColor: string, avatar: string, currentCity?: string | null, hometown?: string | null, timeZone?: string | null, bio?: string | null, claimedAccount: boolean, coverImageId?: string | null, createdAt: string, profileVideo?: { __typename?: 'Video', playbackId?: string | null, aspect?: number | null } | null, preferences: { __typename?: 'UserPreferences', id: string, showQuestionsOnboarding: boolean, isProfileSetup: boolean, allowCookies?: boolean | null, allowEmailNotifications?: boolean | null } };

export type UserQueryVariables = Exact<{
  username: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
}>;


export type UserQuery = { __typename?: 'Query', user?: (
    { __typename?: 'User', organizationMembership?: { __typename?: 'OrganizationMembership', id: string, createdAt: string, roles: Array<{ __typename?: 'OrganizationRole', id: string, label: string, color: OrganizationRoleColor, isAdmin: boolean }> } | null }
    & UserProfileFragment
  ) | null };

export type UserGroupMembershipsQueryVariables = Exact<{
  username: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
}>;


export type UserGroupMembershipsQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, username: string, groupMemberships?: Array<{ __typename?: 'GroupMembership', id: string, group: { __typename?: 'Group', id: string, name: string, emoji: string } }> | null } | null };

export type AllUsersQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
}>;


export type AllUsersQuery = { __typename?: 'Query', allUsers: { __typename?: 'UserConnection', totalCount: number, edges: Array<{ __typename?: 'UserObjectEdge', cursor: string, node: (
        { __typename?: 'User', email: string, organizationMemberships: Array<{ __typename?: 'OrganizationMembership', id: string, roles: Array<{ __typename?: 'OrganizationRole', id: string, label: string, color: OrganizationRoleColor, isAdmin: boolean }>, organization: (
            { __typename?: 'Organization' }
            & OrganizationCoreFragment
          ) }> }
        & UserProfileFragment
      ) }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: (
    { __typename?: 'User' }
    & UserProfileFragment
  ) };

export type UsersQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  groupId?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
}>;


export type UsersQuery = { __typename?: 'Query', users: { __typename?: 'UserConnection', totalCount: number, edges: Array<{ __typename?: 'UserObjectEdge', cursor: string, node: { __typename?: 'User', id: string, username: string, name: string, firstName: string, profileImageId?: string | null, avatarBgColor: string, avatar: string, createdAt: string, organizationMembership?: { __typename?: 'OrganizationMembership', id: string, createdAt: string, roles: Array<{ __typename?: 'OrganizationRole', id: string, label: string, color: OrganizationRoleColor, isAdmin: boolean, memberSubscriptionPlan?: { __typename?: 'MemberSubscriptionPlan', id: string, name: string } | null }> } | null } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } };

export type CreateOrDeleteReactionMutationVariables = Exact<{
  input: CreateOrDeleteReactionInput;
}>;


export type CreateOrDeleteReactionMutation = { __typename?: 'Mutation', createOrDeleteReaction: { __typename: 'Reaction', id: string, type: ReactionTypes, hasReacted: boolean, numReactions: number, emojiUnicode: string, createdByUsers: Array<{ __typename?: 'User', id: string, name: string, firstName: string, lastName: string, username: string, profileImageId?: string | null, avatar: string, avatarBgColor: string }> } };

export type CommentCoreFragment = { __typename: 'Comment', id: string, type: CommentTypes, content: { [key: string]: any }, contentVersion: number, isBestAnswer: boolean, createdAt: string, createdBy: (
    { __typename?: 'User' }
    & UserPreviewFragment
  ), reactions: Array<{ __typename?: 'Reaction', id: string, type: ReactionTypes, hasReacted: boolean, numReactions: number, emojiUnicode: string, createdByUsers: Array<(
      { __typename?: 'User' }
      & UserPreviewFragment
    )> }>, resources: Array<{ __typename?: 'Resource', id: string, description?: string | null, domain?: string | null, favicon?: string | null, imageUrl?: string | null, logo?: string | null, logoTitle?: string | null, title?: string | null, url: string, expanded: boolean }>, media: Array<{ __typename?: 'Giphy', id: string, aspectRatio: number, giphyId: string, giphyUrl: string, giphyMp4Url: string, width: number, height: number } | { __typename?: 'Image', id: string, aspectRatio: number, publicId: string, width: number, height: number }> };

export type CommentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type CommentQuery = { __typename?: 'Query', comment?: (
    { __typename?: 'Comment' }
    & CommentCoreFragment
  ) | null };

export type CreateCommentMutationVariables = Exact<{
  input: CreateCommentInput;
}>;


export type CreateCommentMutation = { __typename?: 'Mutation', createComment: (
    { __typename?: 'Comment' }
    & CommentCoreFragment
  ) };

export type UpdateCommentMutationVariables = Exact<{
  input: UpdateCommentInput;
}>;


export type UpdateCommentMutation = { __typename?: 'Mutation', updateComment: (
    { __typename?: 'Comment' }
    & CommentCoreFragment
  ) };

export type ToggleCommentAsBestAnswerMutationVariables = Exact<{
  input: ToggleCommentAsBestAnswerInput;
}>;


export type ToggleCommentAsBestAnswerMutation = { __typename?: 'Mutation', toggleCommentAsBestAnswer: (
    { __typename?: 'Comment' }
    & CommentCoreFragment
  ) };

export type DeleteCommentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteCommentMutation = { __typename?: 'Mutation', deleteComment: { __typename?: 'Comment', id: string } };

export type SendFeedbackEmailMutationVariables = Exact<{
  input: SendFeedbackEmailInput;
}>;


export type SendFeedbackEmailMutation = { __typename?: 'Mutation', sendFeedbackEmail: { __typename?: 'User', id: string } };

export type CommentConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type CommentConfigQuery = { __typename?: 'Query', commentConfig: { __typename?: 'CommentConfig', maxCommentLength: number } };

export type OrganizationCoreFragment = { __typename?: 'Organization', id: string, shortId: string, legacyShortId?: string | null, color: string, name: string, domain?: string | null, logoId?: string | null, description?: string | null, isGoogleWorkspace: boolean, isSetup: boolean, numMembers: number, numGroups: number, createdAt: string, groupCreationPolicy: GroupCreationPolicy, joinPolicy: JoinPolicy, addMemberPolicy: AddMemberPolicy, widgetSizeVariant: WidgetSizeVariant, enableNewMemberIntroductions: boolean, newMemberIntroductionMessage: string, enableAutoMessageNewMembers: boolean, autoMessageNewMembersMessage: string, autoMessageNewMembersFromId?: string | null, isPublic: boolean, hasCoursesEnabled: boolean, hasEventsEnabled: boolean, hasInsightsEnabled: boolean, templateType: OrganizationTemplateType, adminSubscription?: { __typename?: 'AdminSubscription', subscriptionPlan: AdminSubscriptionPlanId } | null };

export type OrganizationExtendedFragment = (
  { __typename?: 'Organization', memberSubscriptionPlan?: { __typename?: 'MemberSubscriptionPlan', id: string, active: boolean, name: string, description?: string | null, priceMonthlyUsd: number, pendingBalance: number, subscriberCount: number, totalRevenue: number, groupEntitlements: Array<{ __typename?: 'Group', id: string, name: string, description?: { [key: string]: any } | null, emoji: string, numPosts: number }> } | null, customDomain?: { __typename?: 'CustomDomain', id: string, status: CustomDomainStatus, name: string } | null }
  & OrganizationCoreFragment
);

export type OrganizationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type OrganizationQuery = { __typename?: 'Query', organization?: (
    { __typename?: 'Organization' }
    & OrganizationExtendedFragment
  ) | null };

export type OrganizationByDomainQueryVariables = Exact<{
  domain: Scalars['String']['input'];
}>;


export type OrganizationByDomainQuery = { __typename?: 'Query', organizationByDomain?: { __typename?: 'Organization', id: string, shortId: string } | null };

export type OrganizationByShortIdQueryVariables = Exact<{
  shortId: Scalars['ID']['input'];
}>;


export type OrganizationByShortIdQuery = { __typename?: 'Query', organizationByShortId?: (
    { __typename?: 'Organization' }
    & OrganizationExtendedFragment
  ) | null };

export type OrganizationActiveMembersQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type OrganizationActiveMembersQuery = { __typename?: 'Query', organization?: { __typename?: 'Organization', id: string, activeMembers: Array<{ __typename?: 'User', id: string, username: string, firstName: string, lastName: string, profileImageId?: string | null, avatar: string, avatarBgColor: string }> } | null };

export type CustomDomainVerificationInfoQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type CustomDomainVerificationInfoQuery = { __typename?: 'Query', customDomainVerificationInfo: { __typename?: 'CustomDomainVerificationInfo', apexName: string, name: string, verified: boolean, verification: Array<{ __typename?: 'CustomDomainVerification', type: string, domain: string, value: string, reason: string }> } };

export type UserActivityCountsQueryVariables = Exact<{
  username: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
}>;


export type UserActivityCountsQuery = { __typename?: 'Query', userActivityCounts?: { __typename?: 'UserActivityCounts', numGroups: number, numPosts: number } | null };

export type OrganizationInviteQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type OrganizationInviteQuery = { __typename?: 'Query', organizationInvite: { __typename?: 'OrganizationInvite', inviteCode: string, expiresAt?: string | null } };

export type UpdateUserDigestNotificationFrequencyMutationVariables = Exact<{
  input: UpdateUserDigestNotificationFrequencyInput;
}>;


export type UpdateUserDigestNotificationFrequencyMutation = { __typename?: 'Mutation', updateUserDigestNotificationFrequency: { __typename?: 'UserPreferences', id: string, digestNotificationFrequency: DigestNotificationFrequency } };

export type MyResourceFragment = { __typename?: 'Resource', id: string, description?: string | null, domain?: string | null, imageUrl?: string | null, title?: string | null, url: string, logo?: string | null, favicon?: string | null, expanded: boolean };

export type UnfurlUrlMutationVariables = Exact<{
  input: UnfurlUrlInput;
}>;


export type UnfurlUrlMutation = { __typename?: 'Mutation', unfurlUrl: (
    { __typename?: 'Resource' }
    & MyResourceFragment
  ) | { __typename?: 'ResourceError', message: string } };

export type CreateImageMutationVariables = Exact<{
  input: CreateImageInput;
}>;


export type CreateImageMutation = { __typename?: 'Mutation', createImage: { __typename?: 'Image', id: string, aspectRatio: number, publicId: string, height: number, width: number } };

export type CreateTemporaryImageMutationVariables = Exact<{
  input: CreateTemporaryImageInput;
}>;


export type CreateTemporaryImageMutation = { __typename?: 'Mutation', createTemporaryImage: { __typename?: 'Image', id: string, aspectRatio: number, publicId: string, height: number, width: number } };

export type CreateGiphyMutationVariables = Exact<{
  input: CreateGiphyInput;
}>;


export type CreateGiphyMutation = { __typename?: 'Mutation', createGiphy: { __typename?: 'Giphy', id: string, aspectRatio: number, height: number, width: number, giphyMp4Url: string, giphyUrl: string, giphyId: string } };

export type OrganizationPublicFieldsFragment = (
  { __typename?: 'Organization', activeMembers: Array<{ __typename?: 'User', id: string, name: string, firstName: string, lastName: string, username: string, profileImageId?: string | null, avatar: string, avatarBgColor: string }> }
  & OrganizationCoreFragment
);

export type PublicOrganizationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PublicOrganizationQuery = { __typename?: 'Query', organization?: (
    { __typename?: 'Organization' }
    & OrganizationPublicFieldsFragment
  ) | null };

export type PublicOrganizationShortIdsQueryVariables = Exact<{ [key: string]: never; }>;


export type PublicOrganizationShortIdsQuery = { __typename?: 'Query', publicOrganizationShortIds: Array<string> };

export type VisitedPublicOrganizationsQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type VisitedPublicOrganizationsQuery = { __typename?: 'Query', visitedPublicOrganizations: Array<(
    { __typename?: 'Organization' }
    & OrganizationPublicFieldsFragment
  )> };

export type ReorderOrganizationMembershipMutationVariables = Exact<{
  input: ReorderOrganizationMembershipInput;
}>;


export type ReorderOrganizationMembershipMutation = { __typename?: 'Mutation', reorderOrganizationMembership: { __typename?: 'OrganizationMembership', id: string } };

export type AddOrganizationVisitMutationVariables = Exact<{
  input: AddOrganizationVisitInput;
}>;


export type AddOrganizationVisitMutation = { __typename?: 'Mutation', addOrganizationVisit: (
    { __typename?: 'Organization' }
    & OrganizationPublicFieldsFragment
  ) };

export type HideOrganizationVisitMutationVariables = Exact<{
  input: HideOrganizationVisitInput;
}>;


export type HideOrganizationVisitMutation = { __typename?: 'Mutation', hideOrganizationVisit: (
    { __typename?: 'Organization' }
    & OrganizationPublicFieldsFragment
  ) };

export type UserPreviewFragment = { __typename?: 'User', id: string, name: string, firstName: string, lastName: string, username: string, profileImageId?: string | null, avatar: string, avatarBgColor: string, coverImageId?: string | null };

export type ResourceCoreFragment = { __typename?: 'Resource', id: string, description?: string | null, domain?: string | null, favicon?: string | null, imageUrl?: string | null, logo?: string | null, logoTitle?: string | null, title?: string | null, url: string };

export type PostCoreFragment = { __typename: 'Post', id: string, type: PostTypes, bgColor?: string | null, deleted?: boolean | null, content?: { [key: string]: any } | null, createdAt: string, updatedAt: string, colorTheme: PostColorTheme, aspectRatio: number, pinned: boolean, showAuthor: boolean, showCommentsAndReactions: boolean, title?: string | null, hideFromProfile: boolean, isDraft: boolean, scheduledAt?: string | null, resource?: (
    { __typename?: 'Resource' }
    & ResourceCoreFragment
  ) | null, resources: Array<{ __typename?: 'Resource', id: string, description?: string | null, domain?: string | null, favicon?: string | null, imageUrl?: string | null, logo?: string | null, logoTitle?: string | null, title?: string | null, url: string, expanded: boolean }>, media: Array<{ __typename?: 'Giphy', id: string, aspectRatio: number, giphyId: string, giphyUrl: string, giphyMp4Url: string, width: number, height: number } | { __typename?: 'Image', id: string, aspectRatio: number, publicId: string, width: number, height: number, coordinates?: { __typename?: 'Coordinates', x: number, y: number, width: number, height: number } | null } | { __typename?: 'Video', id: string, aspect?: number | null, playbackId?: string | null, previewPlaybackId?: string | null, previewStatus?: VideoStatus | null, mirror: boolean, status: VideoStatus, type: VideoType, assetId?: string | null, createdAt: string }>, poll?: (
    { __typename?: 'Poll' }
    & PollCoreFragment
  ) | null, createdBy: (
    { __typename?: 'User' }
    & UserPreviewFragment
  ), organization: (
    { __typename?: 'Organization' }
    & OrganizationCoreFragment
  ) };

export type PollCoreFragment = { __typename?: 'Poll', id: string, options: Array<{ __typename?: 'PollOption', id: string, name: string, votes: Array<{ __typename?: 'PollVote', createdBy: (
        { __typename?: 'User' }
        & UserPreviewFragment
      ) }> }> };

export type PostFeedFragment = (
  { __typename?: 'Post', numOfAnonymousViews: number, isBookmarked: boolean, group: (
    { __typename?: 'Group' }
    & GroupCoreFragment
  ), comments: Array<(
    { __typename?: 'Comment' }
    & CommentCoreFragment
  )>, reactions: Array<{ __typename?: 'Reaction', id: string, type: ReactionTypes, hasReacted: boolean, numReactions: number, emojiUnicode: string, createdByUsers: Array<(
      { __typename?: 'User' }
      & UserPreviewFragment
    )> }>, revisions: Array<{ __typename?: 'PostRevision', id: string, createdAt: string }>, views: Array<{ __typename?: 'PostView', id: string, user: (
      { __typename?: 'User' }
      & UserPreviewFragment
    ) }>, completions: Array<{ __typename?: 'LessonCompletion', user: (
      { __typename?: 'User' }
      & UserPreviewFragment
    ) }> }
  & PostCoreFragment
);

export type GroupCoreFragment = { __typename: 'Group', id: string, name: string, deleted?: boolean | null, description?: { [key: string]: any } | null, lastActivityAt: string, questionEnabled?: boolean | null, isPrivate?: boolean | null, questionText?: string | null, questionCronExpression?: string | null, enableNewPostNotificationsForNewMembers: boolean, timeZone?: string | null, hasPosts: boolean, numPosts: number, numMembers: number, emoji: string, coverImageId?: string | null, hidden: boolean, createdAt: string, postCreationPolicy: PostCreationPolicy, autoAddMembers: boolean, sortIndex: number, hasUnseenPosts?: boolean | null, includePostsOnHome: boolean, isDraft: boolean, groupType: GroupTypes, createdBy: (
    { __typename?: 'User' }
    & UserPreviewFragment
  ), organization: (
    { __typename?: 'Organization' }
    & OrganizationCoreFragment
  ), unlockedBy?: { __typename?: 'MemberSubscriptionPlan', id: string, active: boolean } | null };

export type GroupWithMembersFragment = (
  { __typename?: 'Group', membership?: { __typename?: 'GroupMembership', id: string, allowNewOccurrenceNotifications?: boolean | null, allowNewPostNotifications?: boolean | null } | null, createdBy: (
    { __typename?: 'User' }
    & UserPreviewFragment
  ), members: Array<(
    { __typename?: 'User' }
    & UserPreviewFragment
  )> }
  & GroupCoreFragment
);

export type UserGroupNotificationPreferencesFragment = { __typename?: 'Group', membership?: { __typename?: 'GroupMembership', allowNewOccurrenceNotifications?: boolean | null, allowNewPostNotifications?: boolean | null } | null };

export type GroupSidebarFragment = (
  { __typename?: 'Group', membership?: { __typename?: 'GroupMembership', id: string, allowNewOccurrenceNotifications?: boolean | null, allowNewPostNotifications?: boolean | null } | null }
  & GroupCoreFragment
);

export type PaginatedSidebarGroupsConnectionFragment = { __typename?: 'GroupsConnection', totalCount: number, edges: Array<{ __typename?: 'GroupObjectEdge', cursor: string, node: (
      { __typename?: 'Group' }
      & GroupSidebarFragment
    ) }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } };

export type GroupsQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type GroupsQuery = { __typename?: 'Query', groups: { __typename?: 'GroupsConnection', totalCount: number, edges: Array<{ __typename?: 'GroupObjectEdge', cursor: string, node: (
        { __typename?: 'Group' }
        & GroupWithMembersFragment
      ) }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } };

export type SidebarGroupsQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type SidebarGroupsQuery = { __typename?: 'Query', groups: (
    { __typename?: 'GroupsConnection' }
    & PaginatedSidebarGroupsConnectionFragment
  ) };

export type GroupQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GroupQuery = { __typename?: 'Query', group?: (
    { __typename: 'Group' }
    & GroupWithMembersFragment
  ) | { __typename: 'GroupError', reason: GroupErrorReason, message: string } | null };

export type GroupUnseenPostsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GroupUnseenPostsQuery = { __typename?: 'Query', group?: { __typename: 'Group', id: string, hasUnseenPosts?: boolean | null } | { __typename: 'GroupError', message: string } | null };

export type CreateAndJoinGroupMutationVariables = Exact<{
  input: CreateGroupInput;
}>;


export type CreateAndJoinGroupMutation = { __typename?: 'Mutation', createAndJoinGroup: (
    { __typename?: 'Group' }
    & GroupCoreFragment
  ) | { __typename?: 'GroupError' } };

export type UpdateGroupMutationVariables = Exact<{
  input: UpdateGroupInput;
}>;


export type UpdateGroupMutation = { __typename?: 'Mutation', updateGroup: (
    { __typename?: 'Group' }
    & GroupCoreFragment
  ) | { __typename?: 'GroupError' } };

export type DeleteGroupMutationVariables = Exact<{
  input: DeleteGroupInput;
}>;


export type DeleteGroupMutation = { __typename?: 'Mutation', deleteGroup: (
    { __typename?: 'Group' }
    & GroupCoreFragment
  ) | { __typename?: 'GroupError' } };

export type AddGroupMembersMutationVariables = Exact<{
  input: AddGroupMembersInput;
}>;


export type AddGroupMembersMutation = { __typename?: 'Mutation', addGroupMembers: (
    { __typename?: 'Group' }
    & GroupCoreFragment
  ) | { __typename?: 'GroupError' } };

export type JoinGroupMutationVariables = Exact<{
  input: JoinGroupInput;
}>;


export type JoinGroupMutation = { __typename?: 'Mutation', joinGroup: (
    { __typename?: 'Group' }
    & GroupCoreFragment
  ) | { __typename?: 'GroupError' } };

export type RemoveGroupMemberMutationVariables = Exact<{
  input: RemoveGroupMemberInput;
}>;


export type RemoveGroupMemberMutation = { __typename?: 'Mutation', removeGroupMember: (
    { __typename?: 'Group' }
    & GroupCoreFragment
  ) | { __typename?: 'GroupError' } };

export type AddGroupViewMutationVariables = Exact<{
  input: AddViewInput;
}>;


export type AddGroupViewMutation = { __typename?: 'Mutation', addGroupView: { __typename?: 'Group', id: string } };

export type ReorderGroupsMutationVariables = Exact<{
  input: ReorderGroupsInput;
}>;


export type ReorderGroupsMutation = { __typename?: 'Mutation', reorderGroups: (
    { __typename?: 'GroupsConnection' }
    & PaginatedSidebarGroupsConnectionFragment
  ) };

export type HomeFeedQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
}>;


export type HomeFeedQuery = { __typename?: 'Query', homeFeed?: { __typename?: 'FeedItemConnection', totalCount: number, edges: Array<{ __typename?: 'FeedItemEdge', cursor: string, node: { __typename: 'OrganizationMembership', id: string, createdAt: string, isCreator: boolean } | (
        { __typename: 'Post' }
        & PostFeedFragment
      ) }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } | null };

export type HomeFeedLatestContentQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type HomeFeedLatestContentQuery = { __typename?: 'Query', homeFeedLatestItem?: { __typename: 'OrganizationMembership', id: string, createdAt: string } | { __typename: 'Post', id: string, createdAt: string } | null };

export type UpsertPostMutationVariables = Exact<{
  input: UpsertPostInput;
}>;


export type UpsertPostMutation = { __typename?: 'Mutation', upsertPost: { __typename: 'Post', id: string } | { __typename: 'PostError', message: string } };

export type PostQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PostQuery = { __typename?: 'Query', post?: (
    { __typename: 'Post' }
    & PostFeedFragment
  ) | { __typename: 'PostError', message: string } | null };

export type DeletePostMutationVariables = Exact<{
  input: DeletePostInput;
}>;


export type DeletePostMutation = { __typename?: 'Mutation', deletePost: { __typename: 'Post', id: string } | { __typename: 'PostError', message: string } };

export type CreateTemporaryPostMutationVariables = Exact<{
  input: CreateTemporaryPostInput;
}>;


export type CreateTemporaryPostMutation = { __typename?: 'Mutation', createTemporaryPost: { __typename?: 'TemporaryPost', id: string, token: string, userId: string } };

export type TemporaryPostPollQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type TemporaryPostPollQuery = { __typename?: 'Query', temporaryPost?: { __typename?: 'TemporaryPost', id: string, token: string, media: Array<{ __typename?: 'Giphy', id: string, aspectRatio: number, giphyId: string, giphyUrl: string, giphyMp4Url: string, width: number, height: number } | { __typename?: 'Image', id: string, aspectRatio: number, publicId: string, width: number, height: number } | { __typename?: 'Video', id: string, aspect?: number | null, playbackId?: string | null, previewPlaybackId?: string | null, previewStatus?: VideoStatus | null, mirror: boolean, status: VideoStatus, type: VideoType, assetId?: string | null, createdAt: string }> } | null };

export type TemporaryPostQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type TemporaryPostQuery = { __typename?: 'Query', temporaryPost?: { __typename?: 'TemporaryPost', id: string, token: string, userId: string, group?: (
      { __typename?: 'Group', members: Array<(
        { __typename?: 'User' }
        & UserPreviewFragment
      )> }
      & GroupCoreFragment
    ) | null } | null };

export type UpdateTemporaryPostMutationVariables = Exact<{
  input: UpdateTemporaryPostInput;
}>;


export type UpdateTemporaryPostMutation = { __typename?: 'Mutation', updateTemporaryPost: { __typename?: 'TemporaryPost', id: string, token: string, userId: string, media: Array<{ __typename?: 'Giphy' } | { __typename?: 'Image', id: string } | { __typename?: 'Video', id: string }> } };

export type UpdateMembershipMutationVariables = Exact<{
  input: UpdateMembershipInput;
}>;


export type UpdateMembershipMutation = { __typename?: 'Mutation', updateMembership: { __typename?: 'GroupMembership', id: string, allowNewOccurrenceNotifications?: boolean | null, allowNewPostNotifications?: boolean | null } };

export type GroupsSidebarStateQueryVariables = Exact<{ [key: string]: never; }>;


export type GroupsSidebarStateQuery = { __typename?: 'Query', groupsSidebarState: { __typename?: 'GroupsSidebarState', privateGroupsExpanded?: boolean | null, publicGroupsExpanded?: boolean | null, courseGroupsExpanded?: boolean | null } };

export type GroupsMenuSectionsStateQueryVariables = Exact<{ [key: string]: never; }>;


export type GroupsMenuSectionsStateQuery = { __typename?: 'Query', groupsMenuSectionsState: { __typename?: 'GroupsMenuSectionsState', menuSectionIdsCollapsed?: Array<string | null> | null } };

export type ToggleGroupAutoAddMembersMutationVariables = Exact<{
  input: ToggleGroupAutoAddMembersInput;
}>;


export type ToggleGroupAutoAddMembersMutation = { __typename?: 'Mutation', toggleGroupAutoAddMembers: { __typename?: 'Group', id: string, autoAddMembers: boolean } };

export type ToggleGroupEnableNewPostNotificationsForNewMembersMutationVariables = Exact<{
  input: ToggleGroupEnableNewPostNotificationsForNewMembersInput;
}>;


export type ToggleGroupEnableNewPostNotificationsForNewMembersMutation = { __typename?: 'Mutation', toggleGroupEnableNewPostNotificationsForNewMembers: { __typename?: 'Group', id: string, enableNewPostNotificationsForNewMembers: boolean } };

export type ToggleGroupIncludePostsOnHomeMutationVariables = Exact<{
  input: ToggleGroupIncludePostsOnHomeInput;
}>;


export type ToggleGroupIncludePostsOnHomeMutation = { __typename?: 'Mutation', toggleGroupIncludePostsOnHome: { __typename?: 'Group', id: string, includePostsOnHome: boolean } };

export type RequestToJoinOrganizationMutationVariables = Exact<{
  input: RequestToJoinOrganizationInput;
}>;


export type RequestToJoinOrganizationMutation = { __typename?: 'Mutation', requestToJoinOrganization: { __typename: 'RequestToJoin', id: string } | { __typename: 'RequestToJoinError', message: string } };

export type UserInvitesQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<InvitesOrderByInput>;
}>;


export type UserInvitesQuery = { __typename?: 'Query', userInvites: { __typename?: 'InvitesPaginated', totalCount: number, items: Array<{ __typename?: 'UserInvite', id: string, email: string, inviteCode: string, createdAt: string }> } };

export type UserRequestToJoinOrganizationQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type UserRequestToJoinOrganizationQuery = { __typename?: 'Query', userRequestToJoinOrganization?: { __typename?: 'RequestToJoin', id: string } | null };

export type UserRequestsToJoinOrganizationQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<RequestsOrderByInput>;
}>;


export type UserRequestsToJoinOrganizationQuery = { __typename?: 'Query', userRequestsToJoinOrganization: { __typename?: 'RequestsPaginated', totalCount: number, items: Array<{ __typename?: 'RequestToJoin', id: string, createdAt: string, email: string, status: RequestToJoinStatusTypes, user: { __typename?: 'User', id: string, username: string, name: string, avatarBgColor: string, avatar: string, profileImageId?: string | null } }> } };

export type AcceptRequestToJoinMutationVariables = Exact<{
  input: AcceptRequestToJoinInput;
}>;


export type AcceptRequestToJoinMutation = { __typename?: 'Mutation', acceptRequestToJoin: { __typename: 'RequestToJoin', id: string } | { __typename: 'RequestToJoinError', message: string } };

export type AcceptAllRequestsToJoinMutationVariables = Exact<{
  input: AcceptAllRequestsToJoinInput;
}>;


export type AcceptAllRequestsToJoinMutation = { __typename?: 'Mutation', acceptAllRequestsToJoin: { __typename?: 'Organization', id: string } };

export type RejectRequestToJoinMutationVariables = Exact<{
  input: RejectRequestToJoinInput;
}>;


export type RejectRequestToJoinMutation = { __typename?: 'Mutation', rejectRequestToJoin: { __typename: 'RequestToJoin', id: string } | { __typename: 'RequestToJoinError', message: string } };

export type OrganizationPushEventsSubscriptionVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type OrganizationPushEventsSubscription = { __typename?: 'Subscription', organizationPushEvent: { __typename?: 'PushRefetchHint', id: string, postId?: string | null, groupId?: string | null } };

export type UpdateImageCoordinatesMutationVariables = Exact<{
  input: UpdateImageCoordinatesInput;
}>;


export type UpdateImageCoordinatesMutation = { __typename?: 'Mutation', updateImageCoordinates: { __typename?: 'Image', id: string, coordinates?: { __typename?: 'Coordinates', x: number, y: number, width: number, height: number } | null } };

export type CreateVoteMutationVariables = Exact<{
  input: CreateVoteInput;
}>;


export type CreateVoteMutation = { __typename?: 'Mutation', createVote: (
    { __typename: 'Poll' }
    & PollCoreFragment
  ) | { __typename: 'PollError', message: string } };

export type DeleteVoteMutationVariables = Exact<{
  input: DeleteVoteInput;
}>;


export type DeleteVoteMutation = { __typename?: 'Mutation', deleteVote: (
    { __typename: 'Poll' }
    & PollCoreFragment
  ) | { __typename: 'PollError', message: string } };

export type SearchResultFragment = { __typename?: 'Search', id: string, excerpt: string, createdAt: string, link: string, type: SearchResultTypes, createdBy: (
    { __typename?: 'User' }
    & UserPreviewFragment
  ), node?: (
    { __typename?: 'Group' }
    & GroupCoreFragment
  ) | null };

export type SearchQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  query: Scalars['String']['input'];
}>;


export type SearchQuery = { __typename?: 'Query', search?: Array<(
    { __typename?: 'Search' }
    & SearchResultFragment
  )> | null };

export type PostsByGroupQueryVariables = Exact<{
  groupId: Scalars['ID']['input'];
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type PostsByGroupQuery = { __typename?: 'Query', postsByGroup: { __typename?: 'PostConnection', totalCount: number, edges: Array<{ __typename?: 'PostObjectEdge', cursor: string, node: (
        { __typename?: 'Post' }
        & PostFeedFragment
      ) }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } };

export type DraftPostsQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  isScheduled?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type DraftPostsQuery = { __typename?: 'Query', draftPosts: { __typename?: 'PostConnection', totalCount: number, edges: Array<{ __typename?: 'PostObjectEdge', cursor: string, node: (
        { __typename?: 'Post' }
        & PostFeedFragment
      ) }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } };

export type OrganizationRoleCoreFragment = { __typename?: 'OrganizationRole', id: string, label: string, color: OrganizationRoleColor, isAdmin: boolean, memberSubscriptionPlan?: { __typename?: 'MemberSubscriptionPlan', id: string, name: string } | null };

export type CreateOrganizationRoleMutationVariables = Exact<{
  input: CreateOrganizationRoleInput;
}>;


export type CreateOrganizationRoleMutation = { __typename?: 'Mutation', createOrganizationRole: (
    { __typename: 'OrganizationRole' }
    & OrganizationRoleCoreFragment
  ) };

export type UpdateOrganizationRoleMutationVariables = Exact<{
  input: UpdateOrganizationRoleInput;
}>;


export type UpdateOrganizationRoleMutation = { __typename?: 'Mutation', updateOrganizationRole: (
    { __typename: 'OrganizationRole' }
    & OrganizationRoleCoreFragment
  ) };

export type DeleteOrganizationRoleMutationVariables = Exact<{
  input: DeleteOrganizationRoleInput;
}>;


export type DeleteOrganizationRoleMutation = { __typename?: 'Mutation', deleteOrganizationRole: (
    { __typename: 'OrganizationRole' }
    & OrganizationRoleCoreFragment
  ) };

export type OrganizationRolesQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type OrganizationRolesQuery = { __typename?: 'Query', organizationRoles: Array<(
    { __typename: 'OrganizationRole', users: Array<(
      { __typename?: 'User' }
      & UserPreviewFragment
    )> }
    & OrganizationRoleCoreFragment
  )> };

export type OrganizationRolesByUserIdQueryVariables = Exact<{
  userId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
}>;


export type OrganizationRolesByUserIdQuery = { __typename?: 'Query', organizationRolesByUserId: Array<(
    { __typename: 'OrganizationRole' }
    & OrganizationRoleCoreFragment
  )> };

export type DirectMessageTokenFragmentFragment = { __typename?: 'DirectMessageToken', communicationUserId: string, token: string, expiresOn: string };

export type DirectMessageTokenQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type DirectMessageTokenQuery = { __typename?: 'Query', directMessageToken: (
    { __typename: 'DirectMessageToken' }
    & DirectMessageTokenFragmentFragment
  ) };

export type DirectMessageUserFragmentFragment = { __typename?: 'DirectMessageUser', id: string, communicationUserId: string, user: (
    { __typename?: 'User' }
    & UserPreviewFragment
  ) };

export type DirectMessageUserThreadSettingsFragmentFragment = { __typename?: 'DirectMessageUserThreadSettings', id: string, markedUnread?: boolean | null, mutedNotifications?: boolean | null };

export type DirectMessageUserThreadFragmentFragment = { __typename?: 'DirectMessageUserThread', id: string, threadId: string, lastMessageReceivedOn?: string | null, lastMessage?: string | null, unreadCount?: number | null, updatedAt: string, createdBy: (
    { __typename?: 'DirectMessageUser' }
    & DirectMessageUserFragmentFragment
  ), user: (
    { __typename?: 'DirectMessageUser' }
    & DirectMessageUserFragmentFragment
  ), settings: (
    { __typename?: 'DirectMessageUserThreadSettings' }
    & DirectMessageUserThreadSettingsFragmentFragment
  ) };

export type DirectMessageUserThreadQueryVariables = Exact<{
  username: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
}>;


export type DirectMessageUserThreadQuery = { __typename?: 'Query', directMessageUserThread?: (
    { __typename: 'DirectMessageUserThread' }
    & DirectMessageUserThreadFragmentFragment
  ) | null };

export type WelcomeGroupQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type WelcomeGroupQuery = { __typename?: 'Query', welcomeGroup: { __typename?: 'Group', id: string, name: string, emoji: string, hidden: boolean, groupType: GroupTypes } };

export type ToggleWelcomeGroupMutationVariables = Exact<{
  input: ToggleWelcomeGroupInput;
}>;


export type ToggleWelcomeGroupMutation = { __typename?: 'Mutation', toggleWelcomeGroup: { __typename?: 'Group', id: string, hidden: boolean } };

export type DirectMessageThreadsQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type DirectMessageThreadsQuery = { __typename?: 'Query', directMessageThreads: Array<(
    { __typename: 'DirectMessageUserThread' }
    & DirectMessageUserThreadFragmentFragment
  )> };

export type CreateDirectMessageMutationVariables = Exact<{
  input: CreateDirectMessageInput;
}>;


export type CreateDirectMessageMutation = { __typename?: 'Mutation', createDirectMessage: { __typename?: 'DirectMessageUserThread', id: string } };

export type DeleteDirectMessageThreadMutationVariables = Exact<{
  input: DeleteDirectMessageThreadInput;
}>;


export type DeleteDirectMessageThreadMutation = { __typename?: 'Mutation', deleteDirectMessageThread: { __typename?: 'DirectMessageUserThread', id: string } };

export type DirectMessageFragmentFragment = { __typename?: 'DirectMessage', id: string, messageId: string, createdOn: string, editedOn?: string | null, version: number, reactions: Array<{ __typename?: 'Reaction', id: string, type: ReactionTypes, hasReacted: boolean, numReactions: number, emojiUnicode: string, createdByUsers: Array<(
      { __typename?: 'User' }
      & UserPreviewFragment
    )> }>, resources: Array<{ __typename?: 'Resource', id: string, description?: string | null, domain?: string | null, favicon?: string | null, imageUrl?: string | null, logo?: string | null, logoTitle?: string | null, title?: string | null, url: string, expanded: boolean }>, media: Array<{ __typename?: 'Giphy', id: string, aspectRatio: number, giphyId: string, giphyUrl: string, giphyMp4Url: string, width: number, height: number } | { __typename?: 'Image', id: string, aspectRatio: number, publicId: string, width: number, height: number }> };

export type DirectMessageQueryVariables = Exact<{
  id: Scalars['String']['input'];
  threadId: Scalars['String']['input'];
}>;


export type DirectMessageQuery = { __typename?: 'Query', directMessage: (
    { __typename: 'DirectMessage' }
    & DirectMessageFragmentFragment
  ) };

export type CreateOrDeleteDirectMessageReactionMutationVariables = Exact<{
  input: CreateOrDeleteDirectMessageReactionInput;
}>;


export type CreateOrDeleteDirectMessageReactionMutation = { __typename?: 'Mutation', createOrDeleteDirectMessageReaction: (
    { __typename: 'DirectMessage' }
    & DirectMessageFragmentFragment
  ) };

export type DirectMessageNotificationCountsQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type DirectMessageNotificationCountsQuery = { __typename?: 'Query', directMessageNotificationCounts: { __typename: 'DirectMessageNotificationCounts', unread: number } };

export type UpdateDirectMessageThreadSettingsMutationVariables = Exact<{
  input: UpdateDirectMessageUserThreadSettingsInput;
}>;


export type UpdateDirectMessageThreadSettingsMutation = { __typename?: 'Mutation', updateDirectMessageThreadSettings: (
    { __typename: 'DirectMessageUserThreadSettings' }
    & DirectMessageUserThreadSettingsFragmentFragment
  ) };

export type MarkDirectMessageReadMutationVariables = Exact<{
  input: MarkDirectMessageReadInput;
}>;


export type MarkDirectMessageReadMutation = { __typename?: 'Mutation', markDirectMessageRead: { __typename?: 'DirectMessageUserThread', id: string } };

export type EventCoreFragment = { __typename: 'Event', id: string, name: string, description?: { [key: string]: any } | null, coverImageId?: string | null, emoji: string, createdAt: string, startAt: string, endAt: string, timeZone?: string | null, url?: string | null, meetingUrl?: string | null, deleted?: boolean | null, numMembers: number, numPosts: number, postCreationPolicy: PostCreationPolicy, groupType: GroupTypes, series?: { __typename?: 'EventSeries', id: string, recurringCronExpression: string, recurringOccurrencesLimit?: number | null } | null, location?: { __typename?: 'Location', id: string, placeId: string, type: LocationTypes, displayName: string, lon: number, lat: number } | null, membership?: { __typename?: 'GroupMembership', id: string, allowNewOccurrenceNotifications?: boolean | null, allowNewPostNotifications?: boolean | null, allowEventNotifications?: boolean | null } | null, members: Array<(
    { __typename?: 'User' }
    & UserPreviewFragment
  )>, createdBy: (
    { __typename?: 'User' }
    & UserPreviewFragment
  ) };

export type EventsQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  past?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type EventsQuery = { __typename?: 'Query', events: { __typename?: 'EventsConnection', totalCount: number, edges: Array<{ __typename?: 'EventObjectEdge', cursor: string, node: (
        { __typename?: 'Event' }
        & EventCoreFragment
      ) }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } };

export type PostsByEventQueryVariables = Exact<{
  eventId: Scalars['ID']['input'];
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type PostsByEventQuery = { __typename?: 'Query', postsByEvent: { __typename?: 'PostConnection', totalCount: number, edges: Array<{ __typename?: 'PostObjectEdge', cursor: string, node: (
        { __typename?: 'Post' }
        & PostFeedFragment
      ) }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } };

export type EventQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type EventQuery = { __typename?: 'Query', event: (
    { __typename: 'Event' }
    & EventCoreFragment
  ) | { __typename: 'EventError', message: string } };

export type CreateEventMutationVariables = Exact<{
  input: CreateEventInput;
}>;


export type CreateEventMutation = { __typename?: 'Mutation', createEvent: (
    { __typename?: 'Event' }
    & EventCoreFragment
  ) | { __typename?: 'EventError' } };

export type UpdateEventMutationVariables = Exact<{
  input: UpdateEventInput;
}>;


export type UpdateEventMutation = { __typename?: 'Mutation', updateEvent: (
    { __typename?: 'Event' }
    & EventCoreFragment
  ) | { __typename?: 'EventError' } };

export type DeleteEventMutationVariables = Exact<{
  input: DeleteEventInput;
}>;


export type DeleteEventMutation = { __typename?: 'Mutation', deleteEvent: { __typename?: 'Event', id: string } | { __typename?: 'EventError', message: string } };

export type CourseCoreFragment = { __typename: 'Course', id: string, name: string, description?: { [key: string]: any } | null, coverId?: string | null, deleted?: boolean | null, hidden?: boolean | null, emoji: string, members: Array<(
    { __typename?: 'User' }
    & UserPreviewFragment
  )>, createdBy: (
    { __typename?: 'User' }
    & UserPreviewFragment
  ) };

export type LessonCoreFragment = (
  { __typename?: 'Post', sortIndex?: number | null, completions: Array<{ __typename?: 'LessonCompletion', user: (
      { __typename?: 'User' }
      & UserPreviewFragment
    ) }> }
  & PostCoreFragment
);

export type LessonsQueryVariables = Exact<{
  courseId: Scalars['String']['input'];
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type LessonsQuery = { __typename?: 'Query', lessons: { __typename?: 'PostConnection', totalCount: number, edges: Array<{ __typename?: 'PostObjectEdge', cursor: string, node: (
        { __typename?: 'Post' }
        & LessonCoreFragment
      ) }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } };

export type CreateCourseMutationVariables = Exact<{
  input: CreateCourseInput;
}>;


export type CreateCourseMutation = { __typename?: 'Mutation', createCourse: (
    { __typename?: 'Course' }
    & CourseCoreFragment
  ) | { __typename?: 'CourseError' } };

export type ReorderLessonMutationVariables = Exact<{
  input: ReorderLessonInput;
}>;


export type ReorderLessonMutation = { __typename?: 'Mutation', reorderLesson: (
    { __typename?: 'Post' }
    & LessonCoreFragment
  ) | { __typename?: 'PostError' } };

export type ToggleLessonCompletedMutationVariables = Exact<{
  input: ToggleLessonCompletedInput;
}>;


export type ToggleLessonCompletedMutation = { __typename?: 'Mutation', toggleLessonCompleted: (
    { __typename?: 'Post' }
    & LessonCoreFragment
  ) | { __typename?: 'PostError' } };

export type UpsellCoreFragment = { __typename: 'Upsell', id: string, type: UpsellTypes, hidden: boolean };

export type UpsellsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type UpsellsQuery = { __typename?: 'Query', upsells: Array<(
    { __typename?: 'Upsell' }
    & UpsellCoreFragment
  )> };

export type HideUpsellMutationVariables = Exact<{
  input: HideUpsellInput;
}>;


export type HideUpsellMutation = { __typename?: 'Mutation', hideUpsell: (
    { __typename?: 'Upsell' }
    & UpsellCoreFragment
  ) | { __typename?: 'UpsellError' } };

export type PublishCourseMutationVariables = Exact<{
  input: PublishCourseInput;
}>;


export type PublishCourseMutation = { __typename?: 'Mutation', publishCourse: { __typename?: 'Group', id: string, isDraft: boolean } };

export type LocationByNameQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type LocationByNameQuery = { __typename?: 'Query', locationByName: Array<{ __typename?: 'Location', placeId: string, type: LocationTypes, displayName: string, lat: number, lon: number }> };

export type LocationByPlaceIdQueryVariables = Exact<{
  placeId: Scalars['String']['input'];
}>;


export type LocationByPlaceIdQuery = { __typename?: 'Query', locationByPlaceId: { __typename?: 'Location', placeId: string, type: LocationTypes, displayName: string, lat: number, lon: number } };

export type ToggleEventPostCreationPolicyMutationVariables = Exact<{
  input: ToggleEventPostCreationPolicyInput;
}>;


export type ToggleEventPostCreationPolicyMutation = { __typename?: 'Mutation', toggleEventPostCreationPolicy: (
    { __typename?: 'Event' }
    & EventCoreFragment
  ) | { __typename?: 'EventError' } };

export type ToggleEventNotificationsMutationVariables = Exact<{
  input: ToggleEventNotificationsInput;
}>;


export type ToggleEventNotificationsMutation = { __typename?: 'Mutation', toggleEventNotifications: (
    { __typename?: 'Event' }
    & EventCoreFragment
  ) | { __typename?: 'EventError' } };

export type CourseCompletionQueryVariables = Exact<{
  courseId: Scalars['ID']['input'];
}>;


export type CourseCompletionQuery = { __typename?: 'Query', courseCompletion: { __typename?: 'CourseCompletionPayload', id: string, percentage: number } };

export type ToggleOrganizationMembershipOnboardingTaskMutationVariables = Exact<{
  input: ToggleOrganizationMembershipOnboardingTaskInput;
}>;


export type ToggleOrganizationMembershipOnboardingTaskMutation = { __typename?: 'Mutation', toggleOrganizationMembershipOnboardingTask: { __typename?: 'OrganizationMembership', id: string, completedOnboardingTasks: Array<OrganizationMembershipOnboardingTasks> } };

export type CreateOrDeletePostBookmarkMutationVariables = Exact<{
  input: CreateOrDeletePostBookmarkInput;
}>;


export type CreateOrDeletePostBookmarkMutation = { __typename?: 'Mutation', createOrDeletePostBookmark: { __typename?: 'PostBookmark', post: (
      { __typename?: 'Post' }
      & PostFeedFragment
    ) } };

export type PostBookmarksQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type PostBookmarksQuery = { __typename?: 'Query', postBookmarks: { __typename?: 'PostBookmarkConnection', totalCount: number, edges: Array<{ __typename?: 'PostBookmarkObjectEdge', cursor: string, node: { __typename?: 'PostBookmark', post: (
          { __typename?: 'Post' }
          & PostFeedFragment
        ) } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } };

export type MenuItemCoreFragment = { __typename?: 'MenuItem', id: string, name?: string | null, sortIndex: number, group?: (
    { __typename?: 'Group', membership?: { __typename?: 'GroupMembership', id: string, allowNewOccurrenceNotifications?: boolean | null, allowNewPostNotifications?: boolean | null } | null }
    & GroupCoreFragment
  ) | null, post?: (
    { __typename?: 'Post' }
    & PostCoreFragment
  ) | null, resource?: (
    { __typename?: 'Resource' }
    & ResourceCoreFragment
  ) | null };

export type MenuSectionCoreFragment = { __typename?: 'MenuSection', id: string, type: MenuSectionTypes, name?: string | null, sortIndex: number, menuItems: Array<(
    { __typename?: 'MenuItem' }
    & MenuItemCoreFragment
  )> };

export type MenuSectionsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type MenuSectionsQuery = { __typename?: 'Query', menuSections: Array<(
    { __typename?: 'MenuSection' }
    & MenuSectionCoreFragment
  )> };

export type CreateMenuSectionMutationVariables = Exact<{
  input: CreateMenuSectionInput;
}>;


export type CreateMenuSectionMutation = { __typename?: 'Mutation', createMenuSection: (
    { __typename?: 'MenuSection' }
    & MenuSectionCoreFragment
  ) };

export type UpdateMenuSectionMutationVariables = Exact<{
  input: UpdateMenuSectionInput;
}>;


export type UpdateMenuSectionMutation = { __typename?: 'Mutation', updateMenuSection: (
    { __typename?: 'MenuSection' }
    & MenuSectionCoreFragment
  ) };

export type DeleteMenuSectionMutationVariables = Exact<{
  input: DeleteMenuSectionInput;
}>;


export type DeleteMenuSectionMutation = { __typename?: 'Mutation', deleteMenuSection: (
    { __typename?: 'MenuSection' }
    & MenuSectionCoreFragment
  ) };

export type ReorderMenuSectionsMutationVariables = Exact<{
  input: ReorderMenuSectionsInput;
}>;


export type ReorderMenuSectionsMutation = { __typename?: 'Mutation', reorderMenuSections: Array<(
    { __typename?: 'MenuSection' }
    & MenuSectionCoreFragment
  )> };

export type CreateMenuItemUrlMutationVariables = Exact<{
  input: CreateMenuItemUrlInput;
}>;


export type CreateMenuItemUrlMutation = { __typename?: 'Mutation', createMenuItemUrl: (
    { __typename?: 'MenuItem' }
    & MenuItemCoreFragment
  ) };

export type CreateMenuItemWithPostMutationVariables = Exact<{
  input: CreateMenuItemWithPostInput;
}>;


export type CreateMenuItemWithPostMutation = { __typename?: 'Mutation', createMenuItemWithPost: (
    { __typename?: 'MenuItem' }
    & MenuItemCoreFragment
  ) };

export type UpdateMenuItemMutationVariables = Exact<{
  input: UpdateMenuItemInput;
}>;


export type UpdateMenuItemMutation = { __typename?: 'Mutation', updateMenuItem: (
    { __typename?: 'MenuItem' }
    & MenuItemCoreFragment
  ) };

export type DeleteMenuItemMutationVariables = Exact<{
  input: DeleteMenuItemInput;
}>;


export type DeleteMenuItemMutation = { __typename?: 'Mutation', deleteMenuItem: (
    { __typename?: 'MenuItem' }
    & MenuItemCoreFragment
  ) };

export type ReorderMenuItemsMutationVariables = Exact<{
  input: ReorderMenuItemsInput;
}>;


export type ReorderMenuItemsMutation = { __typename?: 'Mutation', reorderMenuItems: Array<(
    { __typename?: 'MenuItem' }
    & MenuItemCoreFragment
  )> };

export type HelloPageBlockIntroductionCoreFragment = { __typename: 'HelloPageBlockIntroduction', id: string, type: HelloPageBlockTypes, sortIndex: number, title?: string | null, emojiUnicodeOrCustomImageId?: string | null, content?: { [key: string]: any } | null, createdAt: string };

export type HelloPageBlockNoteCoreFragment = { __typename: 'HelloPageBlockNote', id: string, type: HelloPageBlockTypes, sortIndex: number, content?: { [key: string]: any } | null, createdAt: string };

export type HelloPageBlockResourceCoreFragment = { __typename: 'HelloPageBlockResource', id: string, type: HelloPageBlockTypes, sortIndex: number, url?: string | null, title?: string | null, description?: string | null, createdAt: string, logo?: string | null, logoTitle?: string | null };

export type HelloPageBlockEmailCoreFragment = { __typename: 'HelloPageBlockEmail', id: string, type: HelloPageBlockTypes, sortIndex: number, createdAt: string, email?: string | null, title?: string | null, description?: string | null };

export type HelloPageBlockInternalCoreFragment = { __typename: 'HelloPageBlockInternal', id: string, type: HelloPageBlockTypes, sortIndex: number, createdAt: string, internalType: HelloPageBlockInternalTypes };

export type HelloPageBlockVideoMetaFragment = { __typename?: 'HelloPageBlockVideo', id: string, type: HelloPageBlockTypes, sortIndex: number, createdAt: string, user: { __typename?: 'User', id: string, username: string, firstName: string, position?: string | null }, video: { __typename?: 'Video', id: string } };

export type HelloPageBlockVideoCoreFragment = (
  { __typename: 'HelloPageBlockVideo', user: { __typename?: 'User', id: string, username: string, firstName: string }, video: { __typename?: 'Video', id: string, aspect?: number | null, mirror: boolean, playbackId?: string | null } }
  & HelloPageBlockVideoMetaFragment
);

export type HelloPageBlockImageCoreFragment = { __typename: 'HelloPageBlockImage', id: string, type: HelloPageBlockTypes, sortIndex: number, imageId: string };

export type HelloPageMetaFragment = { __typename?: 'HelloPage', id: string, slug: string, createdAt: string, firstName?: string | null, lastName?: string | null, title?: string | null, recipientProfileImageId?: string | null, emojiUnicodeOrCustomImageId?: string | null, template: HelloPageTemplateTypes, createdBy: { __typename?: 'User', id: string, firstName: string } };

export type HelloPageCoreFragment = (
  { __typename?: 'HelloPage', helloPageBlocks: Array<(
    { __typename: 'HelloPageBlockEmail' }
    & HelloPageBlockEmailCoreFragment
  ) | (
    { __typename: 'HelloPageBlockImage' }
    & HelloPageBlockImageCoreFragment
  ) | (
    { __typename: 'HelloPageBlockInternal' }
    & HelloPageBlockInternalCoreFragment
  ) | (
    { __typename: 'HelloPageBlockIntroduction' }
    & HelloPageBlockIntroductionCoreFragment
  ) | (
    { __typename: 'HelloPageBlockNote' }
    & HelloPageBlockNoteCoreFragment
  ) | (
    { __typename: 'HelloPageBlockResource' }
    & HelloPageBlockResourceCoreFragment
  ) | (
    { __typename: 'HelloPageBlockVideo' }
    & HelloPageBlockVideoCoreFragment
  )> }
  & HelloPageMetaFragment
);

export type HelloPageByShortIdQueryVariables = Exact<{
  shortId: Scalars['String']['input'];
}>;


export type HelloPageByShortIdQuery = { __typename?: 'Query', helloPageByShortId?: (
    { __typename?: 'HelloPage' }
    & HelloPageCoreFragment
  ) | null };

export type InboxStateQueryVariables = Exact<{ [key: string]: never; }>;


export type InboxStateQuery = { __typename?: 'Query', inboxState: { __typename?: 'InboxState', showRead?: boolean | null } };

export type OrganizationStatsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type OrganizationStatsQuery = { __typename?: 'Query', organizationStats: { __typename?: 'OrganizationStats', id: string, totalMembers: number, totalMembersInTheLast14Days: number, totalPosts: number, totalPostsInTheLast14Days: number, totalReactions: number, totalReactionsInTheLast14Days: number, totalComments: number, totalCommentsInTheLast14Days: number } };

export type MembersWithHighestPostCountQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type MembersWithHighestPostCountQuery = { __typename?: 'Query', membersWithHighestPostCount: Array<{ __typename?: 'MemberCountStat', id: string, count: number, user: (
      { __typename?: 'User' }
      & UserPreviewFragment
    ) }> };

export type MembersWithHighestCommentCountQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type MembersWithHighestCommentCountQuery = { __typename?: 'Query', membersWithHighestCommentCount: Array<{ __typename?: 'MemberCountStat', id: string, count: number, user: (
      { __typename?: 'User' }
      & UserPreviewFragment
    ) }> };

export type MembersWithHighestReactionCountQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type MembersWithHighestReactionCountQuery = { __typename?: 'Query', membersWithHighestReactionCount: Array<{ __typename?: 'MemberCountStat', id: string, count: number, user: (
      { __typename?: 'User' }
      & UserPreviewFragment
    ) }> };

export type MostRecentOrganizationActivityQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type MostRecentOrganizationActivityQuery = { __typename?: 'Query', mostRecentOrganizationActivity: Array<{ __typename?: 'MemberActivityStat', id: string, entityId: string, type: ActivityTypes, activityDate: string, user: (
      { __typename?: 'User' }
      & UserPreviewFragment
    ) }> };

export type NewMembershipsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type NewMembershipsQuery = { __typename?: 'Query', newMemberships: Array<{ __typename?: 'OrganizationMembership', id: string, createdAt: string, user: (
      { __typename?: 'User' }
      & UserPreviewFragment
    ) }> };

export type AddEntryToWaitlistMutationVariables = Exact<{
  input: AddEntryToWaitlistInput;
}>;


export type AddEntryToWaitlistMutation = { __typename?: 'Mutation', addEntryToWaitlist: { __typename?: 'WaitlistEntry', id: string } | { __typename?: 'WaitlistEntryError', errorCode: WaitlistEntryErrorCode } };

export type NotificationFragmentFragment = { __typename?: 'Notification', id: string, type: NotificationTypes, emojiUnicode?: string | null, createdAt: string, content: { [key: string]: any }, link: string, imageUrl?: string | null, eventName: NotificationEventNames, quoteContent?: { [key: string]: any } | null, read: boolean, user?: { __typename?: 'User', id: string, profileImageId?: string | null, avatarBgColor: string, avatar: string, username: string } | null };

export type NotificationsQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type NotificationsQuery = { __typename?: 'Query', notifications: { __typename?: 'NotificationConnection', totalCount: number, edges: Array<{ __typename?: 'NotificationObjectEdge', cursor: string, node: (
        { __typename?: 'Notification' }
        & NotificationFragmentFragment
      ) }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } };

export type NotificationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type NotificationQuery = { __typename?: 'Query', notification: (
    { __typename?: 'Notification', otherUser?: { __typename?: 'User', id: string, profileImageId?: string | null, avatarBgColor: string, avatar: string, username: string, firstName: string, email: string } | null, admin?: { __typename?: 'User', id: string, profileImageId?: string | null, avatarBgColor: string, avatar: string, username: string, firstName: string } | null, role?: { __typename?: 'OrganizationRole', id: string, label: string } | null, memberSubscription?: { __typename?: 'MemberSubscription', id: string, expiresAt: string } | null, memberSubscriptionPlan?: { __typename?: 'MemberSubscriptionPlan', id: string, name: string, priceMonthlyUsd: number } | null, group?: { __typename?: 'Group', id: string, type: GroupTypes, name: string } | null, requestToJoins: Array<{ __typename?: 'RequestToJoin', id: string, email: string, createdAt: string, status: RequestToJoinStatusTypes, user: { __typename?: 'User', id: string, profileImageId?: string | null, avatarBgColor: string, avatar: string, username: string, firstName: string } }> }
    & NotificationFragmentFragment
  ) };

export type UserPushEventsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserPushEventsSubscription = { __typename?: 'Subscription', userPushEvent: { __typename?: 'PushRefetchHint', id: string, organizationId: string, postId?: string | null, groupId?: string | null } };

export type NotificationCountsQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type NotificationCountsQuery = { __typename?: 'Query', notificationCounts: { __typename?: 'NotificationCounts', unread: number } };

export type MarkAllNotificationsReadMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type MarkAllNotificationsReadMutation = { __typename?: 'Mutation', markAllNotificationsRead: { __typename?: 'NotificationCounts', unread: number } };

export type MarkNotificationReadMutationVariables = Exact<{
  input: ReadNotificationInput;
}>;


export type MarkNotificationReadMutation = { __typename?: 'Mutation', markNotificationRead: (
    { __typename?: 'Notification' }
    & NotificationFragmentFragment
  ) };

export type CreateUserFcmPushSubscriptionMutationVariables = Exact<{
  input: CreateUserFcmPushSubscriptionInput;
}>;


export type CreateUserFcmPushSubscriptionMutation = { __typename?: 'Mutation', createUserFCMPushSubscription: { __typename?: 'User', id: string } };

export type ProfileSetupMutationVariables = Exact<{
  input: ProfileSetupInput;
}>;


export type ProfileSetupMutation = { __typename?: 'Mutation', profileSetup: { __typename?: 'User', id: string, username: string, email: string, name: string, profileImageId?: string | null, firstName: string, lastName: string, linkedinUrl?: string | null, instagramHandle?: string | null, hometown?: string | null, currentCity?: string | null, bio?: string | null, claimedAccount: boolean, avatarBgColor: string, avatar: string } };

export type UpdateUserOnboardingMutationVariables = Exact<{
  input: UpdateUserOnboardingInput;
}>;


export type UpdateUserOnboardingMutation = { __typename?: 'Mutation', updateUserOnboarding: { __typename?: 'User', id: string, username: string } };

export type AddPostViewMutationVariables = Exact<{
  input: AddViewInput;
}>;


export type AddPostViewMutation = { __typename?: 'Mutation', addPostView: { __typename?: 'Post', id: string } };

export type TogglePinnedPostMutationVariables = Exact<{
  input: TogglePinnedPostInput;
}>;


export type TogglePinnedPostMutation = { __typename?: 'Mutation', togglePinnedPost: { __typename?: 'Post', id: string, pinned: boolean } };

export type ToggleHideFromProfilePostMutationVariables = Exact<{
  input: ToggleHideFromProfilePostInput;
}>;


export type ToggleHideFromProfilePostMutation = { __typename?: 'Mutation', toggleHideFromProfilePost: { __typename?: 'Post', id: string, hideFromProfile: boolean } };

export type ToggleShowAuthorOnPostMutationVariables = Exact<{
  input: ToggleShowAuthorOnPostInput;
}>;


export type ToggleShowAuthorOnPostMutation = { __typename?: 'Mutation', toggleShowAuthorOnPost: { __typename?: 'Post', id: string, showAuthor: boolean } };

export type ToggleShowCommentsAndReactionsOnPostMutationVariables = Exact<{
  input: ToggleShowCommentsAndReactionsOnPostInput;
}>;


export type ToggleShowCommentsAndReactionsOnPostMutation = { __typename?: 'Mutation', toggleShowCommentsAndReactionsOnPost: { __typename?: 'Post', id: string, showCommentsAndReactions: boolean } };

export type WelcomePostQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type WelcomePostQuery = { __typename?: 'Query', welcomePost?: (
    { __typename: 'Post' }
    & PostFeedFragment
  ) | { __typename: 'PostError', message: string } | null };

export type ToggleWelcomePostMutationVariables = Exact<{
  input: ToggleWelcomePostInput;
}>;


export type ToggleWelcomePostMutation = { __typename?: 'Mutation', toggleWelcomePost: { __typename?: 'Post', id: string, deleted?: boolean | null } };

export type DismissWelcomePostMutationVariables = Exact<{
  input: DismissWelcomePostInput;
}>;


export type DismissWelcomePostMutation = { __typename?: 'Mutation', dismissWelcomePost: { __typename?: 'OrganizationMembership', id: string } };

export type NewMemberPostQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;


export type NewMemberPostQuery = { __typename?: 'Query', newMemberPost?: (
    { __typename?: 'Post' }
    & PostFeedFragment
  ) | null };

export type ToggleFollowPostMutationVariables = Exact<{
  input: ToggleFollowPostInput;
}>;


export type ToggleFollowPostMutation = { __typename?: 'Mutation', toggleFollowPost: { __typename?: 'Post', id: string } };

export type IsFollowingPostQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
  postId: Scalars['ID']['input'];
}>;


export type IsFollowingPostQuery = { __typename?: 'Query', isFollowingPost?: { __typename?: 'PostSubscription', id: string, postId: string } | null };

export type MovePostMutationVariables = Exact<{
  input: MovePostInput;
}>;


export type MovePostMutation = { __typename?: 'Mutation', movePost: { __typename?: 'Post', id: string } };

export type PublishDraftPostMutationVariables = Exact<{
  input: PublishDraftPostInput;
}>;


export type PublishDraftPostMutation = { __typename?: 'Mutation', publishDraftPost: { __typename?: 'Post', id: string, isDraft: boolean } };

export type RescheduleDraftPostMutationVariables = Exact<{
  input: RescheduleDraftPostInput;
}>;


export type RescheduleDraftPostMutation = { __typename?: 'Mutation', rescheduleDraftPost: { __typename?: 'Post', id: string, scheduledAt?: string | null } };

export type ShortIdSuggestionQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type ShortIdSuggestionQuery = { __typename?: 'Query', shortIdSuggestion: { __typename: 'OrganizationShortId', shortId: string } | { __typename: 'OrganizationShortIdError', message: string } };

export type UsernameAvailableQueryVariables = Exact<{
  username: Scalars['String']['input'];
}>;


export type UsernameAvailableQuery = { __typename?: 'Query', usernameAvailable: { __typename: 'Username', username: string } | { __typename: 'UsernameError', message: string } };

export type ShortIdAvailableQueryVariables = Exact<{
  shortId: Scalars['String']['input'];
}>;


export type ShortIdAvailableQuery = { __typename?: 'Query', shortIdAvailable: { __typename: 'OrganizationShortId', shortId: string } | { __typename: 'OrganizationShortIdError', message: string } };

export type CreateOrUpdateOrganizationMutationVariables = Exact<{
  input: CreateOrUpdateOrganizationInput;
}>;


export type CreateOrUpdateOrganizationMutation = { __typename?: 'Mutation', createOrUpdateOrganization: (
    { __typename?: 'Organization' }
    & OrganizationCoreFragment
  ) };

export type SetCustomDomainMutationVariables = Exact<{
  input: SetOrganizationCustomDomainInput;
}>;


export type SetCustomDomainMutation = { __typename?: 'Mutation', setCustomDomain: { __typename?: 'Organization', id: string, domain?: string | null } };

export type RemoveCustomDomainMutationVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type RemoveCustomDomainMutation = { __typename?: 'Mutation', removeCustomDomain: { __typename?: 'Organization', id: string, domain?: string | null } };

export type JoinOrganizationMutationVariables = Exact<{
  input: JoinOrganizationInput;
}>;


export type JoinOrganizationMutation = { __typename?: 'Mutation', joinOrganization: { __typename?: 'Organization', id: string, name: string, groupCreationPolicy: GroupCreationPolicy } | { __typename?: 'OrganizationJoinError', message: string } };

export type UpdateUserLanguageMutationVariables = Exact<{
  input: UpdateUserLanguageInput;
}>;


export type UpdateUserLanguageMutation = { __typename?: 'Mutation', updateUserLanguage: { __typename?: 'User', id: string, username: string } };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: string, username: string, name: string, profileImageId?: string | null, avatarBgColor: string, avatar: string, firstName: string, lastName: string, linkedinUrl?: string | null, instagramHandle?: string | null, hometown?: string | null, currentCity?: string | null, bio?: string | null, claimedAccount: boolean } };

export type OrganizationAdministratorsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type OrganizationAdministratorsQuery = { __typename?: 'Query', organization?: { __typename?: 'Organization', id: string, administrators: Array<{ __typename?: 'User', id: string, username: string, firstName: string, lastName: string, profileImageId?: string | null, avatar: string, avatarBgColor: string }> } | null };

export type OrganizationQuotaUsageQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type OrganizationQuotaUsageQuery = { __typename?: 'Query', organization?: { __typename?: 'Organization', id: string, quotaUsage: Array<{ __typename?: 'QuotaUsage', name: FeatureQuota, limit: number, used: number }> } | null };

export type AdminSubscriptionPlanPriceListQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminSubscriptionPlanPriceListQuery = { __typename?: 'Query', adminSubscriptionPlanPriceList: { __typename?: 'PriceList', prices: Array<{ __typename?: 'Price', id: string, unitAmount?: number | null, lookupKey: AdminSubscriptionPlanPriceLookupKey, currency: string }> } };

export type OrganizationEntitlementsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type OrganizationEntitlementsQuery = { __typename?: 'Query', organization?: { __typename?: 'Organization', id: string, entitlements?: { __typename?: 'OrganizationEntitlements', features: Array<FeatureEntitlement>, quotas: Array<{ __typename?: 'QuotaEntitlements', name: FeatureQuota, limit: number }> } | null } | null };

export type OrganizationAdminSubscriptionQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type OrganizationAdminSubscriptionQuery = { __typename?: 'Query', organization?: { __typename?: 'Organization', id: string, adminSubscription?: { __typename?: 'AdminSubscription', stripeSubscriptionStatus: string, stripeCancelAtPeriodEnd: boolean, subscriptionPlan: AdminSubscriptionPlanId, subscriptionPrice: AdminSubscriptionPlanPriceLookupKey, grandfathered: boolean, expiresAt: string } | null } | null };

export type OrganizationMemberSubscriptionPlanQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type OrganizationMemberSubscriptionPlanQuery = { __typename?: 'Query', organization?: { __typename?: 'Organization', id: string, memberSubscriptionPlan?: { __typename?: 'MemberSubscriptionPlan', id: string, name: string, description?: string | null, priceMonthlyUsd: number, active: boolean, stripeAccount?: { __typename?: 'StripeAccount', id: string, disabledReason?: string | null } | null, groupEntitlements: Array<{ __typename?: 'Group', id: string, name: string, description?: { [key: string]: any } | null, emoji: string, numPosts: number, groupType: GroupTypes }> } | null } | null };

export type MemberSubscriptionPlanQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type MemberSubscriptionPlanQuery = { __typename?: 'Query', memberSubscriptionPlan: { __typename?: 'MemberSubscriptionPlan', id: string, subscriberCount: number, pendingBalance: number, priceMonthlyUsd: number, totalRevenue: number, active: boolean, stripeAccount?: { __typename?: 'StripeAccount', id: string, createdAt: string } | null } };

export type ChangeAdminSubscriptionPlanMutationVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  lookupKey: AdminSubscriptionPlanPriceLookupKey;
}>;


export type ChangeAdminSubscriptionPlanMutation = { __typename?: 'Mutation', changeAdminSubscriptionPlan?: { __typename?: 'AdminSubscription', stripeSubscriptionStatus: string } | null };

export type UpsertMemberSubscriptionPlanMutationVariables = Exact<{
  input: UpsertMemberSubscriptionPlanInput;
}>;


export type UpsertMemberSubscriptionPlanMutation = { __typename?: 'Mutation', upsertMemberSubscriptionPlan: { __typename?: 'MemberSubscriptionPlan', id: string } };

export type CancelAdminSubscriptionMutationVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type CancelAdminSubscriptionMutation = { __typename?: 'Mutation', cancelAdminSubscription?: { __typename?: 'AdminSubscription', stripeSubscriptionStatus: string } | null };

export type CancelMemberSubscriptionMutationVariables = Exact<{
  memberSubscriptionId: Scalars['ID']['input'];
}>;


export type CancelMemberSubscriptionMutation = { __typename?: 'Mutation', cancelMemberSubscription?: { __typename?: 'MemberSubscription', id: string } | null };

export type ReactivateMemberSubscriptionMutationVariables = Exact<{
  memberSubscriptionId: Scalars['ID']['input'];
}>;


export type ReactivateMemberSubscriptionMutation = { __typename?: 'Mutation', reactivateMemberSubscription?: { __typename?: 'MemberSubscription', id: string } | null };

export type CreateAdminPlanCheckoutSessionMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  lookupKey: AdminSubscriptionPlanPriceLookupKey;
}>;


export type CreateAdminPlanCheckoutSessionMutation = { __typename?: 'Mutation', createAdminPlanCheckoutSession: { __typename?: 'CheckoutSession', url: string } };

export type CreateMemberPlanCheckoutSessionMutationVariables = Exact<{
  memberSubscriptionPlanId: Scalars['String']['input'];
  returnToGroupId?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateMemberPlanCheckoutSessionMutation = { __typename?: 'Mutation', createMemberPlanCheckoutSession: { __typename?: 'CheckoutSession', url: string } };

export type CreateAdminPlanPortalSessionMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  returnUrl: Scalars['String']['input'];
}>;


export type CreateAdminPlanPortalSessionMutation = { __typename?: 'Mutation', createAdminPlanPortalSession: { __typename?: 'CheckoutSession', url: string } };

export type BillingDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type BillingDetailsQuery = { __typename?: 'Query', viewer: { __typename?: 'User', id: string, username: string, billingDetails?: { __typename?: 'BillingDetails', address: string, name: string, email: string, cardBrand?: string | null, cardLast4?: string | null } | null } };

export type CreateExpressDashboardSessionMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type CreateExpressDashboardSessionMutation = { __typename?: 'Mutation', createExpressDashboardSession: { __typename?: 'CheckoutSession', url: string } };

export type CreateMemberPlanPortalSessionMutationVariables = Exact<{
  returnUrl: Scalars['String']['input'];
}>;


export type CreateMemberPlanPortalSessionMutation = { __typename?: 'Mutation', createMemberPlanPortalSession: { __typename?: 'CheckoutSession', url: string } };

export type CreateStripeAccountLinkSessionMutationVariables = Exact<{
  memberSubscriptionPlanId: Scalars['String']['input'];
}>;


export type CreateStripeAccountLinkSessionMutation = { __typename?: 'Mutation', createStripeAccountLinkSession: { __typename?: 'CheckoutSession', url: string } };

export type DeactivateMemberSubscriptionPlanMutationVariables = Exact<{
  memberSubscriptionPlanId: Scalars['String']['input'];
}>;


export type DeactivateMemberSubscriptionPlanMutation = { __typename?: 'Mutation', deactivateMemberSubscriptionPlan: { __typename?: 'MemberSubscriptionPlan', id: string, active: boolean } };

export type ReactivateMemberSubscriptionPlanMutationVariables = Exact<{
  memberSubscriptionPlanId: Scalars['String']['input'];
}>;


export type ReactivateMemberSubscriptionPlanMutation = { __typename?: 'Mutation', reactivateMemberSubscriptionPlan: { __typename?: 'MemberSubscriptionPlan', id: string, active: boolean } };

export type OrganizationByInviteCodeQueryVariables = Exact<{
  inviteCode: Scalars['ID']['input'];
}>;


export type OrganizationByInviteCodeQuery = { __typename?: 'Query', organizationByInviteCode?: { __typename?: 'Organization', id: string, shortId: string, color: string, name: string, description?: string | null, logoId?: string | null, isSetup: boolean, isGoogleWorkspace: boolean, createdAt: string, numMembers: number, numGroups: number, groupCreationPolicy: GroupCreationPolicy, joinPolicy: JoinPolicy, addMemberPolicy: AddMemberPolicy, enableNewMemberIntroductions: boolean, newMemberIntroductionMessage: string, isPublic: boolean, widgetSizeVariant: WidgetSizeVariant, hasCoursesEnabled: boolean, hasEventsEnabled: boolean, hasInsightsEnabled: boolean, templateType: OrganizationTemplateType } | null };

export type MembersQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<MembersOrderByInput>;
}>;


export type MembersQuery = { __typename?: 'Query', members: { __typename?: 'MembersPaginated', totalCount: number, items: Array<{ __typename?: 'OrganizationMembership', id: string, active: boolean, isCreator: boolean, createdAt: string, email: string, roles: Array<{ __typename?: 'OrganizationRole', id: string, label: string, color: OrganizationRoleColor, isAdmin: boolean, memberSubscriptionPlan?: { __typename?: 'MemberSubscriptionPlan', id: string, name: string } | null }>, user: { __typename?: 'User', id: string, name: string, firstName: string, username: string, avatarBgColor: string, avatar: string, profileImageId?: string | null, memberSubscriptions: Array<{ __typename?: 'MemberSubscription', id: string, subscriptionPlan: { __typename?: 'MemberSubscriptionPlan', id: string } }> } }> } };

export type SuspendUserMutationVariables = Exact<{
  input: SuspendUserInput;
}>;


export type SuspendUserMutation = { __typename?: 'Mutation', suspendUser: { __typename?: 'User', id: string, username: string } };

export type DemoteUserMutationVariables = Exact<{
  input: DemoteUserInput;
}>;


export type DemoteUserMutation = { __typename?: 'Mutation', demoteUser: { __typename?: 'User', id: string, username: string } };

export type LeaveOrganizationMutationVariables = Exact<{
  input: LeaveOrganizationInput;
}>;


export type LeaveOrganizationMutation = { __typename?: 'Mutation', leaveOrganization: { __typename?: 'User', id: string, username: string } };

export type PromoteUserMutationVariables = Exact<{
  input: PromoteUserInput;
}>;


export type PromoteUserMutation = { __typename?: 'Mutation', promoteUser: { __typename?: 'User', id: string, username: string } };

export type AssignRoleMutationVariables = Exact<{
  input: AssignRoleInput;
}>;


export type AssignRoleMutation = { __typename?: 'Mutation', assignRole: { __typename?: 'User', id: string, username: string } };

export type UnassignRoleMutationVariables = Exact<{
  input: UnassignRoleInput;
}>;


export type UnassignRoleMutation = { __typename?: 'Mutation', unassignRole: { __typename?: 'User', id: string, username: string } };

export type InviteUsersMutationVariables = Exact<{
  input: InviteUsersInput;
}>;


export type InviteUsersMutation = { __typename?: 'Mutation', inviteUsers: Array<{ __typename: 'RequestToJoin', id: string } | { __typename: 'RequestToJoinError', message: string } | { __typename: 'UserInvite', id: string, email: string, inviteCode: string }> };

export type RevokeUserInvitesMutationVariables = Exact<{
  input: RevokeUserInvitesInput;
}>;


export type RevokeUserInvitesMutation = { __typename?: 'Mutation', revokeUserInvites: Array<{ __typename?: 'UserInvite', id: string, email: string }> };

export type ResendUserInviteMutationVariables = Exact<{
  input: ResendUserInviteInput;
}>;


export type ResendUserInviteMutation = { __typename?: 'Mutation', resendUserInvite: { __typename?: 'UserInvite', id: string, email: string } };

export type ResetOrganizationInviteMutationVariables = Exact<{
  input: ResetOrganizationInviteInput;
}>;


export type ResetOrganizationInviteMutation = { __typename?: 'Mutation', resetOrganizationInvite: { __typename?: 'OrganizationInvite', id: string } };

export type RestoreUserMutationVariables = Exact<{
  input: RestoreUserInput;
}>;


export type RestoreUserMutation = { __typename?: 'Mutation', restoreUser: { __typename?: 'User', id: string, username: string } };

export type NotificationPreferencesQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type NotificationPreferencesQuery = { __typename?: 'Query', notificationPreferences: Array<{ __typename?: 'UserNotificationPreferences', id: string, type: NotificationPreferencesType, allowNewPostNotifications: boolean, allowCommentNotifications: boolean, allowMentionNotifications: boolean, allowReactionNotifications: boolean, allowReminderNotifications: boolean, allowPollNotifications: boolean, allowGroupNotifications: boolean, allowAccountNotifications: boolean, allowNewMemberRequestNotifications: boolean, allowUserJoinedNotifications: boolean, allowDirectMessageNotifications: boolean }> };

export type CreateUserWebPushSubscriptionMutationVariables = Exact<{
  input: CreateUserWebPushSubscriptionInput;
}>;


export type CreateUserWebPushSubscriptionMutation = { __typename?: 'Mutation', createUserWebPushSubscription: { __typename?: 'User', id: string, username: string } };

export type UpdateUserNotificationPreferencesMutationVariables = Exact<{
  input: UpdateUserNotificationPreferencesInput;
}>;


export type UpdateUserNotificationPreferencesMutation = { __typename?: 'Mutation', updateUserNotificationPreferences: { __typename?: 'UserNotificationPreferences', id: string, type: NotificationPreferencesType, allowNewPostNotifications: boolean, allowCommentNotifications: boolean, allowMentionNotifications: boolean, allowReactionNotifications: boolean, allowReminderNotifications: boolean, allowPollNotifications: boolean, allowGroupNotifications: boolean, allowAccountNotifications: boolean, allowNewMemberRequestNotifications: boolean, allowUserJoinedNotifications: boolean, allowDirectMessageNotifications: boolean } };

export type UpdateNewMemberIntroductionMessageMutationVariables = Exact<{
  input: UpdateNewMemberIntroductionMessageInput;
}>;


export type UpdateNewMemberIntroductionMessageMutation = { __typename?: 'Mutation', updateNewMemberIntroductionMessage: { __typename?: 'Organization', id: string, newMemberIntroductionMessage: string } };

export type ToggleNewMemberIntroductionsMutationVariables = Exact<{
  input: ToggleNewMemberIntroductionsInput;
}>;


export type ToggleNewMemberIntroductionsMutation = { __typename?: 'Mutation', toggleNewMemberIntroductions: { __typename?: 'Organization', id: string, enableNewMemberIntroductions: boolean } };

export type ToggleAutoMessageNewMembersMutationVariables = Exact<{
  input: ToggleAutoMessageNewMembersInput;
}>;


export type ToggleAutoMessageNewMembersMutation = { __typename?: 'Mutation', toggleAutoMessageNewMembers: { __typename?: 'Organization', id: string, enableAutoMessageNewMembers: boolean, autoMessageNewMembersFromId?: string | null } };

export type UpdateAutoMessageNewMembersMessageMutationVariables = Exact<{
  input: UpdateAutoMessageNewMembersMessageInput;
}>;


export type UpdateAutoMessageNewMembersMessageMutation = { __typename?: 'Mutation', updateAutoMessageNewMembersMessage: { __typename?: 'Organization', id: string, autoMessageNewMembersMessage: string, autoMessageNewMembersFromId?: string | null } };

export type TogglePublicCommunityMutationVariables = Exact<{
  input: TogglePublicCommunityInput;
}>;


export type TogglePublicCommunityMutation = { __typename?: 'Mutation', togglePublicCommunity: { __typename?: 'Organization', id: string, isPublic: boolean, joinPolicy: JoinPolicy, addMemberPolicy: AddMemberPolicy } };

export type CheckIfWidgetIsDetectedQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type CheckIfWidgetIsDetectedQuery = { __typename?: 'Query', checkIfWidgetIsDetected?: { __typename?: 'EmbedLoad', id: string, url: string } | null };

export type UpdateWidgetSizeVariantMutationVariables = Exact<{
  input: UpdateWidgetSizeVariantInput;
}>;


export type UpdateWidgetSizeVariantMutation = { __typename?: 'Mutation', updateWidgetSizeVariant: { __typename?: 'Organization', id: string, widgetSizeVariant: WidgetSizeVariant } };

export type DismissFrondIntroPostMutationVariables = Exact<{
  input: DismissFrondIntroPostInput;
}>;


export type DismissFrondIntroPostMutation = { __typename?: 'Mutation', dismissFrondIntroPost: { __typename?: 'OrganizationMembership', id: string, hideFrondIntroPost: boolean } };

export type ToggleOrganizationFeatureMutationVariables = Exact<{
  input: ToggleOrganizationFeatureInput;
}>;


export type ToggleOrganizationFeatureMutation = { __typename?: 'Mutation', toggleOrganizationFeature: { __typename?: 'Organization', id: string, hasCoursesEnabled: boolean, hasEventsEnabled: boolean, hasInsightsEnabled: boolean } };

export type CreateVideoPresignedUploadMutationVariables = Exact<{
  input: CreateVideoPresignedUploadInput;
}>;


export type CreateVideoPresignedUploadMutation = { __typename?: 'Mutation', createVideoPresignedUpload: { __typename?: 'VideoPresignedUpload', url: string, id: string } };

export type CreateTemporaryVideoPresignedUploadMutationVariables = Exact<{
  input: CreateTemporaryVideoPresignedUploadInput;
}>;


export type CreateTemporaryVideoPresignedUploadMutation = { __typename?: 'Mutation', createTemporaryVideoPresignedUpload: { __typename?: 'VideoPresignedUpload', url: string, id: string } };

export type VideoStatusQueryVariables = Exact<{
  videoId: Scalars['ID']['input'];
}>;


export type VideoStatusQuery = { __typename?: 'Query', video?: { __typename?: 'Video', id: string, aspect?: number | null, playbackId?: string | null, previewPlaybackId?: string | null, previewStatus?: VideoStatus | null, mirror: boolean, status: VideoStatus, type: VideoType, assetId?: string | null, createdAt: string } | null };

export const OrganizationAdminFieldsFragmentDoc = gql`
    fragment OrganizationAdminFields on Organization {
  id
  shortId
  name
  domain
  createdBy {
    email
    username
  }
  createdAt
}
    `;
export const UserProfileFragmentDoc = gql`
    fragment UserProfile on User {
  id
  username
  name
  profileImageId
  firstName
  lastName
  linkedinUrl
  instagramHandle
  instagramProfileImage
  avatarBgColor
  avatar
  currentCity
  hometown
  timeZone
  bio
  claimedAccount
  coverImageId
  createdAt
  profileVideo {
    playbackId
    aspect
  }
  preferences {
    id
    showQuestionsOnboarding
    isProfileSetup
    allowCookies
    allowEmailNotifications
  }
}
    `;
export const OrganizationCoreFragmentDoc = gql`
    fragment OrganizationCore on Organization {
  id
  shortId
  legacyShortId
  color
  name
  domain
  logoId
  description
  isGoogleWorkspace
  isSetup
  numMembers
  numGroups
  createdAt
  groupCreationPolicy
  joinPolicy
  addMemberPolicy
  widgetSizeVariant
  enableNewMemberIntroductions
  newMemberIntroductionMessage
  enableAutoMessageNewMembers
  autoMessageNewMembersMessage
  autoMessageNewMembersFromId
  isPublic
  hasCoursesEnabled
  hasEventsEnabled
  hasInsightsEnabled
  templateType
  adminSubscription {
    subscriptionPlan
  }
}
    `;
export const OrganizationExtendedFragmentDoc = gql`
    fragment OrganizationExtended on Organization {
  ...OrganizationCore
  memberSubscriptionPlan {
    id
    active
    name
    description
    priceMonthlyUsd
    pendingBalance
    subscriberCount
    totalRevenue
    groupEntitlements {
      id
      name
      description
      emoji
      numPosts
    }
  }
  customDomain {
    id
    status
    name
  }
}
    ${OrganizationCoreFragmentDoc}`;
export const MyResourceFragmentDoc = gql`
    fragment MyResource on Resource {
  id
  description
  domain
  imageUrl
  title
  url
  logo
  favicon
  expanded
}
    `;
export const OrganizationPublicFieldsFragmentDoc = gql`
    fragment OrganizationPublicFields on Organization {
  ...OrganizationCore
  activeMembers {
    id
    name
    firstName
    lastName
    username
    profileImageId
    avatar
    avatarBgColor
  }
}
    ${OrganizationCoreFragmentDoc}`;
export const ResourceCoreFragmentDoc = gql`
    fragment ResourceCore on Resource {
  id
  description
  domain
  favicon
  imageUrl
  logo
  logoTitle
  title
  url
}
    `;
export const UserPreviewFragmentDoc = gql`
    fragment UserPreview on User {
  id
  name
  firstName
  lastName
  username
  profileImageId
  avatar
  avatarBgColor
  coverImageId
}
    `;
export const PollCoreFragmentDoc = gql`
    fragment PollCore on Poll {
  id
  options {
    id
    name
    votes {
      createdBy {
        ...UserPreview
      }
    }
  }
}
    ${UserPreviewFragmentDoc}`;
export const PostCoreFragmentDoc = gql`
    fragment PostCore on Post {
  __typename
  id
  type
  bgColor
  deleted
  content
  createdAt
  updatedAt
  colorTheme
  aspectRatio
  pinned
  showAuthor
  showCommentsAndReactions
  title
  hideFromProfile
  isDraft
  scheduledAt
  resource {
    ...ResourceCore
  }
  resources {
    id
    description
    domain
    favicon
    imageUrl
    logo
    logoTitle
    title
    url
    expanded
  }
  media {
    ... on Image {
      id
      aspectRatio
      publicId
      width
      height
      coordinates {
        x
        y
        width
        height
      }
    }
    ... on Giphy {
      id
      aspectRatio
      giphyId
      giphyUrl
      giphyMp4Url
      width
      height
    }
    ... on Video {
      id
      aspect
      playbackId
      previewPlaybackId
      previewStatus
      mirror
      status
      type
      assetId
      createdAt
    }
  }
  poll {
    ...PollCore
  }
  createdBy {
    ...UserPreview
  }
  organization {
    ...OrganizationCore
  }
}
    ${ResourceCoreFragmentDoc}
${PollCoreFragmentDoc}
${UserPreviewFragmentDoc}
${OrganizationCoreFragmentDoc}`;
export const GroupCoreFragmentDoc = gql`
    fragment GroupCore on Group {
  __typename
  id
  name
  deleted
  description
  lastActivityAt
  questionEnabled
  isPrivate
  questionText
  questionCronExpression
  enableNewPostNotificationsForNewMembers
  timeZone
  hasPosts
  numPosts
  numMembers
  emoji
  coverImageId
  groupType: type
  hidden
  createdAt
  postCreationPolicy
  autoAddMembers
  sortIndex
  hasUnseenPosts
  includePostsOnHome
  isDraft
  createdBy {
    ...UserPreview
  }
  organization {
    ...OrganizationCore
  }
  unlockedBy {
    id
    active
  }
}
    ${UserPreviewFragmentDoc}
${OrganizationCoreFragmentDoc}`;
export const CommentCoreFragmentDoc = gql`
    fragment CommentCore on Comment {
  __typename
  id
  type
  content
  contentVersion
  isBestAnswer
  createdAt
  createdBy {
    ...UserPreview
  }
  reactions {
    id
    type
    hasReacted
    numReactions
    emojiUnicode
    createdByUsers {
      ...UserPreview
    }
  }
  resources {
    id
    description
    domain
    favicon
    imageUrl
    logo
    logoTitle
    title
    url
    expanded
  }
  media {
    ... on Image {
      id
      aspectRatio
      publicId
      width
      height
    }
    ... on Giphy {
      id
      aspectRatio
      giphyId
      giphyUrl
      giphyMp4Url
      width
      height
    }
  }
}
    ${UserPreviewFragmentDoc}`;
export const PostFeedFragmentDoc = gql`
    fragment PostFeed on Post {
  ...PostCore
  group {
    ...GroupCore
  }
  comments {
    ...CommentCore
  }
  reactions {
    id
    type
    hasReacted
    numReactions
    emojiUnicode
    createdByUsers {
      ...UserPreview
    }
  }
  revisions {
    id
    createdAt
  }
  numOfAnonymousViews
  views {
    id
    user {
      ...UserPreview
    }
  }
  completions {
    user {
      ...UserPreview
    }
  }
  isBookmarked
}
    ${PostCoreFragmentDoc}
${GroupCoreFragmentDoc}
${CommentCoreFragmentDoc}
${UserPreviewFragmentDoc}`;
export const GroupWithMembersFragmentDoc = gql`
    fragment GroupWithMembers on Group {
  ...GroupCore
  membership {
    id
    allowNewOccurrenceNotifications
    allowNewPostNotifications
  }
  createdBy {
    ...UserPreview
  }
  members {
    ...UserPreview
  }
}
    ${GroupCoreFragmentDoc}
${UserPreviewFragmentDoc}`;
export const UserGroupNotificationPreferencesFragmentDoc = gql`
    fragment UserGroupNotificationPreferences on Group {
  membership {
    allowNewOccurrenceNotifications
    allowNewPostNotifications
  }
}
    `;
export const GroupSidebarFragmentDoc = gql`
    fragment GroupSidebar on Group {
  ...GroupCore
  membership {
    id
    allowNewOccurrenceNotifications
    allowNewPostNotifications
  }
}
    ${GroupCoreFragmentDoc}`;
export const PaginatedSidebarGroupsConnectionFragmentDoc = gql`
    fragment PaginatedSidebarGroupsConnection on GroupsConnection {
  edges {
    node {
      ...GroupSidebar
    }
    cursor
  }
  totalCount
  pageInfo {
    endCursor
    hasNextPage
  }
}
    ${GroupSidebarFragmentDoc}`;
export const SearchResultFragmentDoc = gql`
    fragment SearchResult on Search {
  id
  excerpt
  createdAt
  link
  type
  createdBy {
    ...UserPreview
  }
  node {
    ... on Group {
      ...GroupCore
    }
  }
}
    ${UserPreviewFragmentDoc}
${GroupCoreFragmentDoc}`;
export const OrganizationRoleCoreFragmentDoc = gql`
    fragment OrganizationRoleCore on OrganizationRole {
  id
  label
  color
  isAdmin
  memberSubscriptionPlan {
    id
    name
  }
}
    `;
export const DirectMessageTokenFragmentFragmentDoc = gql`
    fragment DirectMessageTokenFragment on DirectMessageToken {
  communicationUserId
  token
  expiresOn
}
    `;
export const DirectMessageUserFragmentFragmentDoc = gql`
    fragment DirectMessageUserFragment on DirectMessageUser {
  id
  communicationUserId
  user {
    ...UserPreview
  }
}
    ${UserPreviewFragmentDoc}`;
export const DirectMessageUserThreadSettingsFragmentFragmentDoc = gql`
    fragment DirectMessageUserThreadSettingsFragment on DirectMessageUserThreadSettings {
  id
  markedUnread
  mutedNotifications
}
    `;
export const DirectMessageUserThreadFragmentFragmentDoc = gql`
    fragment DirectMessageUserThreadFragment on DirectMessageUserThread {
  id
  threadId
  lastMessageReceivedOn
  lastMessage
  unreadCount
  updatedAt
  createdBy {
    ...DirectMessageUserFragment
  }
  user {
    ...DirectMessageUserFragment
  }
  settings {
    ...DirectMessageUserThreadSettingsFragment
  }
}
    ${DirectMessageUserFragmentFragmentDoc}
${DirectMessageUserThreadSettingsFragmentFragmentDoc}`;
export const DirectMessageFragmentFragmentDoc = gql`
    fragment DirectMessageFragment on DirectMessage {
  id
  messageId
  createdOn
  editedOn
  version
  reactions {
    id
    type
    hasReacted
    numReactions
    emojiUnicode
    createdByUsers {
      ...UserPreview
    }
  }
  resources {
    id
    description
    domain
    favicon
    imageUrl
    logo
    logoTitle
    title
    url
    expanded
  }
  media {
    ... on Image {
      id
      aspectRatio
      publicId
      width
      height
    }
    ... on Giphy {
      id
      aspectRatio
      giphyId
      giphyUrl
      giphyMp4Url
      width
      height
    }
  }
}
    ${UserPreviewFragmentDoc}`;
export const EventCoreFragmentDoc = gql`
    fragment EventCore on Event {
  __typename
  id
  name
  description
  coverImageId
  emoji
  createdAt
  startAt
  endAt
  timeZone
  url
  groupType: type
  meetingUrl
  deleted
  numMembers
  numPosts
  postCreationPolicy
  series {
    id
    recurringCronExpression
    recurringOccurrencesLimit
  }
  location {
    id
    placeId
    type
    displayName
    lon
    lat
  }
  membership {
    id
    allowNewOccurrenceNotifications
    allowNewPostNotifications
    allowEventNotifications
  }
  members {
    ...UserPreview
  }
  createdBy {
    ...UserPreview
  }
}
    ${UserPreviewFragmentDoc}`;
export const CourseCoreFragmentDoc = gql`
    fragment CourseCore on Course {
  __typename
  id
  name
  description
  coverId
  deleted
  hidden
  emoji
  members {
    ...UserPreview
  }
  createdBy {
    ...UserPreview
  }
}
    ${UserPreviewFragmentDoc}`;
export const LessonCoreFragmentDoc = gql`
    fragment LessonCore on Post {
  ...PostCore
  sortIndex
  completions {
    user {
      ...UserPreview
    }
  }
}
    ${PostCoreFragmentDoc}
${UserPreviewFragmentDoc}`;
export const UpsellCoreFragmentDoc = gql`
    fragment UpsellCore on Upsell {
  __typename
  id
  type
  hidden
}
    `;
export const MenuItemCoreFragmentDoc = gql`
    fragment MenuItemCore on MenuItem {
  id
  name
  sortIndex
  group {
    ...GroupCore
    membership {
      id
      allowNewOccurrenceNotifications
      allowNewPostNotifications
    }
  }
  post {
    ...PostCore
  }
  resource {
    ...ResourceCore
  }
}
    ${GroupCoreFragmentDoc}
${PostCoreFragmentDoc}
${ResourceCoreFragmentDoc}`;
export const MenuSectionCoreFragmentDoc = gql`
    fragment MenuSectionCore on MenuSection {
  id
  type
  name
  sortIndex
  menuItems {
    ...MenuItemCore
  }
}
    ${MenuItemCoreFragmentDoc}`;
export const HelloPageMetaFragmentDoc = gql`
    fragment HelloPageMeta on HelloPage {
  id
  slug
  createdAt
  firstName
  lastName
  title
  recipientProfileImageId
  emojiUnicodeOrCustomImageId
  template
  createdBy {
    id
    firstName
  }
}
    `;
export const HelloPageBlockIntroductionCoreFragmentDoc = gql`
    fragment HelloPageBlockIntroductionCore on HelloPageBlockIntroduction {
  id
  type
  sortIndex
  title
  emojiUnicodeOrCustomImageId
  content
  createdAt
  __typename
}
    `;
export const HelloPageBlockNoteCoreFragmentDoc = gql`
    fragment HelloPageBlockNoteCore on HelloPageBlockNote {
  id
  type
  sortIndex
  type
  content
  createdAt
  __typename
}
    `;
export const HelloPageBlockResourceCoreFragmentDoc = gql`
    fragment HelloPageBlockResourceCore on HelloPageBlockResource {
  id
  type
  sortIndex
  url
  title
  description
  createdAt
  logo
  logoTitle
  __typename
}
    `;
export const HelloPageBlockVideoMetaFragmentDoc = gql`
    fragment HelloPageBlockVideoMeta on HelloPageBlockVideo {
  id
  type
  sortIndex
  user {
    id
    username
    firstName
    position
  }
  video {
    id
  }
  createdAt
}
    `;
export const HelloPageBlockVideoCoreFragmentDoc = gql`
    fragment HelloPageBlockVideoCore on HelloPageBlockVideo {
  ...HelloPageBlockVideoMeta
  user {
    id
    username
    firstName
  }
  video {
    id
    aspect
    mirror
    playbackId
  }
  __typename
}
    ${HelloPageBlockVideoMetaFragmentDoc}`;
export const HelloPageBlockImageCoreFragmentDoc = gql`
    fragment HelloPageBlockImageCore on HelloPageBlockImage {
  id
  type
  sortIndex
  imageId
  __typename
}
    `;
export const HelloPageBlockInternalCoreFragmentDoc = gql`
    fragment HelloPageBlockInternalCore on HelloPageBlockInternal {
  id
  type
  sortIndex
  createdAt
  internalType
  __typename
}
    `;
export const HelloPageBlockEmailCoreFragmentDoc = gql`
    fragment HelloPageBlockEmailCore on HelloPageBlockEmail {
  id
  type
  sortIndex
  createdAt
  email
  title
  description
  __typename
}
    `;
export const HelloPageCoreFragmentDoc = gql`
    fragment HelloPageCore on HelloPage {
  ...HelloPageMeta
  helloPageBlocks {
    __typename
    ... on HelloPageBlockIntroduction {
      ...HelloPageBlockIntroductionCore
    }
    ... on HelloPageBlockNote {
      ...HelloPageBlockNoteCore
    }
    ... on HelloPageBlockResource {
      ...HelloPageBlockResourceCore
    }
    ... on HelloPageBlockVideo {
      ...HelloPageBlockVideoCore
    }
    ... on HelloPageBlockImage {
      ...HelloPageBlockImageCore
    }
    ... on HelloPageBlockInternal {
      ...HelloPageBlockInternalCore
    }
    ... on HelloPageBlockEmail {
      ...HelloPageBlockEmailCore
    }
  }
}
    ${HelloPageMetaFragmentDoc}
${HelloPageBlockIntroductionCoreFragmentDoc}
${HelloPageBlockNoteCoreFragmentDoc}
${HelloPageBlockResourceCoreFragmentDoc}
${HelloPageBlockVideoCoreFragmentDoc}
${HelloPageBlockImageCoreFragmentDoc}
${HelloPageBlockInternalCoreFragmentDoc}
${HelloPageBlockEmailCoreFragmentDoc}`;
export const NotificationFragmentFragmentDoc = gql`
    fragment NotificationFragment on Notification {
  id
  type
  emojiUnicode
  createdAt
  content
  link
  imageUrl
  eventName
  quoteContent
  read
  user {
    id
    profileImageId
    avatarBgColor
    avatar
    username
  }
}
    `;
export const OrganizationsDocument = gql`
    query Organizations($first: Int!, $after: String, $query: String) {
  organizations(first: $first, query: $query, after: $after) {
    edges {
      node {
        ...OrganizationAdminFields
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
  }
}
    ${OrganizationAdminFieldsFragmentDoc}`;
export const AddOrganizationDocument = gql`
    mutation AddOrganization($input: AddOrganizationInput!) {
  addOrganization(input: $input) {
    ...OrganizationAdminFields
  }
}
    ${OrganizationAdminFieldsFragmentDoc}`;
export const SendDigestToUserDocument = gql`
    mutation SendDigestToUser($userEmail: String!) {
  sendDigestToUser(userEmail: $userEmail) {
    id
  }
}
    `;
export const SendNotificationsDigestToUserDocument = gql`
    mutation SendNotificationsDigestToUser($userEmail: String!) {
  sendNotificationsDigestToUser(userEmail: $userEmail) {
    id
  }
}
    `;
export const DeleteOrganizationDocument = gql`
    mutation DeleteOrganization($organizationId: ID!) {
  deleteOrganization(organizationId: $organizationId) {
    id
  }
}
    `;
export const DuplicateOrganizationDocument = gql`
    mutation DuplicateOrganization($input: DuplicateOrganizationInput!) {
  duplicateOrganization(input: $input) {
    ...OrganizationCore
  }
}
    ${OrganizationCoreFragmentDoc}`;
export const DeleteUserDocument = gql`
    mutation DeleteUser($userEmail: String!) {
  deleteUser(userEmail: $userEmail) {
    id
  }
}
    `;
export const GetDemoOrganizationDocument = gql`
    mutation GetDemoOrganization {
  getDemoOrganization {
    ...OrganizationCore
  }
}
    ${OrganizationCoreFragmentDoc}`;
export const ViewerDocument = gql`
    query Viewer {
  viewer {
    id
    createdAt
    username
    email
    name
    profileImageId
    coverImageId
    firstName
    lastName
    bio
    claimedAccount
    position
    avatar
    avatarBgColor
    timeZone
    userInvites {
      id
      inviteCode
      organization {
        ...OrganizationCore
      }
    }
    memberSubscriptions {
      id
      stripeCancelAtPeriodEnd
      expiresAt
      subscriptionPlan {
        id
      }
    }
    organizationMemberships {
      id
      active
      createdAt
      isCreator
      hideFrondIntroPost
      completedOnboardingTasks
      roles {
        id
        label
        color
        isAdmin
        memberSubscriptionPlan {
          id
        }
      }
      organization {
        ...OrganizationCore
      }
    }
    preferences {
      id
      showQuestionsOnboarding
      questionsOnboardingCompletedAt
      isProfileSetup
      allowCookies
      allowEmailNotifications
      digestNotificationFrequency
    }
  }
}
    ${OrganizationCoreFragmentDoc}`;
export const UserDocument = gql`
    query User($username: String!, $organizationId: String!) {
  user(username: $username, organizationId: $organizationId) {
    ...UserProfile
    organizationMembership(organizationId: $organizationId) {
      id
      createdAt
      roles {
        id
        label
        color
        isAdmin
      }
    }
  }
}
    ${UserProfileFragmentDoc}`;
export const UserGroupMembershipsDocument = gql`
    query UserGroupMemberships($username: String!, $organizationId: String!) {
  user(username: $username, organizationId: $organizationId) {
    id
    username
    groupMemberships(organizationId: $organizationId) {
      id
      group {
        id
        name
        emoji
      }
    }
  }
}
    `;
export const AllUsersDocument = gql`
    query AllUsers($first: Int!, $after: String, $query: String) {
  allUsers(first: $first, query: $query, after: $after) {
    edges {
      node {
        ...UserProfile
        email
        organizationMemberships {
          id
          roles {
            id
            label
            color
            isAdmin
          }
          organization {
            ...OrganizationCore
          }
        }
      }
      cursor
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${UserProfileFragmentDoc}
${OrganizationCoreFragmentDoc}`;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    ...UserProfile
  }
}
    ${UserProfileFragmentDoc}`;
export const UsersDocument = gql`
    query Users($organizationId: String!, $groupId: String, $first: Int!, $after: String, $query: String) {
  users(
    organizationId: $organizationId
    groupId: $groupId
    first: $first
    after: $after
    query: $query
  ) {
    edges {
      cursor
      node {
        ... on User {
          id
          username
          name
          firstName
          profileImageId
          avatarBgColor
          avatar
          createdAt
          organizationMembership(organizationId: $organizationId) {
            id
            createdAt
            roles {
              id
              label
              color
              isAdmin
              memberSubscriptionPlan {
                id
                name
              }
            }
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
    totalCount
  }
}
    `;
export const CreateOrDeleteReactionDocument = gql`
    mutation CreateOrDeleteReaction($input: CreateOrDeleteReactionInput!) {
  createOrDeleteReaction(input: $input) {
    __typename
    id
    type
    hasReacted
    numReactions
    emojiUnicode
    createdByUsers {
      id
      name
      firstName
      lastName
      username
      profileImageId
      avatar
      avatarBgColor
    }
  }
}
    `;
export const CommentDocument = gql`
    query Comment($id: ID!) {
  comment(id: $id) {
    ...CommentCore
  }
}
    ${CommentCoreFragmentDoc}`;
export const CreateCommentDocument = gql`
    mutation CreateComment($input: CreateCommentInput!) {
  createComment(input: $input) {
    ...CommentCore
  }
}
    ${CommentCoreFragmentDoc}`;
export const UpdateCommentDocument = gql`
    mutation UpdateComment($input: UpdateCommentInput!) {
  updateComment(input: $input) {
    ...CommentCore
  }
}
    ${CommentCoreFragmentDoc}`;
export const ToggleCommentAsBestAnswerDocument = gql`
    mutation ToggleCommentAsBestAnswer($input: ToggleCommentAsBestAnswerInput!) {
  toggleCommentAsBestAnswer(input: $input) {
    ...CommentCore
  }
}
    ${CommentCoreFragmentDoc}`;
export const DeleteCommentDocument = gql`
    mutation DeleteComment($id: ID!) {
  deleteComment(id: $id) {
    id
  }
}
    `;
export const SendFeedbackEmailDocument = gql`
    mutation SendFeedbackEmail($input: SendFeedbackEmailInput!) {
  sendFeedbackEmail(input: $input) {
    id
  }
}
    `;
export const CommentConfigDocument = gql`
    query CommentConfig {
  commentConfig {
    maxCommentLength
  }
}
    `;
export const OrganizationDocument = gql`
    query Organization($id: ID!) {
  organization(id: $id) {
    ...OrganizationExtended
  }
}
    ${OrganizationExtendedFragmentDoc}`;
export const OrganizationByDomainDocument = gql`
    query OrganizationByDomain($domain: String!) {
  organizationByDomain(domain: $domain) {
    id
    shortId
  }
}
    `;
export const OrganizationByShortIdDocument = gql`
    query OrganizationByShortId($shortId: ID!) {
  organizationByShortId(shortId: $shortId) {
    ...OrganizationExtended
  }
}
    ${OrganizationExtendedFragmentDoc}`;
export const OrganizationActiveMembersDocument = gql`
    query OrganizationActiveMembers($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    activeMembers {
      id
      username
      firstName
      lastName
      profileImageId
      avatar
      avatarBgColor
    }
  }
}
    `;
export const CustomDomainVerificationInfoDocument = gql`
    query CustomDomainVerificationInfo($organizationId: String!) {
  customDomainVerificationInfo(organizationId: $organizationId) {
    apexName
    name
    verified
    verification {
      type
      domain
      value
      reason
    }
  }
}
    `;
export const UserActivityCountsDocument = gql`
    query UserActivityCounts($username: String!, $organizationId: String!) {
  userActivityCounts(username: $username, organizationId: $organizationId) {
    numGroups
    numPosts
  }
}
    `;
export const OrganizationInviteDocument = gql`
    query OrganizationInvite($organizationId: String!) {
  organizationInvite(organizationId: $organizationId) {
    inviteCode
    expiresAt
  }
}
    `;
export const UpdateUserDigestNotificationFrequencyDocument = gql`
    mutation UpdateUserDigestNotificationFrequency($input: UpdateUserDigestNotificationFrequencyInput!) {
  updateUserDigestNotificationFrequency(input: $input) {
    id
    digestNotificationFrequency
  }
}
    `;
export const UnfurlUrlDocument = gql`
    mutation UnfurlUrl($input: UnfurlUrlInput!) {
  unfurlUrl(input: $input) {
    ...MyResource
    ... on ResourceError {
      message
    }
  }
}
    ${MyResourceFragmentDoc}`;
export const CreateImageDocument = gql`
    mutation CreateImage($input: CreateImageInput!) {
  createImage(input: $input) {
    id
    aspectRatio
    publicId
    height
    width
  }
}
    `;
export const CreateTemporaryImageDocument = gql`
    mutation CreateTemporaryImage($input: CreateTemporaryImageInput!) {
  createTemporaryImage(input: $input) {
    id
    aspectRatio
    publicId
    height
    width
  }
}
    `;
export const CreateGiphyDocument = gql`
    mutation CreateGiphy($input: CreateGiphyInput!) {
  createGiphy(input: $input) {
    id
    aspectRatio
    height
    width
    giphyMp4Url
    giphyUrl
    giphyId
  }
}
    `;
export const PublicOrganizationDocument = gql`
    query PublicOrganization($id: ID!) {
  organization(id: $id) {
    ...OrganizationPublicFields
  }
}
    ${OrganizationPublicFieldsFragmentDoc}`;
export const PublicOrganizationShortIdsDocument = gql`
    query PublicOrganizationShortIds {
  publicOrganizationShortIds
}
    `;
export const VisitedPublicOrganizationsDocument = gql`
    query VisitedPublicOrganizations($userId: ID!) {
  visitedPublicOrganizations(userId: $userId) {
    ...OrganizationPublicFields
  }
}
    ${OrganizationPublicFieldsFragmentDoc}`;
export const ReorderOrganizationMembershipDocument = gql`
    mutation ReorderOrganizationMembership($input: ReorderOrganizationMembershipInput!) {
  reorderOrganizationMembership(input: $input) {
    id
  }
}
    `;
export const AddOrganizationVisitDocument = gql`
    mutation AddOrganizationVisit($input: AddOrganizationVisitInput!) {
  addOrganizationVisit(input: $input) {
    ...OrganizationPublicFields
  }
}
    ${OrganizationPublicFieldsFragmentDoc}`;
export const HideOrganizationVisitDocument = gql`
    mutation HideOrganizationVisit($input: HideOrganizationVisitInput!) {
  hideOrganizationVisit(input: $input) {
    ...OrganizationPublicFields
  }
}
    ${OrganizationPublicFieldsFragmentDoc}`;
export const GroupsDocument = gql`
    query Groups($organizationId: String!, $first: Int!, $after: String) {
  groups(organizationId: $organizationId, first: $first, after: $after) {
    edges {
      node {
        ...GroupWithMembers
      }
      cursor
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${GroupWithMembersFragmentDoc}`;
export const SidebarGroupsDocument = gql`
    query SidebarGroups($organizationId: String!, $first: Int!, $after: String) {
  groups(organizationId: $organizationId, first: $first, after: $after) {
    ...PaginatedSidebarGroupsConnection
  }
}
    ${PaginatedSidebarGroupsConnectionFragmentDoc}`;
export const GroupDocument = gql`
    query Group($id: ID!) {
  group(id: $id) {
    __typename
    ... on Group {
      ...GroupWithMembers
    }
    ... on GroupError {
      reason
      message
    }
  }
}
    ${GroupWithMembersFragmentDoc}`;
export const GroupUnseenPostsDocument = gql`
    query GroupUnseenPosts($id: ID!) {
  group(id: $id) {
    __typename
    ... on Group {
      id
      hasUnseenPosts
    }
    ... on GroupError {
      message
    }
  }
}
    `;
export const CreateAndJoinGroupDocument = gql`
    mutation CreateAndJoinGroup($input: CreateGroupInput!) {
  createAndJoinGroup(input: $input) {
    ...GroupCore
  }
}
    ${GroupCoreFragmentDoc}`;
export const UpdateGroupDocument = gql`
    mutation UpdateGroup($input: UpdateGroupInput!) {
  updateGroup(input: $input) {
    ...GroupCore
  }
}
    ${GroupCoreFragmentDoc}`;
export const DeleteGroupDocument = gql`
    mutation DeleteGroup($input: DeleteGroupInput!) {
  deleteGroup(input: $input) {
    ...GroupCore
  }
}
    ${GroupCoreFragmentDoc}`;
export const AddGroupMembersDocument = gql`
    mutation AddGroupMembers($input: AddGroupMembersInput!) {
  addGroupMembers(input: $input) {
    ...GroupCore
  }
}
    ${GroupCoreFragmentDoc}`;
export const JoinGroupDocument = gql`
    mutation JoinGroup($input: JoinGroupInput!) {
  joinGroup(input: $input) {
    ...GroupCore
  }
}
    ${GroupCoreFragmentDoc}`;
export const RemoveGroupMemberDocument = gql`
    mutation RemoveGroupMember($input: RemoveGroupMemberInput!) {
  removeGroupMember(input: $input) {
    ...GroupCore
  }
}
    ${GroupCoreFragmentDoc}`;
export const AddGroupViewDocument = gql`
    mutation AddGroupView($input: AddViewInput!) {
  addGroupView(input: $input) {
    id
  }
}
    `;
export const ReorderGroupsDocument = gql`
    mutation ReorderGroups($input: ReorderGroupsInput!) {
  reorderGroups(input: $input) {
    ...PaginatedSidebarGroupsConnection
  }
}
    ${PaginatedSidebarGroupsConnectionFragmentDoc}`;
export const HomeFeedDocument = gql`
    query HomeFeed($first: Int!, $organizationId: String!, $after: String, $username: String, $tag: String) {
  homeFeed(
    first: $first
    organizationId: $organizationId
    after: $after
    username: $username
    tag: $tag
  ) {
    edges {
      node {
        ... on Post {
          __typename
          ...PostFeed
        }
        ... on OrganizationMembership {
          __typename
          id
          createdAt
          isCreator
        }
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
    totalCount
  }
}
    ${PostFeedFragmentDoc}`;
export const HomeFeedLatestContentDocument = gql`
    query HomeFeedLatestContent($organizationId: String!) {
  homeFeedLatestItem(organizationId: $organizationId) {
    __typename
    ... on Post {
      id
      createdAt
    }
    ... on OrganizationMembership {
      id
      createdAt
    }
  }
}
    `;
export const UpsertPostDocument = gql`
    mutation UpsertPost($input: UpsertPostInput!) {
  upsertPost(input: $input) {
    __typename
    ... on Post {
      id
    }
    ... on PostError {
      message
    }
  }
}
    `;
export const PostDocument = gql`
    query Post($id: ID!) {
  post(id: $id) {
    __typename
    ... on Post {
      ...PostFeed
    }
    ... on PostError {
      message
    }
  }
}
    ${PostFeedFragmentDoc}`;
export const DeletePostDocument = gql`
    mutation DeletePost($input: DeletePostInput!) {
  deletePost(input: $input) {
    __typename
    ... on Post {
      id
    }
    ... on PostError {
      message
    }
  }
}
    `;
export const CreateTemporaryPostDocument = gql`
    mutation CreateTemporaryPost($input: CreateTemporaryPostInput!) {
  createTemporaryPost(input: $input) {
    id
    token
    userId
  }
}
    `;
export const TemporaryPostPollDocument = gql`
    query TemporaryPostPoll($token: String!) {
  temporaryPost(token: $token) {
    id
    token
    media {
      ... on Video {
        id
        aspect
        playbackId
        previewPlaybackId
        previewStatus
        mirror
        status
        type
        assetId
        createdAt
      }
      ... on Image {
        id
        aspectRatio
        publicId
        width
        height
      }
      ... on Giphy {
        id
        aspectRatio
        giphyId
        giphyUrl
        giphyMp4Url
        width
        height
      }
    }
  }
}
    `;
export const TemporaryPostDocument = gql`
    query TemporaryPost($token: String!) {
  temporaryPost(token: $token) {
    id
    token
    userId
    group {
      ...GroupCore
      members {
        ... on User {
          ...UserPreview
        }
      }
    }
  }
}
    ${GroupCoreFragmentDoc}
${UserPreviewFragmentDoc}`;
export const UpdateTemporaryPostDocument = gql`
    mutation UpdateTemporaryPost($input: UpdateTemporaryPostInput!) {
  updateTemporaryPost(input: $input) {
    id
    token
    media {
      ... on Video {
        id
      }
      ... on Image {
        id
      }
    }
    userId
  }
}
    `;
export const UpdateMembershipDocument = gql`
    mutation UpdateMembership($input: UpdateMembershipInput!) {
  updateMembership(input: $input) {
    id
    allowNewOccurrenceNotifications
    allowNewPostNotifications
  }
}
    `;
export const GroupsSidebarStateDocument = gql`
    query GroupsSidebarState {
  groupsSidebarState @client {
    privateGroupsExpanded @client
    publicGroupsExpanded @client
    courseGroupsExpanded @client
  }
}
    `;
export const GroupsMenuSectionsStateDocument = gql`
    query GroupsMenuSectionsState {
  groupsMenuSectionsState @client {
    menuSectionIdsCollapsed @client
  }
}
    `;
export const ToggleGroupAutoAddMembersDocument = gql`
    mutation ToggleGroupAutoAddMembers($input: ToggleGroupAutoAddMembersInput!) {
  toggleGroupAutoAddMembers(input: $input) {
    id
    autoAddMembers
  }
}
    `;
export const ToggleGroupEnableNewPostNotificationsForNewMembersDocument = gql`
    mutation ToggleGroupEnableNewPostNotificationsForNewMembers($input: ToggleGroupEnableNewPostNotificationsForNewMembersInput!) {
  toggleGroupEnableNewPostNotificationsForNewMembers(input: $input) {
    id
    enableNewPostNotificationsForNewMembers
  }
}
    `;
export const ToggleGroupIncludePostsOnHomeDocument = gql`
    mutation ToggleGroupIncludePostsOnHome($input: ToggleGroupIncludePostsOnHomeInput!) {
  toggleGroupIncludePostsOnHome(input: $input) {
    id
    includePostsOnHome
  }
}
    `;
export const RequestToJoinOrganizationDocument = gql`
    mutation RequestToJoinOrganization($input: RequestToJoinOrganizationInput!) {
  requestToJoinOrganization(input: $input) {
    __typename
    ... on RequestToJoin {
      id
    }
    ... on RequestToJoinError {
      message
    }
  }
}
    `;
export const UserInvitesDocument = gql`
    query UserInvites($organizationId: String!, $offset: Int, $limit: Int, $query: String, $orderBy: InvitesOrderByInput) {
  userInvites(
    organizationId: $organizationId
    offset: $offset
    limit: $limit
    query: $query
    orderBy: $orderBy
  ) {
    items {
      id
      email
      inviteCode
      createdAt
    }
    totalCount
  }
}
    `;
export const UserRequestToJoinOrganizationDocument = gql`
    query UserRequestToJoinOrganization($organizationId: String!) {
  userRequestToJoinOrganization(organizationId: $organizationId) {
    id
  }
}
    `;
export const UserRequestsToJoinOrganizationDocument = gql`
    query UserRequestsToJoinOrganization($organizationId: String!, $offset: Int, $limit: Int, $query: String, $orderBy: RequestsOrderByInput) {
  userRequestsToJoinOrganization(
    organizationId: $organizationId
    offset: $offset
    limit: $limit
    query: $query
    orderBy: $orderBy
  ) {
    items {
      id
      createdAt
      email
      status
      user {
        id
        username
        name
        avatarBgColor
        avatar
        profileImageId
      }
    }
    totalCount
  }
}
    `;
export const AcceptRequestToJoinDocument = gql`
    mutation AcceptRequestToJoin($input: AcceptRequestToJoinInput!) {
  acceptRequestToJoin(input: $input) {
    __typename
    ... on RequestToJoin {
      id
    }
    ... on RequestToJoinError {
      message
    }
  }
}
    `;
export const AcceptAllRequestsToJoinDocument = gql`
    mutation AcceptAllRequestsToJoin($input: AcceptAllRequestsToJoinInput!) {
  acceptAllRequestsToJoin(input: $input) {
    id
  }
}
    `;
export const RejectRequestToJoinDocument = gql`
    mutation RejectRequestToJoin($input: RejectRequestToJoinInput!) {
  rejectRequestToJoin(input: $input) {
    __typename
    ... on RequestToJoin {
      id
    }
    ... on RequestToJoinError {
      message
    }
  }
}
    `;
export const OrganizationPushEventsDocument = gql`
    subscription OrganizationPushEvents($organizationId: ID!) {
  organizationPushEvent(id: $organizationId) {
    ... on PushRefetchHint {
      id
      postId
      groupId
    }
  }
}
    `;
export const UpdateImageCoordinatesDocument = gql`
    mutation UpdateImageCoordinates($input: UpdateImageCoordinatesInput!) {
  updateImageCoordinates(input: $input) {
    id
    coordinates {
      x
      y
      width
      height
    }
  }
}
    `;
export const CreateVoteDocument = gql`
    mutation CreateVote($input: CreateVoteInput!) {
  createVote(input: $input) {
    __typename
    ... on Poll {
      ...PollCore
    }
    ... on PollError {
      message
    }
  }
}
    ${PollCoreFragmentDoc}`;
export const DeleteVoteDocument = gql`
    mutation DeleteVote($input: DeleteVoteInput!) {
  deleteVote(input: $input) {
    __typename
    ... on Poll {
      ...PollCore
    }
    ... on PollError {
      message
    }
  }
}
    ${PollCoreFragmentDoc}`;
export const SearchDocument = gql`
    query Search($organizationId: String!, $query: String!) {
  search(organizationId: $organizationId, query: $query) {
    ...SearchResult
  }
}
    ${SearchResultFragmentDoc}`;
export const PostsByGroupDocument = gql`
    query PostsByGroup($groupId: ID!, $first: Int!, $after: String) {
  postsByGroup(groupId: $groupId, first: $first, after: $after) {
    edges {
      node {
        ...PostFeed
      }
      cursor
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${PostFeedFragmentDoc}`;
export const DraftPostsDocument = gql`
    query DraftPosts($first: Int!, $after: String, $organizationId: ID!, $isScheduled: Boolean) {
  draftPosts(
    organizationId: $organizationId
    first: $first
    after: $after
    isScheduled: $isScheduled
  ) {
    edges {
      node {
        ...PostFeed
      }
      cursor
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${PostFeedFragmentDoc}`;
export const CreateOrganizationRoleDocument = gql`
    mutation CreateOrganizationRole($input: CreateOrganizationRoleInput!) {
  createOrganizationRole(input: $input) {
    __typename
    ...OrganizationRoleCore
  }
}
    ${OrganizationRoleCoreFragmentDoc}`;
export const UpdateOrganizationRoleDocument = gql`
    mutation UpdateOrganizationRole($input: UpdateOrganizationRoleInput!) {
  updateOrganizationRole(input: $input) {
    __typename
    ...OrganizationRoleCore
  }
}
    ${OrganizationRoleCoreFragmentDoc}`;
export const DeleteOrganizationRoleDocument = gql`
    mutation DeleteOrganizationRole($input: DeleteOrganizationRoleInput!) {
  deleteOrganizationRole(input: $input) {
    __typename
    ...OrganizationRoleCore
  }
}
    ${OrganizationRoleCoreFragmentDoc}`;
export const OrganizationRolesDocument = gql`
    query OrganizationRoles($organizationId: String!) {
  organizationRoles(organizationId: $organizationId) {
    __typename
    ...OrganizationRoleCore
    users {
      ...UserPreview
    }
  }
}
    ${OrganizationRoleCoreFragmentDoc}
${UserPreviewFragmentDoc}`;
export const OrganizationRolesByUserIdDocument = gql`
    query OrganizationRolesByUserId($userId: String!, $organizationId: String!) {
  organizationRolesByUserId(userId: $userId, organizationId: $organizationId) {
    __typename
    ...OrganizationRoleCore
  }
}
    ${OrganizationRoleCoreFragmentDoc}`;
export const DirectMessageTokenDocument = gql`
    query DirectMessageToken($organizationId: String!) {
  directMessageToken(organizationId: $organizationId) {
    __typename
    ...DirectMessageTokenFragment
  }
}
    ${DirectMessageTokenFragmentFragmentDoc}`;
export const DirectMessageUserThreadDocument = gql`
    query DirectMessageUserThread($username: String!, $organizationId: String!) {
  directMessageUserThread(username: $username, organizationId: $organizationId) {
    __typename
    ...DirectMessageUserThreadFragment
  }
}
    ${DirectMessageUserThreadFragmentFragmentDoc}`;
export const WelcomeGroupDocument = gql`
    query WelcomeGroup($organizationId: String!) {
  welcomeGroup(organizationId: $organizationId) {
    id
    name
    emoji
    groupType: type
    hidden
  }
}
    `;
export const ToggleWelcomeGroupDocument = gql`
    mutation ToggleWelcomeGroup($input: ToggleWelcomeGroupInput!) {
  toggleWelcomeGroup(input: $input) {
    id
    hidden
  }
}
    `;
export const DirectMessageThreadsDocument = gql`
    query DirectMessageThreads($organizationId: String!) {
  directMessageThreads(organizationId: $organizationId) {
    __typename
    ...DirectMessageUserThreadFragment
  }
}
    ${DirectMessageUserThreadFragmentFragmentDoc}`;
export const CreateDirectMessageDocument = gql`
    mutation CreateDirectMessage($input: CreateDirectMessageInput!) {
  createDirectMessage(input: $input) {
    id
  }
}
    `;
export const DeleteDirectMessageThreadDocument = gql`
    mutation DeleteDirectMessageThread($input: DeleteDirectMessageThreadInput!) {
  deleteDirectMessageThread(input: $input) {
    id
  }
}
    `;
export const DirectMessageDocument = gql`
    query DirectMessage($id: String!, $threadId: String!) {
  directMessage(id: $id, threadId: $threadId) {
    __typename
    ...DirectMessageFragment
  }
}
    ${DirectMessageFragmentFragmentDoc}`;
export const CreateOrDeleteDirectMessageReactionDocument = gql`
    mutation CreateOrDeleteDirectMessageReaction($input: CreateOrDeleteDirectMessageReactionInput!) {
  createOrDeleteDirectMessageReaction(input: $input) {
    __typename
    ...DirectMessageFragment
  }
}
    ${DirectMessageFragmentFragmentDoc}`;
export const DirectMessageNotificationCountsDocument = gql`
    query DirectMessageNotificationCounts($organizationId: String!) {
  directMessageNotificationCounts(organizationId: $organizationId) {
    __typename
    unread
  }
}
    `;
export const UpdateDirectMessageThreadSettingsDocument = gql`
    mutation UpdateDirectMessageThreadSettings($input: UpdateDirectMessageUserThreadSettingsInput!) {
  updateDirectMessageThreadSettings(input: $input) {
    __typename
    ...DirectMessageUserThreadSettingsFragment
  }
}
    ${DirectMessageUserThreadSettingsFragmentFragmentDoc}`;
export const MarkDirectMessageReadDocument = gql`
    mutation MarkDirectMessageRead($input: MarkDirectMessageReadInput!) {
  markDirectMessageRead(input: $input) {
    id
  }
}
    `;
export const EventsDocument = gql`
    query Events($organizationId: String!, $first: Int!, $after: String, $past: Boolean) {
  events(
    organizationId: $organizationId
    first: $first
    after: $after
    past: $past
  ) {
    edges {
      node {
        ...EventCore
      }
      cursor
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${EventCoreFragmentDoc}`;
export const PostsByEventDocument = gql`
    query PostsByEvent($eventId: ID!, $first: Int!, $after: String) {
  postsByEvent(eventId: $eventId, first: $first, after: $after) {
    edges {
      node {
        ...PostFeed
      }
      cursor
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${PostFeedFragmentDoc}`;
export const EventDocument = gql`
    query Event($id: String!) {
  event(id: $id) {
    __typename
    ... on Event {
      ...EventCore
    }
    ... on EventError {
      message
    }
  }
}
    ${EventCoreFragmentDoc}`;
export const CreateEventDocument = gql`
    mutation CreateEvent($input: CreateEventInput!) {
  createEvent(input: $input) {
    ...EventCore
  }
}
    ${EventCoreFragmentDoc}`;
export const UpdateEventDocument = gql`
    mutation UpdateEvent($input: UpdateEventInput!) {
  updateEvent(input: $input) {
    ...EventCore
  }
}
    ${EventCoreFragmentDoc}`;
export const DeleteEventDocument = gql`
    mutation DeleteEvent($input: DeleteEventInput!) {
  deleteEvent(input: $input) {
    ... on Event {
      id
    }
    ... on EventError {
      message
    }
  }
}
    `;
export const LessonsDocument = gql`
    query Lessons($courseId: String!, $first: Int!, $after: String) {
  lessons(courseId: $courseId, first: $first, after: $after) {
    edges {
      node {
        ...LessonCore
      }
      cursor
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${LessonCoreFragmentDoc}`;
export const CreateCourseDocument = gql`
    mutation CreateCourse($input: CreateCourseInput!) {
  createCourse(input: $input) {
    ...CourseCore
  }
}
    ${CourseCoreFragmentDoc}`;
export const ReorderLessonDocument = gql`
    mutation ReorderLesson($input: ReorderLessonInput!) {
  reorderLesson(input: $input) {
    ...LessonCore
  }
}
    ${LessonCoreFragmentDoc}`;
export const ToggleLessonCompletedDocument = gql`
    mutation ToggleLessonCompleted($input: ToggleLessonCompletedInput!) {
  toggleLessonCompleted(input: $input) {
    ...LessonCore
  }
}
    ${LessonCoreFragmentDoc}`;
export const UpsellsDocument = gql`
    query Upsells($organizationId: ID!) {
  upsells(organizationId: $organizationId) {
    ...UpsellCore
  }
}
    ${UpsellCoreFragmentDoc}`;
export const HideUpsellDocument = gql`
    mutation HideUpsell($input: HideUpsellInput!) {
  hideUpsell(input: $input) {
    ...UpsellCore
  }
}
    ${UpsellCoreFragmentDoc}`;
export const PublishCourseDocument = gql`
    mutation PublishCourse($input: PublishCourseInput!) {
  publishCourse(input: $input) {
    id
    isDraft
  }
}
    `;
export const LocationByNameDocument = gql`
    query LocationByName($name: String!) {
  locationByName(name: $name) {
    placeId
    type
    displayName
    lat
    lon
  }
}
    `;
export const LocationByPlaceIdDocument = gql`
    query LocationByPlaceId($placeId: String!) {
  locationByPlaceId(placeId: $placeId) {
    placeId
    type
    displayName
    lat
    lon
  }
}
    `;
export const ToggleEventPostCreationPolicyDocument = gql`
    mutation ToggleEventPostCreationPolicy($input: ToggleEventPostCreationPolicyInput!) {
  toggleEventPostCreationPolicy(input: $input) {
    ...EventCore
  }
}
    ${EventCoreFragmentDoc}`;
export const ToggleEventNotificationsDocument = gql`
    mutation ToggleEventNotifications($input: ToggleEventNotificationsInput!) {
  toggleEventNotifications(input: $input) {
    ...EventCore
  }
}
    ${EventCoreFragmentDoc}`;
export const CourseCompletionDocument = gql`
    query CourseCompletion($courseId: ID!) {
  courseCompletion(courseId: $courseId) {
    id
    percentage
  }
}
    `;
export const ToggleOrganizationMembershipOnboardingTaskDocument = gql`
    mutation ToggleOrganizationMembershipOnboardingTask($input: ToggleOrganizationMembershipOnboardingTaskInput!) {
  toggleOrganizationMembershipOnboardingTask(input: $input) {
    id
    completedOnboardingTasks
  }
}
    `;
export const CreateOrDeletePostBookmarkDocument = gql`
    mutation CreateOrDeletePostBookmark($input: CreateOrDeletePostBookmarkInput!) {
  createOrDeletePostBookmark(input: $input) {
    post {
      ...PostFeed
    }
  }
}
    ${PostFeedFragmentDoc}`;
export const PostBookmarksDocument = gql`
    query PostBookmarks($organizationId: ID!, $first: Int!, $after: String) {
  postBookmarks(organizationId: $organizationId, first: $first, after: $after) {
    edges {
      node {
        post {
          ...PostFeed
        }
      }
      cursor
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${PostFeedFragmentDoc}`;
export const MenuSectionsDocument = gql`
    query MenuSections($organizationId: ID!) {
  menuSections(organizationId: $organizationId) {
    ...MenuSectionCore
  }
}
    ${MenuSectionCoreFragmentDoc}`;
export const CreateMenuSectionDocument = gql`
    mutation CreateMenuSection($input: CreateMenuSectionInput!) {
  createMenuSection(input: $input) {
    ...MenuSectionCore
  }
}
    ${MenuSectionCoreFragmentDoc}`;
export const UpdateMenuSectionDocument = gql`
    mutation UpdateMenuSection($input: UpdateMenuSectionInput!) {
  updateMenuSection(input: $input) {
    ...MenuSectionCore
  }
}
    ${MenuSectionCoreFragmentDoc}`;
export const DeleteMenuSectionDocument = gql`
    mutation DeleteMenuSection($input: DeleteMenuSectionInput!) {
  deleteMenuSection(input: $input) {
    ...MenuSectionCore
  }
}
    ${MenuSectionCoreFragmentDoc}`;
export const ReorderMenuSectionsDocument = gql`
    mutation ReorderMenuSections($input: ReorderMenuSectionsInput!) {
  reorderMenuSections(input: $input) {
    ...MenuSectionCore
  }
}
    ${MenuSectionCoreFragmentDoc}`;
export const CreateMenuItemUrlDocument = gql`
    mutation CreateMenuItemUrl($input: CreateMenuItemUrlInput!) {
  createMenuItemUrl(input: $input) {
    ...MenuItemCore
  }
}
    ${MenuItemCoreFragmentDoc}`;
export const CreateMenuItemWithPostDocument = gql`
    mutation CreateMenuItemWithPost($input: CreateMenuItemWithPostInput!) {
  createMenuItemWithPost(input: $input) {
    ...MenuItemCore
  }
}
    ${MenuItemCoreFragmentDoc}`;
export const UpdateMenuItemDocument = gql`
    mutation UpdateMenuItem($input: UpdateMenuItemInput!) {
  updateMenuItem(input: $input) {
    ...MenuItemCore
  }
}
    ${MenuItemCoreFragmentDoc}`;
export const DeleteMenuItemDocument = gql`
    mutation DeleteMenuItem($input: DeleteMenuItemInput!) {
  deleteMenuItem(input: $input) {
    ...MenuItemCore
  }
}
    ${MenuItemCoreFragmentDoc}`;
export const ReorderMenuItemsDocument = gql`
    mutation ReorderMenuItems($input: ReorderMenuItemsInput!) {
  reorderMenuItems(input: $input) {
    ...MenuItemCore
  }
}
    ${MenuItemCoreFragmentDoc}`;
export const HelloPageByShortIdDocument = gql`
    query HelloPageByShortId($shortId: String!) {
  helloPageByShortId(shortId: $shortId) {
    ...HelloPageCore
  }
}
    ${HelloPageCoreFragmentDoc}`;
export const InboxStateDocument = gql`
    query InboxState {
  inboxState @client {
    showRead @client
  }
}
    `;
export const OrganizationStatsDocument = gql`
    query OrganizationStats($organizationId: ID!) {
  organizationStats(organizationId: $organizationId) {
    id
    totalMembers
    totalMembersInTheLast14Days
    totalPosts
    totalPostsInTheLast14Days
    totalReactions
    totalReactionsInTheLast14Days
    totalComments
    totalCommentsInTheLast14Days
  }
}
    `;
export const MembersWithHighestPostCountDocument = gql`
    query MembersWithHighestPostCount($organizationId: ID!) {
  membersWithHighestPostCount(organizationId: $organizationId) {
    id
    count
    user {
      ...UserPreview
    }
  }
}
    ${UserPreviewFragmentDoc}`;
export const MembersWithHighestCommentCountDocument = gql`
    query MembersWithHighestCommentCount($organizationId: ID!) {
  membersWithHighestCommentCount(organizationId: $organizationId) {
    id
    count
    user {
      ...UserPreview
    }
  }
}
    ${UserPreviewFragmentDoc}`;
export const MembersWithHighestReactionCountDocument = gql`
    query MembersWithHighestReactionCount($organizationId: ID!) {
  membersWithHighestReactionCount(organizationId: $organizationId) {
    id
    count
    user {
      ...UserPreview
    }
  }
}
    ${UserPreviewFragmentDoc}`;
export const MostRecentOrganizationActivityDocument = gql`
    query MostRecentOrganizationActivity($organizationId: ID!) {
  mostRecentOrganizationActivity(organizationId: $organizationId) {
    id
    entityId
    type
    activityDate
    user {
      ...UserPreview
    }
  }
}
    ${UserPreviewFragmentDoc}`;
export const NewMembershipsDocument = gql`
    query NewMemberships($organizationId: ID!) {
  newMemberships(organizationId: $organizationId) {
    id
    createdAt
    user {
      ...UserPreview
    }
  }
}
    ${UserPreviewFragmentDoc}`;
export const AddEntryToWaitlistDocument = gql`
    mutation AddEntryToWaitlist($input: AddEntryToWaitlistInput!) {
  addEntryToWaitlist(input: $input) {
    ... on WaitlistEntry {
      id
    }
    ... on WaitlistEntryError {
      errorCode
    }
  }
}
    `;
export const NotificationsDocument = gql`
    query Notifications($organizationId: String!, $first: Int!, $after: String) {
  notifications(organizationId: $organizationId, first: $first, after: $after) {
    edges {
      cursor
      node {
        ...NotificationFragment
      }
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${NotificationFragmentFragmentDoc}`;
export const NotificationDocument = gql`
    query Notification($id: String!) {
  notification(id: $id) {
    ...NotificationFragment
    otherUser {
      id
      profileImageId
      avatarBgColor
      avatar
      username
      firstName
      email
    }
    admin {
      id
      profileImageId
      avatarBgColor
      avatar
      username
      firstName
    }
    role {
      id
      label
    }
    memberSubscription {
      id
      expiresAt
    }
    memberSubscriptionPlan {
      id
      name
      priceMonthlyUsd
    }
    group {
      id
      type
      name
    }
    requestToJoins {
      id
      email
      createdAt
      status
      user {
        id
        profileImageId
        avatarBgColor
        avatar
        username
        firstName
      }
    }
  }
}
    ${NotificationFragmentFragmentDoc}`;
export const UserPushEventsDocument = gql`
    subscription UserPushEvents {
  userPushEvent {
    ... on PushRefetchHint {
      id
      organizationId
      postId
      groupId
      postId
    }
  }
}
    `;
export const NotificationCountsDocument = gql`
    query NotificationCounts($organizationId: String!) {
  notificationCounts(organizationId: $organizationId) {
    unread
  }
}
    `;
export const MarkAllNotificationsReadDocument = gql`
    mutation MarkAllNotificationsRead($organizationId: String!) {
  markAllNotificationsRead(organizationId: $organizationId) {
    unread
  }
}
    `;
export const MarkNotificationReadDocument = gql`
    mutation MarkNotificationRead($input: ReadNotificationInput!) {
  markNotificationRead(input: $input) {
    ...NotificationFragment
  }
}
    ${NotificationFragmentFragmentDoc}`;
export const CreateUserFcmPushSubscriptionDocument = gql`
    mutation CreateUserFCMPushSubscription($input: CreateUserFCMPushSubscriptionInput!) {
  createUserFCMPushSubscription(input: $input) {
    id
  }
}
    `;
export const ProfileSetupDocument = gql`
    mutation ProfileSetup($input: ProfileSetupInput!) {
  profileSetup(input: $input) {
    id
    username
    email
    name
    profileImageId
    firstName
    lastName
    linkedinUrl
    instagramHandle
    hometown
    currentCity
    bio
    claimedAccount
    avatarBgColor
    avatar
  }
}
    `;
export const UpdateUserOnboardingDocument = gql`
    mutation UpdateUserOnboarding($input: UpdateUserOnboardingInput!) {
  updateUserOnboarding(input: $input) {
    id
    username
  }
}
    `;
export const AddPostViewDocument = gql`
    mutation AddPostView($input: AddViewInput!) {
  addPostView(input: $input) {
    id
  }
}
    `;
export const TogglePinnedPostDocument = gql`
    mutation TogglePinnedPost($input: TogglePinnedPostInput!) {
  togglePinnedPost(input: $input) {
    id
    pinned
  }
}
    `;
export const ToggleHideFromProfilePostDocument = gql`
    mutation ToggleHideFromProfilePost($input: ToggleHideFromProfilePostInput!) {
  toggleHideFromProfilePost(input: $input) {
    id
    hideFromProfile
  }
}
    `;
export const ToggleShowAuthorOnPostDocument = gql`
    mutation ToggleShowAuthorOnPost($input: ToggleShowAuthorOnPostInput!) {
  toggleShowAuthorOnPost(input: $input) {
    id
    showAuthor
  }
}
    `;
export const ToggleShowCommentsAndReactionsOnPostDocument = gql`
    mutation ToggleShowCommentsAndReactionsOnPost($input: ToggleShowCommentsAndReactionsOnPostInput!) {
  toggleShowCommentsAndReactionsOnPost(input: $input) {
    id
    showCommentsAndReactions
  }
}
    `;
export const WelcomePostDocument = gql`
    query WelcomePost($organizationId: ID!) {
  welcomePost(organizationId: $organizationId) {
    __typename
    ... on Post {
      ...PostFeed
    }
    ... on PostError {
      message
    }
  }
}
    ${PostFeedFragmentDoc}`;
export const ToggleWelcomePostDocument = gql`
    mutation ToggleWelcomePost($input: ToggleWelcomePostInput!) {
  toggleWelcomePost(input: $input) {
    id
    deleted
  }
}
    `;
export const DismissWelcomePostDocument = gql`
    mutation DismissWelcomePost($input: DismissWelcomePostInput!) {
  dismissWelcomePost(input: $input) {
    id
  }
}
    `;
export const NewMemberPostDocument = gql`
    query NewMemberPost($organizationId: ID!, $userId: ID!) {
  newMemberPost(organizationId: $organizationId, userId: $userId) {
    ...PostFeed
  }
}
    ${PostFeedFragmentDoc}`;
export const ToggleFollowPostDocument = gql`
    mutation ToggleFollowPost($input: ToggleFollowPostInput!) {
  toggleFollowPost(input: $input) {
    id
  }
}
    `;
export const IsFollowingPostDocument = gql`
    query IsFollowingPost($userId: ID!, $postId: ID!) {
  isFollowingPost(userId: $userId, postId: $postId) {
    id
    postId
  }
}
    `;
export const MovePostDocument = gql`
    mutation MovePost($input: MovePostInput!) {
  movePost(input: $input) {
    id
  }
}
    `;
export const PublishDraftPostDocument = gql`
    mutation PublishDraftPost($input: PublishDraftPostInput!) {
  publishDraftPost(input: $input) {
    id
    isDraft
  }
}
    `;
export const RescheduleDraftPostDocument = gql`
    mutation RescheduleDraftPost($input: RescheduleDraftPostInput!) {
  rescheduleDraftPost(input: $input) {
    id
    scheduledAt
  }
}
    `;
export const ShortIdSuggestionDocument = gql`
    query ShortIdSuggestion($name: String!) {
  shortIdSuggestion(name: $name) {
    __typename
    ... on OrganizationShortId {
      shortId
    }
    ... on OrganizationShortIdError {
      message
    }
  }
}
    `;
export const UsernameAvailableDocument = gql`
    query UsernameAvailable($username: String!) {
  usernameAvailable(username: $username) {
    __typename
    ... on Username {
      username
    }
    ... on UsernameError {
      message
    }
  }
}
    `;
export const ShortIdAvailableDocument = gql`
    query ShortIdAvailable($shortId: String!) {
  shortIdAvailable(shortId: $shortId) {
    __typename
    ... on OrganizationShortId {
      shortId
    }
    ... on OrganizationShortIdError {
      message
    }
  }
}
    `;
export const CreateOrUpdateOrganizationDocument = gql`
    mutation CreateOrUpdateOrganization($input: CreateOrUpdateOrganizationInput!) {
  createOrUpdateOrganization(input: $input) {
    ...OrganizationCore
  }
}
    ${OrganizationCoreFragmentDoc}`;
export const SetCustomDomainDocument = gql`
    mutation SetCustomDomain($input: SetOrganizationCustomDomainInput!) {
  setCustomDomain(input: $input) {
    id
    domain
  }
}
    `;
export const RemoveCustomDomainDocument = gql`
    mutation RemoveCustomDomain($organizationId: ID!) {
  removeCustomDomain(organizationId: $organizationId) {
    id
    domain
  }
}
    `;
export const JoinOrganizationDocument = gql`
    mutation JoinOrganization($input: JoinOrganizationInput!) {
  joinOrganization(input: $input) {
    ... on Organization {
      id
      name
      groupCreationPolicy
    }
    ... on OrganizationJoinError {
      message
    }
  }
}
    `;
export const UpdateUserLanguageDocument = gql`
    mutation UpdateUserLanguage($input: UpdateUserLanguageInput!) {
  updateUserLanguage(input: $input) {
    id
    username
  }
}
    `;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    username
    name
    profileImageId
    avatarBgColor
    avatar
    firstName
    lastName
    linkedinUrl
    instagramHandle
    hometown
    currentCity
    bio
    claimedAccount
  }
}
    `;
export const OrganizationAdministratorsDocument = gql`
    query OrganizationAdministrators($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    administrators {
      id
      username
      firstName
      lastName
      profileImageId
      avatar
      avatarBgColor
    }
  }
}
    `;
export const OrganizationQuotaUsageDocument = gql`
    query OrganizationQuotaUsage($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    quotaUsage {
      name
      limit
      used
    }
  }
}
    `;
export const AdminSubscriptionPlanPriceListDocument = gql`
    query AdminSubscriptionPlanPriceList {
  adminSubscriptionPlanPriceList {
    prices {
      id
      unitAmount
      lookupKey
      currency
    }
  }
}
    `;
export const OrganizationEntitlementsDocument = gql`
    query OrganizationEntitlements($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    entitlements {
      features
      quotas {
        name
        limit
      }
    }
  }
}
    `;
export const OrganizationAdminSubscriptionDocument = gql`
    query OrganizationAdminSubscription($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    adminSubscription {
      stripeSubscriptionStatus
      stripeCancelAtPeriodEnd
      subscriptionPlan
      subscriptionPrice
      grandfathered
      expiresAt
    }
  }
}
    `;
export const OrganizationMemberSubscriptionPlanDocument = gql`
    query OrganizationMemberSubscriptionPlan($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    memberSubscriptionPlan {
      id
      name
      description
      priceMonthlyUsd
      active
      stripeAccount {
        id
        disabledReason
      }
      groupEntitlements {
        id
        name
        description
        emoji
        numPosts
        groupType: type
      }
    }
  }
}
    `;
export const MemberSubscriptionPlanDocument = gql`
    query MemberSubscriptionPlan($id: ID!) {
  memberSubscriptionPlan(id: $id) {
    id
    subscriberCount
    pendingBalance
    priceMonthlyUsd
    totalRevenue
    active
    stripeAccount {
      id
      createdAt
    }
  }
}
    `;
export const ChangeAdminSubscriptionPlanDocument = gql`
    mutation ChangeAdminSubscriptionPlan($organizationId: ID!, $lookupKey: AdminSubscriptionPlanPriceLookupKey!) {
  changeAdminSubscriptionPlan(
    organizationId: $organizationId
    lookupKey: $lookupKey
  ) {
    stripeSubscriptionStatus
  }
}
    `;
export const UpsertMemberSubscriptionPlanDocument = gql`
    mutation UpsertMemberSubscriptionPlan($input: UpsertMemberSubscriptionPlanInput!) {
  upsertMemberSubscriptionPlan(input: $input) {
    id
  }
}
    `;
export const CancelAdminSubscriptionDocument = gql`
    mutation CancelAdminSubscription($organizationId: ID!) {
  cancelAdminSubscription(organizationId: $organizationId) {
    stripeSubscriptionStatus
  }
}
    `;
export const CancelMemberSubscriptionDocument = gql`
    mutation CancelMemberSubscription($memberSubscriptionId: ID!) {
  cancelMemberSubscription(memberSubscriptionId: $memberSubscriptionId) {
    id
  }
}
    `;
export const ReactivateMemberSubscriptionDocument = gql`
    mutation ReactivateMemberSubscription($memberSubscriptionId: ID!) {
  reactivateMemberSubscription(memberSubscriptionId: $memberSubscriptionId) {
    id
  }
}
    `;
export const CreateAdminPlanCheckoutSessionDocument = gql`
    mutation CreateAdminPlanCheckoutSession($organizationId: String!, $lookupKey: AdminSubscriptionPlanPriceLookupKey!) {
  createAdminPlanCheckoutSession(
    organizationId: $organizationId
    lookupKey: $lookupKey
  ) {
    url
  }
}
    `;
export const CreateMemberPlanCheckoutSessionDocument = gql`
    mutation CreateMemberPlanCheckoutSession($memberSubscriptionPlanId: String!, $returnToGroupId: String) {
  createMemberPlanCheckoutSession(
    memberSubscriptionPlanId: $memberSubscriptionPlanId
    returnToGroupId: $returnToGroupId
  ) {
    url
  }
}
    `;
export const CreateAdminPlanPortalSessionDocument = gql`
    mutation CreateAdminPlanPortalSession($organizationId: String!, $returnUrl: String!) {
  createAdminPlanPortalSession(
    organizationId: $organizationId
    returnUrl: $returnUrl
  ) {
    url
  }
}
    `;
export const BillingDetailsDocument = gql`
    query BillingDetails {
  viewer {
    id
    username
    billingDetails {
      address
      name
      email
      cardBrand
      cardLast4
    }
  }
}
    `;
export const CreateExpressDashboardSessionDocument = gql`
    mutation CreateExpressDashboardSession($organizationId: String!) {
  createExpressDashboardSession(organizationId: $organizationId) {
    url
  }
}
    `;
export const CreateMemberPlanPortalSessionDocument = gql`
    mutation CreateMemberPlanPortalSession($returnUrl: String!) {
  createMemberPlanPortalSession(returnUrl: $returnUrl) {
    url
  }
}
    `;
export const CreateStripeAccountLinkSessionDocument = gql`
    mutation CreateStripeAccountLinkSession($memberSubscriptionPlanId: String!) {
  createStripeAccountLinkSession(
    memberSubscriptionPlanId: $memberSubscriptionPlanId
  ) {
    url
  }
}
    `;
export const DeactivateMemberSubscriptionPlanDocument = gql`
    mutation DeactivateMemberSubscriptionPlan($memberSubscriptionPlanId: String!) {
  deactivateMemberSubscriptionPlan(
    memberSubscriptionPlanId: $memberSubscriptionPlanId
  ) {
    id
    active
  }
}
    `;
export const ReactivateMemberSubscriptionPlanDocument = gql`
    mutation ReactivateMemberSubscriptionPlan($memberSubscriptionPlanId: String!) {
  reactivateMemberSubscriptionPlan(
    memberSubscriptionPlanId: $memberSubscriptionPlanId
  ) {
    id
    active
  }
}
    `;
export const OrganizationByInviteCodeDocument = gql`
    query OrganizationByInviteCode($inviteCode: ID!) {
  organizationByInviteCode(inviteCode: $inviteCode) {
    id
    shortId
    color
    name
    description
    logoId
    isSetup
    isGoogleWorkspace
    createdAt
    numMembers
    numGroups
    groupCreationPolicy
    joinPolicy
    addMemberPolicy
    enableNewMemberIntroductions
    newMemberIntroductionMessage
    isPublic
    widgetSizeVariant
    hasCoursesEnabled
    hasEventsEnabled
    hasInsightsEnabled
    templateType
  }
}
    `;
export const MembersDocument = gql`
    query Members($organizationId: String!, $offset: Int, $limit: Int, $query: String, $orderBy: MembersOrderByInput) {
  members(
    organizationId: $organizationId
    offset: $offset
    limit: $limit
    query: $query
    orderBy: $orderBy
  ) {
    items {
      id
      active
      isCreator
      createdAt
      email
      roles {
        id
        label
        color
        isAdmin
        memberSubscriptionPlan {
          id
          name
        }
      }
      user {
        id
        name
        firstName
        username
        avatarBgColor
        avatar
        profileImageId
        memberSubscriptions {
          id
          subscriptionPlan {
            id
          }
        }
      }
    }
    totalCount
  }
}
    `;
export const SuspendUserDocument = gql`
    mutation SuspendUser($input: SuspendUserInput!) {
  suspendUser(input: $input) {
    id
    username
  }
}
    `;
export const DemoteUserDocument = gql`
    mutation DemoteUser($input: DemoteUserInput!) {
  demoteUser(input: $input) {
    id
    username
  }
}
    `;
export const LeaveOrganizationDocument = gql`
    mutation LeaveOrganization($input: LeaveOrganizationInput!) {
  leaveOrganization(input: $input) {
    id
    username
  }
}
    `;
export const PromoteUserDocument = gql`
    mutation PromoteUser($input: PromoteUserInput!) {
  promoteUser(input: $input) {
    id
    username
  }
}
    `;
export const AssignRoleDocument = gql`
    mutation AssignRole($input: AssignRoleInput!) {
  assignRole(input: $input) {
    id
    username
  }
}
    `;
export const UnassignRoleDocument = gql`
    mutation UnassignRole($input: UnassignRoleInput!) {
  unassignRole(input: $input) {
    id
    username
  }
}
    `;
export const InviteUsersDocument = gql`
    mutation InviteUsers($input: InviteUsersInput!) {
  inviteUsers(input: $input) {
    __typename
    ... on UserInvite {
      id
      email
      inviteCode
    }
    ... on RequestToJoin {
      id
    }
    ... on RequestToJoinError {
      message
    }
  }
}
    `;
export const RevokeUserInvitesDocument = gql`
    mutation RevokeUserInvites($input: RevokeUserInvitesInput!) {
  revokeUserInvites(input: $input) {
    id
    email
  }
}
    `;
export const ResendUserInviteDocument = gql`
    mutation ResendUserInvite($input: ResendUserInviteInput!) {
  resendUserInvite(input: $input) {
    id
    email
  }
}
    `;
export const ResetOrganizationInviteDocument = gql`
    mutation ResetOrganizationInvite($input: ResetOrganizationInviteInput!) {
  resetOrganizationInvite(input: $input) {
    id
  }
}
    `;
export const RestoreUserDocument = gql`
    mutation RestoreUser($input: RestoreUserInput!) {
  restoreUser(input: $input) {
    id
    username
  }
}
    `;
export const NotificationPreferencesDocument = gql`
    query NotificationPreferences($userId: ID!) {
  notificationPreferences(userId: $userId) {
    id
    type
    allowNewPostNotifications
    allowCommentNotifications
    allowMentionNotifications
    allowReactionNotifications
    allowReminderNotifications
    allowPollNotifications
    allowGroupNotifications
    allowAccountNotifications
    allowNewMemberRequestNotifications
    allowUserJoinedNotifications
    allowDirectMessageNotifications
  }
}
    `;
export const CreateUserWebPushSubscriptionDocument = gql`
    mutation CreateUserWebPushSubscription($input: CreateUserWebPushSubscriptionInput!) {
  createUserWebPushSubscription(input: $input) {
    id
    username
  }
}
    `;
export const UpdateUserNotificationPreferencesDocument = gql`
    mutation UpdateUserNotificationPreferences($input: UpdateUserNotificationPreferencesInput!) {
  updateUserNotificationPreferences(input: $input) {
    id
    type
    allowNewPostNotifications
    allowCommentNotifications
    allowMentionNotifications
    allowReactionNotifications
    allowReminderNotifications
    allowPollNotifications
    allowGroupNotifications
    allowAccountNotifications
    allowNewMemberRequestNotifications
    allowUserJoinedNotifications
    allowDirectMessageNotifications
  }
}
    `;
export const UpdateNewMemberIntroductionMessageDocument = gql`
    mutation UpdateNewMemberIntroductionMessage($input: UpdateNewMemberIntroductionMessageInput!) {
  updateNewMemberIntroductionMessage(input: $input) {
    id
    newMemberIntroductionMessage
  }
}
    `;
export const ToggleNewMemberIntroductionsDocument = gql`
    mutation ToggleNewMemberIntroductions($input: ToggleNewMemberIntroductionsInput!) {
  toggleNewMemberIntroductions(input: $input) {
    id
    enableNewMemberIntroductions
  }
}
    `;
export const ToggleAutoMessageNewMembersDocument = gql`
    mutation ToggleAutoMessageNewMembers($input: ToggleAutoMessageNewMembersInput!) {
  toggleAutoMessageNewMembers(input: $input) {
    id
    enableAutoMessageNewMembers
    autoMessageNewMembersFromId
  }
}
    `;
export const UpdateAutoMessageNewMembersMessageDocument = gql`
    mutation UpdateAutoMessageNewMembersMessage($input: UpdateAutoMessageNewMembersMessageInput!) {
  updateAutoMessageNewMembersMessage(input: $input) {
    id
    autoMessageNewMembersMessage
    autoMessageNewMembersFromId
  }
}
    `;
export const TogglePublicCommunityDocument = gql`
    mutation TogglePublicCommunity($input: TogglePublicCommunityInput!) {
  togglePublicCommunity(input: $input) {
    id
    isPublic
    joinPolicy
    addMemberPolicy
  }
}
    `;
export const CheckIfWidgetIsDetectedDocument = gql`
    query CheckIfWidgetIsDetected($organizationId: ID!) {
  checkIfWidgetIsDetected(organizationId: $organizationId) {
    id
    url
  }
}
    `;
export const UpdateWidgetSizeVariantDocument = gql`
    mutation UpdateWidgetSizeVariant($input: UpdateWidgetSizeVariantInput!) {
  updateWidgetSizeVariant(input: $input) {
    id
    widgetSizeVariant
  }
}
    `;
export const DismissFrondIntroPostDocument = gql`
    mutation DismissFrondIntroPost($input: DismissFrondIntroPostInput!) {
  dismissFrondIntroPost(input: $input) {
    id
    hideFrondIntroPost
  }
}
    `;
export const ToggleOrganizationFeatureDocument = gql`
    mutation ToggleOrganizationFeature($input: ToggleOrganizationFeatureInput!) {
  toggleOrganizationFeature(input: $input) {
    id
    hasCoursesEnabled
    hasEventsEnabled
    hasInsightsEnabled
  }
}
    `;
export const CreateVideoPresignedUploadDocument = gql`
    mutation CreateVideoPresignedUpload($input: CreateVideoPresignedUploadInput!) {
  createVideoPresignedUpload(input: $input) {
    url
    id
  }
}
    `;
export const CreateTemporaryVideoPresignedUploadDocument = gql`
    mutation CreateTemporaryVideoPresignedUpload($input: CreateTemporaryVideoPresignedUploadInput!) {
  createTemporaryVideoPresignedUpload(input: $input) {
    url
    id
  }
}
    `;
export const VideoStatusDocument = gql`
    query VideoStatus($videoId: ID!) {
  video(id: $videoId) {
    id
    aspect
    playbackId
    previewPlaybackId
    previewStatus
    mirror
    status
    type
    assetId
    createdAt
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    Organizations(variables: OrganizationsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<OrganizationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrganizationsQuery>(OrganizationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Organizations', 'query', variables);
    },
    AddOrganization(variables: AddOrganizationMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddOrganizationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddOrganizationMutation>(AddOrganizationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddOrganization', 'mutation', variables);
    },
    SendDigestToUser(variables: SendDigestToUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<SendDigestToUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SendDigestToUserMutation>(SendDigestToUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SendDigestToUser', 'mutation', variables);
    },
    SendNotificationsDigestToUser(variables: SendNotificationsDigestToUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<SendNotificationsDigestToUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SendNotificationsDigestToUserMutation>(SendNotificationsDigestToUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SendNotificationsDigestToUser', 'mutation', variables);
    },
    DeleteOrganization(variables: DeleteOrganizationMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteOrganizationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteOrganizationMutation>(DeleteOrganizationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteOrganization', 'mutation', variables);
    },
    DuplicateOrganization(variables: DuplicateOrganizationMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DuplicateOrganizationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DuplicateOrganizationMutation>(DuplicateOrganizationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DuplicateOrganization', 'mutation', variables);
    },
    DeleteUser(variables: DeleteUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteUserMutation>(DeleteUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteUser', 'mutation', variables);
    },
    GetDemoOrganization(variables?: GetDemoOrganizationMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetDemoOrganizationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetDemoOrganizationMutation>(GetDemoOrganizationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetDemoOrganization', 'mutation', variables);
    },
    Viewer(variables?: ViewerQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ViewerQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ViewerQuery>(ViewerDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Viewer', 'query', variables);
    },
    User(variables: UserQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UserQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserQuery>(UserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'User', 'query', variables);
    },
    UserGroupMemberships(variables: UserGroupMembershipsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UserGroupMembershipsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserGroupMembershipsQuery>(UserGroupMembershipsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UserGroupMemberships', 'query', variables);
    },
    AllUsers(variables: AllUsersQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AllUsersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<AllUsersQuery>(AllUsersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AllUsers', 'query', variables);
    },
    CreateUser(variables: CreateUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateUserMutation>(CreateUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateUser', 'mutation', variables);
    },
    Users(variables: UsersQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UsersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UsersQuery>(UsersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Users', 'query', variables);
    },
    CreateOrDeleteReaction(variables: CreateOrDeleteReactionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateOrDeleteReactionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateOrDeleteReactionMutation>(CreateOrDeleteReactionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateOrDeleteReaction', 'mutation', variables);
    },
    Comment(variables: CommentQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CommentQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CommentQuery>(CommentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Comment', 'query', variables);
    },
    CreateComment(variables: CreateCommentMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateCommentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateCommentMutation>(CreateCommentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateComment', 'mutation', variables);
    },
    UpdateComment(variables: UpdateCommentMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateCommentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateCommentMutation>(UpdateCommentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateComment', 'mutation', variables);
    },
    ToggleCommentAsBestAnswer(variables: ToggleCommentAsBestAnswerMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ToggleCommentAsBestAnswerMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ToggleCommentAsBestAnswerMutation>(ToggleCommentAsBestAnswerDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ToggleCommentAsBestAnswer', 'mutation', variables);
    },
    DeleteComment(variables: DeleteCommentMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteCommentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteCommentMutation>(DeleteCommentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteComment', 'mutation', variables);
    },
    SendFeedbackEmail(variables: SendFeedbackEmailMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<SendFeedbackEmailMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SendFeedbackEmailMutation>(SendFeedbackEmailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SendFeedbackEmail', 'mutation', variables);
    },
    CommentConfig(variables?: CommentConfigQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CommentConfigQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CommentConfigQuery>(CommentConfigDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CommentConfig', 'query', variables);
    },
    Organization(variables: OrganizationQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<OrganizationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrganizationQuery>(OrganizationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Organization', 'query', variables);
    },
    OrganizationByDomain(variables: OrganizationByDomainQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<OrganizationByDomainQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrganizationByDomainQuery>(OrganizationByDomainDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'OrganizationByDomain', 'query', variables);
    },
    OrganizationByShortId(variables: OrganizationByShortIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<OrganizationByShortIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrganizationByShortIdQuery>(OrganizationByShortIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'OrganizationByShortId', 'query', variables);
    },
    OrganizationActiveMembers(variables: OrganizationActiveMembersQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<OrganizationActiveMembersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrganizationActiveMembersQuery>(OrganizationActiveMembersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'OrganizationActiveMembers', 'query', variables);
    },
    CustomDomainVerificationInfo(variables: CustomDomainVerificationInfoQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CustomDomainVerificationInfoQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CustomDomainVerificationInfoQuery>(CustomDomainVerificationInfoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CustomDomainVerificationInfo', 'query', variables);
    },
    UserActivityCounts(variables: UserActivityCountsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UserActivityCountsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserActivityCountsQuery>(UserActivityCountsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UserActivityCounts', 'query', variables);
    },
    OrganizationInvite(variables: OrganizationInviteQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<OrganizationInviteQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrganizationInviteQuery>(OrganizationInviteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'OrganizationInvite', 'query', variables);
    },
    UpdateUserDigestNotificationFrequency(variables: UpdateUserDigestNotificationFrequencyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateUserDigestNotificationFrequencyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateUserDigestNotificationFrequencyMutation>(UpdateUserDigestNotificationFrequencyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateUserDigestNotificationFrequency', 'mutation', variables);
    },
    UnfurlUrl(variables: UnfurlUrlMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UnfurlUrlMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UnfurlUrlMutation>(UnfurlUrlDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UnfurlUrl', 'mutation', variables);
    },
    CreateImage(variables: CreateImageMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateImageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateImageMutation>(CreateImageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateImage', 'mutation', variables);
    },
    CreateTemporaryImage(variables: CreateTemporaryImageMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateTemporaryImageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateTemporaryImageMutation>(CreateTemporaryImageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateTemporaryImage', 'mutation', variables);
    },
    CreateGiphy(variables: CreateGiphyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateGiphyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateGiphyMutation>(CreateGiphyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateGiphy', 'mutation', variables);
    },
    PublicOrganization(variables: PublicOrganizationQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PublicOrganizationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PublicOrganizationQuery>(PublicOrganizationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PublicOrganization', 'query', variables);
    },
    PublicOrganizationShortIds(variables?: PublicOrganizationShortIdsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PublicOrganizationShortIdsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PublicOrganizationShortIdsQuery>(PublicOrganizationShortIdsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PublicOrganizationShortIds', 'query', variables);
    },
    VisitedPublicOrganizations(variables: VisitedPublicOrganizationsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<VisitedPublicOrganizationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VisitedPublicOrganizationsQuery>(VisitedPublicOrganizationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'VisitedPublicOrganizations', 'query', variables);
    },
    ReorderOrganizationMembership(variables: ReorderOrganizationMembershipMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ReorderOrganizationMembershipMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ReorderOrganizationMembershipMutation>(ReorderOrganizationMembershipDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ReorderOrganizationMembership', 'mutation', variables);
    },
    AddOrganizationVisit(variables: AddOrganizationVisitMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddOrganizationVisitMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddOrganizationVisitMutation>(AddOrganizationVisitDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddOrganizationVisit', 'mutation', variables);
    },
    HideOrganizationVisit(variables: HideOrganizationVisitMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<HideOrganizationVisitMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<HideOrganizationVisitMutation>(HideOrganizationVisitDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'HideOrganizationVisit', 'mutation', variables);
    },
    Groups(variables: GroupsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GroupsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GroupsQuery>(GroupsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Groups', 'query', variables);
    },
    SidebarGroups(variables: SidebarGroupsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<SidebarGroupsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<SidebarGroupsQuery>(SidebarGroupsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SidebarGroups', 'query', variables);
    },
    Group(variables: GroupQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GroupQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GroupQuery>(GroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Group', 'query', variables);
    },
    GroupUnseenPosts(variables: GroupUnseenPostsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GroupUnseenPostsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GroupUnseenPostsQuery>(GroupUnseenPostsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GroupUnseenPosts', 'query', variables);
    },
    CreateAndJoinGroup(variables: CreateAndJoinGroupMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateAndJoinGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateAndJoinGroupMutation>(CreateAndJoinGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateAndJoinGroup', 'mutation', variables);
    },
    UpdateGroup(variables: UpdateGroupMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateGroupMutation>(UpdateGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateGroup', 'mutation', variables);
    },
    DeleteGroup(variables: DeleteGroupMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteGroupMutation>(DeleteGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteGroup', 'mutation', variables);
    },
    AddGroupMembers(variables: AddGroupMembersMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddGroupMembersMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddGroupMembersMutation>(AddGroupMembersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddGroupMembers', 'mutation', variables);
    },
    JoinGroup(variables: JoinGroupMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<JoinGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<JoinGroupMutation>(JoinGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'JoinGroup', 'mutation', variables);
    },
    RemoveGroupMember(variables: RemoveGroupMemberMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RemoveGroupMemberMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveGroupMemberMutation>(RemoveGroupMemberDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveGroupMember', 'mutation', variables);
    },
    AddGroupView(variables: AddGroupViewMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddGroupViewMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddGroupViewMutation>(AddGroupViewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddGroupView', 'mutation', variables);
    },
    ReorderGroups(variables: ReorderGroupsMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ReorderGroupsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ReorderGroupsMutation>(ReorderGroupsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ReorderGroups', 'mutation', variables);
    },
    HomeFeed(variables: HomeFeedQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<HomeFeedQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<HomeFeedQuery>(HomeFeedDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'HomeFeed', 'query', variables);
    },
    HomeFeedLatestContent(variables: HomeFeedLatestContentQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<HomeFeedLatestContentQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<HomeFeedLatestContentQuery>(HomeFeedLatestContentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'HomeFeedLatestContent', 'query', variables);
    },
    UpsertPost(variables: UpsertPostMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpsertPostMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpsertPostMutation>(UpsertPostDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpsertPost', 'mutation', variables);
    },
    Post(variables: PostQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PostQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PostQuery>(PostDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Post', 'query', variables);
    },
    DeletePost(variables: DeletePostMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeletePostMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeletePostMutation>(DeletePostDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeletePost', 'mutation', variables);
    },
    CreateTemporaryPost(variables: CreateTemporaryPostMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateTemporaryPostMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateTemporaryPostMutation>(CreateTemporaryPostDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateTemporaryPost', 'mutation', variables);
    },
    TemporaryPostPoll(variables: TemporaryPostPollQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<TemporaryPostPollQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<TemporaryPostPollQuery>(TemporaryPostPollDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'TemporaryPostPoll', 'query', variables);
    },
    TemporaryPost(variables: TemporaryPostQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<TemporaryPostQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<TemporaryPostQuery>(TemporaryPostDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'TemporaryPost', 'query', variables);
    },
    UpdateTemporaryPost(variables: UpdateTemporaryPostMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateTemporaryPostMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateTemporaryPostMutation>(UpdateTemporaryPostDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateTemporaryPost', 'mutation', variables);
    },
    UpdateMembership(variables: UpdateMembershipMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateMembershipMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateMembershipMutation>(UpdateMembershipDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateMembership', 'mutation', variables);
    },
    GroupsSidebarState(variables?: GroupsSidebarStateQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GroupsSidebarStateQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GroupsSidebarStateQuery>(GroupsSidebarStateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GroupsSidebarState', 'query', variables);
    },
    GroupsMenuSectionsState(variables?: GroupsMenuSectionsStateQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GroupsMenuSectionsStateQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GroupsMenuSectionsStateQuery>(GroupsMenuSectionsStateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GroupsMenuSectionsState', 'query', variables);
    },
    ToggleGroupAutoAddMembers(variables: ToggleGroupAutoAddMembersMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ToggleGroupAutoAddMembersMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ToggleGroupAutoAddMembersMutation>(ToggleGroupAutoAddMembersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ToggleGroupAutoAddMembers', 'mutation', variables);
    },
    ToggleGroupEnableNewPostNotificationsForNewMembers(variables: ToggleGroupEnableNewPostNotificationsForNewMembersMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ToggleGroupEnableNewPostNotificationsForNewMembersMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ToggleGroupEnableNewPostNotificationsForNewMembersMutation>(ToggleGroupEnableNewPostNotificationsForNewMembersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ToggleGroupEnableNewPostNotificationsForNewMembers', 'mutation', variables);
    },
    ToggleGroupIncludePostsOnHome(variables: ToggleGroupIncludePostsOnHomeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ToggleGroupIncludePostsOnHomeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ToggleGroupIncludePostsOnHomeMutation>(ToggleGroupIncludePostsOnHomeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ToggleGroupIncludePostsOnHome', 'mutation', variables);
    },
    RequestToJoinOrganization(variables: RequestToJoinOrganizationMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RequestToJoinOrganizationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RequestToJoinOrganizationMutation>(RequestToJoinOrganizationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RequestToJoinOrganization', 'mutation', variables);
    },
    UserInvites(variables: UserInvitesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UserInvitesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserInvitesQuery>(UserInvitesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UserInvites', 'query', variables);
    },
    UserRequestToJoinOrganization(variables: UserRequestToJoinOrganizationQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UserRequestToJoinOrganizationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserRequestToJoinOrganizationQuery>(UserRequestToJoinOrganizationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UserRequestToJoinOrganization', 'query', variables);
    },
    UserRequestsToJoinOrganization(variables: UserRequestsToJoinOrganizationQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UserRequestsToJoinOrganizationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserRequestsToJoinOrganizationQuery>(UserRequestsToJoinOrganizationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UserRequestsToJoinOrganization', 'query', variables);
    },
    AcceptRequestToJoin(variables: AcceptRequestToJoinMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AcceptRequestToJoinMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AcceptRequestToJoinMutation>(AcceptRequestToJoinDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AcceptRequestToJoin', 'mutation', variables);
    },
    AcceptAllRequestsToJoin(variables: AcceptAllRequestsToJoinMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AcceptAllRequestsToJoinMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AcceptAllRequestsToJoinMutation>(AcceptAllRequestsToJoinDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AcceptAllRequestsToJoin', 'mutation', variables);
    },
    RejectRequestToJoin(variables: RejectRequestToJoinMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RejectRequestToJoinMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RejectRequestToJoinMutation>(RejectRequestToJoinDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RejectRequestToJoin', 'mutation', variables);
    },
    OrganizationPushEvents(variables: OrganizationPushEventsSubscriptionVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<OrganizationPushEventsSubscription> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrganizationPushEventsSubscription>(OrganizationPushEventsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'OrganizationPushEvents', 'subscription', variables);
    },
    UpdateImageCoordinates(variables: UpdateImageCoordinatesMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateImageCoordinatesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateImageCoordinatesMutation>(UpdateImageCoordinatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateImageCoordinates', 'mutation', variables);
    },
    CreateVote(variables: CreateVoteMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateVoteMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateVoteMutation>(CreateVoteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateVote', 'mutation', variables);
    },
    DeleteVote(variables: DeleteVoteMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteVoteMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteVoteMutation>(DeleteVoteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteVote', 'mutation', variables);
    },
    Search(variables: SearchQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<SearchQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<SearchQuery>(SearchDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Search', 'query', variables);
    },
    PostsByGroup(variables: PostsByGroupQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PostsByGroupQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PostsByGroupQuery>(PostsByGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PostsByGroup', 'query', variables);
    },
    DraftPosts(variables: DraftPostsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DraftPostsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DraftPostsQuery>(DraftPostsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DraftPosts', 'query', variables);
    },
    CreateOrganizationRole(variables: CreateOrganizationRoleMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateOrganizationRoleMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateOrganizationRoleMutation>(CreateOrganizationRoleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateOrganizationRole', 'mutation', variables);
    },
    UpdateOrganizationRole(variables: UpdateOrganizationRoleMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateOrganizationRoleMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateOrganizationRoleMutation>(UpdateOrganizationRoleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateOrganizationRole', 'mutation', variables);
    },
    DeleteOrganizationRole(variables: DeleteOrganizationRoleMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteOrganizationRoleMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteOrganizationRoleMutation>(DeleteOrganizationRoleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteOrganizationRole', 'mutation', variables);
    },
    OrganizationRoles(variables: OrganizationRolesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<OrganizationRolesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrganizationRolesQuery>(OrganizationRolesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'OrganizationRoles', 'query', variables);
    },
    OrganizationRolesByUserId(variables: OrganizationRolesByUserIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<OrganizationRolesByUserIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrganizationRolesByUserIdQuery>(OrganizationRolesByUserIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'OrganizationRolesByUserId', 'query', variables);
    },
    DirectMessageToken(variables: DirectMessageTokenQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DirectMessageTokenQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DirectMessageTokenQuery>(DirectMessageTokenDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DirectMessageToken', 'query', variables);
    },
    DirectMessageUserThread(variables: DirectMessageUserThreadQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DirectMessageUserThreadQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DirectMessageUserThreadQuery>(DirectMessageUserThreadDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DirectMessageUserThread', 'query', variables);
    },
    WelcomeGroup(variables: WelcomeGroupQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<WelcomeGroupQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<WelcomeGroupQuery>(WelcomeGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'WelcomeGroup', 'query', variables);
    },
    ToggleWelcomeGroup(variables: ToggleWelcomeGroupMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ToggleWelcomeGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ToggleWelcomeGroupMutation>(ToggleWelcomeGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ToggleWelcomeGroup', 'mutation', variables);
    },
    DirectMessageThreads(variables: DirectMessageThreadsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DirectMessageThreadsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DirectMessageThreadsQuery>(DirectMessageThreadsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DirectMessageThreads', 'query', variables);
    },
    CreateDirectMessage(variables: CreateDirectMessageMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateDirectMessageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateDirectMessageMutation>(CreateDirectMessageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateDirectMessage', 'mutation', variables);
    },
    DeleteDirectMessageThread(variables: DeleteDirectMessageThreadMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteDirectMessageThreadMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteDirectMessageThreadMutation>(DeleteDirectMessageThreadDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteDirectMessageThread', 'mutation', variables);
    },
    DirectMessage(variables: DirectMessageQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DirectMessageQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DirectMessageQuery>(DirectMessageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DirectMessage', 'query', variables);
    },
    CreateOrDeleteDirectMessageReaction(variables: CreateOrDeleteDirectMessageReactionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateOrDeleteDirectMessageReactionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateOrDeleteDirectMessageReactionMutation>(CreateOrDeleteDirectMessageReactionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateOrDeleteDirectMessageReaction', 'mutation', variables);
    },
    DirectMessageNotificationCounts(variables: DirectMessageNotificationCountsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DirectMessageNotificationCountsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DirectMessageNotificationCountsQuery>(DirectMessageNotificationCountsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DirectMessageNotificationCounts', 'query', variables);
    },
    UpdateDirectMessageThreadSettings(variables: UpdateDirectMessageThreadSettingsMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateDirectMessageThreadSettingsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateDirectMessageThreadSettingsMutation>(UpdateDirectMessageThreadSettingsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateDirectMessageThreadSettings', 'mutation', variables);
    },
    MarkDirectMessageRead(variables: MarkDirectMessageReadMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MarkDirectMessageReadMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<MarkDirectMessageReadMutation>(MarkDirectMessageReadDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MarkDirectMessageRead', 'mutation', variables);
    },
    Events(variables: EventsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<EventsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<EventsQuery>(EventsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Events', 'query', variables);
    },
    PostsByEvent(variables: PostsByEventQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PostsByEventQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PostsByEventQuery>(PostsByEventDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PostsByEvent', 'query', variables);
    },
    Event(variables: EventQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<EventQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<EventQuery>(EventDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Event', 'query', variables);
    },
    CreateEvent(variables: CreateEventMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateEventMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateEventMutation>(CreateEventDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateEvent', 'mutation', variables);
    },
    UpdateEvent(variables: UpdateEventMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateEventMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateEventMutation>(UpdateEventDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateEvent', 'mutation', variables);
    },
    DeleteEvent(variables: DeleteEventMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteEventMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteEventMutation>(DeleteEventDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteEvent', 'mutation', variables);
    },
    Lessons(variables: LessonsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<LessonsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<LessonsQuery>(LessonsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Lessons', 'query', variables);
    },
    CreateCourse(variables: CreateCourseMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateCourseMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateCourseMutation>(CreateCourseDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateCourse', 'mutation', variables);
    },
    ReorderLesson(variables: ReorderLessonMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ReorderLessonMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ReorderLessonMutation>(ReorderLessonDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ReorderLesson', 'mutation', variables);
    },
    ToggleLessonCompleted(variables: ToggleLessonCompletedMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ToggleLessonCompletedMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ToggleLessonCompletedMutation>(ToggleLessonCompletedDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ToggleLessonCompleted', 'mutation', variables);
    },
    Upsells(variables: UpsellsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpsellsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpsellsQuery>(UpsellsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Upsells', 'query', variables);
    },
    HideUpsell(variables: HideUpsellMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<HideUpsellMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<HideUpsellMutation>(HideUpsellDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'HideUpsell', 'mutation', variables);
    },
    PublishCourse(variables: PublishCourseMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PublishCourseMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PublishCourseMutation>(PublishCourseDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PublishCourse', 'mutation', variables);
    },
    LocationByName(variables: LocationByNameQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<LocationByNameQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<LocationByNameQuery>(LocationByNameDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'LocationByName', 'query', variables);
    },
    LocationByPlaceId(variables: LocationByPlaceIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<LocationByPlaceIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<LocationByPlaceIdQuery>(LocationByPlaceIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'LocationByPlaceId', 'query', variables);
    },
    ToggleEventPostCreationPolicy(variables: ToggleEventPostCreationPolicyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ToggleEventPostCreationPolicyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ToggleEventPostCreationPolicyMutation>(ToggleEventPostCreationPolicyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ToggleEventPostCreationPolicy', 'mutation', variables);
    },
    ToggleEventNotifications(variables: ToggleEventNotificationsMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ToggleEventNotificationsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ToggleEventNotificationsMutation>(ToggleEventNotificationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ToggleEventNotifications', 'mutation', variables);
    },
    CourseCompletion(variables: CourseCompletionQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CourseCompletionQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CourseCompletionQuery>(CourseCompletionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CourseCompletion', 'query', variables);
    },
    ToggleOrganizationMembershipOnboardingTask(variables: ToggleOrganizationMembershipOnboardingTaskMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ToggleOrganizationMembershipOnboardingTaskMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ToggleOrganizationMembershipOnboardingTaskMutation>(ToggleOrganizationMembershipOnboardingTaskDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ToggleOrganizationMembershipOnboardingTask', 'mutation', variables);
    },
    CreateOrDeletePostBookmark(variables: CreateOrDeletePostBookmarkMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateOrDeletePostBookmarkMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateOrDeletePostBookmarkMutation>(CreateOrDeletePostBookmarkDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateOrDeletePostBookmark', 'mutation', variables);
    },
    PostBookmarks(variables: PostBookmarksQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PostBookmarksQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PostBookmarksQuery>(PostBookmarksDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PostBookmarks', 'query', variables);
    },
    MenuSections(variables: MenuSectionsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MenuSectionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MenuSectionsQuery>(MenuSectionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MenuSections', 'query', variables);
    },
    CreateMenuSection(variables: CreateMenuSectionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateMenuSectionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateMenuSectionMutation>(CreateMenuSectionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateMenuSection', 'mutation', variables);
    },
    UpdateMenuSection(variables: UpdateMenuSectionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateMenuSectionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateMenuSectionMutation>(UpdateMenuSectionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateMenuSection', 'mutation', variables);
    },
    DeleteMenuSection(variables: DeleteMenuSectionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteMenuSectionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteMenuSectionMutation>(DeleteMenuSectionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteMenuSection', 'mutation', variables);
    },
    ReorderMenuSections(variables: ReorderMenuSectionsMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ReorderMenuSectionsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ReorderMenuSectionsMutation>(ReorderMenuSectionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ReorderMenuSections', 'mutation', variables);
    },
    CreateMenuItemUrl(variables: CreateMenuItemUrlMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateMenuItemUrlMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateMenuItemUrlMutation>(CreateMenuItemUrlDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateMenuItemUrl', 'mutation', variables);
    },
    CreateMenuItemWithPost(variables: CreateMenuItemWithPostMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateMenuItemWithPostMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateMenuItemWithPostMutation>(CreateMenuItemWithPostDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateMenuItemWithPost', 'mutation', variables);
    },
    UpdateMenuItem(variables: UpdateMenuItemMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateMenuItemMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateMenuItemMutation>(UpdateMenuItemDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateMenuItem', 'mutation', variables);
    },
    DeleteMenuItem(variables: DeleteMenuItemMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteMenuItemMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteMenuItemMutation>(DeleteMenuItemDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteMenuItem', 'mutation', variables);
    },
    ReorderMenuItems(variables: ReorderMenuItemsMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ReorderMenuItemsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ReorderMenuItemsMutation>(ReorderMenuItemsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ReorderMenuItems', 'mutation', variables);
    },
    HelloPageByShortId(variables: HelloPageByShortIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<HelloPageByShortIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<HelloPageByShortIdQuery>(HelloPageByShortIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'HelloPageByShortId', 'query', variables);
    },
    InboxState(variables?: InboxStateQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<InboxStateQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<InboxStateQuery>(InboxStateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'InboxState', 'query', variables);
    },
    OrganizationStats(variables: OrganizationStatsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<OrganizationStatsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrganizationStatsQuery>(OrganizationStatsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'OrganizationStats', 'query', variables);
    },
    MembersWithHighestPostCount(variables: MembersWithHighestPostCountQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MembersWithHighestPostCountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MembersWithHighestPostCountQuery>(MembersWithHighestPostCountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MembersWithHighestPostCount', 'query', variables);
    },
    MembersWithHighestCommentCount(variables: MembersWithHighestCommentCountQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MembersWithHighestCommentCountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MembersWithHighestCommentCountQuery>(MembersWithHighestCommentCountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MembersWithHighestCommentCount', 'query', variables);
    },
    MembersWithHighestReactionCount(variables: MembersWithHighestReactionCountQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MembersWithHighestReactionCountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MembersWithHighestReactionCountQuery>(MembersWithHighestReactionCountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MembersWithHighestReactionCount', 'query', variables);
    },
    MostRecentOrganizationActivity(variables: MostRecentOrganizationActivityQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MostRecentOrganizationActivityQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MostRecentOrganizationActivityQuery>(MostRecentOrganizationActivityDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MostRecentOrganizationActivity', 'query', variables);
    },
    NewMemberships(variables: NewMembershipsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<NewMembershipsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<NewMembershipsQuery>(NewMembershipsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'NewMemberships', 'query', variables);
    },
    AddEntryToWaitlist(variables: AddEntryToWaitlistMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddEntryToWaitlistMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddEntryToWaitlistMutation>(AddEntryToWaitlistDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddEntryToWaitlist', 'mutation', variables);
    },
    Notifications(variables: NotificationsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<NotificationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<NotificationsQuery>(NotificationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Notifications', 'query', variables);
    },
    Notification(variables: NotificationQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<NotificationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<NotificationQuery>(NotificationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Notification', 'query', variables);
    },
    UserPushEvents(variables?: UserPushEventsSubscriptionVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UserPushEventsSubscription> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserPushEventsSubscription>(UserPushEventsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UserPushEvents', 'subscription', variables);
    },
    NotificationCounts(variables: NotificationCountsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<NotificationCountsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<NotificationCountsQuery>(NotificationCountsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'NotificationCounts', 'query', variables);
    },
    MarkAllNotificationsRead(variables: MarkAllNotificationsReadMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MarkAllNotificationsReadMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<MarkAllNotificationsReadMutation>(MarkAllNotificationsReadDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MarkAllNotificationsRead', 'mutation', variables);
    },
    MarkNotificationRead(variables: MarkNotificationReadMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MarkNotificationReadMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<MarkNotificationReadMutation>(MarkNotificationReadDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MarkNotificationRead', 'mutation', variables);
    },
    CreateUserFCMPushSubscription(variables: CreateUserFcmPushSubscriptionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateUserFcmPushSubscriptionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateUserFcmPushSubscriptionMutation>(CreateUserFcmPushSubscriptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateUserFCMPushSubscription', 'mutation', variables);
    },
    ProfileSetup(variables: ProfileSetupMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ProfileSetupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProfileSetupMutation>(ProfileSetupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ProfileSetup', 'mutation', variables);
    },
    UpdateUserOnboarding(variables: UpdateUserOnboardingMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateUserOnboardingMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateUserOnboardingMutation>(UpdateUserOnboardingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateUserOnboarding', 'mutation', variables);
    },
    AddPostView(variables: AddPostViewMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddPostViewMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddPostViewMutation>(AddPostViewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddPostView', 'mutation', variables);
    },
    TogglePinnedPost(variables: TogglePinnedPostMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<TogglePinnedPostMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<TogglePinnedPostMutation>(TogglePinnedPostDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'TogglePinnedPost', 'mutation', variables);
    },
    ToggleHideFromProfilePost(variables: ToggleHideFromProfilePostMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ToggleHideFromProfilePostMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ToggleHideFromProfilePostMutation>(ToggleHideFromProfilePostDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ToggleHideFromProfilePost', 'mutation', variables);
    },
    ToggleShowAuthorOnPost(variables: ToggleShowAuthorOnPostMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ToggleShowAuthorOnPostMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ToggleShowAuthorOnPostMutation>(ToggleShowAuthorOnPostDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ToggleShowAuthorOnPost', 'mutation', variables);
    },
    ToggleShowCommentsAndReactionsOnPost(variables: ToggleShowCommentsAndReactionsOnPostMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ToggleShowCommentsAndReactionsOnPostMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ToggleShowCommentsAndReactionsOnPostMutation>(ToggleShowCommentsAndReactionsOnPostDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ToggleShowCommentsAndReactionsOnPost', 'mutation', variables);
    },
    WelcomePost(variables: WelcomePostQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<WelcomePostQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<WelcomePostQuery>(WelcomePostDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'WelcomePost', 'query', variables);
    },
    ToggleWelcomePost(variables: ToggleWelcomePostMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ToggleWelcomePostMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ToggleWelcomePostMutation>(ToggleWelcomePostDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ToggleWelcomePost', 'mutation', variables);
    },
    DismissWelcomePost(variables: DismissWelcomePostMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DismissWelcomePostMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DismissWelcomePostMutation>(DismissWelcomePostDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DismissWelcomePost', 'mutation', variables);
    },
    NewMemberPost(variables: NewMemberPostQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<NewMemberPostQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<NewMemberPostQuery>(NewMemberPostDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'NewMemberPost', 'query', variables);
    },
    ToggleFollowPost(variables: ToggleFollowPostMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ToggleFollowPostMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ToggleFollowPostMutation>(ToggleFollowPostDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ToggleFollowPost', 'mutation', variables);
    },
    IsFollowingPost(variables: IsFollowingPostQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IsFollowingPostQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IsFollowingPostQuery>(IsFollowingPostDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'IsFollowingPost', 'query', variables);
    },
    MovePost(variables: MovePostMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MovePostMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<MovePostMutation>(MovePostDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MovePost', 'mutation', variables);
    },
    PublishDraftPost(variables: PublishDraftPostMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PublishDraftPostMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PublishDraftPostMutation>(PublishDraftPostDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PublishDraftPost', 'mutation', variables);
    },
    RescheduleDraftPost(variables: RescheduleDraftPostMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RescheduleDraftPostMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RescheduleDraftPostMutation>(RescheduleDraftPostDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RescheduleDraftPost', 'mutation', variables);
    },
    ShortIdSuggestion(variables: ShortIdSuggestionQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ShortIdSuggestionQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ShortIdSuggestionQuery>(ShortIdSuggestionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ShortIdSuggestion', 'query', variables);
    },
    UsernameAvailable(variables: UsernameAvailableQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UsernameAvailableQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UsernameAvailableQuery>(UsernameAvailableDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UsernameAvailable', 'query', variables);
    },
    ShortIdAvailable(variables: ShortIdAvailableQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ShortIdAvailableQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ShortIdAvailableQuery>(ShortIdAvailableDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ShortIdAvailable', 'query', variables);
    },
    CreateOrUpdateOrganization(variables: CreateOrUpdateOrganizationMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateOrUpdateOrganizationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateOrUpdateOrganizationMutation>(CreateOrUpdateOrganizationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateOrUpdateOrganization', 'mutation', variables);
    },
    SetCustomDomain(variables: SetCustomDomainMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<SetCustomDomainMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SetCustomDomainMutation>(SetCustomDomainDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SetCustomDomain', 'mutation', variables);
    },
    RemoveCustomDomain(variables: RemoveCustomDomainMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RemoveCustomDomainMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveCustomDomainMutation>(RemoveCustomDomainDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveCustomDomain', 'mutation', variables);
    },
    JoinOrganization(variables: JoinOrganizationMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<JoinOrganizationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<JoinOrganizationMutation>(JoinOrganizationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'JoinOrganization', 'mutation', variables);
    },
    UpdateUserLanguage(variables: UpdateUserLanguageMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateUserLanguageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateUserLanguageMutation>(UpdateUserLanguageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateUserLanguage', 'mutation', variables);
    },
    UpdateUser(variables: UpdateUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateUserMutation>(UpdateUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateUser', 'mutation', variables);
    },
    OrganizationAdministrators(variables: OrganizationAdministratorsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<OrganizationAdministratorsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrganizationAdministratorsQuery>(OrganizationAdministratorsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'OrganizationAdministrators', 'query', variables);
    },
    OrganizationQuotaUsage(variables: OrganizationQuotaUsageQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<OrganizationQuotaUsageQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrganizationQuotaUsageQuery>(OrganizationQuotaUsageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'OrganizationQuotaUsage', 'query', variables);
    },
    AdminSubscriptionPlanPriceList(variables?: AdminSubscriptionPlanPriceListQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AdminSubscriptionPlanPriceListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<AdminSubscriptionPlanPriceListQuery>(AdminSubscriptionPlanPriceListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AdminSubscriptionPlanPriceList', 'query', variables);
    },
    OrganizationEntitlements(variables: OrganizationEntitlementsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<OrganizationEntitlementsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrganizationEntitlementsQuery>(OrganizationEntitlementsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'OrganizationEntitlements', 'query', variables);
    },
    OrganizationAdminSubscription(variables: OrganizationAdminSubscriptionQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<OrganizationAdminSubscriptionQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrganizationAdminSubscriptionQuery>(OrganizationAdminSubscriptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'OrganizationAdminSubscription', 'query', variables);
    },
    OrganizationMemberSubscriptionPlan(variables: OrganizationMemberSubscriptionPlanQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<OrganizationMemberSubscriptionPlanQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrganizationMemberSubscriptionPlanQuery>(OrganizationMemberSubscriptionPlanDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'OrganizationMemberSubscriptionPlan', 'query', variables);
    },
    MemberSubscriptionPlan(variables: MemberSubscriptionPlanQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MemberSubscriptionPlanQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MemberSubscriptionPlanQuery>(MemberSubscriptionPlanDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MemberSubscriptionPlan', 'query', variables);
    },
    ChangeAdminSubscriptionPlan(variables: ChangeAdminSubscriptionPlanMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ChangeAdminSubscriptionPlanMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ChangeAdminSubscriptionPlanMutation>(ChangeAdminSubscriptionPlanDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ChangeAdminSubscriptionPlan', 'mutation', variables);
    },
    UpsertMemberSubscriptionPlan(variables: UpsertMemberSubscriptionPlanMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpsertMemberSubscriptionPlanMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpsertMemberSubscriptionPlanMutation>(UpsertMemberSubscriptionPlanDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpsertMemberSubscriptionPlan', 'mutation', variables);
    },
    CancelAdminSubscription(variables: CancelAdminSubscriptionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CancelAdminSubscriptionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CancelAdminSubscriptionMutation>(CancelAdminSubscriptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CancelAdminSubscription', 'mutation', variables);
    },
    CancelMemberSubscription(variables: CancelMemberSubscriptionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CancelMemberSubscriptionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CancelMemberSubscriptionMutation>(CancelMemberSubscriptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CancelMemberSubscription', 'mutation', variables);
    },
    ReactivateMemberSubscription(variables: ReactivateMemberSubscriptionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ReactivateMemberSubscriptionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ReactivateMemberSubscriptionMutation>(ReactivateMemberSubscriptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ReactivateMemberSubscription', 'mutation', variables);
    },
    CreateAdminPlanCheckoutSession(variables: CreateAdminPlanCheckoutSessionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateAdminPlanCheckoutSessionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateAdminPlanCheckoutSessionMutation>(CreateAdminPlanCheckoutSessionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateAdminPlanCheckoutSession', 'mutation', variables);
    },
    CreateMemberPlanCheckoutSession(variables: CreateMemberPlanCheckoutSessionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateMemberPlanCheckoutSessionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateMemberPlanCheckoutSessionMutation>(CreateMemberPlanCheckoutSessionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateMemberPlanCheckoutSession', 'mutation', variables);
    },
    CreateAdminPlanPortalSession(variables: CreateAdminPlanPortalSessionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateAdminPlanPortalSessionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateAdminPlanPortalSessionMutation>(CreateAdminPlanPortalSessionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateAdminPlanPortalSession', 'mutation', variables);
    },
    BillingDetails(variables?: BillingDetailsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<BillingDetailsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<BillingDetailsQuery>(BillingDetailsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'BillingDetails', 'query', variables);
    },
    CreateExpressDashboardSession(variables: CreateExpressDashboardSessionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateExpressDashboardSessionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateExpressDashboardSessionMutation>(CreateExpressDashboardSessionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateExpressDashboardSession', 'mutation', variables);
    },
    CreateMemberPlanPortalSession(variables: CreateMemberPlanPortalSessionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateMemberPlanPortalSessionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateMemberPlanPortalSessionMutation>(CreateMemberPlanPortalSessionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateMemberPlanPortalSession', 'mutation', variables);
    },
    CreateStripeAccountLinkSession(variables: CreateStripeAccountLinkSessionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateStripeAccountLinkSessionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateStripeAccountLinkSessionMutation>(CreateStripeAccountLinkSessionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateStripeAccountLinkSession', 'mutation', variables);
    },
    DeactivateMemberSubscriptionPlan(variables: DeactivateMemberSubscriptionPlanMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeactivateMemberSubscriptionPlanMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeactivateMemberSubscriptionPlanMutation>(DeactivateMemberSubscriptionPlanDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeactivateMemberSubscriptionPlan', 'mutation', variables);
    },
    ReactivateMemberSubscriptionPlan(variables: ReactivateMemberSubscriptionPlanMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ReactivateMemberSubscriptionPlanMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ReactivateMemberSubscriptionPlanMutation>(ReactivateMemberSubscriptionPlanDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ReactivateMemberSubscriptionPlan', 'mutation', variables);
    },
    OrganizationByInviteCode(variables: OrganizationByInviteCodeQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<OrganizationByInviteCodeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrganizationByInviteCodeQuery>(OrganizationByInviteCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'OrganizationByInviteCode', 'query', variables);
    },
    Members(variables: MembersQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MembersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MembersQuery>(MembersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Members', 'query', variables);
    },
    SuspendUser(variables: SuspendUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<SuspendUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SuspendUserMutation>(SuspendUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SuspendUser', 'mutation', variables);
    },
    DemoteUser(variables: DemoteUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DemoteUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DemoteUserMutation>(DemoteUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DemoteUser', 'mutation', variables);
    },
    LeaveOrganization(variables: LeaveOrganizationMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<LeaveOrganizationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<LeaveOrganizationMutation>(LeaveOrganizationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'LeaveOrganization', 'mutation', variables);
    },
    PromoteUser(variables: PromoteUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PromoteUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PromoteUserMutation>(PromoteUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PromoteUser', 'mutation', variables);
    },
    AssignRole(variables: AssignRoleMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AssignRoleMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AssignRoleMutation>(AssignRoleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AssignRole', 'mutation', variables);
    },
    UnassignRole(variables: UnassignRoleMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UnassignRoleMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UnassignRoleMutation>(UnassignRoleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UnassignRole', 'mutation', variables);
    },
    InviteUsers(variables: InviteUsersMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<InviteUsersMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<InviteUsersMutation>(InviteUsersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'InviteUsers', 'mutation', variables);
    },
    RevokeUserInvites(variables: RevokeUserInvitesMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RevokeUserInvitesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RevokeUserInvitesMutation>(RevokeUserInvitesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RevokeUserInvites', 'mutation', variables);
    },
    ResendUserInvite(variables: ResendUserInviteMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ResendUserInviteMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ResendUserInviteMutation>(ResendUserInviteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ResendUserInvite', 'mutation', variables);
    },
    ResetOrganizationInvite(variables: ResetOrganizationInviteMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ResetOrganizationInviteMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ResetOrganizationInviteMutation>(ResetOrganizationInviteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ResetOrganizationInvite', 'mutation', variables);
    },
    RestoreUser(variables: RestoreUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RestoreUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RestoreUserMutation>(RestoreUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RestoreUser', 'mutation', variables);
    },
    NotificationPreferences(variables: NotificationPreferencesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<NotificationPreferencesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<NotificationPreferencesQuery>(NotificationPreferencesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'NotificationPreferences', 'query', variables);
    },
    CreateUserWebPushSubscription(variables: CreateUserWebPushSubscriptionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateUserWebPushSubscriptionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateUserWebPushSubscriptionMutation>(CreateUserWebPushSubscriptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateUserWebPushSubscription', 'mutation', variables);
    },
    UpdateUserNotificationPreferences(variables: UpdateUserNotificationPreferencesMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateUserNotificationPreferencesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateUserNotificationPreferencesMutation>(UpdateUserNotificationPreferencesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateUserNotificationPreferences', 'mutation', variables);
    },
    UpdateNewMemberIntroductionMessage(variables: UpdateNewMemberIntroductionMessageMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateNewMemberIntroductionMessageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateNewMemberIntroductionMessageMutation>(UpdateNewMemberIntroductionMessageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateNewMemberIntroductionMessage', 'mutation', variables);
    },
    ToggleNewMemberIntroductions(variables: ToggleNewMemberIntroductionsMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ToggleNewMemberIntroductionsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ToggleNewMemberIntroductionsMutation>(ToggleNewMemberIntroductionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ToggleNewMemberIntroductions', 'mutation', variables);
    },
    ToggleAutoMessageNewMembers(variables: ToggleAutoMessageNewMembersMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ToggleAutoMessageNewMembersMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ToggleAutoMessageNewMembersMutation>(ToggleAutoMessageNewMembersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ToggleAutoMessageNewMembers', 'mutation', variables);
    },
    UpdateAutoMessageNewMembersMessage(variables: UpdateAutoMessageNewMembersMessageMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateAutoMessageNewMembersMessageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateAutoMessageNewMembersMessageMutation>(UpdateAutoMessageNewMembersMessageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateAutoMessageNewMembersMessage', 'mutation', variables);
    },
    TogglePublicCommunity(variables: TogglePublicCommunityMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<TogglePublicCommunityMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<TogglePublicCommunityMutation>(TogglePublicCommunityDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'TogglePublicCommunity', 'mutation', variables);
    },
    CheckIfWidgetIsDetected(variables: CheckIfWidgetIsDetectedQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CheckIfWidgetIsDetectedQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CheckIfWidgetIsDetectedQuery>(CheckIfWidgetIsDetectedDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CheckIfWidgetIsDetected', 'query', variables);
    },
    UpdateWidgetSizeVariant(variables: UpdateWidgetSizeVariantMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateWidgetSizeVariantMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateWidgetSizeVariantMutation>(UpdateWidgetSizeVariantDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateWidgetSizeVariant', 'mutation', variables);
    },
    DismissFrondIntroPost(variables: DismissFrondIntroPostMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DismissFrondIntroPostMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DismissFrondIntroPostMutation>(DismissFrondIntroPostDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DismissFrondIntroPost', 'mutation', variables);
    },
    ToggleOrganizationFeature(variables: ToggleOrganizationFeatureMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ToggleOrganizationFeatureMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ToggleOrganizationFeatureMutation>(ToggleOrganizationFeatureDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ToggleOrganizationFeature', 'mutation', variables);
    },
    CreateVideoPresignedUpload(variables: CreateVideoPresignedUploadMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateVideoPresignedUploadMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateVideoPresignedUploadMutation>(CreateVideoPresignedUploadDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateVideoPresignedUpload', 'mutation', variables);
    },
    CreateTemporaryVideoPresignedUpload(variables: CreateTemporaryVideoPresignedUploadMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateTemporaryVideoPresignedUploadMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateTemporaryVideoPresignedUploadMutation>(CreateTemporaryVideoPresignedUploadDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateTemporaryVideoPresignedUpload', 'mutation', variables);
    },
    VideoStatus(variables: VideoStatusQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<VideoStatusQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VideoStatusQuery>(VideoStatusDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'VideoStatus', 'query', variables);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;