import { MyValue, routes } from '@frond/shared';
import styled, { th, x } from '@xstyled/styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { PropsWithChildren, useState } from 'react';
import { Node } from 'slate';

import { UserPreviewFragment } from '../../../../../generated/types-and-hooks';
import { useOrganization } from '../../../auth/hooks/useOrganization';
import {
  PostMediaResources,
  PostMediaResourcesProps,
} from '../../../posts/components/PostMediaResources';
import analytics from '../../utils/analytics';
import { Avatar } from '../Avatar';
import { ComposerBody } from '../Composer';
import { NextLink } from '../NextLink';
import { AddReactionButton, Reactions, ReactionsProps } from '../Reactions';
import { Text } from '../Text';
import { TimeAgo } from '../TimeAgo';
import { UserCommunityRoles } from '../UserCommunityRoles';
import { CommentCellActionButton } from './CommentCellActionButton';
import { CommentComposer, CommentInputSubmitValue } from './CommentComposer';

const fadeAnimationProps = {
  initial: { opacity: 1, transform: 'translateY(0px)' },
  animate: { opacity: 1, transform: `translateY(-16px)` },
  exit: { opacity: 0, transform: 'translateY(0px)' },
  transition: { duration: 0.15, ease: 'easeInOut' },
};

export const CommentComposerWrapper = styled.divBox`
  word-break: break-word;

  span,
  p,
  code,
  i,
  strong,
  u,
  strike {
    ${th('typographyStyles.text.md-s')}
  }

  pre {
    background-color: white;
  }
`;

export const CommentLayout: React.FC<
  PropsWithChildren &
    ReactionsProps &
    PostMediaResourcesProps & {
      displayName?: string;
      displayTimestamp?: boolean;
      createdAt: Date;
      editable?: boolean;
      showRoles?: boolean;
      user: UserPreviewFragment;
      onClickDelete: () => void;
      onClickBestAnswer?: () => void;
      onSubmit: (value: CommentInputSubmitValue) => void;
      isSubmitting?: boolean;
      content?: MyValue;
      highlight?: boolean;
    }
> = ({
  displayName,
  displayTimestamp,
  createdAt,
  editable,
  showRoles = true,
  user,
  media,
  resources,
  reactions,
  numMediaLoading,
  numResourcesLoading,
  onReactionClick,
  onClickDelete,
  onClickBestAnswer,
  onSubmit,
  isSubmitting,
  colorVariant,
  variant,
  content,
  highlight,
}) => {
  const { organization } = useOrganization();
  const [isEditing, setIsEditing] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [pickerIsOpen, setPickerIsOpen] = useState(false);

  const pickerOrMenuIsOpen = pickerIsOpen || menuIsOpen;

  const hasMedia = media && media.length > 0;
  const hasResources = resources && resources.length > 0;

  const commentHasContent =
    !!content && content.map((n) => Node.string(n)).join().length > 0;

  const handleSubmit = (value: CommentInputSubmitValue) => {
    onSubmit(value);
    setIsEditing(false);
  };

  const commentElement = isEditing ? (
    <CommentComposer
      variant="edit"
      onSubmit={handleSubmit}
      onCancel={() => setIsEditing(false)}
      content={content}
      initialMedia={media}
      initialResources={resources}
      isSubmitting={isSubmitting}
    />
  ) : commentHasContent ? (
    <CommentComposerWrapper display="inline-block">
      <ComposerBody content={content} trimOuterWhitespace />
    </CommentComposerWrapper>
  ) : null;

  return (
    <x.div
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      position="relative"
    >
      <AnimatePresence>
        {(!isEditing && isHovering) || pickerOrMenuIsOpen ? (
          <motion.div {...fadeAnimationProps}>
            <x.div display="flex" right={24} position="absolute" spaceX={2}>
              {editable && (
                <CommentCellActionButton
                  onOpenChange={setMenuIsOpen}
                  onClickDelete={onClickDelete}
                  onClickEdit={() => setIsEditing(true)}
                  onClickBestAnswer={onClickBestAnswer}
                  highlight={highlight}
                  user={user}
                />
              )}
              <AddReactionButton
                variant="secondary"
                onReactionSelected={onReactionClick}
                boxShadow="sm"
                onTogglePicker={(open) => {
                  setPickerIsOpen(open);
                }}
                h={7}
                w={7}
              />
            </x.div>
          </motion.div>
        ) : null}
      </AnimatePresence>
      <x.div
        display="flex"
        alignItems="flex-start"
        bg={{
          _:
            (!isEditing && isHovering) || pickerOrMenuIsOpen
              ? 'brand.50'
              : undefined,
          hover: isEditing ? undefined : 'brand.50',
        }}
        transition
        transitionDuration="fast"
        transitionTimingFunction="ease-in-out"
        p={2}
        px={5}
        spaceX={{ _: 2, sm: 4 }}
        {...(highlight && {
          bg: 'brand.50',
          borderLeft: '4px solid',
          borderColor: 'brand.300',
        })}
      >
        {!isEditing ? (
          <NextLink
            href={routes.groups
              .organization(organization.shortId)
              .person(user.username)}
            metadata={{
              event: analytics.events.PROFILE_PAGE_LINK,
              context: 'comment',
            }}
          >
            <x.div
              display={{
                _: 'none',
                sm: 'block',
              }}
            >
              <Avatar
                size="m-1"
                imageId={user.profileImageId}
                avatar={user.avatar}
                bgColor={user.avatarBgColor}
              />
            </x.div>
            <x.div
              display={{
                _: 'block',
                sm: 'none',
              }}
            >
              <Avatar
                size="s-2"
                imageId={user.profileImageId}
                avatar={user.avatar}
                bgColor={user.avatarBgColor}
              />
            </x.div>
          </NextLink>
        ) : null}

        <x.div flexGrow={1} minWidth={0}>
          {!isEditing && (
            <>
              <x.div
                display="flex"
                flexDirection="row"
                alignItems="center"
                spaceX={2}
              >
                <NextLink
                  href={routes.groups
                    .organization(organization.shortId)
                    .person(user.username)}
                  metadata={{
                    event: analytics.events.PROFILE_PAGE_LINK,
                    context: 'comment',
                  }}
                  styleInheritColor
                >
                  <Text
                    variant="md-s-semibold"
                    as="span"
                    color={{ _: 'gray.500', hover: 'brand.300' }}
                    mb={1}
                  >
                    {displayName || user?.firstName || user?.username}
                  </Text>
                </NextLink>
                {showRoles && (
                  <UserCommunityRoles user={user} limit={1} variant="small" />
                )}
              </x.div>

              {displayTimestamp && (
                <Text
                  display={{
                    _: 'block',
                    sm: 'none',
                  }}
                  whiteSpace="nowrap"
                  variant="sm"
                  color="gray.300"
                  mb={2}
                >
                  <TimeAgo date={createdAt} />
                </Text>
              )}
            </>
          )}
          <x.div mt={1} spaceY={3}>
            {commentElement}
            {!isEditing && (
              <PostMediaResources
                media={media}
                resources={resources}
                colorVariant={colorVariant}
                numMediaLoading={numMediaLoading}
                numResourcesLoading={numResourcesLoading}
              />
            )}
          </x.div>

          {displayTimestamp && !isEditing && (
            <Text
              display={{
                _: 'none',
                sm: 'block',
              }}
              whiteSpace="nowrap"
              variant="sm"
              color="gray.300"
              mt={hasMedia || hasResources ? 3 : 1}
            >
              <TimeAgo date={createdAt} />
            </Text>
          )}
          {reactions && reactions.length > 0 ? (
            <x.div mt={2}>
              <Reactions
                onReactionClick={onReactionClick}
                reactions={reactions}
                showAddReactionButton
                variant={variant}
              />
            </x.div>
          ) : null}
        </x.div>
      </x.div>
    </x.div>
  );
};
